<dialog id="{{ id }}" class="modal">
    <div class="modal-box">
        <div class="mx-auto flex items-center justify-center">
            <img
                alt="Green smiley logo"
                class="mr-5 h-11 w-11"
                ngSrc="/assets/svg/face-green.svg"
                [width]="44"
                [height]="44"
            />
            <h3 class="text-center text-2xl font-semibold">
                Success, your message has been delivered
            </h3>
            <img
                alt="Green smiley logo"
                class="ml-5 h-11 w-11 text-center"
                ngSrc="/assets/svg/face-green.svg"
                [width]="44"
                [height]="44"
            />
        </div>
        <p class="mx-auto my-6 block text-center">Thank you for your query.</p>
        <button
            (click)="handleContinue()"
            class="btn btn-primary block w-full rounded-full text-off-white"
            >Continue</button
        >
    </div>
</dialog>
