<main class="account-container relative flex flex-col p-5">
    <app-account-header></app-account-header>
    <section>
        <h2 class="mb-5 text-xl font-semibold">Information</h2>
        <div class="flex justify-between border-b pb-4">
            <p class="">Full name</p>
            <p class="font-semibold">{{ user?.full_name ?? user?.first_name }}</p>
        </div>
        <div class="mt-4 flex justify-between border-b pb-4">
            <p class="">Email</p>
            <p class="font-semibold">{{ user?.email }}</p>
        </div>
        <div class="mt-4 flex justify-between border-b pb-4">
            <p class="">Password</p>
            <p class="font-semibold">*******</p>
        </div>
        <div class="mt-4 flex justify-between border-b pb-4">
            <p class="">Receive marketing emails?</p>
            <app-shimmer-loader [loading]="!user">
                <p class="font-semibold">{{ user?.user_detail?.email_opt_in ? "Yes" : "no" }}</p>
            </app-shimmer-loader>
        </div>
        <a
            [routerLink]="routeNames.account.editAccount.fullPath()"
            class="btn btn-outline btn-primary mb-16 mt-8 w-full rounded-full uppercase"
        >
            Edit
        </a>
    </section>

    <section>
        <h2 class="mb-5 text-xl font-semibold">VIP Membership</h2>
        <app-membership-status *ngIf="!!user" [user]="user!"></app-membership-status>
        <a
            [routerLink]="routeNames.account.editMembership.fullPath()"
            [replaceUrl]="true"
            class="btn btn-outline btn-primary mt-8 w-full rounded-full uppercase"
        >
            {{ user?.user_detail?.banking_paired ? "Edit" : "Connect" }}
        </a>
    </section>
    <button (click)="logOut()" class="btn btn-outline btn-error mt-[72px] rounded-full uppercase">
        sign out
    </button>
    <button
        class="btn btn-outline btn-error mb-5 mt-4 rounded-full uppercase"
        (click)="showDeleteConfirmModal()"
    >
        deactivate account
    </button>
    <dialog id="delete-account-modal" class="modal">
        <div class="modal-box relative flex flex-col rounded-3xl">
            <button class="absolute right-5 top-5 text-2xl" (click)="closeModal()">
                <fa-icon [icon]="faClose"></fa-icon>
            </button>
            <h3 class="text-xl font-semibold">Are you sure you want to deactivate your account?</h3>
            <p class="mt-3.5 text-md"> We’re sorry to see you go! </p>
            <div class="mt-10 flex gap-2.5">
                <button
                    class="btn btn-primary flex-1 rounded-full text-white"
                    (click)="closeModal()"
                >
                    BACK
                </button>
                <button
                    class="btn btn-error flex-1 rounded-full uppercase text-white"
                    (click)="deleteAccount()"
                >
                    <ng-container *ngIf="!loading">deactivate</ng-container>
                    <span *ngIf="loading" class="loading loading-spinner loading-md"></span>
                </button>
            </div>
        </div>
        <form method="dialog" class="modal-backdrop">
            <button>Close</button>
        </form>
    </dialog>
</main>
