<div class="lg:hidden bg-black text-white font-medium grid grid-cols-3 px-1 mb-3.5 h-14">
    <a [href]="mobileSecondaryNavLocation('bundle')" class="flex flex-col justify-around text-center">Phone deals</a>
    <a [href]="mobileSecondaryNavLocation('phone-only')" class="flex flex-col justify-around text-center -mr-6">SIM-free phones</a>
    <a [href]="mobileSecondaryNavLocation('sim-only')" class="flex flex-col justify-around text-center">SIMs</a>
</div>

<section class="container mb-5">
    <app-breadcrumbs class="mt-1.5 block" [breadcrumbs]="breadcrumbs"></app-breadcrumbs>
    <div class="mb-4 mt-7 ">
        <h1 class="font-display font-bold text-4xl md:text-5xl"> {{ currentPageTitle }}</h1>

        <p class="mt-4" *ngIf="this.dealType === DealType.bundle">
            Compare our best {{ this.phoneTitle }} deals -  updated 2X per day - with full credit check info on every deal.
        </p>

        <p class="mt-4" *ngIf="this.dealType === DealType.phoneOnly">
            Compare our best {{ this.phoneTitle }} SIM free deals -  updated 2X per day - with full credit check info on every deal.
        </p>

        <!--
        <p class="mt-4" *ngIf="this.dealType === DealType.simOnly">
            We have lots of lovely "no credit check" SIM deals, also have a look for Boschhh SIMs that build your Equifax credit score.
        </p>
        -->
    </div>

    <div
        class="mb-3.5 flex "
        [ngClass]="{ 'justify-between': showFilters, 'place-content-end': !showFilters }"
        *ngIf="!isMobile"
    >
        <app-phone-select-tags
            [dealType]="dealTypePhoneSelectTags"
            [colourOptions]="colourOptions"
            [dealsLoading]="loading()"
            *ngIf="showFilters"
        ></app-phone-select-tags>

        <div
            class="flex items-center"
        >
            <p class="mr-4 pr-4 text-md lg:ml-auto lg:border-r">{{ dealCountContent }}</p>
            <deals-order-dropdown [dealType]="dealType"></deals-order-dropdown>
        </div>
    </div>

    <div class="grid grid-cols-5 mb-4" *ngIf="activeFilters.length > 0">
        <ul class="col-span-4 hidden lg:flex lg:flex-wrap lg:gap-2">
            <li *ngFor="let filter of activeFilters">
                <button
                    (click)="removeFilter(filter)"
                    class="flex h-auto min-h-0 items-center justify-center rounded-full border border-black px-4 py-2
                    font-normal text-black"
                >
                    {{ filter.selected.label }}
                    <fa-icon class="ml-2 text-xl" [icon]="faTimes"></fa-icon>
                </button>
            </li>
        </ul>
        <button
            *ngIf="hasQueryParams()"
            class="col-span-1 justify-self-end hidden text-md text-black underline lg:block"
            (click)="clearFilters(dealTypeId ?? 1)"
        >
            Clear all filters
        </button
        >
    </div>

    <section class="flex">
        <aside class="block" *ngIf="!isMobile">
            <app-desktop-filter class="block lg:mr-7" [dealType]="dealType"></app-desktop-filter>
        </aside>
        <div class="mx-auto flex-1">
            <ng-container *ngIf="isMobile">
                <app-phone-select-tags
                    [dealType]="dealTypePhoneSelectTags"
                    [colourOptions]="colourOptions"
                    [dealsLoading]="loading()"
                    *ngIf="showFilters"
                ></app-phone-select-tags>
                <app-credit-toggle
                    [dealType]="dealType"
                    class="block my-3.5"
                ></app-credit-toggle>
                <app-filter-dialog
                    [dealTypeId]="dealTypeId?.toString()"
                    [dealType]="dealType"
                    *ngIf="isMobile"
                ></app-filter-dialog>
                <p class="pt-3.5 text-md lg:ml-auto">{{ dealCountContent }}</p>
            </ng-container>
            <section class="mt-3.5 lg:mt-0 lg:mb-10">
                <ng-container *ngIf="hasDeals">
                    <p class="mt-10 w-full py-5 text-center text-xl lg:text-3xl">No deals found</p>
                </ng-container>
                <ul
                    class="mx-auto flex flex-row flex-wrap items-start justify-center gap-7 xl:w-full xl:flex-col xl:items-stretch"
                >
                    <li *ngFor="let deal of this.deals" class="w-full max-w-[350px] xl:max-w-full">
                        <ng-container *ngIf="dealType === 'sim-only'; else phoneCard">
                            <app-sim-card
                                class="block w-full xl:hidden"
                                [showExtraInfo]="true"
                                [deal]="deal"
                            ></app-sim-card>

                            <app-desktop-deal-list-item
                                [dealType]="dealType"
                                [deal]="deal"
                                class="hidden xl:block"
                            ></app-desktop-deal-list-item>
                        </ng-container>
                        <ng-template #phoneCard>
                            <app-phone-card-nav
                                [dealType]="dealType"
                                class="block xl:hidden"
                                [deal]="deal"
                            ></app-phone-card-nav>
                            <app-desktop-phone-deal-list-item
                                [dealType]="dealType"
                                [deal]="deal"
                                class="hidden xl:block"
                            ></app-desktop-phone-deal-list-item>
                        </ng-template>
                    </li>

                    <ng-container
                        *ngIf="
                            (dealState.status() === HttpStatus.loading || loading()) &&
                            dealState.deals().length === 0
                        "
                    >
                        <app-loading></app-loading>
                    </ng-container>
                    <ng-container
                        *ngIf="
                            dealState.status() === HttpStatus.loading &&
                            dealState.deals().length > 0
                        "
                    >
                        <li *ngFor="let _ of Array(3)">
                            <ng-container *ngIf="dealType !== 'sim-only'; else simLoading">
                                <app-desktop-phone-deal-list-item
                                    [dealType]="dealType"
                                    [loading]="true"
                                    class="hidden xl:block"
                                ></app-desktop-phone-deal-list-item>
                                <app-phone-card-nav
                                    [dealType]="dealType"
                                    class="block xl:hidden"
                                    [loading]="true"
                                ></app-phone-card-nav>
                            </ng-container>
                            <ng-template #simLoading>
                                <app-sim-card
                                    class="block xl:hidden"
                                    [loading]="true"
                                ></app-sim-card>

                                <app-desktop-deal-list-item
                                    [dealType]="dealType"
                                    class="hidden xl:block"
                                ></app-desktop-deal-list-item>
                            </ng-template>
                        </li>
                    </ng-container>
                </ul>
                <button
                    *ngIf="this.dealState.hasNextPage()"
                    (click)="loadMore()"
                    class="btn btn-outline btn-primary mt-9 w-full rounded-full uppercase lg:w-[280px]"
                    >load more</button
                >
            </section>
        </div>
    </section>
</section>
