import { Component } from '@angular/core';

@Component({
  selector: 'app-paper-plane-svg',
  standalone: true,
  imports: [],
  templateUrl: './paper-plane-svg.component.html',
  styleUrl: './paper-plane-svg.component.scss'
})
export class PaperPlaneSvgComponent {

}
