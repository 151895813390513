<svg
    [attr.height]="height"
    viewBox="0 0 152 30"
    [attr.fill]="fill"
    xmlns="http://www.w3.org/2000/svg"
>
    <g id="Group 1437253963">
        <path
            id="Vector"
            d="M151.938 12.7977C151.938 16.4734 150.22 19.6369 147.479 21.535L149.919 25.7531H145.159L143.682 23.2222C142.809 23.4331 141.845 23.5537 140.88 23.5537C134.584 23.5537 129.823 18.9138 129.823 12.7977C129.823 6.68162 134.584 2.04181 140.88 2.04181C147.177 2.04181 151.938 6.68162 151.938 12.7977ZM134.523 12.7977C134.523 15.7202 137.144 17.9196 140.639 18.01L138.35 14.0631H143.11L144.797 16.9555C146.304 16.0215 147.238 14.5452 147.238 12.7977C147.238 9.815 144.496 7.58548 140.88 7.58548C137.265 7.58548 134.523 9.815 134.523 12.7977Z"
            [attr.fill]="fill"
        />
        <path
            id="Vector_2"
            d="M122.805 2.40344H127.354V23.1922H122.805V15.5998H113.887V23.1922H109.337V2.40344H113.887V9.87535H122.805V2.40344Z"
            [attr.fill]="fill"
        />
        <path
            id="Vector_3"
            d="M93.785 7.46539C97.1895 7.46539 99.4492 9.69491 99.4492 13.0994V23.1925H95.2312V14.9373C95.2312 13.5815 93.9357 12.7078 91.9472 12.7078C89.8382 12.7078 88.4221 13.6116 88.4221 14.9976V23.1925H84.2041V7.82694H88.4221V10.8398C89.6574 8.61028 91.4952 7.46539 93.785 7.46539Z"
            [attr.fill]="fill"
        />
        <path
            id="Vector_4"
            d="M79.0765 6.38106C77.2085 6.38106 75.7925 5.14578 75.7925 3.51884C75.7925 1.89189 77.2085 0.656616 79.0765 0.656616C80.9445 0.656616 82.3605 1.89189 82.3605 3.51884C82.3605 5.14578 80.9445 6.38106 79.0765 6.38106ZM76.9675 23.1928V7.82723H81.1855V23.1928H76.9675Z"
            [attr.fill]="fill"
        />
        <path
            id="Vector_5"
            d="M69.7933 7.82694H74.0113V21.5355C74.0113 26.2054 70.8478 29.2785 66.0574 29.2785C61.99 29.2785 59.0976 26.9888 58.2239 23.3131H62.5926C63.1951 23.9458 64.5811 24.3374 66.0574 24.3374C68.317 24.3374 69.7933 23.2227 69.7933 21.5053V20.029C68.7087 21.8066 66.9311 22.8913 64.7317 22.8913C60.574 22.8913 57.4707 19.5771 57.4707 15.1783C57.4707 10.7795 60.574 7.46539 64.7317 7.46539C66.9311 7.46539 68.7087 8.55002 69.7933 10.3276V7.82694ZM65.8163 17.9803C68.1061 17.9803 69.7933 16.7751 69.7933 15.1783C69.7933 13.5815 68.1061 12.3764 65.8163 12.3764C63.5266 12.3764 61.8394 13.5815 61.8394 15.1783C61.8394 16.7751 63.5266 17.9803 65.8163 17.9803Z"
            [attr.fill]="fill"
        />
        <path
            id="Vector_6"
            d="M51.1092 7.82694H55.3272V21.5355C55.3272 26.2054 52.1637 29.2785 47.3733 29.2785C43.3059 29.2785 40.4136 26.9888 39.5398 23.3131H43.9085C44.5111 23.9458 45.897 24.3374 47.3733 24.3374C49.6329 24.3374 51.1092 23.2227 51.1092 21.5053V20.029C50.0246 21.8066 48.247 22.8913 46.0476 22.8913C41.8899 22.8913 38.7866 19.5771 38.7866 15.1783C38.7866 10.7795 41.8899 7.46539 46.0476 7.46539C48.247 7.46539 50.0246 8.55002 51.1092 10.3276V7.82694ZM47.1323 17.9803C49.422 17.9803 51.1092 16.7751 51.1092 15.1783C51.1092 13.5815 49.422 12.3764 47.1323 12.3764C44.8425 12.3764 43.1553 13.5815 43.1553 15.1783C43.1553 16.7751 44.8425 17.9803 47.1323 17.9803Z"
            [attr.fill]="fill"
        />
        <path
            id="Vector_7"
            d="M29.124 23.5541C24.3636 23.5541 20.7783 20.0893 20.7783 15.5097C20.7783 10.9302 24.3636 7.46539 29.124 7.46539C33.8843 7.46539 37.4696 10.9302 37.4696 15.5097C37.4696 20.0893 33.8843 23.5541 29.124 23.5541ZM29.124 18.3117C31.4137 18.3117 33.1009 17.1066 33.1009 15.5097C33.1009 13.9129 31.4137 12.7078 29.124 12.7078C26.8342 12.7078 25.147 13.9129 25.147 15.5097C25.147 17.1066 26.8342 18.3117 29.124 18.3117Z"
            [attr.fill]="fill"
        />
        <path
            id="Vector_8"
            d="M0.0617676 23.1922V2.40344H5.06312L13.3485 13.9427H13.8908V2.40344H18.4402V23.1922H13.4389L5.15351 11.6529H4.61119V23.1922H0.0617676Z"
            [attr.fill]="fill"
        />
    </g>
</svg>
