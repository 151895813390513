import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, effect, EffectRef, OnDestroy, OnInit } from '@angular/core';
import { Router, RouterLinkWithHref } from '@angular/router';
import { routeNames } from 'src/app/app-routing.module';
import { NogginPhoneTileComponent } from 'src/app/noggin-phone/noggin-phone-tile/noggin-phone-tile.component';
import { BankingConnectComponent } from 'src/app/user/components/banking-connect/banking-connect.component';
import { BankingState } from 'src/app/user/state/banking.state';

@Component({
    selector: 'app-share-open-banking',
    standalone: true,
    imports: [
        CommonModule,
        RouterLinkWithHref,
        NgOptimizedImage,
        NogginPhoneTileComponent,
        BankingConnectComponent
    ],
    templateUrl: './share-open-banking.component.html',
    styleUrl: './share-open-banking.component.scss'
})
export class ShareOpenBankingComponent implements OnInit, OnDestroy {
    onConnectSideEffect: EffectRef;
    openItems: string[] = [];

    constructor(
        public bankingState: BankingState,
        private router: Router
    ) {
        gtag('event', 'share_open_banking__page_load');
        this.onConnectSideEffect = effect(() => {
            if (this.bankingState.connected()) {
                gtag('event', 'share_open_banking__connected');
                this.router.navigateByUrl(routeNames.getStarted.finishMyApplication.fullPath())
            }
        });
    }

    async ngOnInit() {
        await this.bankingState.init();
    }

    ngOnDestroy(): void {
        this.onConnectSideEffect.destroy();
    }

    get bulletPoints() {
        return [
            {
                header: "Why do we ask this?",
                text: "We use your digital bank statement to check your affordability. Please share the account you get paid into as we use this instead of your credit score.",
            },
            {
                header: "Is it safe to share my data?",
                text: "Yes. Our Open Banking provider is authorised by the Financial Conduct Authority. Noggin is also GDPR compliant and does not store your account number, sort code or banking logins.",
            },
        ];
    }

    toggle(id: string) {
        if (this.openItems.includes(id)) {
            this.openItems = this.openItems.filter((item) => item != id);
        } else {
            this.openItems.push(id);
        }
    }

}
