<div class="account-container">
    <button
        (click)="back()"
        class="bold mb-6 mr-auto flex items-center justify-center text-[14px] uppercase text-electric-blue h-8"
    >
        <fa-icon class="mr-2.5" [icon]="faChevronLeft"></fa-icon>
        Back
    </button>

    <section class="mb-4">
        <h1 class="text-3xl font-semibold">Choose your phone </h1>
    </section>
</div>

<div class="overflow-hidden mx-auto">
    <app-carousel class="mx-auto" [dark]="false">

        <ng-container *ngIf="loading(); else mainComponent">
            <app-carousel-item>
                <app-phone-card
                    [dealType]="dealType"
                    [loading]="true"
                    [showEstimatedTotalDescText]="false"
                    [showCreditCheckDescText]="false"
                ></app-phone-card>
            </app-carousel-item>
            <app-carousel-item>
                <app-phone-card
                    [dealType]="dealType"
                    [loading]="true"
                    [showEstimatedTotalDescText]="false"
                    [showCreditCheckDescText]="false"
                ></app-phone-card>
            </app-carousel-item>
            <app-carousel-item>
                <app-phone-card
                    [dealType]="dealType"
                    [loading]="true"
                    [showEstimatedTotalDescText]="false"
                    [showCreditCheckDescText]="false"
                ></app-phone-card>
            </app-carousel-item>
        </ng-container>

        <ng-template #mainComponent>
            <app-carousel-item *ngFor="for let deal of deals">
                <button
                    id="button-deal-{{deal.id}}"
                    (click)="selectDeal(deal)"
                >
                    <app-phone-card
                        [dealType]="dealType"
                        [deal]="deal"
                        [loading]="loading()"
                    ></app-phone-card>
                </button>
            </app-carousel-item>
        </ng-template>

    </app-carousel>
</div>

<section class="flex my-8 mx-auto max-w-lg" nomargin>
    <app-noggin-phone-tile
        caption="Why are we doing this?"
        heading="5.8 million people in the UK are excluded from credit!"
        text="We are dedicated to bringing a solution for people unfairly locked out of the current system."
    ></app-noggin-phone-tile>
</section>
