import {
    AfterViewInit,
    Component,
    ContentChildren,
    ElementRef,
    HostBinding,
    Input,
    OnDestroy,
    QueryList,
    signal,
    SimpleChanges,
    ViewChild,
} from "@angular/core";
import { interval } from "rxjs";
import KeenSlider, { KeenSliderInstance } from "keen-slider";
import { NgClass, NgForOf, NgIf, NgOptimizedImage } from "@angular/common";
import { FaIconComponent } from "@fortawesome/angular-fontawesome";
import { NgIcon } from "@ng-icons/core";
import { RouterLink } from "@angular/router";
import { dealFilterToQueryParams } from "../../../deal/state/deal-filter.state";
import { DealFilterParams } from "../../../deal/models/filter";
import { ContentObserver } from "@angular/cdk/observers";

export type CarouselButtonConfig = {
    text: string;
    link: string;
    params?: Partial<DealFilterParams>;
};

@Component({
    selector: "app-carousel",
    standalone: true,
    imports: [NgForOf, NgClass, FaIconComponent, NgIcon, NgOptimizedImage, NgIf, RouterLink],
    templateUrl: "./carousel.component.html",
    styleUrl: "./carousel.component.scss",
})
export class CarouselComponent implements AfterViewInit, OnDestroy {
    @Input({ required: true }) dark = false;
    @Input() subtitle?: string;
    @Input() buttonConfig?: CarouselButtonConfig;
    @Input() title?: string;
    @Input() dotActiveClass = "bg-pink-100";
    @Input("class") @HostBinding("class") className = "";

    @ViewChild("sliderRef") sliderRef!: ElementRef<HTMLElement>;

    slider: KeenSliderInstance | null = null;
    activeDot = signal<number>(0);
    dotHelper = signal<number[]>([]);
    options = {
        slides: { perView: 1 },
        initial: 0,
        optionsChanged: this.setUpDots.bind(this),
        slideChanged: () => {
            this.calcActiveDot();
        },
        breakpoints: {
            "(min-width: 900px)": {
                slides: { perView: 2, spacing: 10 },
            },
            "(min-width: 1380px)": {
                slides: { perView: 3, spacing: 10 },
            },
        },
    };

    constructor(private obs: ContentObserver) {}

    ngOnDestroy() {
        if (this.slider) this.slider.destroy();
    }

    ngAfterViewInit() {
        this.setupKeenSlider();
        this.obs.observe(this.sliderRef.nativeElement).subscribe(() => {
            this.activeDot.set(0);
            this.slider?.update(this.options, 0);
        });
    }

    setupKeenSlider() {
        this.slider = new KeenSlider(this.sliderRef.nativeElement, this.options);
        this.setUpDots();
    }

    setUpDots() {
        const slideOptions = this.slider?.options.slides as { perView: number } | null;
        if (slideOptions && slideOptions.perView) {
            const numOfDots = Math.ceil(
                (this.slider?.track.details.slides.length ?? 0) / slideOptions.perView,
            );
            this.dotHelper.set(
                Array.from(Array(numOfDots).keys()).map((num) => num * slideOptions.perView),
            );
        } else {
            this.dotHelper.set([...Array(this.slider?.track.details.slides.length).keys()]);
        }
        this.calcActiveDot();
    }

    moveToSlide(slide: number) {
        const slideOptions = this.slider?.options.slides as { perView: number } | null;
        let slideOffset = 0;
        if (slideOptions && slideOptions.perView) {
            const length = this.slider?.track.details.slides.length ?? 0;
            const r = length % slideOptions.perView;
            if (r !== 0 && slide >= length - r) {
                slideOffset = slideOptions.perView - r;
            }
        }
        this.slider?.moveToIdx(slide - slideOffset);
    }

    calcActiveDot() {
        for (let i = 0; i < this.dotHelper().length; i++) {
            if (this.isSlideActive(this.dotHelper()[i], this.slider?.track.details.rel ?? 0)) {
                this.activeDot.set(this.dotHelper()[i]);
                break;
            }
        }
    }

    isSlideActive(dotNum: number, currentSlide: number): boolean {
        const slideOptions = this.slider?.options.slides as { perView: number } | null;
        if (slideOptions && slideOptions.perView) {
            const hasReachedEnd = currentSlide === this.slider?.track.details.maxIdx;
            if (hasReachedEnd) {
                return dotNum === this.dotHelper()[this.dotHelper().length - 1];
            }
            return currentSlide >= dotNum && currentSlide < dotNum + slideOptions.perView;
        }
        return currentSlide === dotNum;
    }

    get buttonQueryParams() {
        if (this.buttonConfig?.params) {
            return dealFilterToQueryParams(this.buttonConfig?.params ?? {});
        }
        return undefined;
    }
}
