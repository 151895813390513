import { Injectable, computed, signal } from "@angular/core";
import { Router } from "@angular/router";
import { PhonesService } from "../services/phones.service";
import { DealState } from "./deal.state";
import { Location } from "@angular/common";
import {
    conditionFilter,
    simContractLengthFilter,
    dataAllowanceFilter,
    DealFilterParams,
    dealOrdering,
    DealOrdering,
    DealOrderingParam,
    DealType,
    Filter,
    FilterOption,
    fiveGFilter,
    minutesFilter,
    payTodayFilter,
    phoneMonthlyCostFilter,
    simMonthlyCostFilter,
    textsFilter,
    dealContractLengthFilter,
} from "../models/filter";

export function dealFilterToQueryParams(
    filter: Partial<DealFilterParams>,
): Record<string, unknown> {
    const queryParams: Record<string, unknown> = {};
    for (const entry of Object.entries(filter)) {
        const key = entry[0] as keyof DealFilterParams;
        const value = entry[1];
        if (key === "deal_type" || key === "phone_model" || key === "phone_model_slug") {
            // deal_type will always be part of the page
            // phone_slug will always be in the url path
            continue;
        }
        if (Array.isArray(value)) {
            if (value.length > 0) {
                queryParams[key] = value.join(",");
            }
        } else {
            queryParams[key] = value.toString();
        }
    }
    return queryParams;
}

function parseNumberParam(param: string | undefined): number | undefined {
    if (!param) {
        return undefined;
    }
    const parsed = parseInt(param);
    if (isNaN(parsed)) {
        return undefined;
    }
    return parsed;
}

function parseBooleanParam(param: string | undefined): boolean | undefined {
    if (!param) {
        return undefined;
    }
    return param === "true";
}

function parseArrayParam(param: string | undefined): string[] | undefined {
    if (!param) {
        return undefined;
    }
    return param.split(",");
}

export function dealFilterFromParams(
    params: Partial<Record<keyof DealFilterParams, string>>,
    initialFilter?: Partial<DealFilterParams>,
): Partial<DealFilterParams> {
    const filter = initialFilter ?? {};

    const addToFilter = (
        obj: Record<string, unknown>,
        key: keyof DealFilterParams,
        value: unknown | undefined,
    ) => {
        if (value !== undefined && value !== null) {
            obj[key] = value;
        }
    };
    addToFilter(filter, "device_payment_type", params["device_payment_type"]);
    addToFilter(
        filter,
        "sim_data_allowance_gb_numeric",
        parseNumberParam(params["sim_data_allowance_gb_numeric"]),
    );
    addToFilter(
        filter,
        "sim_contract_length_months",
        parseNumberParam(params["sim_contract_length_months"]?.toString()),
    );
    addToFilter(filter, "sim_unlimited_data", parseBooleanParam(params["sim_unlimited_data"]));
    addToFilter(
        filter,
        "sim_monthly_cost_inc_vat_max",
        parseNumberParam(params["sim_monthly_cost_inc_vat_max"]),
    );
    addToFilter(
        filter,
        "deal_monthly_total_inc_vat_max",
        parseNumberParam(params["deal_monthly_total_inc_vat_max"]),
    );
    addToFilter(filter, "deal_connectivity", params["deal_connectivity"]);
    addToFilter(filter, "deal_is_vip", parseBooleanParam(params["deal_is_vip"]));
    addToFilter(
        filter,
        "sim_unlimited_minutes",
        parseBooleanParam(params["sim_unlimited_minutes"]),
    );
    addToFilter(filter, "ordering", params["ordering"] as DealOrderingParam);
    addToFilter(filter, "network_provider", parseArrayParam(params["network_provider"]));
    addToFilter(filter, "phone_colour", parseArrayParam(params["phone_colour"]));
    addToFilter(
        filter,
        "deal_pay_today_total_inc_vat_max",
        parseNumberParam(params["deal_pay_today_total_inc_vat_max"]),
    );
    addToFilter(filter, "storage_size", parseArrayParam(params["storage_size"]));

    return filter;
}

@Injectable()
export class DealFilterState {
    constructor(
        private phoneService: PhonesService,
        private router: Router,
        private dealState: DealState,
        private location: Location,
    ) { }

    loading = signal<boolean>(false);
    ordering = signal<DealOrdering>(dealOrdering(DealType.bundle)[0]);
    private _selectedFilters = signal<Partial<DealFilterParams>>({});

    get selectedFilters() {
        return this._selectedFilters();
    }

    set selectedFilters(filters: Partial<DealFilterParams>) {
        this._selectedFilters.set(filters);
    }

    filters = signal<Filter[]>([]);

    updateFilters(cb: (filters: Partial<DealFilterParams>) => Partial<DealFilterParams>) {
        this._selectedFilters.update(cb);
    }

    initSimOnlyFilters() {
        this.networkProviderOptions((networkProviderOptions) => {
            this.filters.set([
                simMonthlyCostFilter,
                dataAllowanceFilter,
                simContractLengthFilter,
                {
                    key: "network_provider",
                    title: "Provider",
                    type: "array",
                    options: networkProviderOptions,
                },
                minutesFilter,
                textsFilter,
                fiveGFilter,
            ]);
        });
    }

    initPhoneOnlyFilters() {
        this.filters.set([
            phoneMonthlyCostFilter,
            payTodayFilter,
            dealContractLengthFilter,
            conditionFilter,
        ]);
    }

    initBundleFilters() {
        this.loading.set(true);
        this.networkProviderOptions((networkProviderOptions) => {
            this.filters.set([
                phoneMonthlyCostFilter,
                dataAllowanceFilter,
                payTodayFilter,
                dealContractLengthFilter,
                {
                    key: "network_provider",
                    title: "Provider",
                    type: "array",
                    options: networkProviderOptions,
                },
                conditionFilter,
                minutesFilter,
                textsFilter,
                fiveGFilter,
            ]);
        });
    }

    private networkProviderOptions(cb: (options: FilterOption[]) => void) {
        this.loading.set(true);
        this.phoneService.getNetworkProviders().subscribe({
            next: (networkProviders) => {
                const networkProviderOptions: FilterOption[] = networkProviders.map(
                    (networkProvider) => {
                        return {
                            label: networkProvider.name,
                            value: networkProvider.id.toString(),
                        };
                    },
                );
                cb(networkProviderOptions);
            },
            complete: () => this.loading.set(false),
        });
    }

    togglePaymentType(
        onCredit: boolean,
        dealType: DealType,
        { updateAutomatically }: { updateAutomatically: boolean },
    ) {
        this._selectedFilters.update((selectedFilters) => {
            if (onCredit) {
                return {
                    ...selectedFilters,
                    device_payment_type: dealType === DealType.bundle ? "On credit" : "Lease",
                };
            }
            const newFilters = { ...selectedFilters };
            delete newFilters.device_payment_type;
            return newFilters;
        });
        if (updateAutomatically) {
            this.applyFilters();
        }
    }

    removeFilter({ filter, selected }: { filter: Filter; selected: FilterOption }) {
        this._selectedFilters.update((selectedFilters) => {
            if (filter.key === "sim_data_allowance_gb_numeric" && selected.value == "-1") {
                const newFilters = { ...selectedFilters };
                delete newFilters["sim_unlimited_data"];
                delete newFilters["sim_data_allowance_gb_numeric"];
                return newFilters;
            }
            if (selectedFilters[filter.key] && Array.isArray(selectedFilters[filter.key])) {
                const newFilters = {
                    ...selectedFilters,
                    [filter.key]: (selectedFilters[filter.key] as Array<string>).filter(
                        (v) => v !== selected.value,
                    ),
                };
                if ((newFilters[filter.key] as string[]).length === 0) {
                    delete newFilters[filter.key];
                }
                return newFilters;
            }
            const newFilters = { ...selectedFilters };
            delete newFilters[filter.key];
            return newFilters;
        });
        this.applyFilters();
    }

    removeFilterByKey(
        key: keyof DealFilterParams,
        {
            updateAutomatically = false
        }: { updateAutomatically: boolean },
    ) {
        this._selectedFilters.update(selectedFilters => {
            if (Object.hasOwn(selectedFilters, key)) {
                const newFilters = { ...selectedFilters };
                delete newFilters[key];
                return newFilters;
            }
            return selectedFilters;
        });

        if (updateAutomatically) {
            this.applyFilters();
        }
    }

    toggleDataAllowance(
        data: FilterOption,
        { updateAutomatically }: { updateAutomatically: boolean },
    ) {
        const value = parseInt(data.value.toString());
        if (value === -1) {
            this._selectedFilters.update((selectedFilters) => {
                const newFilters = { ...selectedFilters };
                if (selectedFilters.sim_unlimited_data) {
                    delete newFilters["sim_unlimited_data"];
                    return newFilters;
                }
                const filters: Partial<DealFilterParams> = {
                    ...selectedFilters,
                    sim_data_allowance_gb_numeric: undefined,
                    sim_unlimited_data: true,
                };
                delete filters["sim_data_allowance_gb_numeric"];
                return filters;
            });
        } else {
            this._selectedFilters.update((selectedFilters) => {
                const newFilters = { ...selectedFilters };
                if (newFilters.sim_data_allowance_gb_numeric === value) {
                    delete newFilters["sim_data_allowance_gb_numeric"];
                    return newFilters;
                }
                const filters: Partial<DealFilterParams> = {
                    ...selectedFilters,
                    sim_data_allowance_gb_numeric: value,
                };
                delete filters["sim_unlimited_data"];
                return filters;
            });
        }

        if (updateAutomatically) {
            this.applyFilters();
        }
    }

    toggleBooleanFilter(
        key: keyof DealFilterParams,
        {
            updateAutomatically,
            removeOnFalse = false,
        }: { updateAutomatically: boolean; removeOnFalse?: boolean },
    ) {
        this._selectedFilters.update((selectedFilters) => {
            // is_5g query param doesn't work, so we have to use a workaround, yay
            if (key === "deal_connectivity") {
                if (!selectedFilters.deal_connectivity) {
                    return { ...selectedFilters, deal_connectivity: "5G" };
                }
                const newFilters = { ...selectedFilters };
                delete newFilters["deal_connectivity"];
                return newFilters;
            }
            if (removeOnFalse && selectedFilters[key]) {
                const newFilters = { ...selectedFilters };
                delete newFilters[key];
                return newFilters;
            }
            return { ...selectedFilters, [key]: !selectedFilters[key] };
        });
        if (updateAutomatically) {
            this.applyFilters();
        }
    }

    setBooleanFilter(
        key: keyof DealFilterParams,
        value: boolean,
        {
            updateAutomatically,
            removeOnFalse = false
        }: { updateAutomatically: boolean; removeOnFalse?: boolean },
    ) {
        this._selectedFilters.update((selectedFilters) => {
            // is_5g query param doesn't work, so we have to use a workaround, yay
            if (key === "deal_connectivity") {
                if (value) {
                    return { ...selectedFilters, deal_connectivity: "5G" };
                }
                const newFilters = { ...selectedFilters };
                delete newFilters["deal_connectivity"];
                return newFilters;
            }

            if (removeOnFalse && !value) {
                const newFilters = { ...selectedFilters };
                delete newFilters[key];
                return newFilters;
            }

            return { ...selectedFilters, [key]: value };
        });


        if (updateAutomatically) {
            this.applyFilters();
        }
    }

    toggleArrayFilter(
        key: keyof DealFilterParams,
        filter: FilterOption,
        { updateAutomatically }: { updateAutomatically: boolean },
    ) {
        this._selectedFilters.update((selectedFilters) => {
            const index =
                (selectedFilters[key] as string[] | undefined)?.findIndex(
                    (f) => f === filter.value,
                ) ?? -1;
            if (index === -1) {
                return {
                    ...selectedFilters,
                    [key]: [...((selectedFilters[key] as string[]) ?? []), filter.value],
                };
            } else {
                const newFilters = {
                    ...selectedFilters,
                    [key]: (selectedFilters[key] as string[]).filter((_, i) => i !== index),
                };
                if ((newFilters[key] as string[]).length === 0) {
                    delete newFilters[key];
                }
                return newFilters;
            }
        });

        if (updateAutomatically) {
            this.applyFilters();
        }
    }

    toggleRadioFilter(
        key: keyof DealFilterParams,
        value: string | number | boolean,
        { updateAutomatically }: { updateAutomatically: boolean },
    ) {
        this._selectedFilters.update((selectedFilters) => {
            const newFilters = { ...selectedFilters };
            if (newFilters[key] === value) {
                delete newFilters[key];
                return newFilters;
            } else {
                return { ...selectedFilters, [key]: value.toString() };
            }
        });

        if (updateAutomatically) {
            this.applyFilters();
        }
    }

    setSortBy(ordering: DealOrdering, { updateAutomatically }: { updateAutomatically: boolean }) {
        this.ordering.set(ordering);
        this._selectedFilters.update((filters) => ({
            ...filters,
            ordering: ordering.value,
        }));

        if (updateAutomatically) {
            this.applyFilters();
        }
    }

    async applyFilters() {
        const filters = this.selectedFilters;
        this.dealState.fetchDeals(filters);
        const urlTree = this.router.createUrlTree([], {
            queryParams: dealFilterToQueryParams(this._selectedFilters()),
        });
        this.location.go(urlTree.toString());
    }
}
