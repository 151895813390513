import { Component, Input, signal } from "@angular/core";
import { NgIf } from "@angular/common";
import { Select } from "@ngxs/store";
import { UserState } from "../../../user/state/user/user.state";
import { Observable } from "rxjs";
import { MatSnackBar } from "@angular/material/snack-bar";
import { DealService } from "../../services/deal.service";
import { DealModel } from "../../models/deal";
import { ActivatedRoute, Router } from "@angular/router";
import { routeNames } from "../../../app-routing.module";

@Component({
    selector: "app-get-deal-btn",
    standalone: true,
    imports: [NgIf],
    templateUrl: "./get-deal-btn.component.html",
    styleUrl: "./get-deal-btn.component.scss",
})
export class GetDealBtnComponent {
    @Input({ required: true }) deal!: DealModel;
    linkLoading = signal(false);
    isLoggedIn = false;
    @Select(UserState.getLoginStatus) isLoggedIn$!: Observable<boolean>;

    constructor(
        private dealService: DealService,
        private router: Router,
        private snackBar: MatSnackBar,
    ) {
        this.isLoggedIn$.subscribe({
            next: (value) => {
                this.isLoggedIn = value;
            },
        });
    }

    getDealLink() {
        gtag("event", "deal-btn-clicked", {
            deal_id: this.deal?.id,
        });
        if (!this.deal) return;
        if (this.deal.deal_is_vip && !this.isLoggedIn) {
            this.router.navigate([routeNames.signUp.fullPath()], {
                queryParams: { from: this.router.url },
            });
            this.openSnackBar("Please login to get the deal link");
            return;
        }
        if (this.deal.deal_is_vip) {
            this.vipOffer();
        } else {
            this.gotoOffer();
        }
    }
    private vipOffer() {
        this.linkLoading.set(true);
        return this.router.navigate([routeNames.vipOffer.fullPath(this.deal?.id?.toString())]);
    }

    private gotoOffer() {
        this.linkLoading.set(true);
        const windowRef = window.open("", "_blank");

        this.dealService.getDealLink(this.deal?.id).subscribe({
            next: (res) => {
                if (windowRef?.location) {
                    windowRef.location = res.url;
                }
                gtag("event", "deal-btn-offer-redirect", {
                    deal_id: this.deal?.id,
                });
            },
            error: () => {
                windowRef?.close();
                this.openSnackBar("Unable to get deal link");
            },
            complete: () => {
                this.linkLoading.set(false);
            },
        });
    }

    private openSnackBar(errorDetail: string): void {
        this.snackBar.open("Error:", errorDetail, {
            duration: 5000,
        });
    }
}
