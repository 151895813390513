import { routeNames } from "../../app-routing.module";
import { dealFilterToQueryParams } from "../../deal/state/deal-filter.state";

export type NavbarConfig = {
    title: string;
    link: string;
    /** Optional sections to display in the hover menu on desktop or the second page of the mobile menu */
    sections?: HoverMenu[];
};
export type HoverMenu = {
    title: string;
    link?: string;
    footerLink?: {
        title: string;
        link: string;
    };
    options: HoverMenuOption[];
};

export type HoverMenuOption = {
    title: string;
    subtitle?: string;
    link: string;
    params?: Record<string, unknown>;
    type: "article" | "link";
};

export function createConfig(): NavbarConfig[] {
    return [
        {
            title: "Phone deals",
            link: routeNames.bundle.selectPhone.fullPath(),
            sections: [
                {
                    title: "Latest Models",
                    options: [
                        {
                            title: "iPhone 15",
                            type: "link",
                            link: routeNames.bundle.list.fullPath({
                                phoneModel: "iphone-15",
                            }),
                        },
                        {
                            title: "iPhone 15 Pro",
                            type: "link",
                            link: routeNames.bundle.list.fullPath({
                                phoneModel: "iphone-15-pro",
                            }),
                        },
                        {
                            title: "iPhone 15 Plus",
                            type: "link",
                            link: routeNames.bundle.list.fullPath({
                                phoneModel: "iphone-15-plus",
                            }),
                        },
                        {
                            title: "Samsung Galaxy S24",
                            type: "link",
                            link: routeNames.bundle.list.fullPath({
                                phoneModel: "samsung-galaxy-s24",
                            }),
                        },

                        {
                            title: "Google Pixel 8",
                            type: "link",
                            link: routeNames.bundle.list.fullPath({
                                phoneModel: "google-pixel-8",
                            }),
                        },

                        {
                            title: "Google Pixel 8 Pro",
                            type: "link",
                            link: routeNames.bundle.list.fullPath({
                                phoneModel: "google-pixel-8-pro",
                            }),
                        },
                    ],
                },

                {
                    title: "Popular models",
                    options: [
                        {
                            title: "iPhone 14",
                            type: "link",
                            link: routeNames.bundle.list.fullPath({
                                phoneModel: "iphone-14",
                            }),
                        },

                        {
                            title: "iPhone 14 Plus",
                            type: "link",
                            link: routeNames.bundle.list.fullPath({
                                phoneModel: "iphone-14-plus",
                            }),
                        },

                        {
                            title: "iPhone 14 Pro",
                            type: "link",
                            link: routeNames.bundle.list.fullPath({
                                phoneModel: "iphone-14-pro",
                            }),
                        },
                        {
                            title: "iPhone 13",
                            type: "link",
                            link: routeNames.bundle.list.fullPath({
                                phoneModel: "iphone-13",
                            }),
                        },

                        {
                            title: "iPhone 12",
                            type: "link",
                            link: routeNames.bundle.list.fullPath({
                                phoneModel: "iphone-12",
                            }),
                        },

                        {
                            title: "Samsung Galaxy S23",
                            type: "link",
                            link: routeNames.bundle.list.fullPath({
                                phoneModel: "samsung-galaxy-s23",
                            }),
                        },
                    ],
                },
                {
                    title: "Cheap(er) phones",
                    options: [
                        {
                            title: "iPhone SE (2022)",
                            type: "link",
                            link: routeNames.bundle.list.fullPath({
                                phoneModel: "iphone-se-2022-1",
                            }),
                        },

                        {
                            title: "iPhone 11",
                            type: "link",
                            link: routeNames.bundle.list.fullPath({
                                phoneModel: "iphone-11",
                            }),
                        },
                    ],
                },
                {
                    title: "SIM-free phones",
                    link: routeNames.simFreePhoneSelect.fullPath(),
                    options: [
                        {
                            title: "Search SIM-free phones",
                            type: "link",
                            link: routeNames.simFreePhoneSelect.fullPath(),
                        },
                        {
                            title: "iPhone 15",
                            type: "link",
                            link: routeNames.simFreeDeals.list.fullPath({
                                phoneModel: "iphone-15",
                            }),
                        },
                        {
                            title: "iPhone 15 Pro",
                            type: "link",
                            link: routeNames.simFreeDeals.list.fullPath({
                                phoneModel: "iphone-15-pro",
                            }),
                        },
                        {
                            title: "iPhone 15 Plus",
                            type: "link",
                            link: routeNames.simFreeDeals.list.fullPath({
                                phoneModel: "iphone-15-plus",
                            }),
                        },
                        {
                            title: "iPhone 15 Pro Max",
                            type: "link",
                            link: routeNames.simFreeDeals.list.fullPath({
                                phoneModel: "iphone-15-pro-max",
                            }),
                        },
                        {
                            title: "Samsung Galaxy S24",
                            type: "link",
                            link: routeNames.simFreeDeals.list.fullPath({
                                phoneModel: "samsung-galaxy-s24",
                            }),
                        },
                        {
                            title: "Google Pixel 8",
                            type: "link",
                            link: routeNames.simFreeDeals.list.fullPath({
                                phoneModel: "google-pixel-8",
                            }),
                        },
                    ],
                },
            ],
        },
        {
            title: "SIMs",
            link: routeNames.simOnlyDeals.list.fullPath(),
            sections: [
                {
                    title: "Providers",
                    options: [
                        {
                            title: "giffgaff SIM-only deals",
                            link: routeNames.simOnlyDeals.list.fullPath(),
                            params: dealFilterToQueryParams({
                                network_provider: ["1"],
                            }),
                            type: "link",
                        },

                        {
                            title: "Lebara SIM-only deals",
                            link: routeNames.simOnlyDeals.list.fullPath(),
                            params: dealFilterToQueryParams({
                                network_provider: ["4"],
                            }),
                            type: "link",
                        },

                        {
                            title: "Your Co-op SIM-only deals",
                            link: routeNames.simOnlyDeals.list.fullPath(),
                            params: dealFilterToQueryParams({
                                network_provider: ["11"],
                            }),
                            type: "link",
                        },

                        {
                            title: "Smarty SIM-only deals",
                            link: routeNames.simOnlyDeals.list.fullPath(),
                            params: dealFilterToQueryParams({
                                network_provider: ["13"],
                            }),
                            type: "link",
                        },
                    ],
                },

                {
                    title: "Noggin specials",
                    options: [
                        {
                            title: "Our VIP SIM deals (free goodies)",
                            link: routeNames.simOnlyDeals.list.fullPath(),
                            params: dealFilterToQueryParams({
                                deal_is_vip: true,
                            }),
                            type: "link",
                        },
                        {
                            title: "Pay as you go SIMs",
                            link: routeNames.simOnlyDeals.list.fullPath(),
                            params: dealFilterToQueryParams({
                                sim_contract_length_months: 0,
                            }),
                            type: "link",
                        },

                        {
                            title: "Unlimited data SIMs",
                            link: routeNames.simOnlyDeals.list.fullPath(),
                            params: dealFilterToQueryParams({
                                sim_unlimited_data: true,
                            }),
                            type: "link",
                        },

                        {
                            title: "SIM Only",
                            link: routeNames.simOnlyDeals.list.fullPath(),
                            type: "link",
                        },
                    ],
                },
                {
                    title: "Popular SIMs",
                    options: [
                        {
                            title: "SIMs under £10 per month",
                            link: routeNames.simOnlyDeals.list.fullPath(),
                            params: dealFilterToQueryParams({
                                sim_monthly_cost_inc_vat_max: "10",
                            }),
                            type: "link",
                        },

                        {
                            title: "SIMs under £20 per month",
                            link: routeNames.simOnlyDeals.list.fullPath(),
                            params: dealFilterToQueryParams({
                                sim_monthly_cost_inc_vat_max: "20",
                            }),
                            type: "link",
                        },

                        {
                            title: "5G SIMs",
                            link: routeNames.simOnlyDeals.list.fullPath(),
                            params: dealFilterToQueryParams({
                                deal_connectivity: "5G",
                            }),
                            type: "link",
                        },

                        {
                            title: "100GB+ SIMs",
                            link: routeNames.simOnlyDeals.list.fullPath(),
                            params: dealFilterToQueryParams({
                                sim_data_allowance_gb_numeric: 100,
                            }),
                            type: "link",
                        },
                    ],
                },
                {
                    title: "SIM Guides",
                    footerLink: {
                        title: "See all blogs",
                        link: routeNames.articles.list.fullPath(),
                    },
                    options: [
                        {
                            title: "Credit Scoring FAQ | Part two",
                            subtitle: "The credit scoring chronicles continued... 🤓",
                            type: "article",
                            link: routeNames.articles.details.fullPath(
                                "credit-scoring-faq-part-two",
                                "32",
                            ),
                        },
                    ],
                },
            ],
        },
        {
            title: "Help",
            link: routeNames.faq.fullPath(),
            sections: [
                {
                    title: "FAQS",
                    options: [
                        {
                            title: "Read our FAQs 📓",
                            link: routeNames.faq.fullPath(),
                            type: "link",
                        },
                    ],
                },

                {
                    title: "Contact Us",
                    options: [
                        {
                            title: "Contact Noggin HQ’s HQ 🤪",
                            link: routeNames.contact.fullPath(),
                            type: "link",
                        },
                    ],
                },

                {
                    title: "Our Research",
                    footerLink: {
                        title: "See all blogs",
                        link: routeNames.articles.list.fullPath(),
                    },
                    options: [
                        {
                            title: "Read more about Noggin’s research 🥰",
                            link: routeNames.ourResearch.fullPath(),
                            type: "link",
                        },
                        {
                            title: "Credit Scoring FAQ | Part two",
                            subtitle: "The credit scoring chronicles continued... 🤓",
                            type: "article",
                            link: routeNames.articles.details.fullPath(
                                "credit-scoring-faq-part-two",
                                "32",
                            ),
                        },
                    ],
                },
            ],
        },
    ];
}
