import { Component } from "@angular/core";
import { DealDetailsState } from "../../state/deal-details.state";
import { ActivatedRoute } from "@angular/router";
import {
    Breadcrumb,
    BreadcrumbsComponent,
} from "../../../core/components/breadcrumbs/breadcrumbs.component";
import { NgForOf, NgIf, NgOptimizedImage } from "@angular/common";
import { HttpStatus } from "../../../core/models/http-status";
import { routeNames } from "../../../app-routing.module";
import { LoadingComponent } from "../../../core/components/loading/loading.component";
import { SquigglyBackgroundComponent } from "../squigly-background/squiggly-background.component";
import { DetailItemComponent } from "../detail-item/detail-item.component";
import { PerksComponent } from "../perks/perks.component";
import { DisclaimerGraphicComponent } from "../../../core/components/disclaimer-graphic/disclaimer-graphic.component";
import { DealDisclaimerComponent } from "../deal-disclaimer/deal-disclaimer.component";
import { Select } from "@ngxs/store";
import { UserState } from "../../../user/state/user/user.state";
import { Observable } from "rxjs";
import { TagsRowComponent } from "../tags-row/tags-row.component";
import { GetDealBtnComponent } from "../get-deal-btn/get-deal-btn.component";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FaIconComponent } from "@fortawesome/angular-fontawesome";

@Component({
    selector: "app-sim-details",
    standalone: true,
    imports: [
        BreadcrumbsComponent,
        NgIf,
        LoadingComponent,
        LoadingComponent,
        FaIconComponent,
        SquigglyBackgroundComponent,
        NgOptimizedImage,
        DetailItemComponent,
        PerksComponent,
        NgForOf,
        DisclaimerGraphicComponent,
        DealDisclaimerComponent,
        TagsRowComponent,
        GetDealBtnComponent,
    ],
    templateUrl: "./sim-details.component.html",
    styleUrl: "./sim-details.component.scss",
    providers: [DealDetailsState],
})
export class SimDetailsComponent {
    isLoggedIn = false;
    @Select(UserState.getLoginStatus) isLoggedIn$!: Observable<boolean>;
    protected readonly parseFloat = parseFloat;
    protected readonly faInfoCircle = faInfoCircle;
    constructor(
        public dealDetailsState: DealDetailsState,
        private route: ActivatedRoute,
    ) {
        const id = this.route.snapshot.params["id"];
        this.dealDetailsState.fetchDeal(parseInt(id));

        this.isLoggedIn$.subscribe((res) => {
            this.isLoggedIn = res;
        });
    }

    protected readonly HttpStatus = HttpStatus;

    get dealPerks(): string[] | undefined {
        if (this.deal?.deal_perks && this.deal?.deal_perks.length > 0) {
            return this.deal?.deal_perks?.split(";");
        }
        return undefined;
    }
    get creditCheck(): string {
        if (this.deal?.deal_credit_check === undefined || this.deal?.deal_credit_check === null) {
            return "Unknown";
        }
        return this.deal?.deal_credit_check ? "Yes" : "No";
    }
    get deal() {
        return this.dealDetailsState.deal();
    }
    get breadcrumbs(): Breadcrumb[] {
        const breadcrumbs: Breadcrumb[] = [
            { label: "Home", path: routeNames.home.fullPath() },
            { label: "Compare SIM Only deals", path: routeNames.simOnlyDeals.list.fullPath() },
        ];

        if (this.dealDetailsState.deal() && this.dealDetailsState.deal()?.network_provider) {
            breadcrumbs.push({
                label: `${this.dealDetailsState.deal()?.network_provider.name}`,
                path: routeNames.simOnlyDeals.list.fullPath(),
                queryParams: {
                    network_provider: this.dealDetailsState.deal()?.network_provider?.id,
                },
            });
        }
        return breadcrumbs;
    }
}
