import { CommonModule, Location } from '@angular/common';
import { Component, OnInit, signal } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { routeNames } from 'src/app/app-routing.module';
import { CarouselItemComponent } from 'src/app/core/components/carousel-item/carousel-item.component';
import { CarouselComponent } from 'src/app/core/components/carousel/carousel.component';
import { LoadingComponent } from 'src/app/core/components/loading/loading.component';
import { CarouselService } from 'src/app/core/services/carousel.service';
import { PhoneCardComponent } from 'src/app/deal/components/phone-card/phone-card.component';
import { DealModel } from 'src/app/deal/models/deal';
import { DealType } from 'src/app/deal/models/filter';
import { NogginPhoneTileComponent } from 'src/app/noggin-phone/noggin-phone-tile/noggin-phone-tile.component';
import { ProductApplicationService } from 'src/app/user/services/product-application.service';

@Component({
    selector: 'app-user-phone-choice',
    standalone: true,
    imports: [
        CarouselItemComponent,
        CarouselComponent,
        CommonModule,
        FaIconComponent,
        LoadingComponent,
        NogginPhoneTileComponent,
        PhoneCardComponent,
        ReactiveFormsModule,
    ],
    templateUrl: './user-phone-choice.component.html',
    styleUrl: './user-phone-choice.component.scss'
})
export class UserPhoneChoiceComponent implements OnInit {
    form!: FormGroup;
    loading = signal(true);
    dealType = DealType.phoneOnly;
    deals: DealModel[] = [];
    submitting = signal(false);

    constructor(
        private formBuilder: FormBuilder,
        private location: Location,
        private paService: ProductApplicationService,
        private router: Router,
        private carouselService: CarouselService,
    ) { }


    ngOnInit(): void {
        this.carouselService.carousels()
            .subscribe({
                next: (data) => {
                    this.deals = data[4].subsections[0].deals;
                    this.loading.set(false);
                },
                complete: () => {
                    this.loading.set(false);
                },
            });

        this.form = this.formBuilder.group(
            {
                deal_id: ["", Validators.required]
            },
            {}
        );
    }

    async back() {
        this.location.back();
    }

    selectDeal(deal: DealModel) {
        if (this.submitting()) return;

        this.submitting.set(true);

        const data = {
            deal_id: deal.id.toString(),
        };

        this.paService
            .selectDeal(data)
            .subscribe({
                next: () => {
                    this.router.navigateByUrl(routeNames.getStarted.contractAgreement.fullPath());
                }
            });
    }

    protected readonly faChevronLeft = faChevronLeft;

}

