<section [ngClass]="padding">
    <ng-container *ngIf="loading() || !this.carouselData(); else mainComponent">
        <app-carousel [dark]="dark">
            <app-carousel-item>
                <app-phone-card [dealType]="DealType.phoneOnly" [loading]="true" [showEstimatedTotalDescText]="showSecondDescriptionRow" [showCreditCheckDescText]="showSecondDescriptionRow" ></app-phone-card>
            </app-carousel-item>
            <app-carousel-item>
                <app-phone-card [dealType]="DealType.phoneOnly" [loading]="true" [showEstimatedTotalDescText]="showSecondDescriptionRow" [showCreditCheckDescText]="showSecondDescriptionRow"></app-phone-card>
            </app-carousel-item>
            <app-carousel-item>
                <app-phone-card [dealType]="DealType.phoneOnly" [loading]="true" [showEstimatedTotalDescText]="showSecondDescriptionRow" [showCreditCheckDescText]="showSecondDescriptionRow"></app-phone-card>
            </app-carousel-item>
        </app-carousel>
    </ng-container>
    <ng-template #mainComponent>
        <app-carousel
            [title]="this.title || this.carouselData()!.title"
            [subtitle]="this.subtitle || this.carouselData()!.subtitle"
            [dark]="dark"
            [buttonConfig]="buttonConfig"
        >
            <div
                *ngIf="(carouselData()?.subsections?.length ?? 0) > 1"
                tags
                class="mb-10 flex h-auto items-stretch justify-center gap-1.5 lg:gap-4"
            >
                <ng-container *ngFor="let tag of carouselData()!.subsections; index as i">
                    <button
                        [ngClass]="
                            selectedSection() === i
                                ? 'bg-electric-blue text-white'
                                : 'bg-off-white text-electric-blue hover:text-white'
                        "
                        class="btn btn-primary !h-auto min-h-9 w-[112px] rounded-full border-electric-blue py-1 lg:w-[132px]"
                        (click)="selectTag(i)"
                    >
                        {{ carouselData()!.subsections[i].title }}
                    </button>
                </ng-container>
            </div>
            <ng-container *ngIf="(deals?.length ?? 0) > 0">
                <app-carousel-item *ngFor="let deal of deals; trackBy: trackByFn">
                    <ng-container
                        *ngIf="dealTypeFromApi(deal) === DealType.simOnly; else phoneCardTemplate"
                    >
                        <app-sim-card [deal]="deal"></app-sim-card>
                    </ng-container>
                    <ng-template #phoneCardTemplate>
                        <app-phone-card
                            [dealType]="dealTypeFromApi(deal)"
                            [deal]="deal"
                            [showEstimatedTotalDescText]="showSecondDescriptionRow"
                            [showCreditCheckDescText]="showSecondDescriptionRow"
                            [itemClickable]="itemsClickable"
                            [backgroundColour]="backgroundColourPhoneCard"
                        ></app-phone-card>
                    </ng-template>
                </app-carousel-item>
            </ng-container>
        </app-carousel>
    </ng-template>
</section>
