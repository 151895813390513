<div class="account-container">
    <button
        (click)="back()"
        class="bold mb-6 mr-auto flex items-center justify-center text-[14px] uppercase text-electric-blue h-8"
    >
        <fa-icon class="mr-2.5" [icon]="faChevronLeft"></fa-icon>
        Back
    </button>

    <section class="mb-4">
        <h1 class="text-3xl font-semibold">What's your financial position?</h1>

        <form (ngSubmit)="submit()" [formGroup]="form" class="mt-8">
            <div class="flex flex-col">
                <label
                    for="declaration_bankruptcy"
                    class="mb-1.5 text-sm uppercase after:content-['*'] after:ml-0.5 after:text-red-500"
                >
                    Have you ever declared bankruptcy?
                </label>
                <select
                    formControlName="declaration_bankruptcy"
                    id="declaration_bankruptcy"
                    name="declaration_bankruptcy"
                    type="text"
                    class="select rounded-xl border border-black"
                    [attr.readonly]="formSubmitting() ? 'readonly' : null"
                >
                    <option disabled>Select</option>
                    <option value="0">No</option>
                    <option value="1">Yes</option>
                </select>
                @switch(!!declarationBankruptcyErrorMessage) {
                    @case (true) {
                    <small class="text-error-red">{{ declarationBankruptcyErrorMessage }}</small>
                    }
                    @case (false) {
                        <small>&nbsp;</small>
                    }
                }
            </div>

            <div class="flex flex-col">
                <label
                    for="declaration_insolvency"
                    class="mb-1.5 text-sm uppercase after:content-['*'] after:ml-0.5 after:text-red-500"
                >
                    Have you declared insolvency?
                </label>
                <select
                    formControlName="declaration_insolvency"
                    id="declaration_insolvency"
                    name="declaration_insolvency"
                    type="text"
                    class="select rounded-xl border border-black"
                    [attr.readonly]="formSubmitting() ? 'readonly' : null"
                >
                    <option disabled>Select</option>
                    <option value="0">No</option>
                    <option value="1">Yes</option>
                </select>
                @switch(!!declarationInsolvencyErrorMessage) {
                    @case (true) {
                    <small class="text-error-red">{{ declarationInsolvencyErrorMessage }}</small>
                    }
                    @case (false) {
                        <small>&nbsp;</small>
                    }
                }
            </div>

            <div class="flex flex-col">
                <label
                    for="declaration_insolvency"
                    class="mb-1.5 text-sm uppercase after:content-['*'] after:ml-0.5 after:text-red-500"
                >
                    What is your employment status?</label>
                <select
                    formControlName="declaration_employment_status"
                    id="declaration_employment_status"
                    name="declaration_employment_status"
                    type="text"
                    class="select rounded-xl border border-black"
                    [attr.readonly]="formSubmitting() ? 'readonly' : null"
                >
                    <option disabled>Select</option>
                    <option
                        *ngFor="let option of options"
                        [selected]="option[0] === this.form.get('declaration_employment_status')?.value"
                        [value]="option[0]"
                    >
                        {{ option[1] }}
                    </option>
                </select>
                @switch(!!declarationEmploymentStatusErrorMessage) {
                    @case (true) {
                    <small class="text-error-red">{{ declarationEmploymentStatusErrorMessage }}</small>
                    }
                    @case (false) {
                        <small>&nbsp;</small>
                    }
                }
            </div>

            <div class="flex flex-col">
                <label
                    for="declaration_annual_income"
                    class="mb-1.5 text-sm uppercase after:content-['*'] after:ml-0.5 after:text-red-500"
                >
                    What do you estimate you annual income?
                </label>

                <div class="flex input rounded-xl border border-black">
                    <span class="pr-2 flex justify-center items-center text-gray-500" aria-hidden="true">£</span>
                    <input
                        class="grow"
                        formControlName="declaration_annual_income"
                        id="declaration_annual_income"
                        name="declaration_annual_income"
                        type="text"
                        [attr.readonly]="formSubmitting() ? 'readonly' : null"
                    />
                </div>
                @switch(!!declarationAnnualIncomeErrorMessage) {
                    @case (true) {
                    <small class="text-error-red">{{ declarationAnnualIncomeErrorMessage }}</small>
                    }
                    @case (false) {
                        <small>&nbsp;</small>
                    }
                }
            </div>

            <div class="mt-4 mb-8 h-14 w-full">
                <ng-container *ngIf="formSubmitting(); else content">
                    <app-loading class="block"></app-loading>
                </ng-container>
                <ng-template #content>
                    <button
                        class="btn mx-auto w-full block self-stretch rounded-full bg-electric-blue text-center uppercase text-white"
                        name="Submit"
                        type="submit"
                    >
                        Next step
                    </button>
                </ng-template>
            </div>
        </form>
    </section>
</div>

<section class="flex my-8 mx-auto max-w-lg" nomargin>
    <app-noggin-phone-tile
        caption="Why are we doing this?"
        heading="5.8 million people in the UK are excluded from credit!"
        text="We are dedicated to bringing a solution for people unfairly locked out of the current system."
    ></app-noggin-phone-tile>
</section>
