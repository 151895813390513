<div class="account-container">
    <button
        (click)="back()"
        class="bold mr-auto flex items-center justify-center text-[14px] uppercase text-electric-blue h-8"
    >
        <fa-icon class="mr-2.5" [icon]="faChevronLeft"></fa-icon>
        Back
    </button>

    <section class="mt-6 mb-4">
        <h1 class="text-3xl font-semibold">Review your contract</h1>

        <p class="mt-6">
            In accepting the terms of this contract, you will be agreeing that they will come into effect once certain conditions are met.
            This contract will be void if you fail further status checks.
        </p>

        <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mt-6">
            <div class="">
                <h3 class="text-sm text-gray-700 mb-1 uppercase">Consumer "YOU"</h3>
                <p class="font-semibold">{{ this.userName }}</p>
            </div>
            <div class="">
                <h3 class="text-sm text-gray-700 mb-1 uppercase">Contact on</h3>
                <p class="font-semibold">{{ this.userEmail }}</p>
            </div>
            <div class="md:col-span-2">
                <h3 class="text-sm text-gray-700 mb-1 uppercase">At address</h3>
                <div class="font-semibold">
                    <div>{{ this.userAddress.line1 }},</div>
                    <div *ngIf="this.userAddress.line2">{{ this.userAddress.line2 }},</div>
                    <div>{{ this.userAddress.town }}</div>
                    <div>{{ this.userAddress.postcode }}, {{ this.userAddress.countryCode }}</div>
                    <div></div>
                </div>
            </div>
        </div>

        <div class="mt-6">
            <h3 class="text-sm text-gray-700 mb-1 uppercase">Contract terms</h3>
            <section
                class="mt-4 max-h-[720px] py-4 px-4 border border-black overflow-y-auto overscroll-auto"
                [innerHTML]="contract"
            ></section>
        </div>

        <form (ngSubmit)="submit()" [formGroup]="form" class="mt-8">
            <div class="mt-6 mb-4 flex">
                <input
                    id="agreed_to_terms"
                    formControlName="agreed_to_terms"
                    type="checkbox"
                    checked="checked"
                    class="checkbox-primary checkbox mr-2.5"
                    [attr.readonly]="formSubmitting() ? 'readonly' : null"
/>
                <label for="agreed_to_terms">
                    I have read and accept the Terms and Conditions of this contract.
                </label>
            </div>

            <div class="flex">
                <input
                    id="agreed_to_digital_signature"
                    formControlName="agreed_to_digital_signature"
                    type="checkbox"
                    checked="checked"
                    class="checkbox-primary checkbox mr-2.5"
                    [attr.readonly]="formSubmitting() ? 'readonly' : null"
/>
                <label for="agreed_to_digital_signature">
                    I consent to providing a digital signature to enter into a contract
                </label>
            </div>

            <div class="mt-4 mb-8 h-14 w-full">
                <ng-container *ngIf="formSubmitting(); else content">
                    <app-loading class="block"></app-loading>
                </ng-container>
                <ng-template #content>
                    <button
                        class="btn mx-auto w-full block self-stretch rounded-full bg-electric-blue text-center uppercase text-white uppercase"
                        name="Submit"
                        type="submit"
                        [disabled]="!form.valid"
                    >
                        I accept
                    </button>
                </ng-template>
            </div>
        </form>

    </section>
</div>

<section class="flex my-8 mx-auto max-w-lg" nomargin>
    <app-noggin-phone-tile
        caption="Why are we doing this?"
        heading="5.8 million people in the UK are excluded from credit!"
        text="We are dedicated to bringing a solution for people unfairly locked out of the current system."
    ></app-noggin-phone-tile>
</section>
