import { Component, HostListener } from "@angular/core";
import { routeNames } from "../../../app-routing.module";

@Component({
    selector: "app-not-found",
    templateUrl: "./not-found.component.html",
    styleUrls: ["./not-found.component.scss"],
})
export class NotFoundComponent {
    protected readonly routeNames = routeNames;
}
