import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, shareReplay } from "rxjs";
import { environment } from "../../../environments/environment";
import {
    ManufacturerModel,
    NetworkProviderModel,
    PaymentMethodModel,
    PhoneColorModel,
    PhoneModel,
    TagOptionsModel,
} from "../models/phone";
import type { DealType } from "../models/filter";

@Injectable({
    providedIn: "root",
})
export class PhonesService {
    private baseUrl: string = environment.baseUrl;

    constructor(private http: HttpClient) { }

    // TODO: Determine if we can make dealType mandatory
    getManufacturers(dealType?: DealType): Observable<ManufacturerModel[]> {
        const url = dealType
            ? `${this.baseUrl}/filters/manufacturers?dealType=${dealType}`
            : `${this.baseUrl}/filters/manufacturers`

        return this.http
            .get<ManufacturerModel[]>(url)
            .pipe(shareReplay());
    }

    getPhoneModels(manufacturerId?: string | number, dealType?: DealType): Observable<PhoneModel[]> {
        let url: string;

        if (manufacturerId) {
            url = dealType
                ? `${this.baseUrl}/filters/manufacturers/${manufacturerId}/phone-models?dealType=${dealType}`
                : `${this.baseUrl}/filters/manufacturers/${manufacturerId}/phone-models`
        } else {
            url = dealType
                ? `${this.baseUrl}/filters/phone-models?dealType=${dealType}`
                : `${this.baseUrl}/filters/phone-models`
        }

        return this.http
            .get<PhoneModel[]>(url)
            .pipe(shareReplay());
    }

    getAllPhoneModels(): Observable<PhoneModel[]> {
        return this.http
            .get<PhoneModel[]>(`${this.baseUrl}/filters/phone-models`)
            .pipe(shareReplay());
    }

    getPhoneModelBySlug(slug: string, dealTypeId?: number) {
        const queryParams = dealTypeId ? `?deal_type_id=${dealTypeId}` : "";
        return this.http.get<PhoneModel>(`${this.baseUrl}/deals/phone/${slug}/${queryParams}`);
    }

    getStorageSizes(): Observable<[{ id: number; size: number }]> {
        return this.http
            .get<[{ id: number; size: number }]>(`${this.baseUrl}/filters/storage-sizes`)
            .pipe(shareReplay());
    }

    getPhoneColors(phoneModelId: number): Observable<PhoneColorModel[]> {
        return this.http.get<PhoneColorModel[]>(
            `${this.baseUrl}/filters/phone-models/${phoneModelId}/colors`,
        );
    }

    getTagOptions(): Observable<TagOptionsModel[]> {
        return this.http.get<TagOptionsModel[]>(`${this.baseUrl}/filters/tag-options`);
    }

    getNetworkProviders(): Observable<NetworkProviderModel[]> {
        return this.http.get<NetworkProviderModel[]>(`${this.baseUrl}/filters/network-providers`);
    }

    getPaymentMethods(): Observable<PaymentMethodModel[]> {
        return this.http.get<PaymentMethodModel[]>(`${this.baseUrl}/filters/payment-methods`);
    }

    getPhoneModelInfo(manufacturerId: number): Observable<PhoneModel[]> {
        return this.http.get<PhoneModel[]>(
            `${this.baseUrl}/filters/phone-models/?manufacturer__in=${manufacturerId}`,
        );
    }
}
