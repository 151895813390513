import { Component, effect, OnInit } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import {
    DynamicTextType,
    FaqItem,
    WebsiteCmsService,
} from "../../../core/services/website-cms.service";
import { BankingState } from "../../state/banking.state";
import { UserService } from "../../services/user.service";
import { routeNames } from "../../../app-routing.module";

@Component({
    selector: "app-share-banking-data",
    templateUrl: "./share-banking-data.component.html",
    styleUrls: ["./share-banking-data.component.scss"],
})
export class ShareBankingDataComponent implements OnInit {
    loading = false;
    faqItems: FaqItem[] = [];
    fromPage?: string;
    openBankingReasons: string[] = [];
    protected readonly routeNames = routeNames;

    constructor(
        private _snackBar: MatSnackBar,
        private cmsService: WebsiteCmsService,
        public bankingState: BankingState,
        private router: Router,
        private userService: UserService,
        private route: ActivatedRoute,
    ) {
        this.fromPage = this.route.snapshot.queryParams["from"];
    }
    ngOnInit() {
        this.bankingState.init();
        this.cmsService.getBankingFaqContent().subscribe({
            next: (r) => {
                this.faqItems = r;
            },
            error: (error) => {
                this.openSnackBar(error?.error?.detail, "Error");
            },
        });
        this.cmsService.getDynamicText(DynamicTextType.openBanking).subscribe({
            next: (r) => {
                this.openBankingReasons = r.map((reason) => reason.content);
            },
            error: (error) => {
                this.openSnackBar(error?.error?.detail, "Error");
            },
        });
    }

    get link() {
        if (this.fromPage) {
            return this.fromPage;
        }
        return routeNames.compare.mobile.fullPath();
    }

    goToOffer() {
        return this.router.navigate([this.link]);
    }

    private openSnackBar(message: string, type: string): void {
        this._snackBar.open(type + ": " + message, "Dismiss", {
            panelClass: [type],
        });
    }
}
