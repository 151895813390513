import { Component } from '@angular/core';
import { RouterLink, RouterModule } from '@angular/router';
import { routeNames } from 'src/app/app-routing.module';
import { LogoFullComponent } from "src/app/core/components/logo-full/logo-full.component";


@Component({
    selector: 'app-onboarding-scaffold',
    standalone: true,
    imports: [
        LogoFullComponent,
        RouterLink,
        RouterModule
    ],
    templateUrl: './onboarding-scaffold.component.html',
    styleUrl: './onboarding-scaffold.component.scss'
})
export class OnboardingScaffoldComponent {
    routeNames = routeNames;
}
