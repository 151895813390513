import { Component, Input, TrackByFunction, signal, OnInit } from "@angular/core";
import { CarouselApi, CarouselService } from "../../services/carousel.service";
import { CommonModule } from "@angular/common";
import { CarouselItemComponent } from "../carousel-item/carousel-item.component";
import { PhoneCardNavComponent } from "../../../deal/components/phone-card-nav/phone-card-nav.component";
import { CarouselButtonConfig, CarouselComponent } from "../carousel/carousel.component";
import { DealType } from "src/app/deal/models/filter";
import { DealModel } from "src/app/deal/models/deal";
import { SimCardComponent } from "../../../deal/components/sim-card/sim-card.component";

@Component({
    selector: "app-cms-carousel",
    standalone: true,
    templateUrl: "./cms-carousel.component.html",
    styleUrl: "./cms-carousel.component.scss",
    imports: [
        CommonModule,
        CarouselItemComponent,
        PhoneCardNavComponent,
        CarouselComponent,
        SimCardComponent,
    ],
})
export class CmsCarouselComponent implements OnInit {
    @Input({ required: true }) dark!: boolean;
    @Input() carouselIndex = 0;
    @Input() showSecondDescriptionRow = true;
    @Input() itemsClickable = true;
    @Input() padding = "pt-20";
    selectedSection = signal(0);
    loading = signal(true);
    carouselData = signal<CarouselApi | undefined>(undefined);
    protected DealType = DealType;

    constructor(private carouselService: CarouselService) { }

    ngOnInit(): void {
        this.carouselService.carousels().subscribe({
            next: (data) => {
                if (data.length >= this.carouselIndex) {
                    this.carouselData.set(data[this.carouselIndex]);
                } else {
                    console.error("Carousel index out of range");
                }
            },
            complete: () => {
                this.loading.set(false);
            },
        });
    }

    get buttonConfig(): CarouselButtonConfig | undefined {
        const section = this.carouselData()?.subsections[this.selectedSection()];
        if (section?.has_button && section?.button_link) {
            try {
                const [pathname, searchParams] = section?.button_link?.split("?") ?? [
                    undefined,
                    undefined,
                ];
                const params = searchParams?.split("&").reduce((acc, param) => {
                    const [key, value] = param.split("=");
                    return { ...acc, [key]: value };
                }, {});

                return {
                    link: pathname,
                    text: section.button_title ?? "See all",
                    params,
                };
            } catch (e) {
                console.error("Invalid URL", e);
                return undefined;
            }
        }
        return undefined;
    }

    public dealTypeFromApi(deal: DealModel): DealType {
        // should be an enum from the api but it's not
        switch (deal.deal_type.id) {
            case 1:
                return DealType.simOnly;
            case 2:
                return DealType.phoneOnly;
            case 3:
                return DealType.bundle;
            default:
                return DealType.bundle;
        }
    }

    get deals() {
        return this.carouselData()?.subsections[this.selectedSection()].deals;
    }

    async selectTag(index: number) {
        // this.loading.set(true);
        this.selectedSection.set(index);
        // await wait(0);
        // this.loading.set(false);
    }

    trackByFn: TrackByFunction<DealModel> = (_: number, item: DealModel) => {
        return item.id;
    };
}
