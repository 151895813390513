import { Component, signal } from "@angular/core";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { LogoFullComponent } from "../logo-full/logo-full.component";
import { FcaLogoComponent } from "../fca-logo/fca-logo.component";
import {
    faFacebook,
    faInstagram,
    faLinkedin,
    faTiktok,
    faXTwitter,
    faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { FooterLinkComponent } from "../footer-link/footer-link.component";
import { routeNames } from "../../../app-routing.module";
import { environment } from '../../../../environments/environment';

@Component({
    selector: "app-footer",
    templateUrl: "./footer.component.html",
    styleUrls: ["./footer.component.scss"],
    standalone: true,
    imports: [FontAwesomeModule, LogoFullComponent, FcaLogoComponent, FooterLinkComponent],
})
export class FooterComponent {
    protected readonly faFacebook = faFacebook;
    protected readonly faInstagram = faInstagram;
    protected readonly faXTwitter = faXTwitter;
    protected readonly faLinkedin = faLinkedin;
    protected readonly faYoutube = faYoutube;
    protected readonly routeNames = routeNames;
    protected readonly faTiktok = faTiktok;
    email = signal("");

    protected readonly footer = environment.footer;
}
