<button
    (click)="openFilterModal()"
    class="btn btn-outline btn-primary-0 bg-white shadow-md shadow-black fixed bottom-1 left-4 right-4 z-20 flex justify-between rounded-xl group"
>
    <span class="flex items-center justify-start text-md font-bold">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            class="mr-2 fill-black group-hover:fill-[#BEBEBE]"
        >
            <path
                d="M2.5 5H17.5"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M5 10H15"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M7 15H13"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
        Filter &amp; Sort
    </span>
    <span class="text-md font-normal">
        {{ this.filterLength }}
        {{ filterLength > 1 || filterLength === 0 ? "filters" : "filter" }}
        applied
    </span>
</button>
<dialog id="deals-refine-filter" class="modal">
    <div
        class="modal-box relative flex h-full max-h-full w-screen max-w-full flex-col overflow-auto rounded-none"
    >
        <button class="ml-auto text-2xl" (click)="applyFilters()">
            <fa-icon [icon]="faClose"></fa-icon>
        </button>
        <h3 class="mb-5 text-3xl font-semibold">Filter &amp; Sort</h3>

        <h3 class="border-b pb-2 text-xl font-semibold">Sort by</h3>
        <div class="py-5">
            <label *ngFor="let order of dealOrdering(dealType)" class="label cursor-pointer">
                <span>{{ order.label }}</span>
                <input
                    type="radio"
                    name="monthly_cost"
                    class="radio-primary radio"
                    (change)="this.setSortBy(order)"
                    [checked]="this.dealFilterState.ordering().value === order.value"
                />
            </label>
        </div>
        <h3 class="border-b pb-2 text-xl font-semibold">Filters</h3>
        <app-deal-filter [dealType]="dealType"></app-deal-filter>
        <button
            class="btn btn-outline btn-primary mt-6 flex-1 rounded-full text-white"
            (click)="clearFilters()"
        >
            CLEAR FILTERS
        </button>
        <button
            class="btn btn-primary mt-6 flex-1 rounded-full text-white"
            (click)="applyFilters()"
        >
            SEE RESULTS
        </button>
    </div>
    <form method="dialog" class="modal-backdrop">
        <button>Close</button>
    </form>
</dialog>
