import { effect, Injectable, signal } from "@angular/core";
import { UserService } from "../services/user.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { environment } from "src/environments/environment";
import { DateTime } from "luxon";
import { UserModel } from "../models/user";
import { firstValueFrom } from "rxjs";

@Injectable({ providedIn: "root" })
export class BankingState {
    loading = signal(false);
    connected = signal(false);
    linkToken = signal<string | null>(null);
    environment = "sandbox";

    constructor(
        private userService: UserService,
        private snackBar: MatSnackBar,
    ) {
        this.environment = environment.plaid_environment;
        this.init();
    }
    async init() {
        this.loading.set(true);
        try {
            const user = await firstValueFrom(this.userService.getUserInfo());
            this.connected.set(user.user_detail.banking_paired);
            if (!this.connected()) {
                this.createPlaidLink();
            }
        } catch (e) {
            console.error(e);
        } finally {
            if (this.connected()) {
                this.loading.set(false);
            }
        }
    }

    daysLeft(user?: UserModel) {
        if (!user) return;
        const pairedDateStr = user?.user_detail.banking_paired_date;
        if (!pairedDateStr) return 0;
        const pairedDate = DateTime.fromISO(pairedDateStr);

        const now = DateTime.now();
        const futureDate = pairedDate.plus({ days: 90 });
        return Math.round(futureDate.diff(now, "days").days);
    }

    disconnect(): void {
        if (this.loading()) return;
        this.loading.set(true);
        this.userService.disconnectPlaid().subscribe({
            next: () => {
                this.openSnackBar("Banking Successfully Disconnected", "Success");
                this.connected.set(false);
                this.createPlaidLink();
            },
            error: (e) => {
                this.openSnackBar(e?.error?.detail, "Error");
            },
        });
    }

    createPlaidLink() {
        this.loading.set(true);
        return this.userService.createPlaidLink().subscribe({
            next: (r) => {
                this.linkToken.set(r.link_token);
                this.loading.set(false);
            },
            error: (e) => {
                console.error(e);
                this.openSnackBar(e?.error?.detail, "Error");
                this.loading.set(false);
            },
            complete: () => {
                this.loading.set(false);
            },
        });
    }

    onPlaidSuccess(event: any): void {
        this.userService.setPlaidLink(event.metadata.public_token).subscribe({
            next: async () => {
                this.openSnackBar("Banking Successfully Connected", "Success");
                this.connected.set(true);
            },
            error: (e) => {
                console.error(e);
                this.openSnackBar(e?.error?.detail, "Error");
            },
        });
    }

    private openSnackBar(message: string, type: string): void {
        this.snackBar.open(type + ": " + message, "Dismiss", {
            panelClass: [type],
        });
    }
}
