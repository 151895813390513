<!-- Generated by IcoMoon.io -->
<svg
    xmlns="http://www.w3.org/2000/svg"
    [attr.width]="width"
    [attr.height]="height"
    viewBox="0 0 32 32"
>
    <title>menu</title>
    <path
        fill="none"
        stroke-linejoin="round"
        stroke-linecap="round"
        stroke-miterlimit="4"
        stroke-width="2.4"
        stroke="currentColor"
        d="M4 8h24"
    ></path>
    <path
        fill="none"
        stroke-linejoin="round"
        stroke-linecap="round"
        stroke-miterlimit="4"
        stroke-width="2.4"
        stroke="currentColor"
        d="M4 16h24"
    ></path>
    <path
        fill="none"
        stroke-linejoin="round"
        stroke-linecap="round"
        stroke-miterlimit="4"
        stroke-width="2.4"
        stroke="currentColor"
        d="M4 24h24"
    ></path>
</svg>
