<main class="account-container relative flex h-full flex-col p-5">
    <header class="my-3 h-[27px] w-full">
        <a [routerLink]="routeNames.compare.mobile.fullPath()">
            <app-logo-full></app-logo-full>
        </a>
    </header>
    <button
        (click)="back()"
        class="bold my-6 mr-auto flex items-center justify-center text-[14px] uppercase text-electric-blue"
    >
        <fa-icon class="mr-2.5 text-[24px]" [icon]="faChevronLeft"></fa-icon>
        Back
    </button>

    <section class="mb-8">
        <h1 class="mb-4 text-3xl font-semibold">Forgot your password?</h1>
        <p class="text-black">
            Enter your email and we will send you a link to update your password
        </p>
    </section>
    <form (ngSubmit)="submit()" class="flex flex-1 flex-col" [formGroup]="form">
        <div class="flex flex-col">
            <label for="email" class="mb-1.5 text-sm uppercase">Email</label>
            <input
                formControlName="email"
                id="email"
                type="email"
                class="input rounded-xl border border-black p-4"
            />
        </div>
        <small
            *ngIf="
                form.get('email')?.status === 'INVALID' &&
                form.get('email')?.touched &&
                !form.get('email')?.errors?.['required'] &&
                form.get('email')?.errors?.['email']
            "
            class="form-errors"
        >
            Please enter valid email address, eg: example&#64;domain.com
        </small>
        <small
            *ngIf="
                form.get('email')?.status === 'INVALID' &&
                form.get('email')?.touched &&
                form.get('email')?.errors?.['required']
            "
            class="form-errors"
        >
            Email Address is required
        </small>
        <div class="flex flex-1 flex-col justify-end">
            <button
                name="Submit forgot password form"
                type="submit"
                [disabled]="form.get('email')?.errors"
                class="btn mt-12 h-14 self-stretch rounded-full bg-electric-blue uppercase text-white"
            >
                Send
            </button>
        </div>
    </form>
</main>
