<div *ngIf="state.message()" @toastAnimation class="toast">
    <div class="min-w alert flex min-h-12 justify-between border border-black bg-off-white">
        <span>{{ state.message()?.message }}</span>
        <button
            class="btn btn-secondary"
            *ngIf="state.message()?.dismissable"
            (click)="state.dismiss()"
            >Dismiss</button
        >
    </div>
</div>
