import { Component, Input } from "@angular/core";
import { UserModel } from "../../models/user";

@Component({
    selector: "app-vip-card",
    standalone: true,
    templateUrl: "./vip-card.component.html",
    styleUrls: ["./vip-card.component.scss"],
})
export class VipCardComponent {
    @Input({ required: true }) user!: UserModel;
}
