import { Component, Input } from "@angular/core";
import { NgIf, NgOptimizedImage } from "@angular/common";
import { DealModel } from "../../models/deal";

@Component({
    selector: "app-tags-row",
    standalone: true,
    imports: [NgIf, NgOptimizedImage],
    templateUrl: "./tags-row.component.html",
    styleUrl: "./tags-row.component.scss",
})
export class TagsRowComponent {
    @Input({ required: true }) deal!: DealModel;

    get isVip() {
        return !!this.deal?.deal_is_vip;
    }

    get isOnCredit() {
        return this.deal?.device_payment_type === 'On credit';
    }

}
