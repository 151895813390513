<main class="account-container relative flex h-full flex-col p-5">
    <header class="my-3 h-[27px] w-full">
        <a [routerLink]="routeNames.compare.mobile.fullPath()"><app-logo-full></app-logo-full></a>
    </header>
    <button
        (click)="back()"
        class="bold my-6 mr-auto flex items-center justify-center text-[14px] uppercase text-electric-blue"
    >
        <fa-icon class="mr-2.5 text-[24px]" [icon]="faChevronLeft"></fa-icon>
        Back
    </button>
    <section class="mb-8">
        <h1 class="mb-4 text-3xl font-semibold">Create an account</h1>
        <p class="text-black">
            Already have an account?
            <a
                [queryParams]="fromPage ? { from: fromPage } : undefined"
                [routerLink]="routeNames.login.fullPath()"
                class="text-electric-blue underline"
                >Login here</a
            >
        </p>
    </section>
    <form (ngSubmit)="submit()" [formGroup]="registerUserForm" class="mb-2">
        <h6 class="text-sm uppercase text-opacity-60">Step 1 of 3</h6>
        <h3 class="mb-8 text-xl font-semibold">Fill out your information</h3>
        <div class="flex flex-col">
            <label for="full_name" class="mb-1.5 text-sm uppercase">Full name</label>
            <input
                formControlName="full_name"
                id="full_name"
                type="text"
                class="input rounded-xl border border-black p-4"
            />
        </div>
        <small
            *ngIf="
                registerUserForm.get('full_name')?.status === 'INVALID' &&
                registerUserForm.get('full_name')?.touched &&
                registerUserForm.get('full_name')?.errors?.['required']
            "
            class="text-error-red"
        >
            Full Name is required
        </small>
        <div class="mt-8 flex flex-col">
            <label for="email" class="mb-1.5 text-sm uppercase">email</label>
            <input
                formControlName="email"
                id="email"
                type="email"
                class="input rounded-xl border border-black p-4"
            />
        </div>
        <small
            *ngIf="
                registerUserForm.get('email')?.status === 'INVALID' &&
                registerUserForm.get('email')?.touched &&
                registerUserForm.get('email')?.errors?.['required']
            "
            class="text-error-red"
        >
            Email Address is required
        </small>

        <small
            *ngIf="
                registerUserForm.get('email')?.status === 'INVALID' &&
                registerUserForm.get('email')?.touched &&
                !registerUserForm.get('email')?.errors?.['required'] &&
                registerUserForm.get('email')?.errors?.['email']
            "
            class="text-error-red"
        >
            Please enter valid email address, eg: example&#64;domain.com
        </small>

        <app-password-field
            autocomplete="new-password"
            [control]="getFormControl('password')"
        ></app-password-field>

        <div class="mt-4 flex items-center justify-center gap-1">
            <span
                *ngFor="let i of [1, 2, 3]"
                class="h-2 flex-1 rounded-full"
                [ngClass]="{
                    'bg-error-red': i <= passwordLevel && passwordLevel === 1,
                    'bg-electric-blue': i <= passwordLevel && passwordLevel === 2,
                    'bg-success-green': i <= passwordLevel && passwordLevel === 3,
                    'bg-black bg-opacity-20': i > passwordLevel
                }"
            ></span>
            <p class="w-14 text-right text-sm uppercase">{{ passwordLevelText }}</p>
        </div>
        <small
            *ngIf="
                registerUserForm.get('password')?.status === 'INVALID' &&
                registerUserForm.get('password')?.touched &&
                registerUserForm.get('password')?.errors?.['required']
            "
            class="text-error-red"
        >
            Password is required
        </small>
        <small
            *ngIf="
                registerUserForm.get('password')?.status === 'INVALID' &&
                registerUserForm.get('password')?.touched &&
                registerUserForm.get('password')?.errors?.['pattern']
            "
            class="text-error-red"
        >
            Your password must be at least 8 characters long, contain at least one number, contain
            at least one special character and have a mixture of uppercase and lowercase letters.
        </small>

        <h6 class="mb-2 mt-[70px] text-sm uppercase text-opacity-60">Step 2 of 3</h6>
        <h3 class="mb-8 text-xl font-semibold">Accept terms and conditions</h3>
        <p class="mb-4 text-black text-opacity-60">Noggin is a Credit Broker, not a Lender.</p>
        <p class="mb-4 text-black text-opacity-60">
            Noggin cannot show you your credit score(s), but we can show you repayment data linked
            to this product with your consent.
        </p>
        <p class="mb-4 text-black text-opacity-60">
            Noggin is not a Credit Reference Agency and cannot determine your credit score.
        </p>
        <p class="mb-10 text-black text-opacity-60">
            Noggin will never sell any of the data you choose to share to support our research. The
            data you share will be used for our own internal learning and will not be commodified in
            any way.
        </p>

        <div class="mb-4 flex">
            <input
                id="email_opt_in"
                formControlName="email_opt_in"
                type="checkbox"
                checked="checked"
                class="checkbox-primary checkbox mr-2.5"
            />
            <label for="email_opt_in">I would like to recieve emails about upcoming offers</label>
        </div>
        <div class="flex">
            <input
                id="terms_agree"
                formControlName="terms_agree"
                type="checkbox"
                checked="checked"
                class="checkbox-primary checkbox mr-2.5"
            />
            <label for="terms_agree">
                I have read and accept Noggin's
                <a class="text-electric-blue underline">Privacy Policy</a>
                and
                <a class="text-electric-blue underline">Terms & Conditions</a>
            </label>
        </div>
        <small
            *ngIf="
                registerUserForm.get('terms_agree')?.status === 'INVALID' &&
                registerUserForm.get('terms_agree')?.touched
            "
            class="text-error-red"
        >
            To register you must agree to the terms
        </small>

        <button
            name="Submit"
            type="submit"
            [disabled]="!registerUserForm.valid"
            class="btn mx-auto mb-8 mt-14 block h-14 w-full self-stretch rounded-full bg-electric-blue text-center uppercase text-white"
        >
            Confirm & proceed to Final step
        </button>
    </form>
</main>
