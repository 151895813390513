<div
    @navBackgroundAnimation
    *ngIf="this.menuHovered()"
    class="pointer-events-none fixed bottom-0 left-0 right-0 top-28 bg-black bg-opacity-20"
></div>
<div class="h-full" (mouseenter)="itemHovered(true)" (mouseleave)="itemHovered(false)">
    <a [routerLink]="config.link" class="flex h-full items-center justify-center">
        <span>
            {{ config.title }}
            <span
                class="origin-center !rotate-180 transform transition-transform group-hover:rotate-180"
            >
                <fa-icon [icon]="faChevronDown"></fa-icon>
            </span>
        </span>
    </a>

    <section
        [ngClass]="{ 'bg-black text-off-white': dark, 'bg-off-white text-black': !dark }"
        class="absolute left-0 right-0 top-[72px]"
    >
        <div
            id="{`accordion-text-01`}"
            role="region"
            aria-labelledby="{`accordion-title-01`}"
            class="grid overflow-hidden border-b transition-all duration-300 ease-in-out"
            [ngClass]="
                this.menuHovered()
                    ? 'grid-rows-[1fr] pt-1 opacity-100'
                    : 'grid-rows-[0fr] opacity-0'
            "
        >
            <div class="container flex justify-evenly overflow-hidden">
                <div class="" *ngFor="let section of config.sections">
                    <h3 class="mb-5 text-md font-semibold uppercase" *ngIf="!section.link; else sectionTitleWithLink">
                        {{ section.title }}
                    </h3>
                    <ng-template #sectionTitleWithLink> 
                        <h3 class="mb-5 text-md font-semibold uppercase">
                            <a [routerLink]="section.link">{{ section.title }}</a>
                        </h3>
                    </ng-template>
                    <ul class="mb-8">
                        <li
                            *ngFor="let option of section.options"
                            class="mb-4 flex items-start justify-start"
                        >
                            <span
                                class="mr-3 mt-1.5 h-3 w-3 rounded-full"
                                [ngClass]="{
                                    'bg-green-gradient': option.type === 'article',
                                    'bg-black': option.type === 'link' && !dark,
                                    'bg-off-white': option.type === 'link' && dark
                                }"
                            ></span>
                            <ng-container *ngIf="option.type === 'link'; else article">
                                <a
                                    [routerLink]="option.link"
                                    class="text-base font-semibold hover:underline"
                                    [queryParams]="option.params"
                                    (click)="sendAnalyticsNav(option.title)"
                                >
                                    {{ option.title }}
                                </a>
                            </ng-container>
                            <ng-template #article>
                                <a
                                    [routerLink]="option.link"
                                    class="text-base font-semibold hover:underline"
                                    [queryParams]="option.params"
                                    (click)="sendAnalyticsNav(option.title)"
                                >
                                    {{ option.title }}
                                    <p class="text-md font-normal" *ngIf="option.subtitle">{{
                                        option.subtitle
                                    }}</p>
                                </a>
                            </ng-template>
                        </li>
                    </ul>
                    <ng-container *ngIf="section.footerLink">
                        <a
                            [routerLink]="section.footerLink.link"
                            class="my-8 block text-base font-normal hover:underline"
                        >
                            {{ section.footerLink.title }}
                            <fa-icon class="ml-1 text-md" [icon]="faChevronRight"></fa-icon>
                        </a>
                    </ng-container>
                </div>
            </div>
        </div>
    </section>
</div>
