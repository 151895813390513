import { Component, HostBinding, Input } from "@angular/core";
import { CommonModule, NgOptimizedImage } from "@angular/common";

@Component({
    selector: "home-disclaimer-graphic",
    standalone: true,
    imports: [CommonModule, NgOptimizedImage],
    templateUrl: "./disclaimer-graphic.component.html",
    styleUrls: ["./disclaimer-graphic.component.scss"],
})
export class DisclaimerGraphicComponent {
    @Input("class") @HostBinding("class") className = "";
}
