<section class="mx-5 mb-14">
    <div class="mb-6 flex flex-col items-center justify-center">
        <h4 class="text-sm">BLOG</h4>
        <h2 class="text-3xl font-semibold uppercase">BLOGGIN HQ</h2>
    </div>
    <ul class="mx-auto flex flex-row flex-wrap justify-center gap-8">
        <li *ngFor="let article of articles">
            <home-blog-card [article]="article"></home-blog-card>
        </li>
    </ul>
</section>
