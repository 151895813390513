<section class="relative overflow-x-clip bg-black py-16">
    <img
        class="absolute left-[180px] top-[-130px] z-30 hidden h-40 w-40 rotate-[-15deg]"
        [ngClass]="{
            'xl:block': true,
            '2xl:left-[200px] 2xl:top-[-170px] 2xl:h-[230px] 2xl:w-[230px]': true
        }"
        role="presentation"
        alt=""
        ngSrc="assets/svg/face-green.svg"
        height="3rem"
        width="3rem"
    />

    <img
        class="absolute -right-8 top-[10px] hidden h-32 w-32 rotate-[30deg]"
        [ngClass]="{
            'lg:top-24 lg:block lg:h-40 lg:w-40': true,
            'xl:-right-12 xl:top-16 xl:h-52 xl:w-52': true,
            '2xl:top-36 2xl:h-[266px] 2xl:w-[266px]': true
        }"
        role="presentation"
        alt=""
        ngSrc="assets/svg/face-red.svg"
        height="3rem"
        width="3rem"
    />
    <img
        class="absolute left-[-30px] top-48 hidden h-40 w-40 rotate-[-15deg]"
        [ngClass]="{
            'lg:block': true,
            'xl:left-[-70px] xl:top-44 xl:h-[214px] xl:w-[214px]': true,
            '2xl:top-60': true
        }"
        role="presentation"
        alt=""
        ngSrc="assets/svg/face-blue.svg"
        height="3rem"
        width="3rem"
    />

    <div class="container relative z-10 mx-auto">
        <div class="items-center justify-center lg:flex">
            <h1
                class="mx-auto font-display text-5xl font-bold uppercase tracking-tight text-off-white md:text-[70px] md:leading-[90px] lg:text-center xl:text-[90px] xl:leading-[90px] 2xl:text-[110px] 2xl:leading-[110px]"
            >
                help<span class="ml-3.5 inline-block lg:hidden">
                    <img
                        class="h-9 w-9 md:h-12 md:w-12"
                        role="presentation"
                        alt=""
                        ngSrc="assets/svg/face-green.svg"
                        height="3rem"
                        width="3rem"
                    />
                </span>
                us make<br class="lg:hidden" />
                access<span class="ml-3.5 inline-block lg:hidden">
                    <img
                        class="h-9 w-9 md:h-12 md:w-12"
                        role="presentation"
                        alt=""
                        ngSrc="assets/svg/face-red.svg"
                        height="3rem"
                        width="3rem"
                    /> </span
                ><br class="lg:hidden" />
                to<span class="ml-3.5 inline-block lg:hidden">
                    <img
                        class="h-9 w-9 md:h-12 md:w-12"
                        role="presentation"
                        alt=""
                        ngSrc="assets/svg/face-blue.svg"
                        height="3rem"
                        width="3rem"
                    /> </span
                ><br class="hidden lg:block" />
                credit fairer
            </h1>
        </div>
        <p
            class="mt-8 max-w-[738px] border-b border-b-off-white pb-8 text-lg text-off-white lg:mx-auto lg:mt-11 lg:text-center lg:text-2xl"
        >
            Noggin is on a mission to power equitable credit access and help the 14 million people
            in the UK who are facing exclusion due to their credit score.
        </p>
        <section class="flex justify-center">
            <div class="mx-auto inline-flex flex-col items-center">
                <h2
                    class="my-4 self-start font-display text-3xl font-semibold text-off-white lg:mx-auto lg:my-10 lg:text-center lg:text-[60px]"
                    >HOW?</h2
                >
                <div
                    class="flex flex-col items-center justify-center gap-4 lg:flex-row lg:items-stretch"
                >
                    <div class="bg-blue-gradient w-full max-w-[350px] rounded-3xl p-[5px]">
                        <div class="h-full rounded-3xl bg-off-white p-4">
                            <h3 class="font-display text-2xl font-semibold">Step 1</h3>
                            <p class="text-md"
                                >Find the perfect deal for you, by exploring over 10,000 deals on
                                our price comparison platform.</p
                            >
                        </div>
                    </div>

                    <div class="bg-red-gradient w-full max-w-[350px] rounded-3xl p-[5px]">
                        <div class="h-full rounded-3xl bg-off-white p-4">
                            <h3 class="font-display text-2xl font-semibold">Step 2</h3>
                            <p class="text-md"
                                >Become a Noggin member and share Open Banking data. To say thank
                                you, we’ve created VIP deals with cash back offers!</p
                            >
                        </div>
                    </div>

                    <div class="bg-lime-to-yellow w-full max-w-[350px] rounded-3xl p-[5px]">
                        <div class="h-full rounded-3xl bg-off-white p-4">
                            <h3 class="font-display text-2xl font-semibold">Step 3</h3>
                            <p class="text-md"
                                >Using this data, we’ll work hard to build a credit score that will
                                create a new route for people to access credit they need, fairly.</p
                            >
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</section>
