<ng-container *ngIf="user && bankingState.connected()">
    <app-vip-card [user]="user"></app-vip-card
></ng-container>
<div class="mt-8 flex justify-between border-b pb-4">
    <p class="">Open banking status</p>

    <span *ngIf="bankingState.connected()" class="flex">
        <span class="mr-3 h-5 w-5 rounded-full bg-success-green"></span>
        <p>Connected</p>
    </span>
    <span *ngIf="!bankingState.connected()" class="flex">
        <span class="mr-3 h-5 w-5 rounded-full bg-error-red"></span>
        <p>Disconnected</p>
    </span>
</div>
<p class="mt-4" *ngIf="user?.user_detail?.banking_paired && bankingState.connected()"
    >Automatically disconnects in <strong>{{ bankingState.daysLeft(user) }} days</strong></p
>
