<section class="pt-8 md:pt-12 relative">
    <div class="flex flex-col md:flex-row relative rounded-3xl py-8 md:py-10 px-6 md:px-10 mx-4 md:mx-8 lg:mx-auto max-w-[880px] grid grid-rows-auto place-items-center bg-[#FFFFFF] drop-shadow">
        <h1 class="mx-auto font-display font-black text-3xl md:text-5xl lg:text-7xl text-center w-full leading-tight">
            {{ content_hero_title }}
        </h1>
        <p
            class="mt-4 md:mt-6 text-base md:text-xl text-center text-black text-opacity-60"
            [innerHTML]="content_hero_subtitle"
        ></p>

        <a
            class="btn btn-primary btn-wide rounded-full mt-4 md:mt-6 uppercase text-white"
            [href]="createAccount"
            (click)="userClickedApply(1)"
            (keyup.enter)="userClickedApply(1)"
        >
            Check Eligibility
        </a>
        <h2 class="mt-4 md:mt-6 text-sm uppercase text-black text-opacity-60">{{ content_hero_disclaimer }}</h2>
    </div>

    <section class="mt-8 mb-4 z-40">
        <h2 class="text-center text-lg uppercase">As seen in</h2>
        <div class="mx-auto mt-4 flex flex-wrap items-center justify-around max-w-[440px] px-8 z-40">
            <a
                class="h-[72px] w-[72px] overflow-hidden rounded-full border object-cover object-center z-40"
                href="https://netimesmagazine.co.uk/magazine/five-minutes-with-evangeline-atkinson-and-laura-mills/"
                target="_blank"
            >
                <figure class="h-[72px] w-[72px]">
                    <img
                        class="h-full w-full object-cover object-center"
                        alt="North East Times Magazine logo"
                        ngSrc="/assets/images/netimesmagazine_logo_2.png"
                        height="72"
                        width="72"
                    />
                </figure>
            </a>

            <a
                class="h-[72px] w-[72px] overflow-hidden rounded-full border object-cover object-center z-40"
                href="https://techfundingnews.com/female-founded-noggin-hq-bags-710k-to-help-users-get-access-to-credit/"
                target="_blank"
            >
                <figure >
                    <img
                        class="h-full w-full object-cover object-center"
                        alt="Tech Funding News logo"
                        ngSrc="/assets/images/techfundingnews_logo.png"
                        height="72"
                        width="72"
                    />
                </figure>
            </a>

            <a
                class="h-[72px] w-[72px] overflow-hidden rounded-full border object-cover object-center bg-[#FDCFB3] z-40"
                href="https://www.ft.com/content/9f9d3a54-d08b-4d9c-a000-d50460f818dc"
                target="_blank"
            >
                <figure>
                    <img
                        class="h-full w-full object-cover object-center mt-[14px]"
                        alt="Financial Times logo"
                        ngSrc="/assets/images/ft_logo.jpeg"
                        height="72"
                        width="72"
                    />
                </figure>
            </a>
        </div>
    </section>
    <div
        class="absolute bg-transparent bg-repeat-x w-[102%] h-[170px] p-0 mr-0 my-0 -mx-[2%] border-none z-30 -bottom-[180px] overflow-hidden"
        style="background-image: url(assets/svg/drip.svg);"
    ></div>
</section>

<section class="flex flex-col bg-black pt-[180px] min-h-[500px] relative pb-28">
    <div class="flex flex-col items-center justify-center">
        <h2 class="mx-auto mb-10 text-center text-3xl lg:text-5xl text-off-white font-semibold uppercase order-2">
            {{ content_testimonials_heading }}
        </h2>
        <h3 class="mx-auto mb-3 block text-center text-sm text-off-white uppercase order-1">
            {{ content_testimonials_subheading }}
        </h3>
    </div>

    <div class="flex flex-col gap-8 md:flex-row md:flex-wrap items-center justify-center z-20 mx-4 md:mx-8">
        <div class="flex px-8 py-8 bg-off-white rounded-3xl max-w-[400px] relative" *ngFor="let testimonial of content_testimonials">
            <span class="absolute top-3 left-3 font-display font-black text-3xl">“</span>
            <p class="text-center">{{ testimonial.text }}</p>
            <span class="absolute bottom-1 right-3 font-display font-black text-3xl">”</span>
        </div>
    </div>
    <div
        class="absolute bg-transparent bg-repeat-x w-full x-w-[102%] h-[170px] p-0 mr-0 my-0 x-mx-[2%] border-none z-10 -bottom-[160px] overflow-hidden z-10"
        style="background-image: url(assets/svg/drip_black.svg);"
    ></div>
</section>

<section class="flex flex-col pt-[120px]">
    <app-cms-carousel
        [dark]="false"
        [carouselIndex]="4"
        class="block"
        [showSecondDescriptionRow]="false"
        [itemsClickable]="false"
        padding="pt-12"
        [title]="phone_deal_carousel_heading"
        [subtitle]="phone_deal_carousel_subheading"
        backgroundColourPhoneCard="bg-lime-green"
    ></app-cms-carousel>
    <a
        class="btn btn-primary btn-wide rounded-full mt-12 uppercase text-white place-self-center"
        [href]="createAccount"
        (click)="userClickedApply(2)"
        (keyup.enter)="userClickedApply(2)"
    >
        {{ phone_deal_carousel_button }}
    </a>
</section>

<section class="mt-20 mb-20 mx-8 lg:mx-auto max-w-[880px]">
    <app-faq
        filterByTag="noggin-no-credit-check-phone-faq"
    ></app-faq>
</section>
