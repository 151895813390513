<main class="relative flex h-full flex-col p-5">
    <div class="">
        <header class="my-3 h-[27px] w-full">
            <a [routerLink]="routeNames.noCreditCheckPhones.fullPath()">
                <app-logo-full></app-logo-full>
            </a>
        </header>

        <router-outlet></router-outlet>
    </div>
</main>
