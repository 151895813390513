import { Component, Input, OnInit } from "@angular/core";
import { NgClass } from "@angular/common";
import { FaIconComponent } from "@fortawesome/angular-fontawesome";
import { faChevronDown, faChevronLeft } from "@fortawesome/free-solid-svg-icons";

@Component({
    selector: "deals-filter-collapse",
    standalone: true,
    imports: [NgClass, FaIconComponent],
    templateUrl: "./filter-collapse.component.html",
    styleUrl: "./filter-collapse.component.scss",
})
export class FilterCollapseComponent implements OnInit {
    @Input() defaultOpen = false;
    @Input() padding = "py-5";
    @Input({ required: true }) title!: string;
    open!: boolean;
    ngOnInit() {
        this.open = this.defaultOpen;
    }

    toggle() {
        this.open = !this.open;
    }

    protected readonly faChevronLeft = faChevronLeft;
    protected readonly faChevronDown = faChevronDown;
}
