import { Component, effect, Input } from "@angular/core";
import { DealFilterComponent } from "../deal-filter/deal-filter.component";
import { CreditToggleComponent } from "../credit-toggle/credit-toggle.component";
import { DealType } from "../../models/filter";

@Component({
    selector: "app-desktop-filter",
    standalone: true,
    imports: [DealFilterComponent, CreditToggleComponent],
    templateUrl: "./desktop-filter.component.html",
    styleUrl: "./desktop-filter.component.scss",
})
export class DesktopFilterComponent {
    @Input({ required: true }) dealType!: DealType;
}
