<article *ngIf="websiteContent" class="mx-auto max-w-[880px] p-5">
    <h1 class="mb-10 mt-6 text-5xl font-semibold">{{ websiteContent?.title }}</h1>
    <ng-container *ngIf="websiteContent.image">
        <img
            *ngIf="websiteContent?.image"
            ngSrc="{{ websiteContent?.image }}"
            alt="Image describing: {{ websiteContent?.title }}"
            width="350px"
            height="240px"
            class="mt-7 w-full rounded-3xl object-contain"
        />
    </ng-container>
    <section
        class="prose mt-8 font-sans lg:prose-xl prose-headings:my-0 prose-p:my-0 prose-img:mx-auto prose-img:my-0"
        [innerHTML]="content"
    ></section>
</article>
