import { Component } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common'
import { NogginPhoneTileComponent } from 'src/app/noggin-phone/noggin-phone-tile/noggin-phone-tile.component';
import { UserService } from 'src/app/user/services/user.service';
import { routeNames } from 'src/app/app-routing.module';
import { RouterLinkWithHref } from '@angular/router';

@Component({
    selector: 'app-apply-noggin-phone-start',
    standalone: true,
    imports: [
        CommonModule,
        NgOptimizedImage,
        NogginPhoneTileComponent,
        RouterLinkWithHref
    ],
    templateUrl: './apply-noggin-phone-start.component.html',
    styleUrl: './apply-noggin-phone-start.component.scss'
})
export class ApplyNogginPhoneStartComponent {
    firstName!: string;

    constructor(private userService: UserService) { }

    ngOnInit(): void {
        this.userService.getUserInfo().subscribe((user) => {
            this.firstName = user.first_name;
        });
    }

    get openBankingReasons() {
        return [
            {
                main: "You live in the United Kingdom.",
                details: "We can only offer phone contracts to people who live in the UK (Scotland, England, Northern Ireland and Wales)."
            },
            {
                main: "You are over the age of 18.",
                details: "We are only able to offer our “no credit check” phone contract to people over 18."

            },
            {
                main: "You do not have an active IVA or have recently been declared bankrupt.",
                details: "We unfortunately won’t be able to offer our phone contract to people who have an active IVA or have been recently declared bankrupt."
            },
        ]
    }

    get forwardUrl() {
        return routeNames.getStarted.shareOpenBanking.fullPath();
    }
}
