import { Component, OnInit, signal } from "@angular/core";
import { UserModel } from "../../models/user";
import { UserService } from "../../services/user.service";
import { NgIf } from "@angular/common";
import { VipCardComponent } from "../../components/vip-card/vip-card.component";
import { LogoFullComponent } from "../../../core/components/logo-full/logo-full.component";
import { ActivatedRoute, Router, RouterLink } from "@angular/router";
import { routeNames } from "../../../app-routing.module";
import { DealService } from "../../../deal/services/deal.service";
import { LoadingComponent } from "../../../core/components/loading/loading.component";
import { BankingState } from "../../state/banking.state";
import { FaIconComponent } from "@fortawesome/angular-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { NotifyState } from "src/app/core/state/notify.state";

@Component({
    selector: "app-vip-offer",
    standalone: true,
    templateUrl: "./vip-offer.component.html",
    styleUrls: ["./vip-offer.component.scss"],
    imports: [
        NgIf,
        VipCardComponent,
        LogoFullComponent,
        RouterLink,
        LoadingComponent,
        FaIconComponent,
    ],
})
export class VipOfferComponent implements OnInit {
    dealId: string;
    linkUrl?: string;
    linkLoading = signal(true);
    voucherCode?: string;
    constructor(
        public userService: UserService,
        private dealService: DealService,
        private route: ActivatedRoute,
        private router: Router,
        public bankingState: BankingState,
        private notify: NotifyState,
    ) {
        this.dealId = this.route.snapshot.params["id"];
    }

    ngOnInit() {
        this.userService.getUserInfo().subscribe();

        this.dealService.getDealLink(parseInt(this.dealId)).subscribe({
            next: (res) => {
                this.linkUrl = res.url;
                this.voucherCode = res.members_benefit_voucher_code;
            },
            error: () => {
                this.router.navigate([routeNames.notFound.fullPath()]);
            },
            complete: () => {
                this.linkLoading.set(false);
            },
        });
    }
    get user(): UserModel {
        return this.userService.currentUser()!;
    }

    copy() {
        if (this.linkUrl) {
            navigator.clipboard.writeText(this.voucherCode ?? "testing").then(() => {
                this.notify.showMessage({
                    message: "Voucher code copied to clipboard",
                });
            });
        }
    }

    protected readonly routeNames = routeNames;
    protected readonly faCopy = faCopy;
}
