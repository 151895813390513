<ng-container *ngIf="bankingState.loading() && !bankingState.connected(); else content">
    <app-loading class="mt-5 block"></app-loading>
</ng-container>
<ng-template #content>
    <button
        *ngIf="bankingState.connected()"
        [ngClass]="disconnectBtnClass"
        (click)="bankingState.disconnect()"
    >
        <ng-container *ngIf="!bankingState.loading()">disconnect</ng-container>
        <span *ngIf="bankingState.loading()" class="loading loading-spinner loading-md"></span>
    </button>
    <button
        (Success)="bankingState.onPlaidSuccess($event)"
        *ngIf="!bankingState.connected() && !!bankingState.linkToken()"
        [countryCodes]="['GB']"
        [env]="bankingState.environment"
        [product]="['transactions']"
        [token]="bankingState.linkToken()!"
        [ngClass]="connectBtnClass"
        ngxPlaidLink
    >
        {{ connectText }}
    </button>
</ng-template>
