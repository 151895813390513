<svg
    [ngClass]="className"
    xmlns="http://www.w3.org/2000/svg"
    [attr.height]="size"
    [attr.width]="size"
    viewBox="0 0 32 32"
>
    <path
        fill="none"
        stroke-linejoin="round"
        stroke-linecap="butt"
        stroke-miterlimit="4"
        stroke-width="2.4"
        [attr.stroke]="color"
        d="M16 25.6c-5.592 0-10.433-3.98-12.8-9.782 2.367-5.802 7.208-9.418 12.8-9.418s10.433 3.616 12.8 9.418c-2.367 5.802-7.208 9.782-12.8 9.782z"
    ></path>
    <path
        fill="none"
        stroke-linejoin="miter"
        stroke-linecap="butt"
        stroke-miterlimit="4"
        stroke-width="2.4"
        [attr.stroke]="color"
        d="M20 16c0 2.209-1.791 4-4 4s-4-1.791-4-4c0-2.209 1.791-4 4-4s4 1.791 4 4z"
    ></path>
    <path
        fill="none"
        stroke-linejoin="round"
        stroke-linecap="round"
        stroke-miterlimit="4"
        stroke-width="2.4"
        [attr.stroke]="color"
        d="M16 6v-4"
    ></path>
    <path
        fill="none"
        stroke-linejoin="round"
        stroke-linecap="round"
        stroke-miterlimit="4"
        stroke-width="2.4"
        [attr.stroke]="color"
        d="M24 8.4l2-3.464"
    ></path>
    <path
        fill="none"
        stroke-linejoin="round"
        stroke-linecap="round"
        stroke-miterlimit="4"
        stroke-width="2.4"
        [attr.stroke]="color"
        d="M8 8.4l-2-3.464"
    ></path>
</svg>
