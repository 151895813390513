import { Component } from '@angular/core';
import { Router, RouterLinkWithHref } from '@angular/router';
import { routeNames } from 'src/app/app-routing.module';
import { NogginPhoneTileComponent } from 'src/app/noggin-phone/noggin-phone-tile/noggin-phone-tile.component';

@Component({
    selector: 'app-finish-my-application',
    standalone: true,
    imports: [
        RouterLinkWithHref,
        NogginPhoneTileComponent
    ],
    templateUrl: './finish-my-application.component.html',
    styleUrl: './finish-my-application.component.scss'
})
export class FinishMyApplicationComponent {

    constructor(private router: Router) { }

    next() {
        return this.router.navigateByUrl(
            routeNames.typeform.fullPath()
        );
    }
}
