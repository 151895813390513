import { Component, Input } from "@angular/core";
import { NgForOf } from "@angular/common";
import { FaIconComponent } from "@fortawesome/angular-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

@Component({
    selector: "deals-perks",
    standalone: true,
    imports: [NgForOf, FaIconComponent],
    templateUrl: "./perks.component.html",
    styleUrl: "./perks.component.scss",
})
export class PerksComponent {
    @Input({ required: true }) perks!: string[];
    protected readonly faInfoCircle = faInfoCircle;
}
