<div
    class="flex flex-col md:flex-row relative rounded-3xl py-8 md:py-10 px-6 md:px-10 grid grid-rows-auto place-items-center bg-green-gradient"
    [ngClass]="additionalClasses"
>
    <h2 class="mx-auto font-display font-black text-3xl md:text-3xl text-center w-full mt-4 leading-tight">
      {{ heading }}
    </h2>
    <p *ngIf="caption" class="text-sm md:text-base uppercase -order-1 font-thin text-center text-black text-opacity-80">{{ caption }}</p>
    <p class="mt-4 text-lg md:text-xl text-center text-black text-opacity-80">
      {{ text }}
    </p>
</div>

