<main class="account-container relative flex h-screen flex-col p-5">
    <header class="mb-3 mt-3 h-[27px] w-full">
        <a [routerLink]="routeNames.compare.default.fullPath()">
            <app-logo-full></app-logo-full>
        </a>
    </header>
    <h1 class="mt-10 text-3xl font-semibold">Welcome you’re now a Noggin VIP!</h1>
    <p class="mt-6">
        We will receive a fee for introducing you to X (cheers pal) that will go towards improving
        our service!
    </p>
    <ng-container *ngIf="user">
        <app-vip-card [user]="user"></app-vip-card>
    </ng-container>
    <p class="mx-auto mt-6 text-sm uppercase">
        valid for
        <strong>{{ bankingState.daysLeft(user) }} days</strong>
    </p>
    <section class="mt-12" *ngIf="voucherCode">
        <h6 class="mb-1.5 text-sm uppercase">your voucher code</h6>
        <div class="flex h-14 items-center justify-between rounded-xl border px-4">
            <p class="font-semibold">{{ voucherCode }}</p>
            <button (click)="copy()" class="flex h-full items-center">
                <fa-icon [icon]="faCopy" class="text-2xl text-electric-blue"></fa-icon>
            </button>
        </div>
    </section>
    <div class="mt-12 flex flex-col">
        <span *ngIf="linkLoading()" class="btn h-14 self-stretch rounded-full bg-electric-blue uppercase text-white">
            <span class="loading loading-bars loading-lg text-off-white"></span>
        </span>
        <a *ngIf="!linkLoading()" [href]="linkUrl" target="_blank"
            class="btn h-14 self-stretch rounded-full bg-electric-blue uppercase text-white">
            continue to your offer
        </a>
    </div>
</main>
