import { AfterViewInit, Component, ElementRef, ViewChild } from "@angular/core";

@Component({
    selector: "app-cookies-page",
    standalone: true,
    imports: [],
    templateUrl: "./cookies-page.component.html",
    styleUrl: "./cookies-page.component.scss",
})
export class CookiesPageComponent implements AfterViewInit {
    @ViewChild("script") script!: ElementRef;
    // <script
    //     id="CookieDeclaration"
    //     src="https://consent.cookiebot.com/d0f12e95-bfdc-45d4-9dda-3ad0cd381539/cd.js"
    //     type="text/javascript"
    //     async
    // ></script>
    //
    //
    ngAfterViewInit(): void {
        const element = this.script.nativeElement;
        const script = document.createElement("script");
        script.id = "CookieDeclaration";
        script.src = "https://consent.cookiebot.com/d0f12e95-bfdc-45d4-9dda-3ad0cd381539/cd.js";
        script.type = "text/javascript";
        script.async = true;
        element.appendChild(script);
    }
}
