<section class="container flex flex-col gap-14 lg:flex-row lg:gap-24">
    <div class="flex-1">
        <h4 class="mb-3 text-sm uppercase text-black">Noggin members</h4>
        <h3 class="mb-3 text-3xl font-semibold text-black">Help us make finance fairer</h3>
        <p class="">
            We’re a small start-up with big goals: to power equitable credit access. Part of the FCA
            Sandbox, our research aims to address the exclusion currently faced by 7 million people
            in the UK. Become a Noggin member to help, and unlock exclusive VIP deals as a token of
            our appreciation!
        </p>
        <a
            [routerLink]="routeNames.ourResearch.fullPath()"
            class="btn mt-9 h-14 w-full rounded-full bg-black text-md uppercase text-white lg:max-w-40"
        >
            read more
        </a>
    </div>
    <div class="flex-1">
        <div class="flex items-start gap-4">
            <img
                class="h-8 w-8"
                alt="bullet point"
                ngSrc="assets/svg/bullet-tick.svg"
                height="32"
                width="32"
            />
            <div class="mb-12">
                <h5 class="mb-1.5 text-xl font-semibold uppercase text-black">
                    Endless choices, yours to explore
                </h5>
                <p class="">
                    Explore over 9,000 deals tailored just for you - your perfect phone or SIM
                    awaits!
                </p>
            </div>
        </div>

        <div class="flex items-start gap-4">
            <img
                class="h-8 w-8"
                alt="bullet point"
                ngSrc="assets/svg/bullet-tick.svg"
                height="32"
                width="32"
            />
            <div class="mb-12">
                <h5 class="mb-1.5 text-xl font-semibold uppercase text-black">
                    VIP Deals for Noggin Members
                </h5>
                <p class="">
                    Become a Noggin member and access exclusive VIP deals. Because you deserve it
                    sweetie!
                </p>
            </div>
        </div>
        <div class="flex items-start gap-4">
            <img
                alt="bullet point"
                ngSrc="assets/svg/bullet-tick.svg"
                height="32"
                width="32"
                class="h-8 w-8"
            />
            <div class="">
                <h5 class="mb-1.5 text-xl font-semibold uppercase text-black">
                    Our Members are Powering Credit Equality
                </h5>
                <p class="">
                    Join Noggin, share data, become a VIP. Help us to empower equitable credit
                    access for 7 million underserved peers!
                </p>
            </div>
        </div>
        <div class="mt-12 flex justify-center gap-4 lg:hidden">
            <img
                alt="Decoration smiley"
                ngSrc="assets/svg/face-red.svg"
                height="48px"
                width="48px"
                class="h-12 w-12"
            />
            <img
                alt="Decoration smiley"
                ngSrc="assets/svg/face-green.svg"
                height="48px"
                width="48px"
                class="h-12 w-12"
            />

            <img
                alt="Decoration smiley"
                ngSrc="assets/svg/face-blue.svg"
                height="48px"
                width="48px"
                class="h-12 w-12"
            />
        </div>
    </div>
</section>
