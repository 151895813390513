import { Component, Input } from "@angular/core";
import { NgClass, NgForOf } from "@angular/common";
import { FaIconComponent } from "@fortawesome/angular-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { DealFilterState } from "../../state/deal-filter.state";
import { DealOrdering, dealOrdering, DealType } from "../../models/filter";
import { payloadForDealFilterParams } from "src/app/util/analytics";

@Component({
    selector: "deals-order-dropdown",
    standalone: true,
    imports: [NgForOf, NgClass, FaIconComponent],
    templateUrl: "./deal-order-dropdown.component.html",
    styleUrl: "./deal-order-dropdown.component.scss",
})
export class DealOrderDropdownComponent {
    @Input({ required: true }) dealType!: DealType;
    constructor(public dealFilterService: DealFilterState) { }

    setSortBy(value: DealOrdering) {
        const oldOldering = this.dealFilterService.ordering().value;
        this.dealFilterService.setSortBy(value, { updateAutomatically: true });
        (document.activeElement as HTMLElement).blur();
        this.sendAnalytics(oldOldering);
    }

    private sendAnalytics(previousOrdering: string | number | boolean) {
        try {
            gtag(
                "event",
                "deal_list__order_change",
                {
                    ordering_previous: previousOrdering,
                    ...payloadForDealFilterParams(this.dealFilterService.selectedFilters)
                }
            );
        } catch { }
    }

    protected readonly dealOrdering = dealOrdering;
    protected readonly faChevronDown = faChevronDown;
}
