<deals-filter-collapse [defaultOpen]="defaultOpen" [title]="filter.title">
    <ng-container [ngSwitch]="filter.type">
        <ng-container *ngSwitchCase="'radio'">
            <label *ngFor="let item of filter.options" class="label cursor-pointer flex justify-start gap-3">
                <input
                    type="radio"
                    name="{{ filter.key }}"
                    class="radio-primary radio"
                    (click)="toggleRadioFilter(item)"
                    [checked]="isRadioChecked(item.value.toString())"
                />
                <span>{{ item.label }}</span>
            </label
        ></ng-container>

        <ng-container *ngSwitchCase="'data_allowance'">
            <label *ngFor="let item of filter.options" class="label cursor-pointer flex justify-start gap-3">
                <input
                    type="radio"
                    name="{{ filter.key }}"
                    class="radio-primary radio"
                    (click)="toggleDataAllowance(item)"
                    [checked]="
                        item.value === '-1'
                            ? this.dealFilterState.selectedFilters.sim_unlimited_data
                            : this.dealFilterState.selectedFilters.sim_data_allowance_gb_numeric?.toString() ===
                              item.value.toString()
                    "
                /> 
                <span>{{ item.label }}</span>
            </label
        ></ng-container>

        <ng-container *ngSwitchCase="'checkbox'">
            <label *ngFor="let item of filter.options" class="label cursor-pointer flex justify-start gap-3">
                <input
                    type="checkbox"
                    name="{{ filter.key }}"
                    class="checkbox-primary checkbox"
                    (click)="toggleBooleanFilter(item)"
                    [checked]="!!(dealFilterState.selectedFilters[filter.key] ?? false)"
                />
                <span>{{ item.label }}</span>
            </label
        ></ng-container>

        <ng-container *ngSwitchCase="'array'">
            <label *ngFor="let item of filter.options" class="label cursor-pointer flex justify-start gap-3">
                <input
                    type="checkbox"
                    class="checkbox-primary checkbox"
                    (change)="toggleArrayFilter(item)"
                    [checked]="isArrayChecked(item.value.toString())"
                />
                <span>{{ item.label }}</span>
            </label>
        </ng-container>

        <ng-container *ngSwitchCase="'boolean'">
            <div class="flex items-center justify-between flex justify-start gap-3">
                <div id="{{ filter.key }}" class="flex gap-1">
                    <app-toggle-button
                        [active]="!!(dealFilterState.selectedFilters[filter.key] ?? false)"
                        (toggle)="setBooleanFilter($event)"
                    ></app-toggle-button>
                </div>
                <label *ngFor="let option of filter.options" for="{{ filter.key }}_select">{{ option.label }}</label>
            </div>
        </ng-container>
    </ng-container>
</deals-filter-collapse>
