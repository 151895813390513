import { isNever } from "src/app/util/exhaustiveCheck";

export const CustomContentOption = {
    DEFAULT: "default",
    STUDENT: "student",
    THIN_FILE: "thin_file",
    HANDSET_ONLY: "hand_set_only",
    BAD_CREDIT: "bad_credit",
    RENTERS: "renters",
    NEW_TO_COUNTRY: "new_to_country",
} as const;

export type CustomContentOption = typeof CustomContentOption[keyof typeof CustomContentOption];

export function isCustomContentOption(target: string): target is CustomContentOption {
    if (!target) return false;
    return Object.values(CustomContentOption).includes(target as CustomContentOption);
}


export type NoCreditCheckPhoneContent = {
    hero_title: string,
    hero_subtitle: string,
    hero_disclaimer: string,
    testimonials_heading: string,
    testimonials_subheading: string,
    testimonials: { text: string }[],
    phone_deal_carousel_heading: string,
    phone_deal_carousel_subheading: string,
    phone_deal_carousel_button: string,
}

export function getContent(target: CustomContentOption): NoCreditCheckPhoneContent {
    switch (target) {
        case CustomContentOption.DEFAULT:
            return {
                hero_title: "Get your “no credit check” phone contract.",
                hero_subtitle: `Applying is <span class='underline font-semibold text-black text-opacity-70'>free</span> and does <span class='underline font-semibold text-black text-opacity-70'>not impact</span> your credit score!`,
                hero_disclaimer: "0% interest on repayments",
                testimonials_heading: "Who are our users?",
                testimonials_subheading: "Our customers",
                testimonials: [
                    { text: "I have a poor credit rating due to losing my job last year where I was out of work for 8 weeks, now back in full-time employment, but the damage is done. I need this!" },
                    { text: "Hi I just wanted to say I've received the phone, brilliant condition. Thank you for giving a chance to pay this phone I really appreciate it. Thank you for your brilliant support - hoping to get another from you guys in the future" },
                    { text: "Thanks for such clear communication, I think you guys stand out for customer service." },
                ],
                phone_deal_carousel_heading: "No credit check phone deals",
                phone_deal_carousel_subheading: "Our best phone deals",
                phone_deal_carousel_button: "Apply in minutes",
            };


        case CustomContentOption.STUDENT:
            return {
                hero_title: "Get your student phone contract 🧑‍🎓",
                hero_subtitle: "Young people are 3x more likely to be rejected for a phone contract because of their credit score.",
                hero_disclaimer: "0% interest. Applying does not impact your credit score!",
                testimonials_heading: "Who are our users?",
                testimonials_subheading: "Our customers",
                testimonials: [
                    { text: "I'm a student and would really like to start paying for my own phone" },
                    { text: "I just turned 18 and would like to pay for a phone contract without having to send my dad money for the contract and skip the middleman... but I have no credit score" },
                    { text: "I really want a new iPhone but get the cheapest model I can. Hoping there's a student discount I can use to reduce my monthly payments" },
                ],
                phone_deal_carousel_heading: "No credit check phone deals",
                phone_deal_carousel_subheading: "Our best phone deals",
                phone_deal_carousel_button: "Check eligibility now",
            };


        case CustomContentOption.THIN_FILE:
            return {
                hero_title: "Thin file? Get a phone contract here!",
                hero_subtitle: "We offer phone contracts to people who've never borrowed money (thin file) 💸.",
                hero_disclaimer: "0% interest. Applying does not impact your credit score!",
                testimonials_heading: "Who are our users?",
                testimonials_subheading: "Our customers",
                testimonials: [
                    { text: "I’m scared I will fail the security check for having no credit score. I've never had a phone contract before" },
                    { text: "I've never applied for phone contract before and don't know what happens if I get rejected" },
                    { text: "I just panic about everything, I'm a panicker. But I really need a new phone" },
                ],
                phone_deal_carousel_heading: "Phone contracts for people with thin credit files",
                phone_deal_carousel_subheading: "Our best phone deals",
                phone_deal_carousel_button: "Check eligibility now",
            };

        case CustomContentOption.HANDSET_ONLY:
            return {
                hero_title: "Get your handset-only phone contract.",
                hero_subtitle: "Why change your SIM if it's working for you? 🙃",
                hero_disclaimer: "0% interest. Applying does not impact your credit score!",
                testimonials_heading: "Who are our users?",
                testimonials_subheading: "Our customers",
                testimonials: [
                    { text: "I've had the same number for 12 years and don't want the hassle of having to change anything over. I've got enough life admin as is" },
                    { text: "I only really need a new phone today. I've got a Pay As You Go SIM with giffgaff and it works fine" },
                    { text: "I need a new phone but don't want to pay all in one go. Happy with my SIM provider, I've been with them for years" },
                ],
                phone_deal_carousel_heading: "Handset-only phone contract deals",
                phone_deal_carousel_subheading: "Our best phone deals",
                phone_deal_carousel_button: "Check eligibility now",
            };

        case CustomContentOption.BAD_CREDIT:
            return {
                hero_title: "Bad credit score? Get your phone contract here.",
                hero_subtitle: "Do you know you have a bad credit score, but think they've got it wrong? We're here to help!",
                hero_disclaimer: "0% interest. Applying does not impact your credit score!",
                testimonials_heading: "Who are our users?",
                testimonials_subheading: "Our customers",
                testimonials: [
                    { text: "I have a poor credit rating due to losing my job last year where I was out of work for 8 weeks, now back in full-time employment, but the damage is done. I need this!" },
                    { text: "I’ve had poor credit due to a long term illness and being out of consistent work. I am now better with a stable salary, and would really love to show that I can pay back." },
                    { text: "I messed my credit score, but I'm trying to rebuild it. This is my last chance" },
                ],
                phone_deal_carousel_heading: "Bad credit phone contracts",
                phone_deal_carousel_subheading: "Our best phone deals",
                phone_deal_carousel_button: "Check eligibility now",
            };

        case CustomContentOption.RENTERS:
            return {
                hero_title: "Get a phone contract built for renters.",
                hero_subtitle: "Are you tired of rent payments not boosting your credit score? Well they count here. 🥰",
                hero_disclaimer: "0% interest. Applying does not impact your credit score!",
                testimonials_heading: "Who are our users?",
                testimonials_subheading: "Our customers",
                testimonials: [
                    { text: "I really need this phone contract - I have moved around a lot, and this has affected my credit score." },
                    { text: "I don't have 3-year uk address history and it makes it so hard to get any kind of credit. I'm stuck!" },
                    { text: "I am so sick of mortgage payments building your credit score, when I pay almost £1000 a month for rent that doesn't. Surely this should be taken into account?" },
                ],
                phone_deal_carousel_heading: "Phone deals for renters",
                phone_deal_carousel_subheading: "Our best phone deals",
                phone_deal_carousel_button: "Check eligibility now",
            };

        case CustomContentOption.NEW_TO_COUNTRY:
            return {
                hero_title: "Moved to the UK? Get a phone contract here!",
                hero_subtitle: "We know it's hard to get a contract when you move to the UK. We can help 💪",
                hero_disclaimer: "0% interest. Applying does not impact your credit score!",
                testimonials_heading: "Who are our users?",
                testimonials_subheading: "Our customers",
                testimonials: [
                    { text: "I've got a skilled worker VISA to come to the UK. I can definitely afford a phone contract, but keep getting rejected" },
                    { text: "I don't have 3-year uk address history and it makes it so hard to get any kind of credit. I'm stuck!" },
                    { text: "I have stayed in the United Kingdom for only 7 months, but my wife and children are all staying here with me. It's been hardest to get a phone" },
                ],
                phone_deal_carousel_heading: "New to country phone deals",
                phone_deal_carousel_subheading: "Our best phone deals",
                phone_deal_carousel_button: "Check eligibility now",
            };

        default:
            throw isNever(target);
    }
}



export function getGtagEventNgOnInit(target: CustomContentOption): string {
    switch (target) {
        case CustomContentOption.DEFAULT:
            return "no_credit_check_phone_landing__page_load";
        default:
            return `no_credit_check_phone_landing_${target}__page_load`;
    }
}

export function getGtagEventUserClickedApply(target: CustomContentOption): string {
    switch (target) {
        case CustomContentOption.DEFAULT:
            return "no_credit_check_phone_landing__apply_now";
        default:
            return `no_credit_check_phone_landing_${target}__apply_now`;
    }
}


