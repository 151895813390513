<main class="account-container flex flex-col p-5">
    <app-account-header></app-account-header>
    <h2 class="mb-5 text-xl font-semibold">Information</h2>
    <form (ngSubmit)="submit()" [formGroup]="editForm" class="mb-2">
        <div class="flex flex-col">
            <label for="full_name" class="mb-1.5 text-sm uppercase">Full name</label>
            <input
                formControlName="full_name"
                id="full_name"
                type="text"
                class="input rounded-xl border border-black p-4"
            />
        </div>
        <small
            *ngIf="
                editForm.get('full_name')?.status === 'INVALID' &&
                editForm.get('full_name')?.touched &&
                editForm.get('full_name')?.errors?.['required']
            "
            class="text-error-red"
        >
            Full Name is required
        </small>
        <div class="mt-8 flex flex-col">
            <label for="email" class="mb-1.5 text-sm uppercase">email</label>
            <input
                formControlName="email"
                id="email"
                type="email"
                class="input rounded-xl border border-black p-4"
            />
        </div>
        <small
            *ngIf="
                editForm.get('email')?.status === 'INVALID' &&
                editForm.get('email')?.touched &&
                editForm.get('email')?.errors?.['required']
            "
            class="text-error-red"
        >
            Email Address is required
        </small>

        <small
            *ngIf="
                editForm.get('email')?.status === 'INVALID' &&
                editForm.get('email')?.touched &&
                !editForm.get('email')?.errors?.['required'] &&
                editForm.get('email')?.errors?.['email']
            "
            class="text-error-red"
        >
            Please enter valid email address, eg: example&#64;domain.com
        </small>

        <app-password-field
            label="Current password"
            autocomplete="current-password"
            [control]="getFormControl('current_password')"
        ></app-password-field>
        <small
            *ngIf="
                editForm.get('new_password')?.status === 'INVALID' &&
                editForm.errors?.['currentPasswordRequired']
            "
            class="text-error-red"
        >
            Password is required
        </small>
        <app-password-field
            label="New password"
            autocomplete="new-password"
            [control]="getFormControl('new_password')"
        ></app-password-field>

        <small
            *ngIf="
                editForm.get('new_password')?.status === 'INVALID' &&
                editForm.get('new_password')?.touched &&
                editForm.get('new_password')?.errors?.['required']
            "
            class="text-error-red"
        >
            Password is required
        </small>
        <small
            *ngIf="
                editForm.get('new_password')?.status === 'INVALID' &&
                editForm.get('new_password')?.touched &&
                editForm.get('new_password')?.errors?.['pattern']
            "
            class="text-error-red"
        >
            Your password must be at least 8 characters long, contain at least one number, contain
            at least one special character and have a mixture of uppercase and lowercase letters.
        </small>
        <div class="mt-11 flex items-center">
            <input
                id="marketing_agree"
                formControlName="marketing_agree"
                type="checkbox"
                checked="checked"
                class="checkbox-primary checkbox mr-2.5 h-5 w-5 rounded border-2"
            />
            <label for="marketing_agree">I would like to see marketing comunications</label>
        </div>

        <button
            name="Submit forgot password form"
            type="submit"
            class="btn btn-outline btn-primary mx-auto mb-8 mt-14 block h-14 w-full self-stretch rounded-full text-center uppercase"
        >
            apply changes
        </button>
    </form>
</main>
