import { Component, HostListener, OnInit } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { CarouselItemComponent } from '../../core/components/carousel-item/carousel-item.component';
import { CarouselComponent } from '../../core/components/carousel/carousel.component';
import { CmsCarouselComponent } from '../../core/components/cms-carousel/cms-carousel.component';
import { FaqComponent } from '../../core/components/faq/faq.component';
import { routeNames } from 'src/app/app-routing.module';
import { UserState } from 'src/app/user/state/user/user.state';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { toSignal } from '@angular/core/rxjs-interop';
import { CustomContentOption, getContent, getGtagEventNgOnInit, getGtagEventUserClickedApply, isCustomContentOption } from './no-credit-check-phone.model';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-no-credit-check-phone',
    standalone: true,
    imports: [
        CarouselItemComponent,
        CarouselComponent,
        CmsCarouselComponent,
        CommonModule,
        FaqComponent,
        NgOptimizedImage,
    ],
    templateUrl: './no-credit-check-phone.component.html',
    styleUrl: './no-credit-check-phone.component.scss'
})
export class NoCreditCheckPhoneComponent implements OnInit {
    @Select(UserState.getLoginStatus) isLoggedInObservable!: Observable<boolean>;
    isLoggedIn = toSignal(this.isLoggedInObservable)
    dark = false;
    contentOption: CustomContentOption = CustomContentOption.DEFAULT;

    constructor(
        private route: ActivatedRoute
    ) { }

    ngOnInit() {
        this.route.data.subscribe(data => {
            const contentOption = data['contentOption'];
            if (isCustomContentOption(contentOption)) {
                this.contentOption = contentOption;
            }
        });

        gtag('event', getGtagEventNgOnInit(this.contentOption));
    }

    userClickedApply(buttonId: string | number) {
        gtag('event', getGtagEventUserClickedApply(this.contentOption), { button_id: buttonId.toString() });
    }

    get createAccount() {
        return this.isLoggedIn()
            ? routeNames.getStarted.shareOpenBanking.fullPath()
            : routeNames.getStarted.createAccount.fullPath();
    }

    get content_hero_title() {
        return getContent(this.contentOption).hero_title;
    }

    get content_hero_subtitle() {
        return getContent(this.contentOption).hero_subtitle;
    }

    get content_testimonials_heading() {
        return getContent(this.contentOption).testimonials_heading;
    }

    get content_testimonials_subheading() {
        return getContent(this.contentOption).testimonials_subheading;
    }

    get content_testimonials() {
        return getContent(this.contentOption).testimonials;
    }

    get content_hero_disclaimer() {
        return getContent(this.contentOption).hero_disclaimer;
    }

    get phone_deal_carousel_heading() {
        return getContent(this.contentOption).phone_deal_carousel_heading;
    }

    get phone_deal_carousel_subheading() {
        return getContent(this.contentOption).phone_deal_carousel_subheading;
    }

    get phone_deal_carousel_button() {
        return getContent(this.contentOption).phone_deal_carousel_button;
    }

}
