<div class="account-container">
    <div class="bold mb-6 mr-auto flex items-center justify-center text-[14px] uppercase text-electric-blue h-9">
        &nbsp;
    </div>

    <section class="mb-8">
        <h1 class="text-3xl font-semibold">Thank you! Please now complete our form</h1>
        <h2 class="mt-4 text-sm uppercase text-black text-opacity-60">Step 2 of 2</h2>
        <p class="mt-4">
            The final step is to complete a short form and tell us which phone you want!
        </p>
        <p class="mt-4">
            The form shouldn’t take more than 2 minutes to complete and we <span class="font-semibold">cannot</span> consider your application without it.
        </p>

        <button
            class="btn mx-auto mb-12 mt-12 block h-14 w-full self-stretch rounded-full bg-electric-blue text-center uppercase text-white flex"
            (click)="next()"
        >
           Start form
        </button>

    </section>
</div>

<section class="flex my-8 mx-auto max-w-lg" nomargin>
    <app-noggin-phone-tile
        caption="Why are we doing this?"
        heading="5.8 million people in the UK are excluded from credit!"
        text="We are dedicated to bringing a solution for people unfairly locked out of the current system."
    ></app-noggin-phone-tile>
</section>
