import { Component, HostBinding, Input } from "@angular/core";
import { NgClass, NgForOf, NgIf, NgOptimizedImage } from "@angular/common";
import { Router, RouterLink } from "@angular/router";
import { Select } from "@ngxs/store";
import { Observable } from "rxjs";
import { routeNames } from "src/app/app-routing.module";
import { MenuIconComponent } from "../menu-icon/menu-icon.component";
import { UserState } from "../../../user/state/user/user.state";
import { LogoFullComponent } from "../logo-full/logo-full.component";
import { ProfileIconComponent } from "../../../user/components/profile-icon/profile-icon.component";
import { DrawerState } from "../../state/drawer.state";
import { FilterCollapseComponent } from "../../../deal/components/filter-collapse/filter-collapse.component";
import { FaIconComponent } from "@fortawesome/angular-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { NavHoverMenuComponent } from "../nav-hover-menu/nav-hover-menu.component";
import { createConfig } from "../../models/navbar-config.model";

@Component({
    selector: "app-navbar",
    templateUrl: "./navbar.component.html",
    styleUrls: ["./navbar.component.scss"],
    imports: [
        LogoFullComponent,
        NgOptimizedImage,
        RouterLink,
        NgIf,
        NgClass,
        MenuIconComponent,
        ProfileIconComponent,
        FilterCollapseComponent,
        FaIconComponent,
        NavHoverMenuComponent,
        NgForOf,
    ],
    standalone: true,
})
export class NavbarComponent {
    @Input() dark = false;
    @Input() showCenterLinks = true;
    @Input("class") @HostBinding("class") className = "";
    isLoggedIn = false;
    @Select(UserState.getLoginStatus) isLoggedIn$!: Observable<boolean>;
    routes = routeNames;

    constructor(private drawerService: DrawerState, private router: Router) {
        this.isLoggedIn$.subscribe((res) => {
            this.isLoggedIn = res;
        });
    }

    openDrawer(): void {
        this.drawerService.open();
    }

    get rightButtonLink() {
        return routeNames.articles.list.fullPath();
    }

    get rightButtonText() {
        return "Our Blogs";
    }

    protected readonly routeNames = routeNames;
    protected readonly faChevronDown = faChevronDown;
    protected readonly navbarConfig = createConfig();

}
