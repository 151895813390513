import { Component, Input, OnInit } from "@angular/core";
import { FilterCollapseComponent } from "../filter-collapse/filter-collapse.component";
import { NgForOf, NgIf } from "@angular/common";
import { DealOrderDropdownComponent } from "../deal-order-dropdown/deal-order-dropdown.component";
import { DealFilterState } from "../../state/deal-filter.state";
import { PhonesService } from "../../services/phones.service";
import { ToggleButtonComponent } from "../../../core/components/toggle-button/toggle-button.component";
import { FilterInputComponent } from "../filter-input/filter-input.component";
import { DealType, FilterOption } from "../../models/filter";

@Component({
    selector: "app-deal-filter",
    standalone: true,
    imports: [
        FilterCollapseComponent,
        NgForOf,
        DealOrderDropdownComponent,
        NgIf,
        ToggleButtonComponent,
        FilterInputComponent,
    ],
    templateUrl: "./deal-filter.component.html",
    styleUrl: "./deal-filter.component.scss",
})
export class DealFilterComponent implements OnInit {
    @Input({ required: true }) dealType!: DealType;
    @Input() defaultOpen = false;
    /** Updates the filter when anything changes*/
    @Input() updateAutomatically = false;

    constructor(
        public dealFilterService: DealFilterState,
        private phoneService: PhonesService,
    ) {}

    ngOnInit() {
        switch (this.dealType) {
            case DealType.bundle:
                this.dealFilterService.initBundleFilters();
                break;
            case DealType.simOnly:
                this.dealFilterService.initSimOnlyFilters();
                break;
            case DealType.phoneOnly:
                this.dealFilterService.initPhoneOnlyFilters();
                break;
        }
    }
}
