<section class="container relative max-w-[1500px] overflow-hidden">
    <div class="flex flex-col">
        <h2
            [ngClass]="this.dark ? ' text-off-white ' : 'text-black'"
            class="mx-auto mb-10 text-center text-3xl font-semibold uppercase lg:text-5xl max-w-[800px]"
        >
            {{ title }}
        </h2>
        <h3
            [ngClass]="this.dark ? 'text-off-white' : 'text-black'"
            class="mx-auto mb-3 block text-center text-sm uppercase -order-1"
        >
            {{ subtitle }}
        </h3>
    </div>
    <ng-content select="[tags]"></ng-content>
    <div class="flex items-center justify-center">
        <button class="hidden lg:block" (click)="slider?.prev()">
            <img
                class="h-11 w-11"
                ngSrc="/assets/svg/circle_chev_left.svg"
                alt="arrow-left"
                height="44"
                width="44"
            />
        </button>

        <div class="keen-slider relative max-w-[1200px]" #sliderRef>
            <ng-content #carouselItem></ng-content>
        </div>
        <button class="hidden lg:block" (click)="this.slider?.next()">
            <img
                class="h-11 w-11"
                ngSrc="/assets/svg/circle_chev_right.svg"
                alt="arrow-right"
                height="44"
                width="44"
            />
        </button>
    </div>
    <div class="mt-14 flex justify-center lg:mt-16">
        <button
            *ngFor="let slide of dotHelper()"
            class="mx-2 h-4 w-4 rounded-full border"
            (click)="moveToSlide(slide)"
            [ngClass]="[
                activeDot() === slide ? dotActiveClass : '',
                dark ? 'border-white' : 'border-black'
            ]"
        >
            <span class="sr-only">Change to slide {{ slide }}</span>
        </button>
    </div>
    <ng-container *ngIf="buttonConfig">
        <a
            [ngClass]="dark ? 'border-white text-off-white' : 'border-black text-black'"
            routerLink="{{ buttonConfig.link }}"
            [queryParams]="buttonQueryParams"
            class="btn mx-auto mt-10 flex h-14 w-80 items-center justify-center rounded-full border bg-transparent uppercase"
        >
            {{ buttonConfig.text }}
        </a>
    </ng-container>
</section>
