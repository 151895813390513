import { Component, HostListener, OnInit } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { CarouselItemComponent } from '../../core/components/carousel-item/carousel-item.component';
import { CarouselComponent } from '../../core/components/carousel/carousel.component';
import { CardOnboardingStepsComponent } from '../../core/components/card-onboarding-steps/card-onboarding-steps.component';
import { CmsCarouselComponent } from '../../core/components/cms-carousel/cms-carousel.component';
import { FaqComponent } from '../../core/components/faq/faq.component';
import { routeNames } from 'src/app/app-routing.module';
import { NogginPhoneTileComponent } from '../noggin-phone-tile/noggin-phone-tile.component';
import { UserState } from 'src/app/user/state/user/user.state';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { toSignal } from '@angular/core/rxjs-interop';

const mdBreakpoint = 768;

@Component({
    selector: 'app-no-credit-check-phone-student',
    standalone: true,
    imports: [
        CarouselItemComponent,
        CarouselComponent,
        CardOnboardingStepsComponent,
        CmsCarouselComponent,
        CommonModule,
        FaqComponent,
        NogginPhoneTileComponent,
        NgOptimizedImage,
    ],
    templateUrl: './no-credit-check-phone-students.component.html',
    styleUrl: './no-credit-check-phone-students.component.scss'
})
export class NoCreditCheckPhoneStudentsComponent implements OnInit {
    @Select(UserState.getLoginStatus) isLoggedInObservable!: Observable<boolean>;
    isLoggedIn = toSignal(this.isLoggedInObservable)
    dark = false;
    isSmall = window.innerWidth < mdBreakpoint;

    constructor(
        private userState: UserState
    ) { }

    ngOnInit() {
        gtag('event', 'no_credit_check_phone_landing_student__page_load');
    }

    userClickedApply(buttonId: string | number) {
        gtag('event', 'no_credit_check_phone_landing_student__apply_now', { button_id: buttonId.toString() });
    }


    @HostListener("", ["$event"])
    onResize(event: Event) {
        try {
            const width = (event.target as Window).innerWidth;
            if (width >= mdBreakpoint) {
                this.isSmall = false;
            } else {
                this.isSmall = true;
            }
        } catch {
            // Ignore error and stay with small as a default
            this.isSmall = true;
        }
    }

    get createAccount() {
        return this.isLoggedIn()
            ? routeNames.getStarted.beforeYouStart.fullPath()
            : routeNames.getStarted.createAccount.fullPath();
    }
}
