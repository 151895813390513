import { Component, Input } from "@angular/core";
import { NgClass, NgIf, NgOptimizedImage } from "@angular/common";
import { DealModel } from "../../models/deal";
import { SquigglyBackgroundComponent } from "../squigly-background/squiggly-background.component";
import { routeNames } from "../../../app-routing.module";
import { RouterLink } from "@angular/router";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FaIconComponent } from "@fortawesome/angular-fontawesome";

@Component({
    selector: "app-sim-card",
    standalone: true,
    imports: [
        NgIf,
        NgClass,
        SquigglyBackgroundComponent,
        NgOptimizedImage,
        RouterLink,
        FaIconComponent,
    ],
    templateUrl: "./sim-card.component.html",
    styleUrl: "./sim-card.component.scss",
})
export class SimCardComponent {
    @Input() loading = false;
    @Input() deal?: DealModel;
    @Input() showExtraInfo = false;

    protected readonly faInfoCircle = faInfoCircle;
    get isVip() {
        return !!this.deal?.deal_is_vip;
    }

    protected readonly routeNames = routeNames;
    protected readonly parseFloat = parseFloat;

    get creditCheck() {
        if (this.deal?.deal_credit_check === undefined || this.deal?.deal_credit_check === null) {
            return "Unknown";
        }
        return this.deal.deal_credit_check ? "Required" : "Not required";
    }
}
