import { Injectable, signal } from "@angular/core";
import { createConfig, NavbarConfig } from "../models/navbar-config.model";

@Injectable({ providedIn: "root" })
export class DrawerState {
    isOpen = signal(false);
    openMenu = signal<NavbarConfig | undefined>(undefined);
    readonly navbarConfig = createConfig();
    toggle(): void {
        this.isOpen.update((v) => !v);
    }
    open(): void {
        this.isOpen.set(true);
    }
    close(): boolean {
        this.isOpen.set(false);
        this.openMenu.set(undefined);
        return true;
    }

    setSection(title?: string) {
        if (!title) {
            this.openMenu.set(undefined);
        }
        const config = this.navbarConfig.find((config) => config.title === title);
        this.openMenu.set(config);
    }
}
