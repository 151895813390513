<article class="w-[330px] md:w-[350px]">
    <div class="w-full overflow-hidden rounded-3xl border bg-off-white">
        <a
            class="h-full w-full"
            (click)="handleEvent($event)"
            (keydown)="handleEvent($event)"
            [routerLink]="link"
        >
            <section class="relative h-[330px] w-full border-b bg-[#FFF] p-5">
                <figure
                    class="absolute -bottom-[16px] rounded-xl border bg-off-white px-2 py-1"
                    *ngIf="deal?.deal_retailer_logo_url || deal?.network_provider?.logo"
                >
                    <img
                        class="h-6 w-auto object-cover"
                        alt="Manufacturer logo"
                        [ngSrc]="deal?.deal_retailer_logo_url || deal?.network_provider?.logo ?? ''"
                        width="32"
                        height="32"
                /></figure>
                <ul class="absolute right-3 top-3 z-10 flex items-center justify-end gap-1.5">
                    <li
                        *ngIf="deal?.device_condition_friendly !== 'New' && deal?.device_condition_friendly"
                        class="h-8 min-w-[101px] px-3 flex items-center justify-center rounded-full border border-black
                        bg-off-white text-center text-xs font-semibold uppercase text-black"
                        class="flex h-8 w-[101px] items-center justify-center rounded-full border border-black bg-off-white "
                    >
                        {{ deal?.device_condition_friendly }}
                    </li >
                    <li
                        *ngIf="isOnCredit"
                        class="h-8 min-w-[101px] px-3 flex items-center justify-center rounded-full border border-black text-center text-xs font-semibold uppercase bg-green-gradient"
                        >on credit</li
                    >

                    <li
                        *ngIf="deal?.device_payment_type === 'Lease'"
                        class="bg-pink-red-gradient flex h-8 w-[101px] items-center justify-center rounded-full border border-black text-center text-sm font-semibold uppercase text-white"
                        >on lease</li
                    >
                </ul>
                <figure class="relative h-full w-full">
                    <ng-container *ngIf="loading; else title">
                        <div class="skeleton h-full w-full rounded-2xl"></div>
                    </ng-container>
                    <ng-template #title>
                        <img
                            *ngIf="deal?.deal_image_uploaded_file || deal?.device_image_1"
                            alt="{{ deal.phone_model.name }} image"
                            class="object-contain"
                            fill=""
                            [ngSrc]="deal?.deal_image_uploaded_file ?? deal.device_image_1"
                        />
                    </ng-template>
                </figure>
            </section>
            <section class="px-5 pb-2 pt-4 lg:pb-4 lg:pt-7">
                <app-shimmer-loader loadingClass="w-full h-5" class="mb-0.5" [loading]="loading">
                    <h3 class="text-xl font-semibold">{{ deal?.deal_name }}</h3>
                </app-shimmer-loader>

                <app-shimmer-loader
                    class="mb-4 block border-b border-black border-opacity-10 pb-4"
                    [loading]="loading"
                >
                    <p *ngIf="isBundle">
                        <span
                            *ngIf="isRetailerHidden(deal)"
                            class="after:content-['·'] after:mx-2 after:font-black"
                        >
                            {{ deal?.network_provider?.name }} SIM
                        </span>
                        <span>{{ dealColor }}</span>
                    </p>
                    <p *ngIf="!isBundle">
                        <span class="after:content-['·'] after:mx-2 after:font-black">SIM free</span>
                        <span>{{ dealColor }}</span>
                    </p>
                </app-shimmer-loader>
                <div class="grid grid-cols-2 gap-2.5 py-4">
                    <div>
                        <p class="mb-0.5 text-sm uppercase">
                            {{ isBundle ? "data allowance" : "condition" }}
                        </p>
                        <app-shimmer-loader [ngClass]="{ 'mb-4': showCreditCheckDescText }" [loading]="loading">
                            <p *ngIf="!isBundle" class="font-semibold">
                                {{ deal?.device_condition }}
                            </p>
                            <p *ngIf="isBundle" class="font-semibold">
                                {{
                                    !deal?.sim_unlimited_data
                                        ? deal?.sim_data_allowance_gb_numeric
                                              ?.toString()
                                              ?.concat("GB")
                                        : "Unlimited"
                                }}
                            </p>
                        </app-shimmer-loader>
                        <ng-container *ngIf="showCreditCheckDescText">
                            <p class="mb-0.5 flex items-center text-sm uppercase">est total cost</p>
                            <app-shimmer-loader [loading]="loading">
                                <p class="font-semibold"
                                    >£{{ deal?.deal_est_total_cost_inc_vat?.toFixed(2) }}</p
                                >
                            </app-shimmer-loader>
                        </ng-container>
                    </div>
                    <div>
                        <p class="mb-0.5 text-sm uppercase">{{ contractLenghtLabel }}</p>
                        <app-shimmer-loader [ngClass]="{ 'mb-4': showCreditCheckDescText }" [loading]="loading">
                            <p class="font-semibold">
                                {{ deal?.deal_contract_length ?? deal?.sim_contract_length_months }} Month{{
                                    ((deal?.deal_contract_length ?? deal?.sim_contract_length_months ?? 0) === 1) ? "" : "s"
                                }}
                            </p>
                        </app-shimmer-loader>
                        <ng-container *ngIf="showCreditCheckDescText">
                            <p class="mb-0.5 text-sm uppercase">Credit Check?</p>
                            <app-shimmer-loader [loading]="loading">
                                <p class="font-semibold">{{ hasCreditCheck ? "Required" : "Not Required" }}</p>
                            </app-shimmer-loader>
                        </ng-container>
                    </div>
                </div>
            </section>
            <app-shimmer-loader loadingClass="h-14 w-full rounded-none" [loading]="loading">
                <footer>
                    <section
                        [ngClass]="backgroundColour || (deal?.members_benefit_text ? 'bg-green-gradient' : 'bg-pink-100')"
                        class="flex divide-x-[1px] divide-black divide-opacity-10 p-4"
                    >
                        <div class="flex-1">
                            <h3 class="text-3.5xl font-medium">
                                <ng-container
                                    >£{{
                                        deal?.deal_pay_today_total_inc_vat
                                            ?.toString()
                                            ?.split(".")?.[0]
                                    }}.</ng-container
                                ><span class="text-xl font-medium">{{
                                    deal?.deal_pay_today_total_inc_vat
                                        ?.toString()
                                        ?.split(".")?.[1] ?? "00"
                                }}</span>
                            </h3>

                            <p class="flex items-center text-md"> Total today </p>
                        </div>
                        <div *ngIf="deal?.deal_monthly_total_inc_vat" class="flex-1 pl-7">
                            <h3 class="text-3.5xl font-medium">
                                <ng-container>
                                    £{{
                                        deal?.deal_monthly_total_inc_vat
                                            ?.toString()
                                            ?.split(".")?.[0]
                                    }}.</ng-container
                                ><span class="text-xl font-medium">{{
                                    parseFloat(deal?.deal_monthly_total_inc_vat?.toString() ?? "0")
                                        .toFixed(2)
                                        ?.split(".")?.[1] ?? "00"
                                }}</span>
                            </h3>
                            <p class="flex items-center text-md">Est. total monthly</p>
                        </div>
                    </section>
                    <section
                        class="bg-black px-6 py-2.5 text-md text-off-white"
                        *ngIf="deal?.deal_price_rises"
                    >
                        <p class="flex items-center"
                            ><fa-icon [icon]="faInfoCircle" class="mr-2"></fa-icon
                            >{{ deal?.deal_price_rises }}</p
                        >
                    </section>
                </footer>
            </app-shimmer-loader>
        </a>
    </div>
    <section
        class="bg-green-gradient mt-2.5 min-h-16 w-full rounded-3xl border border-black"
        *ngIf="deal?.members_benefit_text && showExtraInfo"
    >
        <a
            href="{{ deal?.members_benefit_link }}"
            class="flex h-full w-full items-center justify-center px-5 py-4"
        >
            <span class="font-bold">+</span>
            <p class="ml-2 flex-1">{{ deal?.members_benefit_text }}</p>
            <figure class="relative h-full w-16 object-contain" *ngIf="deal?.members_benefit_image"
                ><img
                    alt="Member benefit icon image"
                    class="object-contain"
                    fill=""
                    [ngSrc]="deal.members_benefit_image"
            /></figure>
        </a>
    </section>
</article>
