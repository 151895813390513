import { Component, HostBinding, Input } from "@angular/core";
import { NgOptimizedImage } from "@angular/common";
import { RouterLink } from "@angular/router";
import { routeNames } from "src/app/app-routing.module";

@Component({
    selector: "home-member-benefits",
    standalone: true,
    imports: [NgOptimizedImage, RouterLink],
    templateUrl: "./member-benefits.component.html",
    styleUrl: "./member-benefits.component.scss",
})
export class MemberBenefitsComponent {
    @Input("class") @HostBinding("class") className = "";
    protected readonly routeNames = routeNames;
}
