import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { Store } from "@ngxs/store";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { Location } from "@angular/common";
import { LoginUserAction } from "../../state/user/user.actions";
import { routeNames } from "../../../app-routing.module";
import {ActivatedRoute} from "@angular/router";

@Component({
    selector: "app-login",
    templateUrl: "./login.component.html",
    styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
    loginForm!: FormGroup;
    fromPage?: string;

    constructor(
        private formBuilder: FormBuilder,
        private location: Location,
        private store: Store,
        private route: ActivatedRoute,
    ) {
       this.fromPage = this.route.snapshot.queryParams["from"];
    }

    ngOnInit(): void {
        this.loginForm = this.formBuilder.group({
            email: ["", [Validators.email, Validators.required]],
            password: ["", [Validators.required]],
            resetPasswordRequestEmail: ["", [Validators.email, Validators.required]],
        });
    }
    async back(): Promise<void> {
        this.location.back();
    }

    getPasswordControl(): FormControl {
        return this.loginForm.get("password") as FormControl;
    }

    submit(): void {
        this.store.dispatch(new LoginUserAction(this.loginForm.value, this.fromPage));
    }

    protected readonly faChevronLeft = faChevronLeft;
    protected readonly routeNames = routeNames;
}
