<div class="relative">
    <svg
        class="absolute bottom-0 left-0 top-0 h-full"
        viewBox="0 0 12 66"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            id="Vector"
            d="M8 11C8 8.08159 3.99564 6.08085 1.65302 3.53699C0.156531 1.91193 1.79086 0 4 0L12 0L12 66H6C2.68629 66 0.595877 63.253 3.10221 61.0853C5.35144 59.14 8 57.3735 8 55C8 50.875 -9.22639e-07 48.5833 -8.35598e-07 44C-7.48556e-07 39.4167 8 37.5833 8 33C8 28.4167 -5.0484e-07 26.5833 -4.17799e-07 22C-3.30758e-07 17.4167 8 15.125 8 11Z"
            fill="black"
        />
    </svg>
    <ng-content></ng-content>
    <svg
        class="absolute bottom-0 right-0 top-0 h-full"
        viewBox="0 0 12 66"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            id="Vector"
            d="M4 55C4 57.9184 8.00436 59.9191 10.347 62.463C11.8435 64.0881 10.2091 66 8 66L-7.62939e-06 66L-1.19886e-06 -9.41299e-07L6 -4.70649e-07C9.31371 -2.10717e-07 11.4041 2.74698 8.89779 4.91469C6.64856 6.86002 4 8.62653 4 11C4 15.125 12 17.4167 12 22C12 26.5833 4 28.4167 4 33C4 37.5833 12 39.4167 12 44C12 48.5833 4 50.875 4 55Z"
            fill="black"
        />
    </svg>
</div>
