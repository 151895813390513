<div class="w-[350px]">
    <a
        [routerLink]="
            routeNames.articles.details.fullPath(article.slug ?? '', article.id?.toString() ?? '')
        "
    >
        <figure class="mb-5 h-[240px] w-[350px] overflow-hidden rounded-3xl">
            <img
                [ngSrc]="article.cover_image"
                alt="'Image for blog: {{ article.title }}'"
                width="350px"
                height="240px"
            />
        </figure>
    </a>
    <a
        [routerLink]="
            routeNames.articles.details.fullPath(article.slug ?? '', article.id?.toString() ?? '')
        "
    >
        <h3 class="text-lg font-semibold text-pink-700">{{ article.title }}</h3>
    </a>
    <ul class="mb-6 mt-4 flex flex-wrap gap-2">
        <li
            *ngFor="let tag of article.tags; let i = index"
            class="rounded-[1.25rem] bg-pink-100 px-4 py-1.5"
        >
            <a
                [queryParams]="{ tags: tag.name }"
                [routerLink]="
                    article.tags.length <= 3 || i < 3 ? routeNames.articles.list.fullPath() : null
                "
            >
                <ng-container *ngIf="article.tags.length <= 3 || i < 3; else overflow">
                    <h5 class="text-sm font-bold uppercase">{{ tag.name }}</h5>
                </ng-container>
                <ng-template #overflow>
                    <ng-container *ngIf="i === 3 && article.tags.length > 3">
                        <h5 class="text-sm font-bold uppercase">...</h5>
                    </ng-container>
                </ng-template>
            </a>
        </li>
    </ul>
    <ng-container *ngIf="date">
        <p class="text-sm uppercase">{{ date }}</p>
    </ng-container>
</div>
