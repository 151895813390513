<div class="grid grid-cols-2 gap-2 md:gap-4 max-w-[416px]">
    <select
        *ngIf="showColorSelect"
        [value]="dealFilterState.selectedFilters.phone_colour ?? null"
        (change)="onPhoneColourChange($event)"
        class="select select-bordered appearance-none rounded-xl border-black bg-[url('/assets/svg/pencil-swoosh.svg')] bg-[length:24px_24px] text-black focus:outline-none focus-visible:outline-none"
    >
        <option selected value="null"> -- Select a color -- </option>
        <option
            *ngFor="let option of colourOptions"
            [selected]="dealFilterState.selectedFilters.phone_colour?.includes(option.id.toString())"
            [value]="option.id?.toString()"
        >
            {{ option.name }}
        </option>
    </select>
    <select
        *ngIf="!showColorSelect"
        [value]="null"
        class="select select-bordered appearance-none rounded-xl border-black min-w-[48px] bg-[url('/assets/svg/pencil-swoosh.svg')] bg-[length:24px_24px] text-black focus:outline-none focus-visible:outline-none"
        disabled
    >
        <option disabled selected value="null"> </option>
    </select>


    <select
        *ngIf="showStorageSizeSelect"
        (change)="onStorageSizeChange($event)"
        [value]="dealFilterState.selectedFilters.storage_size ?? null"
        class="select select-bordered appearance-none rounded-xl border-black bg-[url('/assets/svg/pencil-swoosh.svg')] bg-[length:24px_24px] text-black focus:outline-none focus-visible:outline-none"
    >
        <option selected value="null"> -- Select storage -- </option>
        <option
            *ngFor="let option of storageOptions()"
            [selected]="dealFilterState.selectedFilters.storage_size?.includes(option.value.toString())"
            [value]="option.value?.toString()"
        >
            {{ option.label }}
        </option>
    </select>
    <select
        *ngIf="!showStorageSizeSelect"
        [value]="null"
        class="select select-bordered mr-4 appearance-none rounded-xl min-w-[48px] border-black bg-[url('/assets/svg/pencil-swoosh.svg')] bg-[length:24px_24px] text-black focus:outline-none focus-visible:outline-none"
        disabled
    >
        <option disabled selected value="null"> </option>
    </select>
</div>
