<div class="relative z-20">
    <nav class="flex h-[72px] items-center justify-between bg-black pl-5 text-off-white lg:px-5">
        <a [routerLink]="routeNames.compare.mobile.fullPath()" class="py-3">
            <app-logo-full
                [fill]="'currentColor'"
                class="stroke-off-white text-off-white"
                height="28px"
            ></app-logo-full>
        </a>
        <div class="flex items-center justify-center">
            <a
                *ngIf="isLoggedIn"
                [routerLink]="routes.account.details.fullPath()"
                class="mr-2 flex h-10 w-10 cursor-pointer items-center justify-center lg:mr-2"
            >
                <app-profile-icon
                    [width]="'20px'"
                    [height]="'20px'"
                    class="stroke-off-white text-off-white"
                ></app-profile-icon>
            </a>
            <a
                *ngIf="!isLoggedIn"
                class="mr-4 font-semibold md:mr-8"
                [routerLink]="routes.login.fullPath()"
            >
                <app-profile-icon
                    [width]="'20px'"
                    [height]="'20px'"
                    class="stroke-off-white text-off-white md:hidden"
                ></app-profile-icon>
                <span class="hidden md:block">Login</span>
            </a>
            <a
                [routerLink]="routeNames.compare.mobile.fullPath()"
                class="btn mr-5 flex h-8 min-h-0 items-center justify-center rounded-full bg-off-white py-0 text-center text-black lg:h-10"
            >
                <span class="hidden md:block">Back To </span>Deals
            </a>
        </div>
    </nav>
</div>
