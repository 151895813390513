<ng-container *ngIf="href; else router">
    <a [href]="href" class="flex h-[26px] items-center">
        <ng-container *ngIf="icon">
            <fa-icon class="mr-3 text-[24px]" [icon]="icon" aria-hidden="false" />
        </ng-container>
        <ng-container *ngTemplateOutlet="content"></ng-container>
    </a>
</ng-container>
<ng-template #router>
    <a [routerLink]="routerLink" class="flex h-[26px] items-center">
        <ng-container *ngIf="icon">
            <fa-icon class="mr-3 text-[24px]" [icon]="icon" />
        </ng-container>
        <ng-container *ngTemplateOutlet="content"></ng-container>
    </a>
</ng-template>
<ng-template #content>
    <ng-content></ng-content>
</ng-template>
