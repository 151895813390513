import { Component, EventEmitter, Output } from "@angular/core";
import { CreditToggleComponent } from "../../../deal/components/credit-toggle/credit-toggle.component";
import { DealFilterComponent } from "../../../deal/components/deal-filter/deal-filter.component";
import { FaIconComponent } from "@fortawesome/angular-fontawesome";
import { NgForOf, NgOptimizedImage } from "@angular/common";

@Component({
    selector: "app-success",
    standalone: true,
    imports: [
        CreditToggleComponent,
        DealFilterComponent,
        FaIconComponent,
        NgForOf,
        NgOptimizedImage,
    ],
    templateUrl: "./success.component.html",
    styleUrl: "./success.component.scss",
})
export class SuccessComponent {
    public static id = "success-dialog";
    protected readonly id = SuccessComponent.id;
    @Output() continue = new EventEmitter<void>();

    handleContinue(): void {
        this.continue.emit();
    }
}
