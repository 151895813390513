import { Component } from '@angular/core';

@Component({
  selector: 'app-celebration-box-svg',
  standalone: true,
  imports: [],
  templateUrl: './celebration-box-svg.component.html',
  styleUrl: './celebration-box-svg.component.scss'
})
export class CelebrationBoxSvgComponent {

}
