import { DealModel } from "../deal/models/deal";

const mobiles_co_uk_regex = /Mobiles\.co\.uk/i;
const carphone_warehouse_regex = /Carphone Warehouse/i;
export function isRetailerHidden(deal: Pick<DealModel, "deal_retailer_name"> | undefined): boolean {
    if (!deal) return false;
    const deal_retailer_name = deal.deal_retailer_name
    if (!deal_retailer_name) return false;
    return ![
        mobiles_co_uk_regex,
        carphone_warehouse_regex
    ].some(regex => regex.test(deal_retailer_name))
}
