import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { ArticlesService, Tag } from "../../services/articles.service";
import { Article } from "../../models/article";
import { NgClass, NgForOf, NgIf } from "@angular/common";
import { BlogCardComponent } from "../../components/blog-card/blog-card.component";
import { FaIconComponent } from "@fortawesome/angular-fontawesome";

@Component({
    selector: "app-article-list",
    standalone: true,
    templateUrl: "./article-list.component.html",
    styleUrls: ["./article-list.component.scss"],
    imports: [NgClass, BlogCardComponent, FaIconComponent, NgIf, NgForOf],
})
export class ArticleListComponent implements OnInit {
    tags: string[] = [];
    allTags: Tag[] = [];
    articles: Article[] = [];

    constructor(
        private route: ActivatedRoute,
        private articleService: ArticlesService,
        private snackBar: MatSnackBar,
        private router: Router,
    ) {
        this.route.queryParams.subscribe((params) => {
            const tags = params["tags"];
            if (tags) {
                this.tags = tags.split(",");
            }
        });
    }

    ngOnInit(): void {
        this.getArticles();
        this.articleService.getAllTags().subscribe({
            next: (res) => {
                this.allTags = res;
            },
            error: (err) => {
                this.snackBar.open(err, "Error", { duration: 2000 });
            },
        });
    }

    toggleTag(tag: string): void {
        if (this.tags?.includes(tag)) {
            this.tags = this.tags?.filter((t) => t !== tag);
        } else {
            this.tags?.push(tag);
        }
    }
    async applyTag(tag: string) {
        this.toggleTag(tag);
        await this.applyFilter();
    }

    getArticles(): void {
        this.articleService.getAllArticles(this.tags).subscribe({
            next: (res) => {
                this.articles = res;
            },
            error: (err) => {
                this.snackBar.open(err, "Error", { duration: 2000 });
            },
        });
    }

    protected readonly faClose = faClose;

    openFilterModal(): void {
        const modal = document.getElementById("blog-filter-dialog") as HTMLDialogElement;
        modal.showModal();
    }
    closeFilterModal(): void {
        const modal = document.getElementById("blog-filter-dialog") as HTMLDialogElement;
        modal.close();
    }
    async applyFilter(): Promise<void> {
        await this.router.navigate([], {
            queryParams: { tags: this.tags.join() },
        });
        this.getArticles();
    }

    async applyFilterModal() {
        this.closeFilterModal();
        await this.applyFilter();
    }
}
