import { Component } from "@angular/core";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { Location } from "@angular/common";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { Store } from "@ngxs/store";
import { MatSnackBar } from "@angular/material/snack-bar";
import { SendResetPasswordAction } from "../../state/user/user.actions";
import { routeNames } from "../../../app-routing.module";

@Component({
    selector: "app-forgotten-password",
    templateUrl: "./forgotten-password.component.html",
    styleUrls: ["./forgotten-password.component.scss"],
})
export class ForgottenPasswordComponent {
    form!: FormGroup;

    constructor(
        private location: Location,
        private fb: FormBuilder,
        private store: Store,
        private snackBar: MatSnackBar,
    ) {
        this.form = this.fb.group({
            email: new FormControl("", [Validators.required, Validators.email]),
        });
    }

    protected readonly faChevronLeft = faChevronLeft;

    async back(): Promise<void> {
        this.location.back();
    }

    submit() {
        const email = this.form.get("email")?.value;
        if (email) {
            this.store.dispatch(new SendResetPasswordAction(email)).subscribe({
                next: (next) => {
                    if (next.user.requestPasswordMessage) {
                        this.openSnackBar(next.user.requestPasswordMessage);
                    }
                },
                error: (error) => {
                    this.openSnackBar(error);
                },
            });
        }
    }

    private openSnackBar(message: string): void {
        this.snackBar.open(message, undefined, {
            duration: 5000,
        });
    }

    protected readonly routeNames = routeNames;
}
