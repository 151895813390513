import { Component, Input } from "@angular/core";
import { NgClass, NgForOf, NgIf, NgOptimizedImage } from "@angular/common";
import { FaIconComponent } from "@fortawesome/angular-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { routeNames } from "../../../app-routing.module";
import { Router, RouterLink } from "@angular/router";
import { ShimmerLoaderComponent } from "../../../core/components/shimmer-loader/shimmer-loader.component";
import { DealModel } from "../../models/deal";
import { DealType } from "../../models/filter";
import { isRetailerHidden } from "src/app/util/dealViewHelpers";

@Component({
    selector: "app-phone-card-nav",
    standalone: true,
    imports: [
        NgOptimizedImage,
        NgIf,
        FaIconComponent,
        RouterLink,
        NgClass,
        ShimmerLoaderComponent,
        NgForOf,
    ],
    templateUrl: "./phone-card-nav.component.html",
    styleUrl: "./phone-card-nav.component.scss",
})
export class PhoneCardNavComponent {
    @Input() loading = false;
    @Input() deal!: DealModel;
    @Input({ required: true }) dealType!: DealType;
    @Input() showExtraInfo = true;
    @Input() showEstimatedTotalDescText = true;
    @Input() showCreditCheckDescText = true;
    @Input() itemClickable = true;

    protected readonly routeNames = routeNames;
    isRetailerHidden = isRetailerHidden;

    constructor(private router: Router) { }
    protected readonly faInfoCircle = faInfoCircle;

    goToDeal() {
        if (this.dealType === DealType.bundle) {
            return this.router.navigateByUrl(
                routeNames.bundle.details.fullPath({
                    phoneModel: this.deal.phone_model.id.toString(),
                    dealId: this.deal.id.toString(),
                }),
            );
        }
        return this.router.navigateByUrl(
            routeNames.simFreeDeals.details.fullPath({
                phoneModel: this.deal.phone_model.id.toString(),
                dealId: this.deal.id.toString(),
            }),
        );
    }

    handleEvent(event: Event) {
        if (!this.itemClickable) {
            event.preventDefault();
            event.stopPropagation();
        }
    }

    get isBundle() {
        return this.dealType === DealType.bundle;
    }

    get link() {
        if (!this.itemClickable) return null;

        if (this.dealType === DealType.bundle) {
            return routeNames.bundle.details.fullPath({
                dealId: this.deal?.id?.toString() ?? "",
                phoneModel: this.deal?.phone_model?.slug ?? "",
            });
        }
        return routeNames.simFreeDeals.details.fullPath({
            dealId: this.deal?.id?.toString() ?? "",
            phoneModel: this.deal?.phone_model?.slug ?? "",
        });
    }

    get dealColor() {
        return this.deal?.device_colour?.replaceAll("(PRODUCT)", "")?.trim();
    }


    get hasCreditCheck() {
        return this.deal?.deal_credit_check;
    }

    get isOnCredit() {
        return this.deal?.device_payment_type === 'On credit';
    }

    get contractLenghtLabel() {
        return "Contract length"
    }

    protected readonly parseFloat = parseFloat;
}
