import { CommonModule, Location } from "@angular/common";
import { Component, OnInit, signal } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { DomSanitizer } from "@angular/platform-browser";
import { Router, RouterLink } from '@angular/router';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { routeNames } from 'src/app/app-routing.module';
import { LoadingComponent } from 'src/app/core/components/loading/loading.component';
import { NogginPhoneTileComponent } from 'src/app/noggin-phone/noggin-phone-tile/noggin-phone-tile.component';
import { UserAddressModel } from 'src/app/user/models/user';
import { ProductApplicationService } from 'src/app/user/services/product-application.service';
import { UserService } from 'src/app/user/services/user.service';

@Component({
  selector: 'app-contract-agreement',
  standalone: true,
  imports: [
    CommonModule,
    LoadingComponent,
    NogginPhoneTileComponent,
    ReactiveFormsModule,
    RouterLink,
    FaIconComponent,
  ],
  templateUrl: './contract-agreement.component.html',
  styleUrl: './contract-agreement.component.scss'
})
export class ContractAgreementComponent implements OnInit {
  form!: FormGroup;
  formSubmitting = signal(false);
  userName!: string;
  userEmail!: string;
  userAddress!: UserAddressModel;
  contract!: any;

  constructor(
    private location: Location,
    private formBuilder: FormBuilder,
    private router: Router,
    private paService: ProductApplicationService,
    private userService: UserService,
    private sanitized: DomSanitizer,
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group(
      {
        agreed_to_terms: [false, Validators.requiredTrue],
        agreed_to_digital_signature: [false, Validators.requiredTrue],
      },
      {},
    );

    this.userService.getUserInfo()
      .subscribe(x => {
        this.userName = x.full_name;
        this.userEmail = x.email;
      });

    this.paService.getSession()
      .subscribe(session => {
        this.userAddress = session.user_address;
        this.contract = this.sanitized.bypassSecurityTrustHtml(session.contract_terms_section);
      });
  }

  submit() {
    if (this.form.invalid) return;
    if (this.formSubmitting()) return;

    this.formSubmitting.set(true);

    const data = {
      agreed_to_terms: this.form.get("agreed_to_terms")?.value,
      agreed_to_digital_signature: this.form.get("agreed_to_digital_signature")?.value,
    };

    this.paService
      .agreeToTerms(data)
      .subscribe({
        next: () => {
          this.router.navigateByUrl(routeNames.noCreditCheckPhones.fullPath());
        }
      });
  }

  async back() {
    this.location.back();
  }

  protected readonly faChevronLeft = faChevronLeft;

}
