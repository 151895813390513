import { Component, Input } from '@angular/core';
import { BankSvgComponent } from '../../components/bank-svg/bank-svg.component';
import { CelebrationBoxSvgComponent } from '../../components/celebration-box-svg/celebration-box-svg.component';
import { ChoiceSvgComponent } from '../../components/choice-svg/choice-svg.component';
import { PaperPlaneSvgComponent } from '../../components/paper-plane-svg/paper-plane-svg.component';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-card-onboarding-steps',
    standalone: true,
    imports: [
        BankSvgComponent,
        CelebrationBoxSvgComponent,
        ChoiceSvgComponent,
        PaperPlaneSvgComponent,
        CommonModule
    ],
    templateUrl: './card-onboarding-steps.component.html',
    styleUrl: './card-onboarding-steps.component.scss'
})
export class CardOnboardingStepsComponent {
    @Input({ required: true }) description!: string;
    @Input({ required: true }) step!: "1" | "2" | "3" | "4";
}
