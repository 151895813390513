import { Component } from "@angular/core";
import { NgClass, NgOptimizedImage } from "@angular/common";

@Component({
    selector: "app-our-research-hero",
    standalone: true,
    imports: [NgOptimizedImage, NgClass],
    templateUrl: "./our-research-hero.component.html",
    styleUrl: "./our-research-hero.component.scss",
})
export class OurResearchHeroComponent {}
