import { Component, Input } from "@angular/core";
import { CommonModule } from "@angular/common";

@Component({
    selector: "app-eye-close",
    standalone: true,
    imports: [CommonModule],
    templateUrl: "./eye-close.component.html",
    styleUrls: ["./eye-close.component.scss"],
})
export class EyeCloseComponent {
    @Input() color = "#0047FF";
    @Input() size = "24px";
    @Input("class") className = "";
}
