<section class="mb-12 grid grid-cols-2 items-stretch gap-3">
    <deals-detail-item label="credit check?" [value]="creditCheck"></deals-detail-item>

    <deals-detail-item
        [label]="lengthLabel"
        *ngIf="deal?.deal_contract_length"
        [value]="deal.deal_contract_length.toString() + ' months'"
    ></deals-detail-item>

    <deals-detail-item
        label="data allowance"
        *ngIf="deal?.sim_data_allowance_gb_numeric || deal?.sim_unlimited_data"
        [value]="
            deal?.sim_unlimited_data ? 'Unlimited' : deal?.sim_data_allowance_gb_numeric + 'GB'
        "
    ></deals-detail-item>

    <deals-detail-item
        label="connectivity"
        *ngIf="deal?.deal_connectivity"
        [value]="deal?.deal_connectivity"
    ></deals-detail-item>

    <deals-detail-item
        *ngIf="deal?.sim_text_allowance_numeric || deal?.sim_unlimited_texts"
        label="texts"
        [value]="deal?.sim_text_allowance_numeric?.toString() ?? 'Unlimited'"
    ></deals-detail-item>

    <deals-detail-item
        *ngIf="deal?.sim_minute_allowance_numeric || deal?.sim_unlimited_minutes"
        label="minutes"
        [value]="deal?.sim_minute_allowance_numeric?.toString() ?? 'Unlimited'"
    ></deals-detail-item>
    <deals-detail-item
        label="colour"
        *ngIf="deal?.device_colour"
        [value]="deal?.device_colour?.replaceAll('(PRODUCT)', '')?.trim()"
    ></deals-detail-item>
</section>
