<div class="flex flex-col" [ngClass]="marginTop">
    <label [for]="'password_' + label" class="mb-1.5 text-sm uppercase">{{ label }}</label>
    <div class="relative overflow-hidden">
        <input
            [formControl]="control"
            [attr.autocomplete]="autocomplete"
            id="'password_' + label"
            [attr.type]="passwordVisible() ? 'text' : 'password'"
            class="input w-full rounded-xl border border-black py-4 pl-4 pr-12"
            [attr.readonly]="isreadonly ? 'readonly' : null"
        />
        <button
            (click)="togglePasswordVisibility()"
            type="button"
            class="absolute right-4 top-1/2 translate-y-[-50%]"
        >
            <ng-container *ngIf="passwordVisible(); else notVisible"
                ><app-eye-open class="text-base"></app-eye-open>
            </ng-container>
            <ng-template #notVisible>
                <app-eye-close></app-eye-close>
            </ng-template>
        </button>
    </div>
</div>
