<div class="relative z-20">
    <nav
        class="flex h-[72px] items-center justify-between ml-5 lg:mx-16"
        [ngClass]="{ 'bg-black text-off-white': dark, 'text-black': !dark }"
    >
        <a 
            [routerLink]="routeNames.compare.mobile.fullPath()"
            class="h-[27px]"
        >
            <app-logo-full
                [fill]="'currentColor'"
                [ngClass]="dark ? 'stroke-off-white text-off-white' : 'text-black'"
            ></app-logo-full>
        </a>
        <ul
            *ngIf="showCenterLinks"
            class="hidden h-full items-stretch justify-around w-1/3 font-semibold lg:flex"
        >
            <li *ngFor="let item of navbarConfig">
                <app-nav-hover-menu [config]="item" [dark]="dark"></app-nav-hover-menu>
            </li>
        </ul>
        <div class="flex items-center justify-center">
            <a
                *ngIf="isLoggedIn"
                [routerLink]="routes.account.details.fullPath()"
                class="mr-2 flex h-10 w-10 cursor-pointer items-center justify-center lg:mr-2"
            >
                <app-profile-icon
                    [width]="'20px'"
                    [height]="'20px'"
                    [ngClass]="dark ? 'stroke-off-white text-off-white' : 'text-black'"
                ></app-profile-icon>
            </a>
            <a
                *ngIf="!isLoggedIn"
                class="mr-8 hidden font-semibold lg:block"
                [routerLink]="routes.login.fullPath()"
            >
                Login
            </a>
            <a
                [routerLink]="routeNames.ourResearch.fullPath()"
                [ngClass]="dark ? 'bg-off-white text-black' : 'bg-black text-white'"
                class="btn hidden h-8 min-h-0 items-center justify-center rounded-full py-0 text-center lg:flex lg:h-10"
            >
                Our research
            </a>
            <button
                (click)="openDrawer()"
                class="flex h-10 items-center justify-center px-5 lg:hidden"
            >
                <app-menu-icon
                    [width]="'24px'"
                    [height]="'24px'"
                    [ngClass]="dark ? 'stroke-off-white text-off-white' : 'text-black'"
                ></app-menu-icon>
            </button>
        </div>
    </nav>
</div>
