<article *ngIf="loading; else articleView">
    <app-loading class="my-28 block"></app-loading>
</article>
<ng-template #articleView>
    <article class="mx-auto max-w-[880px] p-5">
        <h1 class="mb-7 mt-6 text-3xl font-semibold">{{ article?.title }}</h1>
        <ul
            *ngIf="article?.tags && (article?.tags?.length ?? 0) > 0"
            class="mb-8 flex flex-wrap gap-2"
        >
            <ng-container *ngFor="let tag of article?.tags">
                <li class="mb-2 rounded-full bg-pink-100 px-4 py-1.5 text-center text-sm font-bold">
                    <a
                        [queryParams]="{ tags: tag.name }"
                        [routerLink]="routeNames.articles.list.fullPath()"
                        >{{ tag.name }}</a
                    >
                </li>
            </ng-container>
        </ul>
        <div class="flex w-full flex-col justify-between gap-5 md:flex-row">
            <p class="text-sm uppercase text-pink-700">{{ date }}</p>
            <p class="text-sm uppercase text-pink-700">
                written by: {{ article?.author?.first_name ?? article?.author?.full_name }}
            </p>
        </div>
        <img
            *ngIf="article?.cover_image"
            ngSrc="{{ article?.cover_image }}"
            alt="Image describing: {{ article?.title }}"
            width="350px"
            height="240px"
            class="mt-7 w-full rounded-3xl object-contain"
        />
        <section
            class="prose mx-auto mt-8 font-sans lg:prose-xl prose-headings:my-0 prose-p:my-0 prose-img:mx-auto prose-img:my-0"
            [innerHTML]="article?.text"
        ></section>
    </article>
</ng-template>
