import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { BankingState } from '../state/banking.state';
import { UserState } from "../state/user/user.state";
import { routeNames } from "src/app/app-routing.module";
import { toSignal } from "@angular/core/rxjs-interop";
import { Observable } from "rxjs";
import { Select } from "@ngxs/store";

@Injectable({
    providedIn: "root",
})
export class OpenBankingRequiredGuard implements CanActivate {
    @Select(UserState.getLoginStatus) isLoggedInObservable!: Observable<boolean>;
    isLoggedIn = toSignal(this.isLoggedInObservable)

    constructor(private router: Router, private bankingState: BankingState) { }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        await this.bankingState.init();
        if (this.isLoggedIn() && this.bankingState.connected()) {
            return true
        } else if (this.isLoggedIn() && !this.bankingState.connected()) {
            await this.router.navigate([routeNames.getStarted.shareOpenBanking.fullPath()])
            return false;
        } else {
            await this.router.navigate([routeNames.getStarted.createAccount.fullPath()]);
            return false;
        }
    }
}
