<section class="mx-auto max-w-[1160px] p-5">
    <h1 class="my-8 text-center font-display text-5xl font-semibold">BLOGGIN HQ</h1>
    <div class="mb-8 hidden border-b pb-5 lg:block">
        <ul *ngIf="allTags && allTags.length > 0" class="flex flex-wrap gap-2">
            <ng-container *ngFor="let tag of allTags">
                <li>
                    <button
                        (click)="applyTag(tag.name)"
                        class="mb-2 rounded-full border border-electric-blue px-4 py-1.5 text-center text-sm font-bold"
                        [ngClass]="
                            tags.includes(tag.name)
                                ? 'bg-electric-blue text-white'
                                : 'bg-white text-electric-blue'
                        "
                    >
                        {{ tag.name }}
                    </button>
                </li>
            </ng-container>
        </ul>
        <!--        <div>-->
        <!--            <label for="sort-by">SORT BY:</label>-->
        <!--            <select name="sort-by" id="sort-by">-->
        <!--                <option value="newest">Newest</option>-->
        <!--                <option value="oldest">Oldest</option>-->
        <!--            </select>-->
        <!--        </div>-->
    </div>
    <button
        (click)="openFilterModal()"
        class="btn btn-outline btn-primary mb-8 flex w-full justify-between rounded-xl lg:hidden"
    >
        <span class="flex items-center justify-start text-md font-bold">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                class="mr-2"
            >
                <path
                    d="M2.5 5H17.5"
                    stroke="#0047FF"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M5 10H15"
                    stroke="#0047FF"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M7 15H13"
                    stroke="#0047FF"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
            REFINE
        </span>
        <span class="text-md font-normal">
            {{ tags.length }}
            {{ tags.length > 1 || tags.length === 0 ? "filters" : "filter" }}
            applied
        </span>
    </button>
    <ul class="flex flex-wrap justify-center gap-8">
        <ng-container *ngFor="let article of articles">
            <home-blog-card [article]="article"></home-blog-card>
        </ng-container>
    </ul>

    <dialog id="blog-filter-dialog" class="modal">
        <div class="modal-box relative flex flex-col rounded-3xl">
            <button class="absolute right-5 top-5 text-2xl" (click)="closeFilterModal()">
                <fa-icon [icon]="faClose"></fa-icon>
            </button>
            <h3 class="text-xl font-semibold">Filter by tag</h3>
            <ul *ngIf="allTags && allTags.length > 0" class="my-8 flex flex-wrap gap-2">
                <ng-container *ngFor="let tag of allTags">
                    <li>
                        <button
                            (click)="toggleTag(tag.name)"
                            class="mb-2 rounded-full px-4 py-1.5 text-center text-sm font-bold"
                            [ngClass]="
                                tags.includes(tag.name)
                                    ? 'bg-pink-700 text-white'
                                    : 'bg-pink-100 text-black'
                            "
                        >
                            {{ tag.name }}
                        </button>
                    </li>
                </ng-container>
            </ul>
            <button
                class="btn btn-outline btn-primary flex-1 rounded-full text-white"
                (click)="applyFilterModal()"
            >
                CONFIRM
            </button>
        </div>
        <form method="dialog" class="modal-backdrop">
            <button>Close</button>
        </form>
    </dialog>
</section>
