import { Component, Input } from "@angular/core";
import { DealModel } from "../../models/deal";
import { RouterLink } from "@angular/router";
import { routeNames } from "src/app/app-routing.module";
import { SquigglyBackgroundComponent } from "../squigly-background/squiggly-background.component";
import { NgClass, NgIf, NgOptimizedImage } from "@angular/common";
import { ShimmerLoaderComponent } from "../../../core/components/shimmer-loader/shimmer-loader.component";
import { DealType } from "../../models/filter";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FaIconComponent } from "@fortawesome/angular-fontawesome";

@Component({
    selector: "app-desktop-deal-list-item",
    standalone: true,
    imports: [
        SquigglyBackgroundComponent,
        FaIconComponent,
        NgIf,
        ShimmerLoaderComponent,
        NgOptimizedImage,
        NgClass,
        RouterLink,
    ],
    templateUrl: "./desktop-deal-list-item.component.html",
    styleUrl: "./desktop-deal-list-item.component.scss",
})
export class DesktopDealListItemComponent {
    @Input() loading = false;
    @Input() deal: DealModel | undefined;
    @Input() bundle = false;
    @Input({ required: true }) dealType!: DealType;
    protected readonly routeNames = routeNames;
    protected readonly faInfoCircle = faInfoCircle;

    get isVip() {
        return !!this.deal?.deal_is_vip;
    }

    get isOnCredit() {
        return this.deal?.device_payment_type === 'On credit';
    }

    get hasCreditCheck() {
        return this.deal?.deal_credit_check;
    }

    get hasCreditCheckContent() {
        if (this.deal?.deal_credit_check === null) {
            return "Unknown";
        }

        return this.hasCreditCheck ? "Required" : "Not required";
    }

    dealUrl() {
        return routeNames.simOnlyDeals.details.fullPath({
            dealId: this.deal?.id?.toString() ?? "",
        });
    }

    protected readonly DealType = DealType;
    protected readonly parseFloat = parseFloat;
}
