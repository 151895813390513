import { Component, OnInit } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { UserModel } from "../../models/user";
import { FaqItem, WebsiteCmsService } from "../../../core/services/website-cms.service";
import { UserService } from "../../services/user.service";
import { routeNames } from "../../../app-routing.module";

@Component({
    selector: "app-edit-membership",
    templateUrl: "./edit-membership.component.html",
    styleUrls: ["./edit-membership.component.scss"],
})
export class EditMembershipComponent implements OnInit {
    faqItems: FaqItem[] = [];
    error?: string;
    loading = false;
    constructor(
        private userService: UserService,
        private cmsService: WebsiteCmsService,
        private snackBar: MatSnackBar,
    ) {
        this.loading = true;
        this.cmsService.getBankingFaqContent().subscribe({
            next: (r) => {
                this.faqItems = r;
            },
            error: (error) => {
                this.openSnackBar(error?.error?.detail, "Error");
            },
            complete: () => {
                this.loading = false;
            },
        });
    }
    ngOnInit() {
        this.userService.getUserInfo().subscribe();
    }
    get user(): UserModel {
        return this.userService.currentUser()!;
    }

    private openSnackBar(message: string, type: string): void {
        this.snackBar.open(type + ": " + message, "Dismiss", {
            panelClass: [type],
        });
    }

    protected readonly routeNames = routeNames;
}
