export const DealType = {
    bundle: "bundle",
    simOnly: "sim-only",
    phoneOnly: "phone-only",
} as const;

export type DealType = (typeof DealType)[keyof typeof DealType];
export type DealWithPhoneType = Exclude<DealType, "sim-only">

export type Filter = {
    title: string;
    key: keyof DealFilterParams;
    type: "radio" | "boolean" | "array" | "switch" | "data_allowance" | "checkbox";
    /** If true, the filter will be removed when toggled off rather than set to false */
    removeOnFalse?: boolean;
    options: FilterOption[];
};
export type FilterOption = {
    label: string;
    value: string | number | boolean;
};

export const simMonthlyCostFilter: Filter = {
    title: "Monthly cost",
    key: "sim_monthly_cost_inc_vat_max",
    type: "radio",
    options: [
        { label: "Up to £10", value: "10" },
        { label: "Up to £20", value: "20" },
        { label: "Up to £30", value: "30" },
        { label: "Up to £40", value: "40" },
    ],
};
export const deviceOnCreditFilter: Filter = {
    title: "Buy on credit?",
    key: "device_payment_type",
    type: "switch",
    options: [{ label: "Yes", value: "On credit" }],
};
export const phoneMonthlyCostFilter: Filter = {
    title: "Monthly cost",
    key: "deal_monthly_total_inc_vat_max",
    type: "radio",
    options: [
        { label: "Up to £10 per month", value: "10" },
        { label: "Up to £20 per month", value: "20" },
        { label: "Up to £30 per month", value: "30" },
        { label: "Up to £40 per month", value: "40" },
    ],
};
export const dataAllowanceFilter: Filter = {
    title: "Data allowance",
    type: "data_allowance",
    key: "sim_data_allowance_gb_numeric",
    options: [
        { label: "5GB+", value: "5" },
        { label: "10GB+", value: "10" },
        { label: "20GB+", value: "20" },
        { label: "30GB+", value: "30" },
        { label: "50GB+", value: "50" },
        { label: "100GB+", value: "100" },
        { label: "Unlimited data", value: "-1" },
    ],
};
export const simContractLengthFilter: Filter = {
    title: "Contract length",
    type: "radio",
    key: "sim_contract_length_months",
    options: [
        { label: "No contract", value: "0" },
        { label: "1 month", value: "1" },
        { label: "12 months", value: "12" },
        { label: "18 months", value: "18" },
        { label: "24 months", value: "24" },
        { label: "36 months", value: "36" },
        { label: "48 months", value: "48" },
    ],
};

export const dealContractLengthFilter: Filter = {
    title: "Contract length",
    type: "radio",
    key: "deal_contract_length",
    options: [
        { label: "No contract", value: "0" },
        { label: "1 month", value: "1" },
        { label: "12 months", value: "12" },
        { label: "18 months", value: "18" },
        { label: "24 months", value: "24" },
        { label: "36 months", value: "36" },
        { label: "48 months", value: "48" },
    ],
};
export const payTodayFilter: Filter = {
    title: "Total today (upfront cost)",
    type: "radio",
    key: "deal_pay_today_total_inc_vat_max",
    options: [
        { label: "No up front cost", value: "0" },
        { label: "Up to £50 up front", value: "50" },
        { label: "Up to £100 up front", value: "100" },
        { label: "Up to £150 up front", value: "150" },
        { label: "Up to £300 up front", value: "300" },
    ],
};
export const minutesFilter: Filter = {
    title: "Minutes",
    type: "checkbox",
    key: "sim_unlimited_minutes",
    removeOnFalse: true,
    options: [{ label: "Unlimited minutes", value: true }],
};
export const conditionFilter: Filter = {
    title: "Condition",
    type: "radio",
    key: "device_condition",
    options: [
        { label: "New", value: "New" },
        { label: "Refurbished", value: "Refurbished" },
        { label: "Used", value: "Used" },
    ],
};
export const textsFilter: Filter = {
    title: "Texts",
    type: "checkbox",
    removeOnFalse: true,
    key: "sim_unlimited_texts",
    options: [{ label: "Unlimited texts", value: true }],
};

export const fiveGFilter: Filter = {
    title: "5G",
    type: "checkbox",
    key: "deal_connectivity",
    removeOnFalse: true,
    options: [{ label: "5G only", value: "5G" }],
};

export function dealOrdering(dealType: DealType): FilterOption[] {
    if (dealType === DealType.phoneOnly) {
        return [
            {
                label: "Total today (low to high)",
                value: "deal_pay_today_total_inc_vat",
            },
            {
                label: "Total today (high to low)",
                value: "-deal_pay_today_total_inc_vat",
            },
            {
                label: "Monthly cost (low to high)",
                value: "deal_monthly_total_inc_vat",
            },
            {
                label: "Monthly cost (high to low)",
                value: "-deal_monthly_total_inc_vat",
            },
            {
                label: "Newest",
                value: "created_at",
            },
            {
                label: "Oldest",
                value: "-created_at",
            },
            {
                label: "Name A-Z",
                value: "deal_name",
            },
            {
                label: "Name Z-A",
                value: "-deal_name",
            },
        ];
    }
    if (dealType === DealType.simOnly) {
        return [
            {
                label: "Monthly cost (low to high)",
                value: "sim_monthly_cost_inc_vat",
            },
            {
                label: "Monthly cost (high to low)",
                value: "-sim_monthly_cost_inc_vat",
            },
            {
                label: "Newest",
                value: "created_at",
            },
            {
                label: "Oldest",
                value: "-created_at",
            },
            {
                label: "Name A-Z",
                value: "deal_name",
            },
            {
                label: "Name Z-A",
                value: "-deal_name",
            },
            {
                label: "Data allowance (low to high)",
                value: "sim_data_allowance_gb_numeric",
            },
            {
                label: "Data allowance (high to low)",
                value: "-sim_data_allowance_gb_numeric",
            },
        ];
    }
    return [
        {
            label: "Total today (low to high)",
            value: "deal_pay_today_total_inc_vat",
        },
        {
            label: "Total today (high to low)",
            value: "-deal_pay_today_total_inc_vat",
        },
        {
            label: "Monthly cost (low to high)",
            value: "deal_monthly_total_inc_vat",
        },
        {
            label: "Monthly cost (high to low)",
            value: "-deal_monthly_total_inc_vat",
        },
        {
            label: "Newest",
            value: "created_at",
        },
        {
            label: "Oldest",
            value: "-created_at",
        },
        {
            label: "Name A-Z",
            value: "deal_name",
        },
        {
            label: "Name Z-A",
            value: "-deal_name",
        },
        {
            label: "Data allowance (low to high)",
            value: "sim_data_allowance_gb_numeric",
        },
        {
            label: "Data allowance (high to low)",
            value: "-sim_data_allowance_gb_numeric",
        },
    ];
}

export type DealOrdering = ReturnType<typeof dealOrdering>[number];

export type DealOrderingParam = ReturnType<typeof dealOrdering>[number]["value"];

export type DealFilterParams = {
    ordering: DealOrderingParam;
    page: number;
    page_size: number;
    device_condition: string;

    sim_unlimited_data: boolean;
    deal_has_repayment_data: boolean;
    deal_is_vip: boolean;
    deal_name: string;
    deal_type: string[];
    deal_connectivity: "5G";
    device_payment_type: "On credit" | "N/A" | "Lease";
    manufacturer: string[];
    members_only: boolean;
    network_provider: string[];
    phone_colour: string[];
    phone_model: string[];
    phone_model_slug: string;
    sim_contract_length_months: number;
    sim_data_allowance_gb_numeric: number;
    sim_unlimited_minutes: boolean;
    sim_unlimited_texts: boolean;
    storage_size: string[];
    tags: string[];
    deal_credit_check: boolean;
    deal_contract_length: number;
    deal_monthly_total_inc_vat_max: string;
    deal_pay_today_total_inc_vat_max: string;
    sim_monthly_cost_inc_vat_max: string;
    device_pay_monthly: boolean;
};
