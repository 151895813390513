import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";

export type FaqItem = {
    id: number;
    title: string;
    content: string;
    order: number;
    created_at: string;
    tags?: string;
};

export const DynamicTextType = {
    openBanking: "ob_value_proposition",
} as const;
export type DynamicTextType = (typeof DynamicTextType)[keyof typeof DynamicTextType];
export type DynamicText = {
    id: number;
    created_at: string;
    updated_at: string;
    deleted: boolean;
    item_type: DynamicTextType;
    content: string;
    order: number;
};

@Injectable({
    providedIn: "root",
})
export class WebsiteCmsService {
    private baseURL: string = environment.baseUrl;

    constructor(private http: HttpClient) { }

    getWebsiteCMSContent(slug: string): Observable<any> {
        return this.http.get<any>(`${this.baseURL}/website-page/${slug}`);
    }

    getFaqContent(): Observable<FaqItem[]> {
        return this.http.get<FaqItem[]>(`${this.baseURL}/faq-items/`);
    }

    getBankingFaqContent(): Observable<any> {
        return this.http.get<any>(`${this.baseURL}/faq-items/banking/`);
    }

    getDynamicText(itemType: DynamicTextType): Observable<DynamicText[]> {
        return this.http.get<DynamicText[]>(`${this.baseURL}/dynamic-text?item_type=${itemType}`);
    }
}
