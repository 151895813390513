import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, shareReplay } from "rxjs";
import { DealModel } from "src/app/deal/models/deal";
import { environment } from "src/environments/environment";

export type CarouselApi = {
    id: number;
    title: string;
    subtitle: string;
    has_button: boolean;
    button_link: string;
    button_title: string;
    gradient_colour_top: CarouselBackground;
    gradient_colour_bottom: string;
    accent_colour: string;
    order: number;
    subsections: CarouselSubsection[];
    created_at: string;
    updated_at: string;
    deleted: boolean;
};
type CarouselSubsection = {
    id: number;
    title: string;
    has_button: boolean;
    button_link: string;
    button_title: string;
    order: number;
    deals: DealModel[];
    created_at: string;
    updated_at: string;
    deleted: boolean;
    carousel_section: number;
};

export const CarouselBackground = {
    green: "bg-green-gradient",
    lime: "bg-lime-gradient",
    blue: "bg-blue-gradient",
    pink: "bg-pink-gradient",
    pinkRed: "bg-pink-red-gradient",
    black: "bg-black",
    limeYellow: "bg-lime-to-yellow",
} as const;
export type CarouselBackground = (typeof CarouselBackground)[keyof typeof CarouselBackground];

@Injectable({ providedIn: "root" })
export class CarouselService {
    private baseURL: string = environment.baseUrl;
    private _carousels?: Observable<CarouselApi[]>;

    constructor(private http: HttpClient) {}

    carousels() {
        if (!this._carousels) {
            this._carousels = this.http
                .get<CarouselApi[]>(`${this.baseURL}/carousels`)
                .pipe(shareReplay());
        }
        return this._carousels!;
    }
}
