import { Component, Input, OnInit, signal } from "@angular/core";
import { PhonesService } from "../../services/phones.service";
import { ColoursModel } from "../../models/phone";
import { FormsModule } from "@angular/forms";
import { NgForOf, NgIf } from "@angular/common";
import { DealFilterState } from "../../state/deal-filter.state";
import { ActivatedRoute, Router } from "@angular/router";
import { routeNames } from "../../../app-routing.module";
import { DealType, DealWithPhoneType } from "../../models/filter";
import { InputFieldDetails } from "src/app/core/models/InputFieldDetails";

@Component({
    selector: "app-phone-select-tags",
    standalone: true,
    imports: [FormsModule, NgForOf, NgIf],
    templateUrl: "./phone-select-tags.component.html",
    styleUrl: "./phone-select-tags.component.scss",
})
export class PhoneSelectTagsComponent implements OnInit {
    @Input({ required: true }) dealType!: DealWithPhoneType;
    @Input({ required: true }) colourOptions: ColoursModel[] = [];
    @Input({ required: true }) dealsLoading = true;
    storageOptions = signal<InputFieldDetails[]>([]);
    routes = routeNames;

    constructor(
        private phoneService: PhonesService,
        public dealFilterState: DealFilterState,
        private router: Router,
        private route: ActivatedRoute,
    ) { }

    ngOnInit(): void {
        this.phoneService.getStorageSizes().subscribe({
            next: (sizes) => {
                this.storageOptions.set(
                    sizes
                        .sort((a, b) => a.size - b.size)
                        .map((s) => ({ label: s.size.toString(), value: s.size.toString() }))
                );
            },
        });
    }

    get showColorSelect() {
        return !this.dealsLoading && this.colourOptions.length > 0;
    }

    get showStorageSizeSelect() {
        return !this.dealsLoading && !!this.storageOptions()
    }

    onStorageSizeChange(event: Event) {
        const value = (event.target as HTMLSelectElement).value;

        gtag(
            "event",
            "deal_list__filter__storage_size",
            {
                deal_type: this.dealType,
                value
            }
        );

        if (this.dealFilterState.selectedFilters.phone_model_slug) {
            const key: keyof typeof this.routes = this.dealType === DealType.bundle ? "bundle" : "simFreeDeals";

            const queryParams: any = value !== "null" ? { storage_size: value } : {};

            if (this.dealFilterState.selectedFilters.phone_colour) {
                queryParams["phone_colour"] = this.dealFilterState.selectedFilters.phone_colour?.join(",");
            }

            if (typeof this.dealFilterState.selectedFilters.deal_credit_check !== "undefined") {
                queryParams["deal_credit_check"] = this.dealFilterState.selectedFilters.deal_credit_check;
            }

            if (typeof this.dealFilterState.selectedFilters.device_pay_monthly !== "undefined") {
                queryParams["device_pay_monthly"] = this.dealFilterState.selectedFilters.device_pay_monthly;
            }


            this.router.navigate(
                [
                    this.routes[key].list.fullPath({
                        phoneModel: this.dealFilterState.selectedFilters.phone_model_slug,
                    }),
                ],
                {
                    queryParams,
                },
            );
        }
    }

    onPhoneColourChange(event: Event) {
        const value = (event.target as HTMLSelectElement).value;

        gtag(
            "event",
            "deal_list__filter__colour",
            {
                deal_type: this.dealType,
                value
            }
        );

        if (this.dealFilterState.selectedFilters.phone_model_slug) {
            const key: keyof typeof this.routes = this.dealType === DealType.bundle ? "bundle" : "simFreeDeals";

            const queryParams: any = value !== "null" ? { phone_colour: value } : {};

            if (this.dealFilterState.selectedFilters.storage_size) {
                queryParams["storage_size"] = this.dealFilterState.selectedFilters.storage_size?.join(",");
            }

            if (typeof this.dealFilterState.selectedFilters.deal_credit_check !== "undefined") {
                queryParams["deal_credit_check"] = this.dealFilterState.selectedFilters.deal_credit_check;
            }

            if (typeof this.dealFilterState.selectedFilters.device_pay_monthly !== "undefined") {
                queryParams["device_pay_monthly"] = this.dealFilterState.selectedFilters.device_pay_monthly;
            }

            this.router.navigate(
                [
                    this.routes[key].list.fullPath({
                        phoneModel: this.dealFilterState.selectedFilters.phone_model_slug,
                    }),
                ],
                {
                    queryParams,
                },
            );
        }
    }
}
