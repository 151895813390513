import { Injectable } from "@angular/core";
import {
    HttpContext,
    HttpContextToken,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from "@angular/common/http";
import { Observable, switchMap, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { Store } from "@ngxs/store";
import jwtDecode from "jwt-decode";
import { environment } from "../../../environments/environment";
import { UserService } from "../../user/services/user.service";
import { LogoutUserAction } from "../../user/state/user/user.actions";

export const SkipAuthRefresh = new HttpContextToken<boolean>(() => false);

@Injectable()
export class AuthorizationHeaderInterceptor implements HttpInterceptor {
    apiUrl = `${environment.baseUrl}`;

    constructor(
        private userService: UserService,
        private store: Store,
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const accessToken: string | null = localStorage.getItem("accessToken");
        const refreshToken: string | null = localStorage.getItem("refreshToken");
        const skipRefresh = req.context.get(SkipAuthRefresh);

        if (accessToken && refreshToken && !skipRefresh) {
            const clonedRequest: HttpRequest<any> = req.clone({
                setHeaders: { Authorization: `Bearer ${accessToken}` },
            });
            return next.handle(clonedRequest).pipe(
                catchError((error) => {
                    if (error.status === 401 || error.status === 403) {
                        // this.store.dispatch(new LogoutUserAction());
                        console.error("unauthorized");
                        return this.refreshAccessToken(refreshToken).pipe(
                            switchMap((newAccessToken) => {
                                localStorage.setItem("accessToken", newAccessToken.access);

                                const newRequest: HttpRequest<any> = req.clone({
                                    setHeaders: {
                                        Authorization: `Bearer ${newAccessToken.access}`,
                                    },
                                });
                                return next.handle(newRequest);
                            }),
                            catchError((refreshError) => {
                                console.error("Token refresh error:", refreshError);
                                this.store.dispatch(new LogoutUserAction());
                                return throwError(refreshError);
                            }),
                        );
                    }
                    return throwError(error);
                }),
            );
        }
        return next.handle(req);
    }

    refreshAccessToken(refreshToken: string): Observable<any> {
        return this.userService.refreshAccessToken(refreshToken);
    }

    isTokenExpired(token: string): boolean {
        try {
            const decodedToken: any = jwtDecode(token);
            const currentTimeInSeconds: number = Math.floor(Date.now() / 1000);
            return decodedToken.exp <= currentTimeInSeconds;
        } catch (error) {
            console.error("Error decoding token:", error);
            return true;
        }
    }
}
