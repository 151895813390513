<div class="drawer">
    <input id="app-drawer" type="checkbox" class="drawer-toggle" [checked]="drawerOpen" />
    <main class="drawer-content">
        <ng-content></ng-content>
    </main>
    <div class="drawer-side z-50">
        <button (click)="toggleDrawer()" aria-label="close sidebar" class="drawer-overlay"></button>

        <div class="flex min-h-full w-80 flex-col bg-off-white">
            <div class="relative">
                <div class="flex">
                    <button
                        class="ml-4 mr-auto mt-4 flex h-10 w-10 items-center text-md font-bold uppercase"
                        *ngIf="!!drawerService.openMenu()"
                        (click)="drawerService.setSection()"
                    >
                        <fa-icon class="mr-2.5" [icon]="faChevronLeft"></fa-icon>
                        Back
                    </button>
                    <button
                        class="ml-auto mr-4 mt-4 flex h-10 w-10 items-start justify-end"
                        (click)="drawerService.close()"
                    >
                        <fa-icon class="text-xl" [icon]="faTimes"></fa-icon>
                    </button>
                </div>
                <a
                    (click)="drawerService.close()"
                    [routerLink]="routeNames.compare.mobile.fullPath()"
                    class="mx-auto mt-9 flex items-center justify-center"
                >
                    <app-logo-full height="28px"></app-logo-full>
                </a>
            </div>
            <div class="relative flex-1 overflow-hidden">
                <ul
                    *ngIf="showCenterLinks"
                    [ngClass]="!!drawerService.openMenu() ? '-translate-x-full' : 'translate-x-0'"
                    class="absolute bottom-0 left-0 right-0 top-14 flex flex-col overflow-y-scroll transition-transform ease-out"
                >
                    <li *ngFor="let item of drawerService.navbarConfig" class="mx-4 mb-2 pb-4">
                        <button
                            class="flex w-full items-center justify-between"
                            (click)="drawerService.setSection(item.title)"
                        >
                            {{ item.title }}
                            <fa-icon class="ml-auto text-md" [icon]="faChevronRight"></fa-icon>
                        </button>
                    </li>
                </ul>
                <div
                    class="absolute bottom-0 left-0 right-0 top-0 overflow-y-scroll transition-transform ease-out"
                    [ngClass]="!!drawerService.openMenu() ? 'translate-x-0' : 'translate-x-full'"
                >
                    <h3 class="mx-4 mb-4 mt-8 border-b pb-3 text-xl font-semibold">
                        <a
                            [routerLink]="drawerService.openMenu()?.link"
                            (click)="drawerService.close()"
                            class="flex items-center justify-between"
                        >
                            {{ drawerService.openMenu()?.title }}
                            <fa-icon class="ml-auto text-md" [icon]="faChevronRight"></fa-icon>
                        </a>
                    </h3>
                    <ul class="flex flex-col justify-center px-4">
                        <li *ngFor="let item of drawerService.openMenu()?.sections ?? []">
                            <deals-filter-collapse
                                padding="py-3"
                                class="capitalize"
                                [title]="item.title"
                                [defaultOpen]="false"
                            >
                                <ul>
                                    <li
                                        *ngFor="let link of item.options"
                                        class="mt-4 flex items-center justify-start text-black"
                                    >
                                        <span
                                            class="mr-3 mt-1 h-4 w-4 self-start rounded-full"
                                            [ngClass]="{
                                                'bg-green-gradient': link.type === 'article',
                                                'bg-black': link.type === 'link'
                                            }"
                                        ></span>
                                        <a
                                            [routerLink]="link.link"
                                            (click)="drawerService.close()"
                                            class="text-base font-semibold"
                                            [queryParams]="link.params"
                                        >
                                            <ng-container *ngIf="link.type === 'link'; else article" >
                                                {{ link.title }}
                                            </ng-container>
                                            <ng-template #article>
                                                {{ link.title }}
                                                <p class="text-md font-normal" *ngIf="link.subtitle">
                                                    {{ link.subtitle }}
                                                </p>
                                            </ng-template>
                                        </a>
                                    </li>
                                    <li class="mt-5" *ngIf="item.footerLink">
                                        <a
                                            (click)="closeDrawer()"
                                            class="text-black"
                                            [routerLink]="item.footerLink.link"
                                        >
                                            {{ item.footerLink.title }}
                                            <fa-icon class="ml-4 text-md" [icon]="faChevronRight" ></fa-icon>
                                        </a>
                                    </li>
                                </ul>
                            </deals-filter-collapse>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="flex flex-col p-4">
                <a
                    (click)="closeDrawer()"
                    [routerLink]="routeNames.login.fullPath()"
                    class="btn btn-outline btn-secondary rounded-full text-md uppercase"
                    *ngIf="!isLoggedIn"
                >
                    Login
                </a>
                <a
                    (click)="closeDrawer()"
                    [routerLink]="bottomButtonLink"
                    class="btn btn-secondary mt-4 rounded-full text-md uppercase"
                >
                    {{ bottomButtonText }}
                </a>
            </div>
        </div>
    </div>
</div>
