import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { Select } from "@ngxs/store";
import { Observable } from "rxjs";
import { UserState } from "../state/user/user.state";

@Injectable({
    providedIn: "root",
})
export class AuthGuard implements CanActivate {
    @Select(UserState.getLoginStatus) isLoggedIn$!: Observable<boolean>;
    isLoggedIn = false;

    constructor(private router: Router) {}

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        this.isLoggedIn$.subscribe((res) => {
            this.isLoggedIn = res;
        });

        if (this.isLoggedIn) {
            return true;
        } else {
            await this.router.navigate(["/login"]);
            return false;
        }
    }
}
