import { CommonModule, Location } from '@angular/common';
import { Component, OnInit, signal } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { routeNames } from 'src/app/app-routing.module';
import { LoadingComponent } from 'src/app/core/components/loading/loading.component';
import { NogginPhoneTileComponent } from 'src/app/noggin-phone/noggin-phone-tile/noggin-phone-tile.component';
import { UserService } from 'src/app/user/services/user.service';

@Component({
    selector: 'app-user-address',
    standalone: true,
    imports: [
        ReactiveFormsModule,
        CommonModule,
        NogginPhoneTileComponent,
        LoadingComponent,
        FaIconComponent,
    ],
    templateUrl: './user-address.component.html',
    styleUrl: './user-address.component.scss'
})
export class UserAddressComponent implements OnInit {
    form!: FormGroup;
    formSubmitting = signal(false);
    options = [
        ["STUDENT_ACCOMMODATION", "Student accommodation"],
        ["OWNER_OUTRIGHT", "Owner outright"],
        ["OWNER_MORTGAGE", "Owner mortgage"],
        ["PRIVATE_TENANT", "Private tenant"],
        ["COUNCIL_TENANT", "Council tenant"],
        ["HOUSING_ASSOCIATION", "Housing association"],
        ["LIVING_WITH_FAMILY", "Living with family"],
        ["OTHER", "Other"],
    ]

    constructor(
        private formBuilder: FormBuilder,
        private location: Location,
        private userService: UserService,
        private router: Router,
    ) { }

    ngOnInit(): void {
        const VALIDATION_REGEX = `^(${this.options.map(x => x[0]).join("|")})$`;

        this.form = this.formBuilder.group(
            {
                residentStatus: ["", [
                    Validators.required,
                    Validators.pattern(`^(${VALIDATION_REGEX})$`)
                ]],
                addressLine1: ["", [
                    Validators.required,
                    Validators.minLength(2),
                ]],
                addressLine2: ["", [
                ]],
                addressTown: ["", [
                    Validators.required,
                    Validators.minLength(2),
                ]],
                addressPostcode: ["", [
                    Validators.required,
                    Validators.pattern(`[A-Z0-9\\s]+`),
                    Validators.minLength(5),
                    Validators.maxLength(8)
                ]],
            },
            {}
        );
    }

    async back() {
        this.location.back();
    }

    submit() {
        if (this.form.invalid) {
            this.form.markAllAsTouched();
            return;
        }

        if (this.formSubmitting()) {
            return;
        }

        const data = {
            residentStatus: this.form.get("residentStatus")?.value,
            line1: this.form.get("addressLine1")?.value,
            line2: this.form.get("addressLine2")?.value,
            town: this.form.get("addressTown")?.value,
            postcode: this.form.get("addressPostcode")?.value,
            countryCode: "GB"
        };

        this.userService
            .saveAddress(data)
            .subscribe({
                next: () => {
                    this.router.navigateByUrl(routeNames.getStarted.shareOpenBanking.fullPath());
                }
            });
    }

    private fromFieldHasError(fieldName: string) {
        return (
            this.form.get(fieldName)?.touched &&
            this.form.get(fieldName)?.status === 'INVALID'
        );
    }

    get declarationLine1ErrorMessage(): string | undefined {
        if (this.fromFieldHasError("addressLine1")) {
            if (this.form.get("addressLine1")?.errors?.['required']) {
                return "Address line 1 is required";
            }
            return "Enter the first line of your address";
        }
        return;
    }

    get declarationLine2ErrorMessage(): string | undefined {
        return;
    }

    get declarationTownErrorMessage(): string | undefined {
        if (this.fromFieldHasError("addressTown")) {
            if (this.form.get("addressTown")?.errors?.['required']) {
                return "Town or city is required";
            }
            return "Enter a town or city";
        }
        return;
    }

    get declarationPostcodeErrorMessage(): string | undefined {
        if (this.fromFieldHasError("addressPostcode")) {
            if (this.form.get("addressPostcode")?.errors?.['required']) {
                return "Postcode is required";
            }
            return "Enter a UK postcode";
        }
        return;
    }

    protected readonly faChevronLeft = faChevronLeft;
}
