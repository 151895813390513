import { Component, signal } from "@angular/core";
import { FooterComponent } from "../footer/footer.component";
import { NavbarComponent } from "../navbar/navbar.component";
import { RouterLink, RouterOutlet } from "@angular/router";
import { Select } from "@ngxs/store";
import { Observable } from "rxjs";
import { UserState } from "../../../user/state/user/user.state";
import { DrawerState } from "../../state/drawer.state";
import { NgClass, NgForOf, NgIf } from "@angular/common";
import { FaIconComponent } from "@fortawesome/angular-fontawesome";
import {
    faArrowLeft,
    faChevronLeft,
    faChevronRight,
    faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { LogoFullComponent } from "../logo-full/logo-full.component";
import { routeNames } from "../../../app-routing.module";
import { FilterCollapseComponent } from "../../../deal/components/filter-collapse/filter-collapse.component";

@Component({
    selector: "app-drawer",
    standalone: true,
    imports: [
        FooterComponent,
        NavbarComponent,
        RouterLink,
        RouterOutlet,
        NgForOf,
        FaIconComponent,
        NgIf,
        NgClass,
        LogoFullComponent,
        FilterCollapseComponent,
    ],
    templateUrl: "./drawer.component.html",
    styleUrl: "./drawer.component.scss",
})
export class DrawerComponent {
    isLoggedIn = false;
    @Select(UserState.getLoginStatus) isLoggedIn$!: Observable<boolean>;

    constructor(public drawerService: DrawerState) {
        this.isLoggedIn$.subscribe((res) => {
            this.isLoggedIn = res;
        });
    }

    get drawerOpen(): boolean {
        return this.drawerService.isOpen();
    }

    toggleDrawer(): void {
        this.drawerService.toggle();
    }

    closeDrawer(): void {
        this.drawerService.close();
    }

    protected readonly faArrowLeft = faArrowLeft;
    protected readonly routeNames = routeNames;
    protected readonly faTimes = faTimes;
    protected readonly faChevronLeft = faChevronLeft;
    protected readonly faChevronRight = faChevronRight;
}
