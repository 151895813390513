import { Component, Input } from "@angular/core";
import { NgClass, NgIf, NgOptimizedImage } from "@angular/common";
import { DealModel } from "../../models/deal";
import { Router, RouterLink } from "@angular/router";
import { routeNames } from "../../../app-routing.module";
import { FaIconComponent } from "@fortawesome/angular-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { ShimmerLoaderComponent } from "../../../core/components/shimmer-loader/shimmer-loader.component";
import { SquigglyBackgroundComponent } from "../squigly-background/squiggly-background.component";
import { DealType } from "../../models/filter";
import { TagsRowComponent } from "../tags-row/tags-row.component";
import { isRetailerHidden } from "src/app/util/dealViewHelpers";
import { isIdea14Deal } from "src/app/util/features";

@Component({
    selector: "app-desktop-phone-deal-list-item",
    standalone: true,
    imports: [
        NgIf,
        NgOptimizedImage,
        ShimmerLoaderComponent,
        SquigglyBackgroundComponent,
        FaIconComponent,
        NgClass,
        RouterLink,
        TagsRowComponent,
    ],
    templateUrl: "./desktop-phone-deal-list-item.component.html",
    styleUrl: "./desktop-phone-deal-list-item.component.scss",
})
export class DesktopPhoneDealListItemComponent {
    @Input() loading = false;
    @Input() deal: DealModel | undefined;
    @Input({ required: true }) dealType!: DealType;
    isRetailerHidden = isRetailerHidden;

    get isVip() {
        return this.deal?.members_benefit_text;
    }
    protected readonly faInfoCircle = faInfoCircle;

    constructor(private router: Router) { }

    get dealColor() {
        return this.deal?.device_colour?.replaceAll("(PRODUCT)", "")?.trim();
    }

    dealUrl() {
        if (this.dealType === DealType.bundle) {
            return routeNames.bundle.details.fullPath({
                phoneModel: this.deal?.phone_model?.slug ?? "",
                dealId: this.deal?.id?.toString() ?? "",
            });
        }
        return routeNames.simFreeDeals.details.fullPath({
            phoneModel: this.deal?.phone_model?.slug?.toString() ?? "",
            dealId: this.deal?.id?.toString() ?? "",
        });
    }

    get isOnCredit() {
        return this.deal?.deal_credit_check;
    }

    get contractLenghtLabel() {
        return this.deal && isIdea14Deal(this.deal) ? "length" : "contract length"
    }


    protected readonly DealType = DealType;
    protected readonly parseFloat = parseFloat;
}
