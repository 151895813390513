<div class="flex flex-col w-full -mx-5 -mt-5">
    <div
        class="h-3 bg-lime-green w-8/12 rounded-r-full"
    ></div>
</div>

<div class="account-container mt-6">
    <div class="flex justify-center">
        <p class="mx-auto uppercase text-sm font-light">Step 3 of 3</p>
    </div>

    <section class="mt-4 mb-8">
        <h1 class="text-3xl font-semibold">Submit our typeform</h1>
        <p class="mt-4">
            This is the final step and shouldn’t take more than 2 minutes to complete. We are unable to consider your application without it.
        </p>
        <button
            class="btn btn-primary mx-auto mb-12 mt-12 block h-14 w-full self-stretch rounded-full text-center uppercase text-white flex"
            (click)="next()"
        >
           Start form
        </button>

    </section>
</div>

<section class="flex my-8 mx-auto max-w-lg" nomargin>
    <app-noggin-phone-tile
        caption="Why are we doing this?"
        heading="5.8 million people in the UK are excluded from credit!"
        text="We are dedicated to bringing a solution for people unfairly locked out of the current system."
    ></app-noggin-phone-tile>
</section>
