<div class="flex justify-center items-center px-6 md:px-10 py-2 bg-black">
    <!-- TODO: Replace with correct info logo -->
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.0594 5.16016C5.97238 5.16016 1.84375 9.28878 1.84375 14.3758C1.84375 19.4629 5.97238 23.5915 11.0594 23.5915C16.1465 23.5915 20.2751 19.4629 20.2751 14.3758C20.2751 9.28878 16.1465 5.16016 11.0594 5.16016ZM11.981 18.9837H10.1379V13.4543H11.981V18.9837ZM11.981 11.6111H10.1379V9.768H11.981V11.6111Z" fill="white"/>
    </svg>

    <p class="pl-2 text-white text-[10px] md:text-xs">Noggin is authorised by the FCA as a Credit Broker and provider of Credit Information Services. Credit is subject to status.</p>
</div>

<app-mobile-compare-hero
    class="mt-6 md:mt-8"
/>

<section class="flex flex-col bg-black pt-[180px] min-h-[500px] relative pb-28 mb-20">
    <div class="flex flex-col items-center justify-center">
        <h2 class="mx-auto text-center text-3xl lg:text-5xl text-off-white font-semibold uppercase order-2">
            Who supports us?
        </h2>
        <h3 class="mx-auto mb-3 block text-center text-sm text-off-white uppercase order-1">
            Out investors
        </h3>
    </div>

    <div class="mx-auto mt-12 flex flex-wrap items-center justify-center gap-9">
        <figure
            class="grid place-items-center bg-off-white h-[135px] w-[256px] overflow-hidden rounded-3xl border object-cover object-center"
        >
            <img
                class="object-cover object-center"
                alt="Oxford Capital logo"
                ngSrc="/assets/images/oxcp-logo_2.png"
                height="99"
                width="234"
            />
        </figure>

        <figure
            class="grid place-items-center bg-off-white h-[135px] w-[256px] overflow-hidden rounded-3xl border object-cover object-center"
        >
            <img
                class="object-cover object-center"
                alt="BGV logo"
                ngSrc="/assets/images/bgv_logo_2.png"
                height="64"
                width="235"
            />
        </figure>

        <figure
            class="grid place-items-center bg-off-white h-[135px] w-[256px] overflow-hidden rounded-3xl border object-cover object-center"
        >
            <img
                class="object-cover object-center"
                alt="Syndicate Room logo"
                ngSrc="/assets/images/syndicate-room_2.png"
                height="108"
                width="162"
            />
        </figure>
    </div>

    <div
        class="absolute bg-transparent bg-repeat-x w-full x-w-[102%] h-[170px] p-0 mr-0 my-0 x-mx-[2%] border-none z-10 -bottom-[160px] overflow-hidden z-10"
        style="background-image: url(assets/svg/drip_black.svg);"
    ></div>
</section>

