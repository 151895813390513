import { animate, style, transition, trigger } from "@angular/animations";
import { Component, inject } from "@angular/core";
import { NotifyState } from "../../state/notify.state";
import { CommonModule } from "@angular/common";

@Component({
    selector: "app-notify",
    standalone: true,
    imports: [CommonModule],
    templateUrl: "./notify.component.html",
    styleUrl: "./notify.component.scss",
    animations: [
        trigger("toastAnimation", [
            transition(
                ":enter",
                [
                    style({
                        opacity: 0,
                        transform: "translateY(100%)",
                    }),
                    animate("300ms ease-in", style({ opacity: 1, transform: "translateY(0)" })),
                ],
                {
                    params: { hiddenValue: "" },
                },
            ),
            transition(":leave", [
                style({ opacity: 1, transform: "translateY(0)" }),
                animate(
                    "300ms ease-out",
                    style({
                        opacity: 0,
                        transform: "translateY(100%)",
                    }),
                ),
            ]),
        ]),
    ],
})
/**
 * A component to notify of user actions, to replace mui snackbar.
 * Controlled through global state.
 */
export class NotifyComponent {
    state = inject(NotifyState);
}
