import { PathLocationStrategy, APP_BASE_HREF, PlatformLocation } from '@angular/common';
import { Optional, Inject, Injectable } from '@angular/core';
import { UrlSerializer } from '@angular/router';
import { isUtmKey } from './core/models/utm';

@Injectable()
export class SelectiveQueryParamLocationStrategy extends PathLocationStrategy {

  private get selectedSearchParams(): URLSearchParams | undefined {
    const currentQueryParameters = this.platformLocation?.search;

    if (!currentQueryParameters) {
      return;
    }

    const currentURLSearchParams = new URLSearchParams(currentQueryParameters);
    Array
      .from(currentURLSearchParams.keys())
      .forEach(paramKey => {
        if (!isUtmKey(paramKey)) {
          currentURLSearchParams.delete(paramKey)
        }
      })

    return currentURLSearchParams;
  }

  constructor(
    private platformLocation: PlatformLocation,
    private urlSerializer: UrlSerializer,
    @Optional() @Inject(APP_BASE_HREF) _baseHref?: string,
  ) {
    super(platformLocation, _baseHref);
  }

  override prepareExternalUrl(internal: string): string {
    const defaultPath = super.prepareExternalUrl(internal);

    const searchParamsToSave = this.selectedSearchParams;

    if (!searchParamsToSave || searchParamsToSave.size == 0) {
      return defaultPath;
    }

    const existingQueryParams = Object.fromEntries(searchParamsToSave.entries());
    const urlTree = this.urlSerializer.parse(defaultPath);
    const nextQueryParams = urlTree.queryParams;
    urlTree.queryParams = { ...existingQueryParams, ...nextQueryParams };
    return urlTree.toString();
  }
}
