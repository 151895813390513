import { Component, EventEmitter, Input, Output } from "@angular/core";
import { NgClass } from "@angular/common";

@Component({
    selector: "app-toggle-button",
    standalone: true,
    imports: [NgClass],
    templateUrl: "./toggle-button.component.html",
    styleUrl: "./toggle-button.component.scss",
})
export class ToggleButtonComponent {
    @Input({ required: true }) active = false;
    @Output() toggle = new EventEmitter<boolean>();

    onClick(value: boolean) {
        this.active = value;
        this.toggle.emit(this.active);
    }
}
