import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

@Component({
    selector: "app-main-scaffold-simple",
    templateUrl: "./main-scaffold-simple.component.html",
    styleUrls: ["./main-scaffold-simple.component.scss"],
})
export class MainScaffoldSimpleComponent {
    public footerColor = "";

    constructor(
        private router: ActivatedRoute
    ) {
        this.router.data.subscribe(data => {
            const footerColor = data['footerColor'];
            if (footerColor) {
                this.footerColor = footerColor;
            }
        });

    }
}

