import { Component, Input, signal } from "@angular/core";
import { faChevronDown, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FaIconComponent } from "@fortawesome/angular-fontawesome";
import { Router, RouterLink } from "@angular/router";
import { NgClass, NgForOf, NgIf } from "@angular/common";
import { Location } from "@angular/common";
import { NavbarConfig } from "../../models/navbar-config.model";
import { animate, style, transition, trigger } from "@angular/animations";

@Component({
    selector: "app-nav-hover-menu",
    standalone: true,
    imports: [FaIconComponent, RouterLink, NgClass, NgForOf, NgIf],
    templateUrl: "./nav-hover-menu.component.html",
    styleUrl: "./nav-hover-menu.component.scss",
    animations: [
        trigger("navBackgroundAnimation", [
            transition(":enter", [
                style({ opacity: 0 }),
                animate("500ms ease-out", style({ opacity: 1, transform: "translateY(0)" })),
            ]),
            transition(":leave", [
                style({ opacity: 1 }),
                animate("300ms ease-out", style({ opacity: 0 })),
            ]),
        ]),
    ],
})
export class NavHoverMenuComponent {
    @Input({ required: true }) dark!: boolean;
    @Input({ required: true }) config!: NavbarConfig;
    menuHovered = signal<boolean>(false);

    protected readonly faChevronDown = faChevronDown;
    constructor(
        private location: Location,
        private router: Router
    ) {
        this.location.onUrlChange(() => this.itemHovered(false));
    }

    itemHovered(hovered: boolean) {
        this.menuHovered.set(hovered);
        return true;
    }

    protected readonly faChevronRight = faChevronRight;

    sendAnalyticsNav(title: string) {
        gtag(
            "event",
            "navbar__click",
            {
                value: title,
                location: this.router.url
            }
        )
    }
}
