import { Injectable, signal } from "@angular/core";
import { HttpClient, HttpContext, HttpParams } from "@angular/common/http";
import { BehaviorSubject, Observable, shareReplay, tap } from "rxjs";
import {
    ContactUsFormModel,
    LoginUserModel,
    PlaidTokenModel,
    RegisterUserModel,
    UserDetailsFormModel,
    UserModel,
    UserAddressModel
} from "../models/user";
import { environment } from "../../../environments/environment";
import { SkipAuthRefresh } from "src/app/core/interceptors/authorization-header-interceptor.service";

@Injectable({
    providedIn: "root",
})
export class UserService {
    private userRequest$?: Observable<UserModel>;
    public currentUser = signal<UserModel | null>(null);
    private baseUrl: string = environment.baseUrl;

    constructor(private http: HttpClient) { }

    registerUser(user: RegisterUserModel): Observable<UserModel> {
        return this.http.post<UserModel>(`${this.baseUrl}/user/`, user);
    }

    signOut(): void {
        this.currentUser.set(null);
    }

    loginUser(user: LoginUserModel): Observable<any> {
        return this.http.post<any>(`${this.baseUrl}/token/`, user);
    }

    getUserInfo(): Observable<UserModel> {
        if (!this.userRequest$) {
            this.userRequest$ = this.http.get<UserModel>(`${this.baseUrl}/user/`).pipe(
                tap({
                    next: (user) => {
                        this.currentUser.set(user);
                    },
                    error: (error) => {
                        console.error("Error getting user: ", error);
                    },
                    complete: () => {
                        this.userRequest$ = undefined;
                    },
                }),
                shareReplay(1),
            );
        }
        return this.userRequest$;
    }

    updateUser(user: Partial<RegisterUserModel>): Observable<UserModel> {
        return this.http.patch<UserModel>(`${this.baseUrl}/user/`, user);
    }

    deleteUser(): Observable<any> {
        return this.http.delete<any>(`${this.baseUrl}/user/`);
    }

    requestPasswordReset(email: string): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/user/request-reset-password/${email}/`);
    }

    getPublicToken(): Observable<PlaidTokenModel> {
        return this.http.get<PlaidTokenModel>(
            `${this.baseUrl}/integration/plaid/create-link-token/`,
        );
    }

    refreshAccessToken(refreshToken: string): Observable<any> {
        return this.http.post<any>(
            `${this.baseUrl}/token/refresh/`,
            { refresh: refreshToken },
            {
                context: new HttpContext().set(SkipAuthRefresh, true),
            },
        );
    }

    setAccessToken(token: string): Observable<any> {
        return this.http.post<any>(`${this.baseUrl}/integration/plaid/set-access-token/`, {
            public_token: token,
        });
    }

    contactUs(form: ContactUsFormModel, complaints?: boolean): Observable<any> {
        let parameters: HttpParams = new HttpParams();
        if (complaints) {
            parameters = parameters.set("complaint", 1);
        }
        return this.http.post<any>(`${this.baseUrl}/contact-us-form-submit/`, form, {
            params: parameters,
        });
    }

    signManifesto(signature: string): Observable<any> {
        return this.http.post<any>(`${this.baseUrl}/manifesto-signings/`, signature);
    }

    //  Online banking
    createPlaidLink(): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/integration/plaid/create-link-token/`);
    }

    setPlaidLink(token: string): Observable<any> {
        return this.http.post<any>(`${this.baseUrl}/integration/plaid/set-access-token/`, {
            public_token: token,
        });
    }

    disconnectPlaid(): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/integration/plaid/unpair/`);
    }

    transactionStatusHistory(): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/user/repayment-status/`);
    }

    transactionHistory(startDate: string, endDate: string): Observable<any> {
        let parameters: HttpParams = new HttpParams();
        if (startDate && endDate) {
            parameters = parameters.set("start_date", startDate);
            parameters = parameters.set("end_date", endDate);
        }
        return this.http.get<any>(`${this.baseUrl}/user/repayment-history`, {
            params: parameters,
        });
    }

    optOutStatus(): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/user/repayment-history/opt-out/`);
    }

    optIn(): Observable<any> {
        return this.http.post<any>(`${this.baseUrl}/user/repayment-history/opt-out/`, {});
    }

    optOut(): Observable<any> {
        return this.http.delete<any>(`${this.baseUrl}/user/repayment-history/opt-out/`);
    }

    saveUserDetails(user: UserDetailsFormModel): Observable<{ id: string }> {
        return this.http.post<{ id: string }>(`${this.baseUrl}/user/details/`, user);
    }

    saveAddress(data: UserAddressModel): Observable<{ id: string }> {
        return this.http.post<{ id: string }>(`${this.baseUrl}/user/address/`, data);
    }

    getAddress(id: string): Observable<UserAddressModel> {
        return this.http.get<UserAddressModel>(`${this.baseUrl}/user/address/${id}/`);
    }
}


