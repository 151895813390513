import { DealFilterParams } from "../deal/models/filter";

function removeEmptyPairs(obj: { [k: string]: unknown }) {
    const clone = { ...obj }
    for (const [key, value] of Object.entries(clone)) {
        if (value || value === false || value === 0) {
            continue;
        }
        delete clone[key];
    }
    return clone;
}

function removeIgnored(obj: { [k: string]: unknown }) {
    const keys_to_ignore = [
        "page_size",
        "deal_has_repayment_data",
        "deal_is_vip",
        "deal_name",
        "members_only",
        "phone_model",
        "tags",
    ]
    const clone = { ...obj }
    for (const key in keys_to_ignore) {
        if (clone[key]) {
            delete clone[key];
        }
    }
    return clone;
}

function remapDealFiltersFieldsAsString(dealFitlerParams: Partial<DealFilterParams>) {
    return {
        ...dealFitlerParams,
        deal_type: dealFitlerParams?.deal_type?.[0],
        manufacturer: dealFitlerParams?.manufacturer?.[0],
        network_provider: dealFitlerParams?.network_provider?.[0],
        phone_colour: dealFitlerParams?.phone_colour?.[0],
        phone_model: dealFitlerParams?.phone_model?.[0],
        storage_size: dealFitlerParams?.storage_size?.[0],
    };
}

export function payloadForDealFilterParams(dealFitlerParams: Partial<DealFilterParams>) {
    return removeIgnored(removeEmptyPairs(remapDealFiltersFieldsAsString(dealFitlerParams)));
}
