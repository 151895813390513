import { Component, inject, Input } from "@angular/core";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { dealFilterFromParams, DealFilterState } from "../../state/deal-filter.state";
import { FaIconComponent } from "@fortawesome/angular-fontawesome";
import { DealOrderDropdownComponent } from "../deal-order-dropdown/deal-order-dropdown.component";
import { DealFilterComponent } from "../deal-filter/deal-filter.component";
import { DealState } from "../../state/deal.state";
import { FilterCollapseComponent } from "../filter-collapse/filter-collapse.component";
import { NgForOf } from "@angular/common";
import { CreditToggleComponent } from "../credit-toggle/credit-toggle.component";
import { ActivatedRoute } from "@angular/router";
import { DealFilterParams, DealOrdering, dealOrdering, DealType } from "../../models/filter";
import { payloadForDealFilterParams } from "src/app/util/analytics";

@Component({
    selector: "app-filter-dialog",
    standalone: true,
    imports: [
        FaIconComponent,
        DealOrderDropdownComponent,
        DealFilterComponent,
        FilterCollapseComponent,
        NgForOf,
        CreditToggleComponent,
    ],
    templateUrl: "./filter-dialog.component.html",
    styleUrl: "./filter-dialog.component.scss",
})
export class FilterDialogComponent {
    protected readonly dealOrdering = dealOrdering;
    protected readonly faClose = faClose;
    dealFilterState = inject(DealFilterState);
    dealState = inject(DealState);
    @Input({ required: true }) dealTypeId?: string;
    @Input({ required: true }) dealType!: DealType;

    constructor(private route: ActivatedRoute) { }

    get filterLength() {
        return Object.entries(this.dealFilterState.selectedFilters).filter(([k, value]) => {
            const key = k as keyof DealFilterParams;
            return value !== undefined && key !== "deal_type";
        }).length;
    }
    openFilterModal(): void {
        const modal = document.getElementById("deals-refine-filter") as HTMLDialogElement;
        modal.showModal();
    }

    closeFilterModal(): void {
        const modal = document.getElementById("deals-refine-filter") as HTMLDialogElement;
        modal.close();
    }

    async applyFilters() {
        await this.dealFilterState.applyFilters();
        this.closeFilterModal();
    }

    setSortBy(value: DealOrdering) {
        const previousOrdering = this.dealFilterState.ordering().value;
        this.dealFilterState.setSortBy(value, { updateAutomatically: false });
        gtag(
            "event",
            "deal_list__order_change",
            {
                ordering_previous: previousOrdering,
                ...payloadForDealFilterParams(this.dealFilterState.selectedFilters)
            }
        );
    }

    async clearFilters() {
        const phoneModel = this.route.snapshot.params["phone_model"];
        this.dealFilterState.selectedFilters = dealFilterFromParams(
            {},
            {
                ...(this.dealTypeId ? { deal_type: [this.dealTypeId] } : {}),
                ...(phoneModel ? { phone_model_slug: phoneModel } : {}),
            },
        );
        await this.applyFilters();
        this.closeFilterModal();
    }
}
