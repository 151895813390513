<ng-container *ngIf="dealDetailsState.status() === HttpStatus.loading; else page">
    <app-loading></app-loading>
</ng-container>
<ng-template #page>
    <section class="container mt-10">
        <app-breadcrumbs class="md:hidden" [breadcrumbs]="breadcrumbs"></app-breadcrumbs>
        <section
            class="flex flex-col items-center gap-4 md:flex-row md:items-start md:gap-7 xl:gap-20"
        >
            <figure
                class="mb-7 mt-8 flex aspect-square w-full min-w-[250px] max-w-[496px] flex-1 items-center justify-center rounded-3xl border bg-[#FFF] md:mb-0 md:mt-0"
                *ngIf="deal?.device_image_1 || deal?.deal_image_uploaded_file"
            >
                <div class="relative m-5 h-full w-full">
                    <img
                        class="aspect-square object-contain"
                        [ngSrc]="deal?.deal_image_uploaded_file ?? deal?.device_image_1 ?? ''"
                        fill=""
                        alt="Deal image"
                /></div>
            </figure>

            <div class="w-full min-w-[290px] flex-1">
                <app-breadcrumbs
                    class="hidden md:block"
                    [breadcrumbs]="breadcrumbs"
                ></app-breadcrumbs>
                <app-tags-row class="mt-7 block" *ngIf="deal" [deal]="deal"></app-tags-row>
                <h1 class="mb-5 mt-3 text-3xl font-semibold">{{ deal?.deal_name }}</h1>
                <p *ngIf="dealType === DealType.bundle && isRetailerHidden(deal)" class="mb-5">{{
                    deal?.deal_retailer_name?.concat(" SIM")
                }}</p>
                <section class="mb-12 flex border-t border-black border-opacity-20 pt-7">
                    <div class="flex-1 border-r border-black border-opacity-20 pl-2.5">
                        <h3 class="text-3.5xl font-medium"
                            >£{{ deal?.deal_pay_today_total_inc_vat?.toFixed(2) }}</h3
                        >
                        <p class=""> total today</p>
                    </div>
                    <div class="flex-1 pl-8">
                        <h3 class="text-3.5xl font-medium"
                            >£{{ deal?.deal_monthly_total_inc_vat?.toFixed(2) }}</h3
                        >
                        <p class=""> est. total monthly</p>
                    </div>
                </section>

                    <app-squiggly-background
                        class="mb-5 mt-9 block w-full"
                        *ngIf="deal?.members_benefit_text"
                    >
                        <a
                            [href]="deal?.members_benefit_link"
                            class="mx-4 flex h-[114px] items-center justify-between bg-black px-4 text-white"
                        >
                            <h4>{{ deal?.members_benefit_text }}</h4>
                            <img
                                alt="Member benefit image"
                                width="50"
                                height="50"
                                *ngIf="deal?.members_benefit_image"
                                [ngSrc]="deal!.members_benefit_image"
                            />
                        </a>
                    </app-squiggly-background>
                    <div *ngIf="deal?.deal_price_rises" class="my-6">
                        <p class="flex items-center"
                            ><fa-icon [icon]="faInfoCircle" class="mr-2"></fa-icon
                            >{{ deal?.deal_price_rises }}</p
                        >
                    </div>

                @switch(isIdea14Deal) {
                    @case(true) {
                        <a
                            class="btn btn-primary fixed bottom-5 left-5 right-5 z-20 rounded-full uppercase text-white md:static md:mb-14 md:w-full"
                            [routerLink]="routeNames.noCreditCheckPhones.fullPath()"
                        >
                            Get this deal
                        </a>
                    }
                    @default {
                        <app-get-deal-btn *ngIf="deal" [deal]="deal"></app-get-deal-btn>
                    }
                }

                <div
                    class="mb-8 border-b border-b-black border-opacity-20 pb-2"
                    *ngIf="deal?.deal_contract_structure === 'phone-contract'"
                >
                    <h3 class="text-xl font-semibold">Contract details</h3>
                </div>

                <div
                    *ngIf="
                        dealType === 'bundle' && deal?.deal_contract_structure !== 'phone-contract'
                    "
                    class="mb-7 mt-9 flex w-full items-center justify-center border-b border-black border-opacity-20"
                >
                    <button
                        class="flex-1 text-center text-xl"
                        [ngClass]="
                            showSimDetails
                                ? 'border-b-4 border-electric-blue font-semibold text-electric-blue'
                                : ''
                        "
                        (click)="showSimDetails = true"
                    >
                        SIM plan
                    </button>
                    <button
                        [ngClass]="
                            !showSimDetails
                                ? 'border-b-4 border-electric-blue font-semibold text-electric-blue'
                                : ''
                        "
                        class="flex-1 text-center text-xl"
                        (click)="showSimDetails = false"
                    >
                        Mobile device
                    </button>
                </div>

                <ng-container
                    *ngIf="showSimDetails && deal?.deal_contract_structure !== 'phone-contract'"
                >
                    <section class="mb-12 grid grid-cols-2 items-stretch gap-3">
                        <deals-detail-item
                            label="SIM monthly cost"
                            *ngIf="deal?.sim_monthly_cost_inc_vat"
                            [value]="
                                '£' +
                                parseFloat(
                                    deal?.sim_monthly_cost_inc_vat?.toString() ?? '0'
                                ).toFixed(2)
                            "
                        ></deals-detail-item>
                        <deals-detail-item
                            label="contract length"
                            *ngIf="deal?.sim_contract_length_friendly"
                            [value]="deal?.sim_contract_length_friendly"
                        ></deals-detail-item>

                        <deals-detail-item
                            *ngIf="this.dealType === DealType.simOnly"
                            label="credit check?"
                            [value]="creditCheck"
                        ></deals-detail-item>

                        <deals-detail-item
                            label="data allowance"
                            *ngIf="deal?.sim_data_allowance_gb_numeric || deal?.sim_unlimited_data"
                            [value]="
                                deal?.sim_unlimited_data
                                    ? 'Unlimited'
                                    : deal?.sim_data_allowance_gb_numeric + 'GB'
                            "
                        ></deals-detail-item>

                        <deals-detail-item
                            label="connectivity"
                            *ngIf="deal?.deal_connectivity"
                            [value]="deal?.deal_connectivity"
                        ></deals-detail-item>

                        <deals-detail-item
                            *ngIf="deal?.sim_text_allowance_numeric || deal?.sim_unlimited_texts"
                            label="texts"
                            [value]="deal?.sim_text_allowance_numeric?.toString() ?? 'Unlimited'"
                        ></deals-detail-item>
                        <deals-detail-item
                            *ngIf="
                                deal?.sim_minute_allowance_numeric || deal?.sim_unlimited_minutes
                            "
                            label="minutes"
                            [value]="deal?.sim_minute_allowance_numeric?.toString() ?? 'Unlimited'"
                        ></deals-detail-item>
                    </section>

                    <section class="mb-12" *ngIf="deal?.sim_details_free_text">
                        <h3 class="mb-4 text-2xl font-semibold">Sim details</h3>
                        <div
                            class="prose mt-5 max-w-none"
                            [innerHTML]="deal?.sim_details_free_text"
                        ></div>
                    </section>
                </ng-container>
                <ng-container
                    *ngIf="!showSimDetails && deal?.deal_contract_structure !== 'phone-contract'"
                >
                    <section class="mb-12 grid grid-cols-2 gap-3">
                        <deals-detail-item
                            label="device monthly cost"
                            *ngIf="deal?.device_monthly_cost_inc_vat"
                            [value]="
                                '£' +
                                parseFloat(
                                    deal?.device_monthly_cost_inc_vat?.toString() ?? '0'
                                ).toFixed(2)
                            "
                        ></deals-detail-item>

                        <deals-detail-item
                            label="contract length"
                            *ngIf="deal?.device_contract_length_months"
                            [value]="deal?.device_contract_length_months?.toString()"
                        ></deals-detail-item>

                        <deals-detail-item
                            label="device total cost"
                            *ngIf="deal?.device_total_cost_inc_vat"
                            [value]="
                                '£' +
                                parseFloat(
                                    deal?.device_total_cost_inc_vat?.toString() ?? '0'
                                ).toFixed(2)
                            "
                        ></deals-detail-item>

                        <deals-detail-item
                            label="device upfront cost"
                            *ngIf="deal?.device_upfront_cost_inc_vat"
                            [value]="
                                '£' +
                                parseFloat(
                                    deal?.device_upfront_cost_inc_vat?.toString() ?? '0'
                                ).toFixed(2)
                            "
                        ></deals-detail-item>

                        <deals-detail-item
                            label="colour"
                            *ngIf="deal?.device_colour"
                            [value]="deal?.device_colour?.replaceAll('(PRODUCT)', '')?.trim()"
                        ></deals-detail-item>

                        <deals-detail-item
                            label="credit check?"
                            [value]="creditCheck"
                        ></deals-detail-item>
                    </section>
                    <section class="mb-12" *ngIf="deal?.device_details_free_text">
                        <h3 class="mb-4 text-2xl font-semibold">Device details</h3>
                        <div
                            class="prose mt-5 max-w-none"
                            [innerHTML]="deal?.device_details_free_text"
                        ></div>
                    </section>
                </ng-container>
                <ng-container *ngIf="deal?.deal_contract_structure === 'phone-contract'">
                    <app-joined-contract-plan *ngIf="deal" [deal]="deal"></app-joined-contract-plan>
                </ng-container>
            </div>
        </section>
        <deals-perks *ngIf="dealPerks" [perks]="dealPerks"></deals-perks>
        <section class="mt-12" *ngIf="deal?.network_provider?.company_ethos">
            <h3 class="text-2xl font-semibold">{{ deal?.network_provider?.name }}'s ethos</h3>
            <div
                class="prose mt-5 max-w-none"
                [innerHTML]="deal?.network_provider?.company_ethos"
            ></div>
        </section>

        <section class="mt-12" *ngIf="deal?.network_provider?.reviews">
            <h3 class="text-2xl font-semibold">Why buy via Noggin HQ?</h3>
            <div class="prose mt-5 max-w-none" [innerHTML]="deal?.network_provider?.reviews"></div>
        </section>
        <app-deal-disclaimer class="mt-10 block"></app-deal-disclaimer>
        <home-disclaimer-graphic class="my-10 block !px-0"></home-disclaimer-graphic>
    </section>
</ng-template>
