<main class="account-container flex flex-col p-5">
    <app-account-header [backLocation]="routeNames.account.details.fullPath()"></app-account-header>
    <h2 class="mb-5 text-xl font-semibold">Manage VIP membership</h2>
    <app-membership-status [user]="user"></app-membership-status>

    <app-banking-connect
        connectText="reconnect"
        connectBtnClass="btn btn-primary rounded-full uppercase text-white w-full"
        class="mb-16 mt-8"
    ></app-banking-connect>

    <app-faq
        headerClass="text-xl"
        questionClass="!text-base"
        *ngIf="faqItems"
        [faqItems]="faqItems"
    ></app-faq>
</main>
