<div class="relative">
    <section class="flex flex-col z-2" [ngClass]="className">
            <div class="flex flex-col justify-center w-5/6 mx-auto md:mt-8">
                <h1 class="mx-auto font-display-serif font-medium text-4xl md:text-5xl lg:text-7xl text-center">
                    Find your perfect phone contract.
                </h1>
                <p class="mx-auto mt-4 md:my-6 text-xl md:text-2xl text-gray-500 font-light text-center">
                    Compare over 10,000 deals for phones, SIMs and handsets.
                </p>
            </div>

            <div class="flex flex-col-reverse md:flex-row justify-center gap-4 md:gap-8 py-8 md:py-10 px-6 md:px-16">
                <home-cta-card
                    [href]="routes.bundle.selectPhone.fullPath()"
                    background="bg-[#FFFFFF]"
                    [shadow]="true"
                    class="h-64 md:h-80"
                >
                    <div class="flex flex-col px-8 pt-8 md:pt-12 items-start justify-between">
                        <h2 class="flex items-center text-2xl md:text-3xl font-semibold">
                            <p>I have a <span class="underline">good</span> credit score</p>
                        </h2>
                        <p class="mt-3 md:mt-4 text-base lg:text-lg font-light text-gray-500">
                            Visit our price comparison platform to compare phone contracts from high-street partners, like giffgaff.
                        </p>
                    </div>
                </home-cta-card>

                <home-cta-card
                    [href]="routes.noCreditCheckPhones.fullPath()"
                    background="bg-[#FFFFFF]"
                    [shadow]="true"
                    class="h-60 md:h-80"
                >
                    <div class="flex flex-col px-8 pt-8 md:pt-12 items-start justify-between">
                        <h2 class="flex items-center text-2xl md:text-3xl font-semibold">
                            <p>I have a <span class="underline">bad</span> credit score</p>
                        </h2>
                        <p class="mt-3 md:mt-4 text-base lg:text-lg font-light text-gray-500">
                            Apply for our 0% interest phone contract for people who are excluded due to credit check barriers.
                        </p>
                    </div>
                </home-cta-card>
            </div>
    </section>

    <section class="mt-4 mb-8 z-40">
        <h2 class="text-center text-lg uppercase">As seen in</h2>
        <div class="mx-auto mt-4 flex flex-wrap items-center justify-around max-w-[440px] px-8 z-40">
            <a
                class="h-[72px] w-[72px] overflow-hidden rounded-full border object-cover object-center z-40"
                href="https://netimesmagazine.co.uk/magazine/five-minutes-with-evangeline-atkinson-and-laura-mills/"
                target="_blank"
            >
                <figure class="h-[72px] w-[72px]">
                    <img
                        class="h-full w-full object-cover object-center"
                        alt="North East Times Magazine logo"
                        ngSrc="/assets/images/netimesmagazine_logo_2.png"
                        height="72"
                        width="72"
                    />
                </figure>
            </a>

            <a
                class="h-[72px] w-[72px] overflow-hidden rounded-full border object-cover object-center z-40"
                href="https://techfundingnews.com/female-founded-noggin-hq-bags-710k-to-help-users-get-access-to-credit/"
                target="_blank"
            >
                <figure >
                    <img
                        class="h-full w-full object-cover object-center"
                        alt="Tech Funding News logo"
                        ngSrc="/assets/images/techfundingnews_logo.png"
                        height="72"
                        width="72"
                    />
                </figure>
            </a>

            <a
                class="h-[72px] w-[72px] overflow-hidden rounded-full border object-cover object-center bg-[#FDCFB3] z-40"
                href="https://www.ft.com/content/9f9d3a54-d08b-4d9c-a000-d50460f818dc"
                target="_blank"
            >
                <figure>
                    <img
                        class="h-full w-full object-cover object-center mt-[14px]"
                        alt="Financial Times logo"
                        ngSrc="/assets/images/ft_logo.jpeg"
                        height="72"
                        width="72"
                    />
                </figure>
            </a>
        </div>
    </section>

    <div
        class="absolute bg-transparent bg-repeat-x w-[102%] h-[170px] p-0 mr-0 my-0 -mx-[2%] border-none z-30 -bottom-[190px] overflow-hidden"
        style="background-image: url(assets/svg/drip.svg);"
    ></div>
</div>
