<svg
    [ngClass]="className"
    xmlns="http://www.w3.org/2000/svg"
    [attr.height]="size"
    [attr.width]="size"
    viewBox="0 0 32 32"
    class="text-electric-blue"
>
    <path
        fill="none"
        stroke-linejoin="round"
        stroke-linecap="round"
        stroke-miterlimit="4"
        stroke-width="2.4"
        [attr.stroke]="color"
        d="M16 26.4v4"
    ></path>
    <path
        fill="none"
        stroke-linejoin="round"
        stroke-linecap="round"
        stroke-miterlimit="4"
        stroke-width="2.4"
        [attr.stroke]="color"
        d="M8 24l-2 3.464"
    ></path>
    <path
        fill="none"
        stroke-linejoin="round"
        stroke-linecap="round"
        stroke-miterlimit="4"
        stroke-width="2.4"
        [attr.stroke]="color"
        d="M24 24l2 3.464"
    ></path>
    <path
        fill="none"
        stroke-linejoin="round"
        stroke-linecap="round"
        stroke-miterlimit="4"
        stroke-width="2.4"
        [attr.stroke]="color"
        d="M3.2 15.818c2.367 5.802 7.208 9.782 12.8 9.782s10.433-3.98 12.8-9.782"
    ></path>
</svg>
