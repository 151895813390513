import { Component, Input } from "@angular/core";

@Component({
    selector: "deals-detail-item",
    standalone: true,
    imports: [],
    templateUrl: "./detail-item.component.html",
    styleUrl: "./detail-item.component.scss",
})
export class DetailItemComponent {
    @Input({ required: true }) label!: string;
    @Input({ required: true }) value?: string;
}
