<div class="account-container">
    <button
        (click)="back()"
        class="bold mb-6 mr-auto flex items-center justify-center text-[14px] uppercase text-electric-blue h-8"
    >
        &nbsp;
    </button>

    <section class="mb-4">
        <h1 class="text-3xl font-semibold">Tell us about yourself 😊</h1>

        <form (ngSubmit)="submit()" [formGroup]="userDetailsForm" class="mt-8">
            <div class="flex flex-col">
                <label for="tel" class="mb-1.5 text-sm uppercase after:content-['*'] after:ml-0.5 after:text-red-500">Phone number</label>
                <input
                    formControlName="tel"
                    id="tel"
                    name="tel"
                    type="text"
                    class="input rounded-xl border border-black p-4"
                    [attr.readonly]="formSubmitting() ? 'readonly' : null"
                    autocomplete="tel"
                />
                @switch(!!telErrorMessage) {
                    @case (true) {
                    <small class="text-error-red">{{ telErrorMessage }}</small>
                    }
                    @case (false) {
                        <small>&nbsp;</small>
                    }
                }
            </div>

            <div class="mt-4">
                <h2 class="text-lg font-semibold after:content-['*'] after:ml-0.5 after:text-red-500 after:font-normal after:text-base">Date of birth</h2>
                <fieldset class="flex justify-between max-w-72 mt-2" role="group">
                    <div class="mt flex flex-col">
                        <label class="mb-1.5 text-sm uppercase" for="bday-day">Day</label>
                        <input
                            formControlName="bday_day"
                            class="input rounded-xl border border-black p-4 max-w-16"
                            id="bday-day"
                            name="bday-day"
                            type="text"
                            inputmode="numeric"
                        />
                    </div>

                    <div class="mt flex flex-col">
                        <label class="mb-1.5 text-sm uppercase" for="bday-month">Month</label>
                        <input
                            formControlName="bday_month"
                            class="input rounded-xl border border-black p-4 max-w-16"
                            id="bday-month"
                            name="bday-month"
                            type="text"
                            inputmode="numeric"
                        />
                    </div>

                    <div class="mt flex flex-col">
                        <label class="mb-1.5 text-sm uppercase" for="bday-year">Year</label>
                        <input
                            formControlName="bday_year"
                            class="input rounded-xl border border-black p-4 max-w-24"
                            id="bday-year"
                            name="bday-year"
                            type="text"
                            inputmode="numeric"
                        />
                    </div>
                </fieldset>
                @switch(!!bdayErrorMessage) {
                    @case (true) {
                        <small class="text-error-red">{{ bdayErrorMessage }}</small>
                    }
                    @case (false) {
                        <small>&nbsp;</small>
                    }
                }
            </div>

            <div class="mt-4 mb-8 h-14 w-full">
                <ng-container *ngIf="formSubmitting(); else content">
                    <app-loading class="block"></app-loading>
                </ng-container>
                <ng-template #content>
                    <button
                        class="btn mx-auto w-full block self-stretch rounded-full bg-electric-blue text-center uppercase text-white"
                        name="Submit"
                        type="submit"
                    >
                        Next step
                    </button>
                </ng-template>
            </div>
        </form>
    </section>
</div>

<section class="flex my-8 mx-auto max-w-lg" nomargin>
    <app-noggin-phone-tile
        caption="Why are we doing this?"
        heading="5.8 million people in the UK are excluded from credit!"
        text="We are dedicated to bringing a solution for people unfairly locked out of the current system."
    ></app-noggin-phone-tile>
</section>
