import { Component, Input } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterLink } from "@angular/router";

@Component({
    selector: "home-cta-card",
    standalone: true,
    imports: [CommonModule, RouterLink],
    templateUrl: "./cta-card.component.html",
    styleUrls: ["./cta-card.component.scss"],
})
export class CtaCardComponent {
    @Input({ required: true }) href!: string;
    @Input() background: string = "";
    @Input() shadow = false;

    get additionalClasses() {
        const additionalClasses = [
            this.background,
            this.shadow ? "drop-shadow" : "",
        ];

        return additionalClasses.join(" ");
    }
}
