import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { DomSanitizer, Meta, Title } from "@angular/platform-browser";
import { WebsitePageModel } from "../../models/website-page";
import { WebsiteCmsService } from "../../services/website-cms.service";

@Component({
    selector: "app-website-cms-page-wrapper",
    templateUrl: "./website-cms-page-wrapper.component.html",
    styleUrls: ["./website-cms-page-wrapper.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class WebsiteCmsPageWrapperComponent implements OnInit {
    slug!: string | undefined;

    websiteContent!: WebsitePageModel;

    content: any;

    constructor(
        private route: ActivatedRoute,
        private websiteCmsService: WebsiteCmsService,
        private metaService: Meta,
        private titleService: Title,
        private sanitized: DomSanitizer,
    ) {}

    ngOnInit(): void {
        this.slug = this.route.snapshot.data["slug"] ?? this.route.snapshot.routeConfig?.path;
        this.getWebsitePageContent();
    }

    setMetadata(page: any): void {
        if (page.cover_image) {
            this.metaService.updateTag({ property: "og:image", content: page.cover_image });
            this.metaService.updateTag({ name: "twitter:image:src", content: page.cover_image });
        }
        if (page.meta_description) {
            this.metaService.updateTag({ name: "description", content: page.meta_description });
            this.metaService.updateTag({
                property: "og:description",
                content: page.meta_description,
            });
            this.metaService.updateTag({
                name: "twitter:description",
                content: page.meta_description,
            });
            this.metaService.updateTag({
                name: "msapplication-tooltip",
                content: page.meta_description,
            });
        }
        if (page.meta_keywords) {
            this.metaService.updateTag({ name: "keywords", content: page.meta_keywords });
        }

        if (page.meta_title) {
            this.titleService.setTitle("Noggin | " + page.meta_title);
            this.metaService.updateTag({
                name: "title",
                content: page.meta_title,
            });
            this.metaService.updateTag({
                name: "twitter:title",
                content: page.meta_title,
            });
            this.metaService.updateTag({
                property: "og:title",
                content: page.meta_title,
            });
        } else if (page.title) {
            this.titleService.setTitle("Noggin | " + this.stripSpanTags(page.title));
            this.metaService.updateTag({
                name: "title",
                content: this.stripSpanTags(page.title),
            });
            this.metaService.updateTag({
                name: "twitter:title",
                content: this.stripSpanTags(page.title),
            });
            this.metaService.updateTag({
                property: "og:title",
                content: this.stripSpanTags(page.title),
            });
        } else {
            this.titleService.setTitle("Noggin | See Our Best SIM & Mobile Deals for You");
            this.metaService.updateTag({
                name: "title",
                content: "Noggin | See Our Best SIM & Mobile Deals for You",
            });
            this.metaService.updateTag({
                name: "twitter:title",
                content: "Noggin | See Our Best SIM & Mobile Deals for You",
            });
            this.metaService.updateTag({
                property: "og:title",
                content: "Noggin | See Our Best SIM & Mobile Deals for You",
            });
        }
    }

    getWebsitePageContent(): void {
        if (this.slug) {
            this.websiteCmsService.getWebsiteCMSContent(this.slug).subscribe({
                next: (res) => {
                    this.websiteContent = res.data;
                    this.content = this.sanitized.bypassSecurityTrustHtml(res.data.content);
                    this.setMetadata(res.data);
                },
            });
        }
    }

    stripSpanTags(text: string): string {
        return text.replace(/<[^>]*>?/gm, " ");
    }
}
