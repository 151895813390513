import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from "@angular/forms";
import { Location } from "@angular/common";
import { MatSnackBar } from "@angular/material/snack-bar";
import { UserModel } from "../../models/user";
import { UserService } from "../../services/user.service";
type EditAccountForm = {
    full_name: FormControl<string | null>;
    marketing_agree: FormControl<boolean | null>;
    email: FormControl<string | null>;
    current_password: FormControl<string | null>;
    new_password: FormControl<string | null>;
};

const updatePasswordValidator: ValidatorFn = (form) => {
    const newPassword = form.get("new_password")?.value;
    const currentPassword = form.get("current_password")?.value;
    if (newPassword && !currentPassword) {
        return { currentPasswordRequired: true };
    }

    return null;
};

@Component({
    selector: "app-edit-account",
    templateUrl: "./edit-account.component.html",
    styleUrls: ["./edit-account.component.scss"],
})
export class EditAccountComponent implements OnInit {
    editForm!: FormGroup;
    user?: UserModel;
    error?: string;

    constructor(
        private userService: UserService,
        private fb: FormBuilder,
        private location: Location,
        private snackBar: MatSnackBar,
    ) {
        this.editForm = this.fb.group<EditAccountForm>(
            {
                full_name: this.fb.control(""),
                marketing_agree: this.fb.control(false),
                email: this.fb.control("", [Validators.email]),
                current_password: this.fb.control(""),
                new_password: this.fb.control("", [
                    Validators.pattern("^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\\W)(?!\\s).{8,}$"),
                ]),
            },
            {
                validators: [updatePasswordValidator],
            },
        );
    }

    ngOnInit(): void {
        this.userService.getUserInfo().subscribe({
            next: (r) => {
                this.user = r;
                this.editForm.patchValue({
                    full_name: r.full_name ?? "",
                    email: r.email ?? "",
                    marketing_agree: r.user_detail.email_opt_in ?? false,
                });
            },
            error: (e) => {
                this.error = e.toString();
            },
        });
    }

    async back(): Promise<void> {
        this.location.back();
    }

    submit() {
        if (this.editForm.invalid) {
            return;
        }

        function removeEmpty(obj: Record<string, unknown>) {
            for (const key in obj) {
                if (obj[key] === undefined || obj[key] === "") {
                    delete obj[key];
                }
            }
        }

        const updatedUser = {
            full_name: this.editForm.get("full_name")?.value,
            first_name: this.editForm.get("full_name")?.value,
            email: this.editForm.get("email")?.value,
            email_opt_in: this.editForm.get("marketing_agree")?.value,
            current_password: this.editForm.get("current_password")?.value,
            password: this.editForm.get("new_password")?.value,
        };

        removeEmpty(updatedUser);

        this.userService.updateUser(updatedUser).subscribe({
            next: () => {
                this.openSnackBar("User Updated Successfully", "Success");
            },
            error: (err) => {
                console.error(err);
                let error = err;
                if (err.error.message) {
                    error = err.error.message;
                } else if (err.error.detail) {
                    error = err.error.detail;
                }
                this.openSnackBar(error, "Error");
            },
        });
    }

    getFormControl(control: string) {
        return this.editForm.get(control) as FormControl;
    }

    private openSnackBar(message: string, type: string): void {
        this.snackBar.open(type + ": " + message, "Dismiss", {
            panelClass: [type],
        });
    }
}
