import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, effect, EffectRef, OnDestroy, OnInit } from '@angular/core';
import { Router, RouterLinkWithHref } from '@angular/router';
import { routeNames } from 'src/app/app-routing.module';
import { NogginPhoneTileComponent } from 'src/app/noggin-phone/noggin-phone-tile/noggin-phone-tile.component';
import { BankingConnectComponent } from 'src/app/user/components/banking-connect/banking-connect.component';
import { BankingState } from 'src/app/user/state/banking.state';

@Component({
    selector: 'app-share-open-banking',
    standalone: true,
    imports: [
        CommonModule,
        RouterLinkWithHref,
        NgOptimizedImage,
        NogginPhoneTileComponent,
        BankingConnectComponent
    ],
    templateUrl: './share-open-banking.component.html',
    styleUrl: './share-open-banking.component.scss'
})
export class ShareOpenBankingComponent implements OnInit, OnDestroy {
    onConnectSideEffect: EffectRef;

    constructor(
        public bankingState: BankingState,
        private router: Router
    ) {
        gtag('event', 'share_open_banking__page_load');
        this.onConnectSideEffect = effect(() => {
            if (this.bankingState.connected()) {
                gtag('event', 'share_open_banking__connected');
                this.router.navigateByUrl(routeNames.getStarted.yourPhoneChoice.fullPath())
            }
        });
    }

    async ngOnInit() {
        await this.bankingState.init();
    }

    ngOnDestroy(): void {
        this.onConnectSideEffect.destroy();
    }

    get bulletPoints() {
        return [
            {
                text: "In order to assess whether we can offer you a deal, we look at your Open Banking data to double-check you can afford our contract!",
            },
            {
                text: "This digital bank statement is used instead of us checking with a Credit Reference Agency and your credit score. Connecting digitally is much faster than sharing a PDF.",
            },
            {
                text: "Sharing this data will not impact your credit score in any way.",
            }
        ];
    }

}
