<article
    class="mb-2 w-full min-w-[300px] max-w-[350px] overflow-hidden rounded-3xl border bg-off-white lg:min-w-[350px]"
>
    <a
        [routerLink]="
            this.deal
                ? routeNames.simOnlyDeals.details.fullPath({ dealId: this.deal.id.toString() })
                : ''
        "
    >
        <ng-container *ngIf="loading; else mainSection">
            <section class="px-5 pb-5 pt-10">
                <div class="skeleton mb-0.5 h-6 w-full text-xl font-semibold"></div>
                <div class="skeleton h-3 border-b border-black border-opacity-10 pb-4"></div>
                <div class="grid grid-cols-2 gap-3 py-4">
                    <div>
                        <p class="mb-0.5 text-sm uppercase">contract length</p>
                        <div class="skeleton mb-4 mr-2 h-2 w-11 font-semibold"></div>
                        <p class="mb-0.5 text-sm uppercase">data allowance</p>
                        <div class="skeleton mr-4 h-2 w-full"></div>
                    </div>
                    <div>
                        <p class="mb-0.5 text-sm uppercase">credit check?</p>
                        <div class="skeleton mb-4 mr-3 h-2 w-full"></div>
                        <p class="mb-0.5 text-sm uppercase">connectivity</p>
                        <div class="skeleton mr-4 h-2 w-14"></div>
                    </div>
                </div>
            </section>
        </ng-container>
        <ng-template #mainSection>
            <section class="px-5 pb-5 pt-5">
                <div class="mb-5 flex items-center justify-between">
                    <figure
                        *ngIf="deal?.deal_retailer_logo_url || deal?.network_provider?.logo"
                        class="overflow-hidden"
                    >
                        <img
                            [ngSrc]="
                                deal?.network_provider?.logo ?? deal?.deal_retailer_logo_url ?? ''
                            "
                            height="32"
                            width="32"
                            [alt]="deal?.network_provider?.name + ' image'"
                            class="h-8 w-auto"
                        />
                    </figure>
                    <div
                        *ngIf="isVip"
                        class="bg-green-gradient rounded-full border px-4 py-1.5 text-sm font-semibold text-black"
                        >VIP DEAL
                    </div>
                </div>
                <h3
                    class="mb-0.5 border-b border-black border-opacity-10 pb-4 text-xl font-semibold"
                    >{{ deal?.deal_name }}</h3
                >
                <div class="grid grid-cols-2 py-4">
                    <div>
                        <p class="mb-0.5 text-sm uppercase">contract length</p>
                        <p class="mb-4 font-semibold">
                            {{ deal?.sim_contract_length_friendly }}
                        </p>
                        <p class="mb-0.5 text-sm uppercase">data allowance</p>
                        <p class="font-semibold">{{
                            deal?.sim_unlimited_data
                                ? "Unlimited"
                                : deal?.sim_data_allowance_gb_numeric?.toString()?.concat("GB") ??
                                  "Unlimited"
                        }}</p>
                    </div>
                    <div>
                        <p class="mb-0.5 text-sm uppercase">credit check?</p>
                        <p class="mb-4 font-semibold underline">
                            {{ creditCheck }}
                        </p>
                        <p class="mb-0.5 text-sm uppercase">connectivity</p>
                        <p class="font-semibold">{{ deal?.deal_connectivity }}</p>
                    </div>
                </div>
            </section>
        </ng-template>
        <ng-container *ngIf="loading; else footer">
            <div class="skeleton h-14 w-full rounded-none"></div>
        </ng-container>
        <ng-template #footer>
            <footer>
                <section [ngClass]="isVip ? 'bg-green-gradient ' : 'bg-pink-100'" class="flex p-4">
                    <div class="flex-1">
                        <h3 class="text-3.5xl font-medium">
                            <ng-container
                                >{{ deal?.deal_currency === "GBP" ? "£" : deal?.deal_currency
                                }}{{
                                    deal?.sim_monthly_cost_inc_vat?.toString()?.split(".")?.[0]
                                }}.</ng-container
                            ><span class="text-xl font-medium">{{
                                parseFloat(deal?.sim_monthly_cost_inc_vat?.toString() ?? "0")
                                    ?.toFixed(2)
                                    ?.split(".")?.[1] ?? "00"
                            }}</span>
                        </h3>
                        <p class="flex items-center text-md"> per month </p>
                    </div>
                    <ng-container *ngIf="isVip">
                        <app-squiggly-background>
                            <div
                                class="mx-2 flex h-[66px] w-[102px] items-center justify-center bg-black text-center text-md font-bold uppercase text-lime-green"
                                ><p>+ vip only reward</p></div
                            >
                        </app-squiggly-background>
                    </ng-container>
                </section>

                <section
                    class="bg-black px-6 py-2.5 text-md text-off-white"
                    *ngIf="deal?.deal_price_rises"
                >
                    <p class="flex items-center"
                        ><fa-icon [icon]="faInfoCircle" class="mr-2"></fa-icon
                        >{{ deal?.deal_price_rises }}</p
                    >
                </section>
            </footer>
        </ng-template>
    </a>
</article>

<section
    class="bg-green-gradient mt-2.5 h-16 w-full rounded-3xl border border-black"
    *ngIf="deal?.members_benefit_text && showExtraInfo"
>
    <a
        href="{{ deal?.members_benefit_link }}"
        class="flex h-full w-full items-center justify-center px-5 py-4"
    >
        <span class="font-bold">+</span>
        <p class="ml-2 flex-1">{{ deal?.members_benefit_text }}</p>
        <span class="h-9 w-9" *ngIf="deal?.members_benefit_image"
            ><img
                alt="Member benefit icon image"
                height="34"
                width="34"
                [ngSrc]="deal?.members_benefit_image ?? ''"
        /></span>
    </a>
</section>
