<section class="container mx-auto max-w-[880px] px-0">
    <h2 class="mb-10 font-semibold uppercase" [ngClass]="headerClass">
        Frequently asked questions
    </h2>
    <ng-container *ngIf="faqItems && faqItems.length">
        <ul>
            <li *ngFor="let item of faqItems" class="mb-7 border-b pb-5">
                <ng-container *ngIf="{ open: openItems.includes(item.id) } as locals">
                    <h2>
                        <button
                            class="flex w-full items-center justify-between text-left text-xl font-semibold lg:text-2xl"
                            [ngClass]="questionClass"
                            (click)="this.toggle(item.id)"
                            aria-controls="{`accordion-text-01`}"
                        >
                            <span>{{ item.title }}</span>
                            <!-- Arrow --------------------------------------- -->
                            <svg
                                [ngClass]="{ '!rotate-180': locals.open }"
                                class="fill-indigo-500 ml-8 shrink-0 origin-center transform transition duration-200 ease-out"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <g id="keyboard_arrow_down" clip-path="url(#clip0_510_21161)">
                                    <path
                                        id="Vector"
                                        d="M7.41 8.59003L12 13.17L16.59 8.59003L18 10L12 16L6 10L7.41 8.59003Z"
                                        fill="black"
                                    />
                                </g>
                                <defs>
                                    <clipPath id="clip0_510_21161">
                                        <rect width="24" height="24" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                            <!-- Arrow --------------------------------------- -->
                        </button>
                    </h2>
                    <div
                        id="{`accordion-text-01`}"
                        role="region"
                        aria-labelledby="{`accordion-title-01`}"
                        [ngClass]="
                            locals.open
                                ? 'grid-rows-[1fr] pt-4 opacity-100'
                                : 'grid-rows-[0fr] opacity-0'
                        "
                        class="grid overflow-hidden text-black text-opacity-60 transition-all duration-300 ease-in-out"
                    >
                        <div
                            class="overflow-hidden text-black text-opacity-60"
                            [innerHtml]="item.content"
                        ></div>
                    </div>
                </ng-container>
            </li>
        </ul>
    </ng-container>
    <ng-container *ngIf="limit && limit < totalItems">
        <a
            [routerLink]="routeNames.faq.fullPath()"
            class="btn rounded-full bg-black uppercase text-off-white"
        >
            Read our faqs
        </a>
    </ng-container>
</section>
