<main class="account-container relative flex h-full flex-col p-5">
    <header class="mb-8 mt-3 h-[27px] w-full">
        <app-logo-full></app-logo-full>
    </header>
    <h1 class="mb-6 text-3xl font-semibold">Create an account</h1>
    <h6 class="mb-2 text-sm uppercase text-opacity-60">Step 3</h6>
    <h2 class="text-xl font-semibold">Connect your open banking</h2>
    <p class="mb-7 mt-3 text-black text-opacity-60">
        We will transfer you to your bank via a secure connection. Please connect the account you receive income into to complete your application.
    </p>
    <section>
        <h3 class="mb-5 font-semibold">Why share your details?</h3>
        <ul>
            <li class="mb-8 flex items-start" *ngFor="let text of this.openBankingReasons">
                <img
                    class="mr-4"
                    width="20px"
                    height="20px"
                    ngSrc="assets/svg/bullet-tick.svg"
                    alt=""
                    role="presentation"
                />
                <p class="text-black text-opacity-60">
                    {{ text }}
                </p>
            </li>
        </ul>
    </section>
    <p>
        More questions?
        <a href="/share-open-banking#faq" class="text-electric-blue underline">Read our FAQ</a>
    </p>
    <div *ngIf="bankingState.connected()" class="my-10 flex items-center">
        <img
            ngSrc="assets/svg/face-green.svg"
            alt=""
            role="presentation"
            height="64px"
            width="64px"
            class="mr-5"
        />
        <h6 class="font-semibold">Your banking data is successfully connected</h6>
    </div>
    <app-banking-connect></app-banking-connect>
    <button
        (click)="goToOffer()"
        [disabled]="!bankingState.connected()"
        class="btn btn-primary mt-6 rounded-full text-white"
    >
        FINISH
    </button>
    <a class="mt-6 text-center text-electric-blue" [routerLink]="link">SKIP</a>
    <section id="faq" class="mt-16">
        <app-faq
            [faqItems]="faqItems"
            headerClass="text-xl lg:text-2xl"
            questionClass="!text-base"
        ></app-faq>
    </section>
</main>
