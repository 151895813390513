import { Injectable } from "@angular/core";

import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable, map, shareReplay } from "rxjs";
import { environment } from "../../../environments/environment";
import { DealModel, DealPagination, DealTypeModel } from "../models/deal";
import { HttpUtil } from "../../util/http.util";
import { DealFilterParams } from "../models/filter";
import { UtmKey } from "src/app/core/models/utm";

type ClickRefKeys = "clickref" | "clickref2" | "clickref3" | "clickref4" | "clickref6";

const UtmClickRefPairing: [UtmKey, ClickRefKeys][] = [
    ["utm_medium", "clickref"],
    ["utm_source", "clickref2"],
    ["utm_campaign", "clickref3"],
    ["utm_term", "clickref4"],
    ["clickid", "clickref6"],
]

@Injectable({
    providedIn: "root",
})
export class DealService {
    private baseUrl: string = environment.baseUrl;
    private _dealTypes?: Observable<DealTypeModel[]>;

    constructor(private http: HttpClient) { }

    getFilteredDeals(filters?: Partial<DealFilterParams>): Observable<DealPagination> {
        return this.http.get<DealPagination>(`${this.baseUrl}/deals`, {
            params: HttpUtil.buildUrlParams(filters),
        });
    }

    getNextPage(url: string): Observable<DealPagination> {
        return this.http.get<DealPagination>(url, {});
    }

    getLatestModels(): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/deals/latest-models`);
    }

    getSelectedDeal(id: number): Observable<DealModel> {
        return this.http.get<DealModel>(`${this.baseUrl}/deals/${id}`);
    }

    getDealTypes(): Observable<DealTypeModel[]> {
        if (!this._dealTypes) {
            this._dealTypes = this.http
                .get<DealTypeModel[]>(`${this.baseUrl}/filters/deal-types`)
                .pipe(shareReplay());
        }
        return this._dealTypes;
    }

    getPlaidToken(): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/integration/plaid/create-link-token`);
    }

    getDealLink(id: number): Observable<{ url: string; members_benefit_voucher_code?: string }> {
        const configuredRedirect = this.http.get<{ url: string }>(`${this.baseUrl}/deals/link/${id}/`)

        const clickRefMappings = this.getClickRefUtmMappings()

        if (!clickRefMappings) {
            return configuredRedirect;
        }

        // We add clickrefs for current utm parameters. This can be removed if done server side.
        const updatedRedirect =
            configuredRedirect
                .pipe(map(({ url }) => {
                    const configuredURL = new URL(url)
                    const configuredSearchParams = new URLSearchParams(configuredURL.searchParams)

                    for (const [key, value] of clickRefMappings.entries()) {
                        configuredSearchParams.set(key, value)
                    }

                    configuredURL.search = configuredSearchParams.toString()

                    return {
                        url: configuredURL.toString()
                    };
                }));

        return updatedRedirect;
    }


    private getClickRefUtmMappings(): Map<ClickRefKeys, string> | null {
        const url = document.location.href
        const urlObj = new URL(url)
        const searchParams = new URLSearchParams(urlObj.search)


        if (searchParams.size === 0) {
            return null;
        }

        const res: Map<ClickRefKeys, string> = new Map();

        UtmClickRefPairing.map(
            ([utmKey, clickRefKey]) => {
                const value = searchParams.get(utmKey)
                if (value) {
                    res.set(clickRefKey, value)
                }
            }
        )

        return res;
    }
}
