import { Component, OnInit, signal } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Store } from "@ngxs/store";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { Location } from "@angular/common";
import { UserService } from "../../services/user.service";
import { LoginUserAction } from "../../state/user/user.actions";
import { routeNames } from "../../../app-routing.module";
import { ActivatedRoute } from "@angular/router";

@Component({
    selector: "app-sign-up",
    templateUrl: "./sign-up.component.html",
    styleUrls: ["./sign-up.component.scss"],
})
export class SignUpComponent implements OnInit {
    registerUserForm!: FormGroup;
    passwordLevel = 0;
    passwordLevelText = "";
    passwordVisible = signal(false);
    fromPage?: string;

    constructor(
        private fb: FormBuilder,
        private userService: UserService,
        private location: Location,
        private snackBar: MatSnackBar,
        private store: Store,
        private route: ActivatedRoute,
    ) {
        this.fromPage = this.route.snapshot.queryParams["from"];
    }

    ngOnInit(): void {
        this.registerUserForm = this.fb.group(
            {
                full_name: ["", Validators.required],
                email: ["", [Validators.required, Validators.email]],
                terms_agree: [false, Validators.requiredTrue],
                email_opt_in: [false],
                password: [
                    "",
                    [
                        Validators.required,
                        Validators.pattern(
                            "^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\\W)(?!\\s).{8,}$",
                        ),
                    ],
                ],
            },
            {},
        );
        this.registerUserForm.get("password")?.valueChanges.subscribe((value) => {
            if (value.length >= 12 && !this.registerUserForm.get("password")?.errors) {
                this.passwordLevel = 3;
                this.passwordLevelText = "strong";
            } else if (value.length >= 8 && !this.registerUserForm.get("password")?.errors) {
                this.passwordLevel = 2;
                this.passwordLevelText = "Okay";
            } else if (value.length <= 8 && value.length > 0) {
                this.passwordLevel = 1;
                this.passwordLevelText = "Weak";
            }
        });
    }

    getFormControl(name: string): FormControl {
        return this.registerUserForm.get(name) as FormControl;
    }

    async back(): Promise<void> {
        this.location.back();
    }

    togglePasswordVisibility(): void {
        this.passwordVisible.update((v) => !v);
    }

    submit(): void {
        if (this.registerUserForm.invalid) {
            return;
        }
        const registerUserValues = {
            first_name: this.registerUserForm.get("full_name")?.value,
            full_name: this.registerUserForm.get("full_name")?.value,
            last_name: "",
            email: this.registerUserForm.get("email")?.value,
            password: this.registerUserForm.get("password")?.value,
            email_opt_in: this.registerUserForm.get("email_opt_in")?.value ?? false,
        };
        this.userService.registerUser(registerUserValues).subscribe({
            next: () =>
                this.registerUserListener(
                    registerUserValues.email_opt_in,
                    registerUserValues.email,
                ),
            error: (err): void => {
                let error = err.error;
                if (err.error.error) {
                    error = err.error.error;
                } else if (err.error.detail) {
                    error = err.error.detail;
                }
                this.openSnackBar(error);
            },
        });
    }

    private registerUserListener = (email_opt_in: boolean, email: string): void => {
        setTimeout((): void => {
            this.store
                .dispatch(
                    new LoginUserAction(
                        {
                            email: this.registerUserForm.get("email")?.value,
                            password: this.registerUserForm.get("password")?.value,
                        },
                        this.fromPage,
                    ),
                )
                .subscribe({
                    next: () => {
                        this.userService.updateUser({ email_opt_in, email }).subscribe();
                    },
                });
            // the api doesn't allow updating this in the register call. Nebular have more things to concentrate on at
            // the moment so we will just update the user after the register call
        }, 1000);
    };

    private openSnackBar(errorDetail: string): void {
        this.snackBar.open("Error:", errorDetail, {
            duration: 5000,
        });
    }

    protected readonly faChevronLeft = faChevronLeft;
    protected readonly FormControl = FormControl;
    protected readonly routeNames = routeNames;
}
