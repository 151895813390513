<div>
    <button
        class="btn btn-outline btn-primary mr-1 h-7 min-h-0 w-[72px] overflow-hidden rounded-md p-0 text-md font-normal"
        [ngClass]="active ? 'border-electric-blue text-electric-blue' : 'border-black !text-black'"
        (click)="onClick(true)"
    >
        Yes
    </button>
    <button
        class="btn btn-outline btn-primary h-7 min-h-0 w-[72px] overflow-hidden rounded-md p-0 text-md font-normal"
        [ngClass]="!active ? 'border-electric-blue text-electric-blue' : 'border-black !text-black'"
        (click)="onClick(false)"
    >
        No
    </button>
</div>
