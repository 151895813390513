<header class="my-3 h-[27px] w-full">
    <a [routerLink]="routeNames.compare.mobile.fullPath()">
        <app-logo-full></app-logo-full>
    </a>
</header>
<button
    (click)="back()"
    class="my-6 mr-auto flex items-center justify-center text-[14px] font-bold uppercase text-electric-blue"
>
    <fa-icon class="mr-2.5 text-[24px]" [icon]="faChevronLeft"></fa-icon>
    Back
</button>
<h1 class="mb-7 text-3xl font-semibold">My account</h1>
