<main class="login account-container relative flex h-screen flex-col p-5">
    <header class="my-3 h-[27px] w-full">
        <a [routerLink]="routeNames.compare.mobile.fullPath()"><app-logo-full></app-logo-full></a>
    </header>
    <button
        (click)="back()"
        class="bold my-6 mr-auto flex items-center justify-center text-[14px] uppercase text-electric-blue"
    >
        <fa-icon class="mr-2.5 text-[24px]" [icon]="faChevronLeft"></fa-icon>
        Back
    </button>
    <section class="mb-8">
        <h1 class="text-3xl font-semibold">Sign in</h1>
    </section>
    <form (ngSubmit)="submit()" [formGroup]="loginForm" class="flex flex-1 flex-col">
        <div class="flex flex-col">
            <label for="email" class="mb-1.5 text-sm uppercase">Email</label>
            <input
                formControlName="email"
                id="email"
                type="email"
                class="input rounded-xl border border-black p-4"
            />
        </div>
        <small
            *ngIf="
                loginForm.get('email')?.status === 'INVALID' &&
                loginForm.get('email')?.touched &&
                !loginForm.get('email')?.errors?.['required'] &&
                loginForm.get('email')?.errors?.['email']
            "
            class="form-errors"
        >
            Please enter valid email address, eg: example&#64;domain.com
        </small>
        <small
            *ngIf="
                loginForm.get('email')?.status === 'INVALID' &&
                loginForm.get('email')?.touched &&
                loginForm.get('email')?.errors?.['required']
            "
            class="form-errors"
        >
            Email Address is required
        </small>

        <app-password-field
            autocomplete="current-password"
            [control]="getPasswordControl()"
        ></app-password-field>
        <small
            *ngIf="
                loginForm.get('password')?.status === 'INVALID' &&
                loginForm.get('password')?.touched &&
                loginForm.get('password')?.errors?.['required']
            "
            class="form-errors"
        >
            Password is required
        </small>

        <a routerLink="/forgotten-password" class="mt-7 block text-md text-electric-blue underline">
            Forgot Password?
        </a>
        <div class="mt-12 flex flex-1 flex-col justify-end md:justify-start">
            <button
                name="Submit forgot password form"
                type="submit"
                class="btn h-14 self-stretch rounded-full bg-electric-blue uppercase text-white"
            >
                log in
            </button>
        </div>
    </form>
</main>
