<div
    @alertAnimation_top
    *ngIf="open && direction === 'top'"
    class="fixed left-0 right-0 top-0 z-[25] p-5"
>
    <ng-container *ngTemplateOutlet="alertPopup"></ng-container>
</div>

<div
    @alertAnimation_bottom
    *ngIf="open && direction === 'bottom'"
    class="fixed bottom-0 left-0 right-0 z-[25] p-5"
>
    <ng-container *ngTemplateOutlet="alertPopup"></ng-container>
</div>

<ng-template #alertPopup>
    <div
        role="alert"
        class="alert flex flex-col justify-start border border-black bg-off-white md:flex-row"
    >
        <ng-content></ng-content>
    </div>
</ng-template>
