import { Component } from "@angular/core";
import { NavbarComponent } from "../../../core/components/navbar/navbar.component";
import { OurResearchHeroComponent } from "../our-research-hero/our-research-hero.component";
import { OurResearchNavbarComponent } from "../our-research-navbar/our-research-navbar.component";
import { NgOptimizedImage } from "@angular/common";
import { OurResearchFaqComponent } from "../our-research-faq/our-research-faq.component";
import { FooterComponent } from "../../../core/components/footer/footer.component";

@Component({
    selector: "app-our-research-page",
    standalone: true,
    imports: [
        NavbarComponent,
        OurResearchHeroComponent,
        OurResearchNavbarComponent,
        NgOptimizedImage,
        OurResearchFaqComponent,
        FooterComponent,
    ],
    templateUrl: "./our-research-page.component.html",
    styleUrl: "./our-research-page.component.scss",
})
export class OurResearchPageComponent {}
