<a
    [routerLink]="href"
    class="grid grid-rows-auto min-h-20 w-full rounded-4xl grow"
    [ngClass]="additionalClasses"
>
    <div>
        <ng-content></ng-content>
    </div>

    <div class="grid place-items-end x-justify-items-end mr-4 mb-4 md:mr-8 md:mb-8">
        <div
            class="flex items-center justify-center bg-lime-green rounded-full h-16 md:h-20 w-16 md:w-20
            drop-shadow-sm border border-gray-100"
        >
            <svg
                width="35"
                height="35"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g id="Icon/ Arrow Right">
                    <path
                        id="Vector 63"
                        d="M11 17L18 10L11 3"
                        stroke="black"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        id="Vector 68"
                        d="M18 10L1.5 10"
                        stroke="black"
                        stroke-width="2"
                        stroke-linecap="round"
                    />
                </g>
            </svg>
        </div>
    </div>
</a>
