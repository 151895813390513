import { CanActivateFn, Router } from "@angular/router";
import { UserService } from "../services/user.service";
import { inject } from "@angular/core";
import { firstValueFrom } from "rxjs";

export const offerGuard: CanActivateFn = async () => {
    const router = inject(Router);
    const user = await firstValueFrom(inject(UserService).getUserInfo());
    if (user.user_detail.banking_paired) {
        return true;
    }
    await router.navigateByUrl("/share-open-banking");
    return false;
};
