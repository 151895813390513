import { Component, Input } from "@angular/core";
import { CommonModule, NgOptimizedImage } from "@angular/common";
import { CtaCardComponent } from "../cta-card/cta-card.component";
import { CtaCardComponent as CtaCardComponentExternal } from "../cta-card-external/cta-card.component";
import { routeNames } from "src/app/app-routing.module";

@Component({
    selector: "app-mobile-compare-hero",
    standalone: true,
    imports: [
        CommonModule,
        NgOptimizedImage,
        CtaCardComponent,
        CtaCardComponentExternal
    ],
    templateUrl: "./mobile-compare-hero.component.html",
    styleUrls: ["./mobile-compare-hero.component.scss"],
})
export class MobileCompareHeroComponent {
    routes = routeNames;
    @Input("class") className = "";
}
