<ul class="flex flex-wrap items-center justify-start text-md">
    <li *ngFor="let link of this.breadcrumbs; let i = index">
        <ng-container *ngIf="i < this.breadcrumbs.length - 1; else lastElement">
            <a
                [routerLink]="link.path"
                [queryParams]="link.queryParams"
                [ngClass]="{ 'pointer-events-none': !link.path }"
                class="text-electric-blue underline"
            >
                {{ link.label }}
            </a >
            <fa-icon class="mx-1 text-sm" [icon]="faChevronRight"></fa-icon>
        </ng-container>
        <ng-template #lastElement>
            <div class="font-medium">{{ link.label }}</div>
        </ng-template>
    </li>
</ul>
