<article class="mx-auto w-full">
    <div class="relative w-full overflow-hidden rounded-3xl border bg-off-white">
        <a class="flex h-full w-full cursor-pointer flex-col" [routerLink]="dealUrl()">
            <section class="flex flex-1 items-stretch justify-between p-5">
                <div class="flex flex-col items-start justify-center">
                    <app-tags-row *ngIf="deal" [deal]="deal"></app-tags-row>
                    <div class="flex flex-1 flex-col items-start justify-center">
                        <app-shimmer-loader
                            loadingClass="w-full h-5"
                            class="mb-0.5"
                            [loading]="loading"
                        >
                            <h3 class="text-2xl font-semibold">{{ deal?.deal_name }}</h3>
                        </app-shimmer-loader>
                        <app-shimmer-loader class="" [loading]="loading">
                            <p *ngIf="dealType === 'bundle'">
                                <span 
                                    *ngIf="isRetailerHidden(deal)"
                                    class="after:content-['·'] after:mx-2 after:font-black"
                                >
                                    {{ deal?.network_provider?.name }} SIM
                                </span>
                                <span>{{ dealColor }}</span>
                            </p>
                            <p *ngIf="dealType === DealType.phoneOnly">
                                <span class="after:content-['·'] after:mx-2 after:font-black">SIM free</span>
                                <span>{{ dealColor }}</span>
                            </p>
                        </app-shimmer-loader>
                    </div>
                </div>

                <app-squiggly-background>
                    <div
                        class="mx-5 grid h-[122px] w-[335px] grid-cols-2 items-center divide-x divide-dashed divide-off-white bg-black px-5"
                    >
                        <app-shimmer-loader loadingClass="w-full h-16" [loading]="loading">
                            <h3 class="text-3.5xl font-medium text-off-white">
                                <ng-container
                                    >£{{
                                        deal?.deal_pay_today_total_inc_vat
                                            ?.toString()
                                            ?.split(".")?.[0]
                                    }}.</ng-container
                                ><span class="text-xl font-medium">{{
                                    deal?.deal_pay_today_total_inc_vat
                                        ?.toString()
                                        ?.split(".")?.[1] ?? "00"
                                }}</span>
                            </h3>
                            <p class="text-md text-off-white">Total today</p>
                        </app-shimmer-loader>
                        <app-shimmer-loader
                            class="block"
                            loadingClass="w-full h-16"
                            [loading]="loading"
                        >
                            <h3 class="pl-5 text-3.5xl font-medium text-off-white">
                                <ng-container
                                    *ngIf="deal?.deal_monthly_total_inc_vat; else notApplicable"
                                >
                                    <ng-container
                                        >£{{
                                            deal?.deal_monthly_total_inc_vat
                                                ?.toString()
                                                ?.split(".")?.[0]
                                        }}.</ng-container
                                    ><span class="text-xl font-medium">{{
                                        parseFloat(
                                            deal?.deal_monthly_total_inc_vat?.toString() ?? "0"
                                        )
                                            .toFixed(2)
                                            ?.split(".")?.[1] ?? "00"
                                    }}</span></ng-container
                                >
                                <ng-template #notApplicable> N/A </ng-template>
                            </h3>
                            <p class="pl-5 text-md text-off-white">Est. total monthly</p>
                        </app-shimmer-loader>
                    </div>
                </app-squiggly-background>
            </section>
            <footer>
                <section [ngClass]="[isVip ? 'bg-green-gradient ' : 'bg-pink-100']">
                    <div class="flex justify-evenly p-5">
                        <div *ngIf="dealType === 'bundle'; else condition">
                            <p class="mb-0.5 text-sm uppercase">data allowance</p>
                            <p class="font-semibold">
                                {{ deal?.sim_data_allowance_gb_numeric ?? "Unlimited " }}GB
                            </p>
                        </div>
                        <ng-template #condition>
                            <div>
                                <p class="mb-0.5 text-sm uppercase">condition</p>
                                <p class="font-semibold underline">
                                    {{ deal?.device_condition_friendly }}
                                </p>
                            </div>
                        </ng-template>

                        <div role="presentation" class="w-[1px] bg-black bg-opacity-20"></div>
                        <div>
                            <p class="mb-0.5 text-sm uppercase">{{ contractLenghtLabel }}</p>
                            <p class="font-semibold">
                                {{ deal?.deal_contract_length }} Month{{
                                    (deal?.deal_contract_length ?? 0) > 1 ||
                                    deal?.deal_contract_length === 0
                                        ? "s"
                                        : ""
                                }}
                            </p>
                        </div>

                        <div role="presentation" class="w-[1px] bg-black bg-opacity-20"></div>
                        <div>
                            <p class="mb-0.5 text-sm uppercase">Est total cost</p>
                            <p class="font-semibold"
                                >£{{
                                    parseFloat(
                                        deal?.deal_est_total_cost_inc_vat?.toString() ?? "0"
                                    ).toFixed(2)
                                }}</p
                            >
                        </div>
                        <div role="presentation" class="w-[1px] bg-black bg-opacity-20"></div>
                        <div>
                            <p class="mb-0.5 text-sm uppercase">Credit Check?</p>
                            <p class="font-semibold">{{ isOnCredit ? "Required" : "Not Required" }}</p>
                        </div>
                    </div>
                </section>
                <section
                    class="bg-black px-6 py-2.5 text-md text-off-white"
                    *ngIf="deal?.deal_price_rises"
                >
                    <p
                        ><fa-icon [icon]="faInfoCircle" class="mr-2"></fa-icon
                        >{{ deal?.deal_price_rises }}</p
                    >
                </section>
            </footer>
        </a>
    </div>

    <section
        class="bg-green-gradient mt-2.5 h-16 w-full rounded-3xl border border-black"
        *ngIf="isVip"
    >
        <a
            href="{{ deal?.members_benefit_link }}"
            class="flex h-full w-full items-center justify-center px-5 py-4"
        >
            <span class="font-bold">+</span>
            <p class="ml-2 flex-1">{{ deal?.members_benefit_text }}</p>
            <figure class="relative h-[95%] w-28 object-contain" *ngIf="deal?.members_benefit_image"
                ><img
                    alt="Member benefit icon image"
                    class="object-contain"
                    fill=""
                    [ngSrc]="deal!.members_benefit_image"
            /></figure>
        </a>
    </section>
</article>
