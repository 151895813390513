import { Component, AfterViewInit, Inject, Input, OnInit } from "@angular/core";
import { ContactCallUsComponent } from "../../components/contact-call-us/contact-call-us.component";
import { SuccessComponent } from "../../components/success/success.component";
import { DOCUMENT } from "@angular/common";
import { environment } from "src/environments/environment";
import { UserService } from "src/app/user/services/user.service";
import { firstValueFrom } from 'rxjs';
import { UtmHelper } from "src/app/core/models/utm";

@Component({
    selector: "app-typeform-page",
    standalone: true,
    imports: [ContactCallUsComponent, SuccessComponent],
    templateUrl: "./typeform-page.component.html",
    styleUrl: "./typeform-page.component.scss",
})
export class TypeformPageComponent implements OnInit, AfterViewInit {
    typeformId: string;
    isProduction: boolean;
    external_id!: string;

    constructor(
        @Inject(DOCUMENT) private document: Document,
        public userService: UserService,
        private utmHelper: UtmHelper
    ) {
        this.typeformId = environment.getStarted.typeformId.getStarted;
        this.isProduction = environment.production;
    }

    async ngOnInit() {
        const user = await firstValueFrom(this.userService.getUserInfo());
        this.external_id = user.external_id;
    }

    ngAfterViewInit() {
        const script = this.document.createElement("script")
        script.src = "//embed.typeform.com/next/embed.js"
        this.document.body.appendChild(script)
    }

    get typeform_hidden_params() {
        const utm_values = (this.utmHelper.values ?? []).map(param => `${param.key}=${param.value}`);
        return [
            `external_id=${this.external_id}`,
            ...utm_values
        ].join(",");
    }

}
