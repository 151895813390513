import { Component } from '@angular/core';

@Component({
  selector: 'app-bank-svg',
  standalone: true,
  imports: [],
  templateUrl: './bank-svg.component.html',
  styleUrl: './bank-svg.component.scss'
})
export class BankSvgComponent {

}
