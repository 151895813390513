<app-drawer>
    <app-navbar></app-navbar>
    <section class="account-container p-5">
        <app-breadcrumbs [breadcrumbs]="breadcrumbs"></app-breadcrumbs>
        <h1 class="mb-6 mt-11 text-3xl font-semibold">
            {{ this.h1 }}
        </h1>
        <!--
        <ng-container *ngIf="dealType === DealType.phoneOnly">
            <p class="mb-6">
                Need a SIM too?
                <a
                    [routerLink]="routes.bundle.selectPhone.fullPath()"
                    class="text-electric-blue underline"
                >
                    Click here for Phone + SIM deals
                </a>
            </p>
        </ng-container>
        <ng-container *ngIf="dealType === DealType.bundle">
            <p class="my-6">
                Don’t need a SIM?
                <a
                    [routerLink]="routes.simFreePhoneSelect.fullPath()"
                    class="text-electric-blue underline"
                >
                    Click here for Phone Only deals
                </a>
            </p>
        </ng-container> -->

        <form (ngSubmit)="showResults()" class="mb-20" #mobileForm="ngForm">
            <label
                class="mb-1.5 block text-sm uppercase text-black text-opacity-60"
                for="manufacturer_select"
            >
                Select manufacturer*
            </label>
            <select
                id="manufacturer_select"
                [disabled]="false"
                class="select select-bordered w-full max-w-xs appearance-none rounded-xl border-electric-blue bg-none outline-0 focus:outline-none focus-visible:outline-none"
                [ngClass]="[
                    manufacturerError ? 'mb-1' : 'mb-4',
                    this.form.modelId ? 'bg-off-white' : 'bg-gray-100'
                ]"
                [value]="this.form.manufacturerId ?? null"
                (change)="onManufacturerChange($event)"
            >
                <option disabled selected value="null"> -- select an option -- </option>
                <option *ngFor="let option of this.manufacturers()" [value]="option.value">
                    {{ option.label }}
                </option>
            </select>
            <p *ngIf="manufacturerError" class="mb-4 text-md text-error-red">{{
                manufacturerError
            }}</p>

            <label
                class="mb-1.5 block text-sm uppercase text-black text-opacity-60"
                for="model_select"
            >
                Select phone*
            </label>
            <fieldset [disabled]="!this.form.manufacturerId">
                <select
                    id="model_select"
                    (change)="onModelChange($event)"
                    [value]="this.form.modelId"
                    class="select select-bordered w-full max-w-xs appearance-none rounded-xl border-electric-blue bg-gray-100 bg-none focus:outline-none focus-visible:outline-none"
                    [ngClass]="[
                        phoneModelError ? 'mb-1' : 'mb-4',
                        this.form.modelId ? 'bg-off-white' : 'bg-gray-100'
                    ]"
                >
                    <option disabled selected value=""> -- select an option -- </option>
                    <option
                        [selected]="form.modelId === option.id.toString()"
                        *ngFor="let option of this.models()"
                        [value]="option.slug?.toString() ?? option.id.toString()"
                    >
                        {{ option.name }}
                    </option>
                </select>
                <p *ngIf="phoneModelError" class="mb-4 text-md text-error-red">{{
                    phoneModelError
                }}</p>
            </fieldset>

            <button
                type="submit"
                class="account-container btn fixed bottom-5 left-5 right-5 rounded-full bg-black uppercase text-white md:static md:mt-6 md:w-full md:max-w-xs"
            >
                show me my deals
            </button>
        </form>
    </section>
</app-drawer>
