<ul class="flex items-center gap-2">
    <li *ngIf="deal?.deal_retailer_logo_url || deal?.network_provider?.logo">
        <figure class="mb-2">
            <img
                [ngSrc]="deal?.deal_retailer_logo_url || deal?.network_provider?.logo || ''"
                height="32"
                width="32"
                [alt]="deal?.deal_retailer_name + ' logo'"
                class="h-7 w-auto" />
        </figure
    ></li>
    <li
        *ngIf="isVip"
        class="mb-2 h-8 min-w-[101px] px-3 py-1.5 flex items-center justify-center rounded-full border text-xs font-semibold text-black bg-green-gradient"
    >
        <p>VIP&nbsp;DEAL</p>
    </li>
    <li
        *ngIf="deal?.device_condition_friendly && deal?.device_condition_friendly !== 'New'"
        class="mb-2 h-8 min-w-[101px] px-3 flex items-center justify-center rounded-full border border-black bg-off-white"
        ><p class="text-center text-sm font-semibold uppercase text-black">{{
            deal?.device_condition_friendly
        }}</p>
    </li>
    <li
        *ngIf="isOnCredit"
        class="mb-2 h-8 min-w-[101px] px-3 flex items-center justify-center rounded-full border border-black text-center text-xs font-semibold uppercase bg-green-gradient"
    >
        on credit
    </li>
    <li
        *ngIf="deal?.device_payment_type === 'Lease'"
        class="mb-2 h-8 min-w-[101px] px-3 flex items-center justify-center rounded-full border border-black text-center text-xs font-semibold uppercase text-white bg-pink-red-gradient"
    >
        on lease
    </li>
</ul>
