import { LoginUserModel } from "../../models/user";

export class LoginUserAction {
    static readonly type = "[User] Login";

    constructor(public user: LoginUserModel, public fromPage?: string) {}
}

export class LogoutUserAction {
    static readonly type = "[User] Logout";
}

export class SendResetPasswordAction {
    static readonly type = "[User] Send Reset Password Link";

    constructor(public email: string) {}
}
