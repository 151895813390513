import { Component, effect, HostBinding, Input } from "@angular/core";
import { CommonModule } from "@angular/common";
import { VipCardComponent } from "../vip-card/vip-card.component";
import { UserModel } from "../../models/user";
import { BankingState } from "../../state/banking.state";
import { DateTime } from "luxon";

@Component({
    selector: "app-membership-status",
    standalone: true,
    imports: [CommonModule, VipCardComponent],
    templateUrl: "./membership-status.component.html",
    styleUrls: ["./membership-status.component.scss"],
})
export class MembershipStatusComponent {
    @HostBinding("class") @Input("class") className = "";
    @Input({ required: true }) user?: UserModel;
    constructor(public bankingState: BankingState) {}

}
