import { Component, OnInit, signal } from "@angular/core";
import { ActivatedRoute, RouterLink } from "@angular/router";
import { routeNames } from "../../../app-routing.module";
import {
    Breadcrumb,
    BreadcrumbsComponent,
} from "../../../core/components/breadcrumbs/breadcrumbs.component";
import { Select } from "@ngxs/store";
import { UserState } from "../../../user/state/user/user.state";
import { Observable } from "rxjs";
import { DealDetailsState } from "../../state/deal-details.state";
import { HttpStatus } from "src/app/core/models/http-status";
import { LoadingComponent } from "../../../core/components/loading/loading.component";
import { SquigglyBackgroundComponent } from "../../components/squigly-background/squiggly-background.component";
import { NgClass, NgIf, NgOptimizedImage } from "@angular/common";
import { DetailItemComponent } from "../../components/detail-item/detail-item.component";
import { PerksComponent } from "../../components/perks/perks.component";
import { DealDisclaimerComponent } from "../../components/deal-disclaimer/deal-disclaimer.component";
import { DisclaimerGraphicComponent } from "../../../core/components/disclaimer-graphic/disclaimer-graphic.component";
import { DealType } from "../../models/filter";
import { TagsRowComponent } from "../../components/tags-row/tags-row.component";
import { GetDealBtnComponent } from "../../components/get-deal-btn/get-deal-btn.component";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FaIconComponent } from "@fortawesome/angular-fontawesome";
import { JoinedContractPlanComponent } from "../../components/joined-contract-plan/joined-contract-plan.component";
import { isNever } from "src/app/util/exhaustiveCheck";
import { isRetailerHidden } from "src/app/util/dealViewHelpers";
import { isIdea14Deal as isIdea14DealFn } from "src/app/util/features";

@Component({
    selector: "app-deal-details-page",
    standalone: true,
    providers: [DealDetailsState],
    templateUrl: "./deal-details-page.component.html",
    styleUrl: "./deal-details-page.component.scss",
    imports: [
        BreadcrumbsComponent,
        LoadingComponent,
        FaIconComponent,
        SquigglyBackgroundComponent,
        NgOptimizedImage,
        NgIf,
        DetailItemComponent,
        PerksComponent,
        DealDisclaimerComponent,
        DisclaimerGraphicComponent,
        NgClass,
        TagsRowComponent,
        GetDealBtnComponent,
        JoinedContractPlanComponent,
        RouterLink,
    ],
})
export class DealDetailsPageComponent implements OnInit {
    isLoggedIn = false;
    phoneModel = "";
    showSimDetails = false;
    dealType: DealType = DealType.bundle;
    @Select(UserState.getLoginStatus) isLoggedIn$!: Observable<boolean>;
    isRetailerHidden = isRetailerHidden;
    routeNames = routeNames;

    constructor(
        public dealDetailsState: DealDetailsState,
        private route: ActivatedRoute,
    ) {

        this.dealType = this.route.snapshot.data["dealType"];
        const id = this.route.snapshot.params["id"];
        this.phoneModel = this.route.snapshot.params["phone_model"];
        this.dealDetailsState.fetchDeal(parseInt(id));
        this.showSimDetails = this.dealType === "bundle";

        this.isLoggedIn$.subscribe((res) => {
            this.isLoggedIn = res;
        });

    }

    ngOnInit() {
        gtag(
            "event",
            "product_details__page_load",
            {
                deal_type: this.dealType,
                manufacturer_id: this.deal?.manufacturer.id,
                phone_model_slug: this.deal?.phone_model.slug || this.phoneModel,
                is_logged_in: this.isLoggedIn,
            }
        )

        if (isIdea14DealFn(this.deal!)) {
            gtag(
                "event",
                "product_details__page_load__idea_14",
                {
                    deal_phone_model: this.deal?.phone_model.name ?? this.deal?.phone_model.slug ?? this.deal?.phone_model.id,
                    deal_contract_length: this.deal?.deal_contract_length,
                    deal_monthly_total_inc_vat: this.deal?.deal_monthly_total_inc_vat,
                    deal_pay_today_total_inc_vat: this.deal?.deal_pay_today_total_inc_vat
                }
            )

        }
    }

    protected readonly faInfoCircle = faInfoCircle;
    protected readonly HttpStatus = HttpStatus;

    get deal() {
        return this.dealDetailsState.deal();
    }

    get breadcrumbs(): Breadcrumb[] {
        switch (this.dealType) {
            case "phone-only":
                return [
                    { label: "Compare SIM free phones", path: routeNames.simFreePhoneSelect.fullPath() },
                    {
                        label: `${this.deal?.phone_model?.name || this.phoneModel} Deals`,
                        path: routeNames.simFreeDeals.list.fullPath({ phoneModel: this.phoneModel })
                    },
                    { label: `Your deal` }
                ];
            case "sim-only":
                return [
                    { label: "Home", path: routeNames.home.fullPath() },
                    { label: "Compare SIM Only deals", path: routeNames.simOnlyDeals.list.fullPath() },
                    { label: `Your deal` }
                ];
            case "bundle":
                return [
                    { label: "Compare phone contacts", path: routeNames.bundle.selectPhone.fullPath() },
                    {
                        label: `${this.deal?.phone_model?.name || this.phoneModel} Deals`,
                        path: routeNames.bundle.list.fullPath({ phoneModel: this.phoneModel })
                    },
                    { label: `Your deal` }
                ];
            default:
                throw isNever(this.dealType);
        }
    }

    protected readonly DealType = DealType;
    get dealPerks(): string[] | undefined {
        if (this.deal?.deal_perks && this.deal?.deal_perks.length > 0) {
            return this.deal?.deal_perks?.split(";");
        }
        return undefined;
    }

    get creditCheck() {
        if (this.deal?.deal_credit_check === undefined || this.deal?.deal_credit_check === null) {
            return "Unknown";
        }
        return this.deal.deal_credit_check ? "Required" : "Not required";
    }

    protected readonly parseFloat = parseFloat;

    get isIdea14Deal() {
        return this.deal && isIdea14DealFn(this.deal);
    }
}
