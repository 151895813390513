<a
    [href]="!isRouterLink ? href : null"
    class="relative flex flex-col justify-center min-h-20 w-full rounded-2xl border grow"
    [routerLink]="isRouterLink ? href : null"
    [ngClass]="additionalClasses"
>
    <div class="flex items-center justify-between gap-x-4 mx-5 p-2">
        <span class="flex items-center text-lg lg:text-xl font-semibold">{{ title }}</span>
        <div class="flex items-center space-x-2" >
            <div class="flex items-center justify-center bg-black rounded-full h-10 w-10" >
                <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g id="Icon/ Arrow Right">
                        <path
                            id="Vector 63"
                            d="M11 17L18 10L11 3"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            id="Vector 68"
                            d="M18 10L1.5 10"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                        />
                    </g>
                </svg>
            </div>
        </div>
    </div>
</a>
