import { Component, computed, inject, OnInit, signal } from "@angular/core";
import { Router } from "@angular/router";
import { routeNames } from "./app-routing.module";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { BankingState } from "./user/state/banking.state";
import { UserService } from "./user/services/user.service";

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
    protected readonly routeNames = routeNames;
    protected readonly faInfoCircle = faInfoCircle;
    bankingState = inject(BankingState);
    userService = inject(UserService);
    private bankingPopupDismissed = signal(false);
    bankingPopupOpen = computed(() => {
        return (
            this.userService.currentUser() != null &&
            !this.bankingState.connected() &&
            !this.bankingPopupDismissed() &&
            !this.router.url.includes("/get-started/")
        );
    });

    constructor(private router: Router) { }

    ngOnInit() {
        this.init();
    }
    async init() {
        await this.bankingState.init();
    }

    closeBankingPopup() {
        this.bankingPopupDismissed.set(true);
    }
}
