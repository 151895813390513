<div
    class="flex flex-col items-center justify-center bg-white rounded-3xl min-w-[240px] max-w-[300px] h-96 pb-4 pt-8 px-4 border border-solid "
    [ngClass]='{ "overflow-visible relative": step === "4" }'
>
    <div class="h-2/3">
        @switch(step) {
            @case("1") {
                    <app-choice-svg></app-choice-svg>
            }
            @case("2") {
                <app-bank-svg></app-bank-svg>
            }
            @case("3") {
                <app-paper-plane-svg></app-paper-plane-svg>
            }
            @case("4") {
                <app-celebration-box-svg></app-celebration-box-svg>
            }
        }
    </div>
    <div class="grid grid-cols-3 h-1/3">
        <div class="col-span-1 font-black text-8xl text-center flex items-center justify-center"><p>{{ step }}</p></div>
        <div class="col-span-2 self-center text-md font-medium flex items-center justify-center"><p>{{ description }}</p></div>
    </div>
</div>

