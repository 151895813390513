import { HttpParams } from "@angular/common/http";

export class HttpUtil {
    private constructor() {
        throw new Error("Utility class, cannot be instantiated");
    }

    public static buildUrlParams(filters?: object): HttpParams {
        let urlSearchParams: HttpParams = new HttpParams();

        if (filters) {
            Object.entries(filters).forEach(([key, val]): void => {
                if (val !== undefined && val !== null) {
                    if (Array.isArray(val)) {
                        val.forEach((value): void => {
                            urlSearchParams = urlSearchParams.append(key, value.toString());
                        });
                    } else {
                        urlSearchParams = urlSearchParams.set(key, val.toString());
                    }
                }
            });
        }
        return urlSearchParams;
    }
}
