<section class="pt-8 md:pt-12 relative">
    <div class="flex flex-col md:flex-row relative rounded-3xl py-8 md:py-10 px-6 md:px-10 mx-4 md:mx-8 lg:mx-auto max-w-[880px] grid grid-rows-auto place-items-center bg-[#FFFFFF] drop-shadow">
        <h1 class="mx-auto font-display font-black text-3xl md:text-5xl lg:text-7xl text-center w-full leading-tight">
            Get your student phone contract 🧑‍🎓
        </h1>
        <p class="mt-4 md:mt-6 text-base md:text-xl text-center text-black text-opacity-60">
            Young people are 3x more likely to be rejected for a phone contract because of their credit score.
            Applying is <span class="underline font-semibold text-black text-opacity-70">free</span>
            and does <span class="underline font-semibold text-black text-opacity-70">not impact</span> your credit score!
        </p>

        <a
            class="btn btn-primary btn-wide rounded-full mt-4 md:mt-6 uppercase text-white"
            [href]="createAccount"
            (click)="userClickedApply(1)"
            (keyup.enter)="userClickedApply(1)"
        >
            Apply in minutes
        </a>
        <h2 class="mt-4 md:mt-6 text-sm uppercase text-black text-opacity-60">0% interest on repayments</h2>
    </div>

    <section class="mt-8 mb-4 z-10">
        <h2 class="text-center text-lg uppercase">As seen in</h2>
        <div class="mx-auto mt-4 flex flex-wrap items-center justify-around max-w-[440px] px-8 z-10">
            <a
                class="h-[72px] w-[72px] overflow-hidden rounded-full border object-cover object-center z-10"
                href="https://netimesmagazine.co.uk/magazine/five-minutes-with-evangeline-atkinson-and-laura-mills/"
                target="_blank"
            >
                <figure class="h-[72px] w-[72px]">
                    <img
                        class="h-full w-full object-cover object-center"
                        alt="North East Times Magazine logo"
                        ngSrc="/assets/images/netimesmagazine_logo_2.png"
                        height="72"
                        width="72"
                    />
                </figure>
            </a>

            <a
                class="h-[72px] w-[72px] overflow-hidden rounded-full border object-cover object-center z-10"
                href="https://techfundingnews.com/female-founded-noggin-hq-bags-710k-to-help-users-get-access-to-credit/"
                target="_blank"
            >
                <figure >
                    <img
                        class="h-full w-full object-cover object-center"
                        alt="Tech Funding News logo"
                        ngSrc="/assets/images/techfundingnews_logo.png"
                        height="72"
                        width="72"
                    />
                </figure>
            </a>

            <a
                class="h-[72px] w-[72px] overflow-hidden rounded-full border object-cover object-center bg-[#FDCFB3] z-10"
                href="https://www.ft.com/content/9f9d3a54-d08b-4d9c-a000-d50460f818dc"
                target="_blank"
            >
                <figure>
                    <img
                        class="h-full w-full object-cover object-center mt-[14px]"
                        alt="Financial Times logo"
                        ngSrc="/assets/images/ft_logo.jpeg"
                        height="72"
                        width="72"
                    />
                </figure>
            </a>
        </div>
    </section>
    <div
        class="absolute bg-transparent bg-repeat-x w-[102%] h-[170px] p-0 mr-0 my-0 -mx-[2%] border-none z-0 -bottom-[180px] overflow-hidden"
        style="background-image: url(assets/svg/drip.svg);"
    ></div>
</section>

<section class="flex flex-col bg-lime-gradient pt-[120px]">
    <app-cms-carousel
        [dark]="false"
        [carouselIndex]="4"
        class="block"
        [showSecondDescriptionRow]="false"
        [itemsClickable]="false"
        padding="pt-12"
    ></app-cms-carousel>
    <a
        class="btn btn-primary btn-wide rounded-full mt-12 uppercase text-white place-self-center"
        [href]="createAccount"
        (click)="userClickedApply(2)"
        (keyup.enter)="userClickedApply(2)"
    >
        Apply in minutes
    </a>
</section>

<section class="mt-12 w-full flex  flex-col items-center">
    <app-noggin-phone-tile
        additionalClasses="mx-4 md:mx-8 lg:mx-auto max-w-[880px]"
        caption="Why are we doing this?"
        heading="36% of young people are rejected when applying for credit 🙄"
        text="We were rejected in our mid-20s when applying for a phone contract. We founded Noggin to make sure that if this happens to you, you’re covered."
    >
    </app-noggin-phone-tile>
</section>

<section class="mt-12">
    <div class="flex flex-col items-center justify-center overflow-hidden">
        <div class="flex flex-col md:flex-row relative rounded-3xl px-6 md:px-10 mx-5 md:mx-16 grid grid-rows-auto place-items-center">
            <h2 class="mx-auto font-semibold text-3xl md:text-7xl text-center w-full mt-4 leading-tight">
                How does it work?
            </h2>
            <p class="mx-auto block text-center text-sm uppercase -order-1">
                See what you can do without a credit check
            </p>
        </div>

        @switch(isSmall) {
            @case(false) {
                <div class="pt-8 px-8 gap-4 hidden md:flex md:flex-wrap items-center justify-center">
                    <app-card-onboarding-steps
                        step="1"
                        description="Create an account with Noggin & choose your iPhone or Samsung deal"
                    ></app-card-onboarding-steps>
                    <app-card-onboarding-steps
                        step="2"
                        description="Share your digital bank statement securely"
                    ></app-card-onboarding-steps>
                    <app-card-onboarding-steps
                        step="3"
                        description="Get an email with phones you can 100% access"
                    ></app-card-onboarding-steps>
                    <app-card-onboarding-steps
                        step="4"
                        description="Once we receive your first payment, we post your phone next day"
                    ></app-card-onboarding-steps>
                </div>
            }
            @case(true) {
                <app-carousel [dark]="dark">
                    <app-carousel-item>
                        <app-card-onboarding-steps
                            step="1"
                            description="Create an account with Noggin & choose your iPhone or Samsung deal"
                        ></app-card-onboarding-steps>
                    </app-carousel-item>

                    <app-carousel-item>
                        <app-card-onboarding-steps
                            step="2"
                            description="Share your digital bank statement securely"
                        ></app-card-onboarding-steps>
                    </app-carousel-item>

                    <app-carousel-item>
                        <app-card-onboarding-steps
                            step="3"
                            description="Get an email with phones you can 100% access"
                        ></app-card-onboarding-steps>
                    </app-carousel-item>

                    <app-carousel-item>
                        <app-card-onboarding-steps
                            step="4"
                            description="Once we receive your first payment, we post your phone next day"
                        ></app-card-onboarding-steps>
                    </app-carousel-item>
                </app-carousel>
            }
        }

        <a
            class="btn btn-primary btn-wide rounded-full mt-12 uppercase text-white"
            [href]="createAccount"
            (click)="userClickedApply(3)"
            (keyup.enter)="userClickedApply(3)"
        >
            Apply in minutes
        </a>
    </div>
</section>

<section class="mt-20 mb-20 mx-8 lg:mx-auto max-w-[880px]">
    <app-faq
        filterByTag="noggin-no-credit-check-phone-faq"
    ></app-faq>
</section>
