<app-credit-toggle
    [dealType]="dealType"
    class="block w-[324px]"
></app-credit-toggle>
<app-deal-filter
    [defaultOpen]="true"
    [updateAutomatically]="true"
    class="block w-[324px] rounded-3xl border p-5"
    [dealType]="dealType"
></app-deal-filter>
