import { Component, Input } from "@angular/core";
import { CommonModule } from "@angular/common";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { Location } from "@angular/common";
import { LogoFullComponent } from "../../../core/components/logo-full/logo-full.component";
import { Router, RouterLink } from "@angular/router";
import { routeNames } from "../../../app-routing.module";

@Component({
    selector: "app-account-header",
    standalone: true,
    imports: [CommonModule, FontAwesomeModule, LogoFullComponent, RouterLink],
    templateUrl: "./account-header.component.html",
    styleUrls: ["./account-header.component.scss"],
})
export class AccountHeaderComponent {
    protected readonly faChevronLeft = faChevronLeft;
    @Input() backLocation?: string;
    constructor(
        private location: Location,
        private router: Router,
    ) {}

    back() {
        if (this.backLocation) {
            return this.router.navigate([this.backLocation], { replaceUrl: true });
        }
        return this.location.back();
    }

    protected readonly routeNames = routeNames;
}
