<figure class="vip-card-bg relative mt-8 h-[190px] w-full">
    <div class="absolute z-10 flex h-full w-full flex-col items-center justify-center">
        <h2 class="text-center text-[52px] font-semibold tracking-wide">VIP</h2>
        <p
            class="mb-4 w-[70%] max-w-[424px] border-b border-dashed pb-4 text-center text-md uppercase tracking-wide"
        >
            {{ user.first_name }}
        </p>
        <p class="mb-4 text-center text-md uppercase tracking-wide">NO. {{ user.id }}</p>
    </div>
</figure>
