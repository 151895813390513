import { Injectable, signal } from "@angular/core";

type MessageOpts = {
    message: string;
    duration?: number;
    dismissable?: boolean;
    /** Level of the message, used to determine the color of the message, defaults to default */
    level?: "default";
};

@Injectable({ providedIn: "root" })
export class NotifyState {
    message = signal<MessageOpts | undefined>(undefined);

    showMessage(opts: MessageOpts) {
        this.message.set(opts);
        setTimeout(() => {
            this.message.set(undefined);
        }, opts.duration ?? 3000);
    }

    dismiss() {
        this.message.set(undefined);
    }
}
