<div class="account-container">
    <div class="bold mb-6 mr-auto flex items-center justify-center text-[14px] uppercase text-electric-blue h-9">
        &nbsp;
    </div>

    <section class="mb-8">
        <h1 class="text-3xl font-semibold">Share your digital bank statement</h1>
        <h2 class="mt-4 text-sm uppercase text-black text-opacity-60">Step 1 of 2</h2>
        <div class="mt-4">
            The first step is to share your digital bank statement via our secure connection to your bank.
            Connect the account you receive <span class="font-semibold text-black text-opacity-80">income</span> into - the more data we can see, the easier it is for us to make a decision.
        </div>

        <div class="mt-4 md:mt-8 text-black font-semibold">
            Why is this step important?
        </div>

        <ul class="mt-8 flex flex-col space-y-8">
            <li class="flex items-start" *ngFor="let row of this.bulletPoints">
                <img
                    class="mr-4"
                    width="20px"
                    height="20px"
                    ngSrc="assets/svg/bullet-tick.svg"
                    alt=""
/>
                <div class="">
                    {{ row.text }}
                </div>
            </li>
        </ul>

        <div class="mt-4">
            <app-banking-connect
                *ngIf="!bankingState.connected()"
            ></app-banking-connect>
        </div>

    </section>
</div>

<section class="flex my-8 mx-auto max-w-lg">
    <app-noggin-phone-tile
        caption="Why are we doing this?"
        heading="5.8 million people in the UK are excluded from credit!"
        text="We are dedicated to bringing a solution for people unfairly locked out of the current system."
    ></app-noggin-phone-tile>
</section>
