import { Component, Input } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterLink } from "@angular/router";
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";

@Component({
    selector: "footer-link",
    standalone: true,
    imports: [CommonModule, RouterLink, FontAwesomeModule],
    templateUrl: "./footer-link.component.html",
    styleUrls: ["./footer-link.component.scss"],
})
export class FooterLinkComponent {
    @Input() href?: string;
    @Input() routerLink?: string;
    @Input() icon?: IconDefinition;
    protected readonly faFacebook = faFacebook;
}
