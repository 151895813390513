import { Component } from '@angular/core';

@Component({
    selector: 'app-choice-svg',
    standalone: true,
    imports: [],
    templateUrl: './choice-svg.component.html',
    styleUrl: './choice-svg.component.scss'
})
export class ChoiceSvgComponent { }
