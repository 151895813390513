import { Component, HostBinding, Input } from "@angular/core";

@Component({
    selector: "app-logo-full",
    templateUrl: "./logo-full.component.html",
    styleUrls: ["./logo-full.component.scss"],
    standalone: true,
})
export class LogoFullComponent {
    @HostBinding("class") @Input("class") className?: string = "text-black";
    @Input() fill = "currentColor";
    @Input() height?: string = "100%";
}
