import { Component, HostBinding, Input } from "@angular/core";
import { Select } from "@ngxs/store";
import { UserState } from "../../../user/state/user/user.state";
import { Observable } from "rxjs";
import { DrawerState } from "../../../core/state/drawer.state";
import { createConfig } from "../../../core/models/navbar-config.model";
import { routeNames } from "src/app/app-routing.module";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { LogoFullComponent } from "../../../core/components/logo-full/logo-full.component";
import { MenuIconComponent } from "../../../core/components/menu-icon/menu-icon.component";
import { NavHoverMenuComponent } from "../../../core/components/nav-hover-menu/nav-hover-menu.component";
import { NgForOf, NgIf } from "@angular/common";
import { ProfileIconComponent } from "../../../user/components/profile-icon/profile-icon.component";
import { RouterLink } from "@angular/router";
import { FaIconComponent } from "@fortawesome/angular-fontawesome";

@Component({
    selector: "app-our-research-navbar",
    standalone: true,
    imports: [
        LogoFullComponent,
        MenuIconComponent,
        NavHoverMenuComponent,
        NgForOf,
        NgIf,
        ProfileIconComponent,
        RouterLink,
        FaIconComponent,
    ],
    templateUrl: "./our-research-navbar.component.html",
    styleUrl: "./our-research-navbar.component.scss",
})
export class OurResearchNavbarComponent {
    isLoggedIn = false;
    @Select(UserState.getLoginStatus) isLoggedIn$!: Observable<boolean>;
    routes = routeNames;

    constructor() {
        this.isLoggedIn$.subscribe((res) => {
            this.isLoggedIn = res;
        });
    }

    protected readonly routeNames = routeNames;
}
