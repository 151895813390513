import { Component, Input } from "@angular/core";
import { DealModel } from "../../models/deal";
import { DetailItemComponent } from "../detail-item/detail-item.component";
import { NgIf } from "@angular/common";
import { isIdea14Deal } from "src/app/util/features";

@Component({
    selector: "app-joined-contract-plan",
    standalone: true,
    templateUrl: "./joined-contract-plan.component.html",
    styleUrl: "./joined-contract-plan.component.scss",
    imports: [DetailItemComponent, NgIf],
})
export class JoinedContractPlanComponent {
    @Input({ required: true }) deal!: DealModel;

    get isIdea14Deal() {
        return isIdea14Deal(this.deal);
    }

    get lengthLabel() {
        return this.isIdea14Deal ? "Length" : "contract length";
    }

    get creditCheck() {
        if (this.deal?.deal_credit_check === undefined || this.deal?.deal_credit_check === null) {
            return "Unknown";
        }
        return this.deal.deal_credit_check ? "Required" : "Not required";
    }
}
