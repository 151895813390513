<div class="flex flex-col w-full -mx-5 -mt-5">
    <div
        class="h-3 bg-lime-green w-3/12 rounded-r-full"
    ></div>
</div>

<div class="account-container mt-6">
    <div class="flex justify-center">
        <p class="mx-auto uppercase text-sm font-light">Step 1 of 3</p>
    </div>

    <section class="my-4">
        <h1 class="text-3xl font-semibold">Create your Noggin account</h1>
        <p class="mt-2 text-black">
            Already have an account?
            <a
                [queryParams]="fromPage ? { from: fromPage } : undefined"
                [routerLink]="routeNames.login.fullPath()"
                class="text-electric-blue underline"
            >
                Login here
            </a>
        </p>
    </section>

    <form (ngSubmit)="submit()" [formGroup]="registerUserForm">
        <div class="flex flex-col">
            <label for="first_name" class="mb-1.5 text-sm uppercase">First name</label>
            <input
                formControlName="first_name"
                id="first_name"
                type="text"
                class="input rounded-xl border border-black p-4"
                [attr.readonly]="formSubmitting() ? 'readonly' : null"
            />
            @switch(showFirstNameErrorIsRequired) {
                @case (true) {
                    <small class="text-error-red">
                        First Name is required
                    </small>
                }
                @case (false) {
                    <small>&nbsp;</small>
                }
            }
        </div>

        <div class="mt-2 flex flex-col">
            <label for="last_name" class="mb-1.5 text-sm uppercase">Last name</label>
            <input
                formControlName="last_name"
                id="last_name"
                type="text"
                class="input rounded-xl border border-black p-4"
                [attr.readonly]="formSubmitting() ? 'readonly' : null"
            />
            @switch(showLastNameErrorIsRequired) {
                @case (true) {
                    <small class="text-error-red">
                        Last Name is required
                    </small>
                }
                @case (false) {
                    <small>&nbsp;</small>
                }
            }
        </div>


        <div class="mt-2 mb-4 flex flex-col">
            <label for="email" class="mb-1.5 text-sm uppercase">email</label>
            <input
                formControlName="email"
                id="email"
                type="email"
                class="input rounded-xl border border-black p-4"
                [attr.readonly]="formSubmitting() ? 'readonly' : null"
            />
            <small *ngIf="showEmailErrorIsRequired" class="text-error-red">Email Address is required</small>
            <small *ngIf="showEmailErrorPattern" class="text-error-red">Please enter valid email address, eg: example&#64;domain.com</small>
            <small *ngIf="!(showEmailErrorIsRequired || showEmailErrorPattern)">&nbsp;</small>
        </div>

        <app-password-field
            autocomplete="new-password"
            [control]="getFormControl('password')"
            marginTop="mt-2"
            [isreadonly]="formSubmitting()"
        ></app-password-field>
        <div class="mt-2 flex items-center justify-center gap-1">
            <span
                *ngFor="let i of [1, 2, 3]"
                class="h-2 flex-1 rounded-full"
                [ngClass]="{
                    'bg-error-red': i <= passwordLevel && passwordLevel === 1,
                    'bg-electric-blue': i <= passwordLevel && passwordLevel === 2,
                    'bg-success-green': i <= passwordLevel && passwordLevel === 3,
                    'bg-black bg-opacity-20': i > passwordLevel
                }"
            ></span>
            <p class="w-14 text-right text-sm uppercase">{{ passwordLevelText }}</p>
        </div>
        <small
            *ngIf="
                registerUserForm.get('password')?.status === 'INVALID' &&
                registerUserForm.get('password')?.touched &&
                registerUserForm.get('password')?.errors?.['required']
            "
            class="text-error-red"
        >
            Password is required
        </small>
        <small
            *ngIf="
                registerUserForm.get('password')?.status === 'INVALID' &&
                registerUserForm.get('password')?.touched &&
                registerUserForm.get('password')?.errors?.['pattern']
            "
            class="text-error-red"
        >
            Your password must be at least 8 characters long, contain at least one number, contain
            at least one special character and have a mixture of uppercase and lowercase letters.
        </small>

        <div class="mt-6 mb-4 flex">
            <input
                id="email_opt_in"
                formControlName="email_opt_in"
                type="checkbox"
                checked="checked"
                class="checkbox-primary checkbox mr-2.5"
                [attr.readonly]="formSubmitting() ? 'readonly' : null"
            />
            <label for="email_opt_in">I would like to recieve emails about upcoming offers</label>
        </div>
        <div class="flex">
            <input
                id="terms_agree"
                formControlName="terms_agree"
                type="checkbox"
                checked="checked"
                class="checkbox-primary checkbox mr-2.5"
                [attr.readonly]="formSubmitting() ? 'readonly' : null"
            />
            <label for="terms_agree">
                I have read and accept Noggin's
                <a class="text-electric-blue underline">Privacy Policy</a>
                and
                <a class="text-electric-blue underline">Terms &amp; Conditions</a>
            </label>
        </div>
        <small
            *ngIf="
                registerUserForm.get('terms_agree')?.status === 'INVALID' &&
                registerUserForm.get('terms_agree')?.touched
            "
            class="text-error-red"
        >
            To register you must agree to the Terms &amp; Conditions
        </small>

        <div class="my-8 h-14 w-full">
            <ng-container *ngIf="formSubmitting(); else content">
                <app-loading class="block"></app-loading>
            </ng-container>
            <ng-template #content>
                <button
                    name="Submit"
                    type="submit"
                    [disabled]="!registerUserForm.valid"
                    class="btn btn-primary mx-auto w-full block self-stretch rounded-full text-center uppercase text-white"
                >
                    Create account
                </button>
            </ng-template>
        </div>
    </form>
</div>

<section class="flex my-8 mx-auto max-w-lg" nomargin>
    <app-noggin-phone-tile
        caption="Why are we doing this?"
        heading="5.8 million people in the UK are excluded from credit!"
        text="We are dedicated to bringing a solution for people unfairly locked out of the current system."
    ></app-noggin-phone-tile>
</section>
