import { Component, computed, HostListener, OnDestroy, OnInit, signal } from "@angular/core";
import { ActivatedRoute, Router, RouterLink } from "@angular/router";
import { faChevronRight, faClose, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FaIconComponent } from "@fortawesome/angular-fontawesome";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DealFilterComponent } from "../../components/deal-filter/deal-filter.component";
import { DealOrderDropdownComponent } from "../../components/deal-order-dropdown/deal-order-dropdown.component";
import { DealCardComponent } from "../../components/deal-card/deal-card.component";
import { dealFilterFromParams, DealFilterState } from "../../state/deal-filter.state";
import { DealState } from "../../state/deal.state";
import { NgForOf, NgIf, NgClass } from "@angular/common";
import { PhoneCardNavComponent } from "../../components/phone-card-nav/phone-card-nav.component";
import { HttpStatus } from "../../../core/models/http-status";
import { FilterDialogComponent } from "../../components/filter-dialog/filter-dialog.component";
import { DesktopFilterComponent } from "../../components/desktop-filter/desktop-filter.component";
import { SimCardComponent } from "../../components/sim-card/sim-card.component";
import { DealTypeModel } from "../../models/deal";
import { DesktopDealListItemComponent } from "../../components/desktop-deal-list-item/desktop-deal-list-item.component";
import { DesktopPhoneDealListItemComponent } from "../../components/desktop-phone-deal-list-item/desktop-phone-deal-list-item.component";
import { PhonesService } from "../../services/phones.service";
import { ColoursModel, PhoneModel, StorageSize } from "../../models/phone";
import {
    Breadcrumb,
    BreadcrumbsComponent,
} from "../../../core/components/breadcrumbs/breadcrumbs.component";
import { routeNames } from "../../../app-routing.module";
import { LoadingComponent } from "../../../core/components/loading/loading.component";
import { DealFilterParams, dealOrdering, DealType, DealWithPhoneType, Filter, FilterOption } from "../../models/filter";
import { mergeWith, Subscription } from "rxjs";
import { PhoneSelectTagsComponent } from "../../components/phone-select-tags/phone-select-tags.component";
import { Title } from "@angular/platform-browser";
import { isNever } from "src/app/util/exhaustiveCheck";
import { DealModel } from "src/app/deal/models/deal";
import { CreditToggleComponent } from "../../components/credit-toggle/credit-toggle.component";
import { payloadForDealFilterParams } from "src/app/util/analytics";

const keys_to_ignore = [
    "page_size",
    "deal_has_repayment_data",
    "deal_is_vip",
    "deal_name",
    "members_only",
    "phone_model",
    "tags",
]

@Component({
    selector: "app-deal-list",
    standalone: true,
    imports: [
        NgForOf,
        NgIf,
        NgClass,
        FaIconComponent,
        FormsModule,
        ReactiveFormsModule,
        RouterLink,
        DealFilterComponent,
        DealOrderDropdownComponent,
        DealCardComponent,
        PhoneCardNavComponent,
        FilterDialogComponent,
        DesktopFilterComponent,
        SimCardComponent,
        DesktopDealListItemComponent,
        DesktopPhoneDealListItemComponent,
        BreadcrumbsComponent,
        LoadingComponent,
        PhoneSelectTagsComponent,
        CreditToggleComponent,
    ],
    providers: [DealFilterState, DealState],
    templateUrl: "./deal-list.component.html",
    styleUrl: "./deal-list.component.scss",
})
export class DealListComponent implements OnInit, OnDestroy {
    dealType!: DealType;
    dealTypePhoneSelectTags!: DealWithPhoneType;
    phoneModelSlug?: string;
    phoneTitle: string | undefined;
    isMobile = window.innerWidth < 1024;
    dealTypeId?: number;
    private paramsSubscription?: Subscription;
    private initialized = false;
    loading = signal(false);
    routeNames: typeof routeNames;

    constructor(
        private route: ActivatedRoute,
        private dealFilterState: DealFilterState,
        public dealState: DealState,
        private phoneService: PhonesService,
        private titleService: Title,
    ) {
        this.routeNames = routeNames;
    }

    ngOnInit() {
        this.route.queryParams
            .pipe(mergeWith(this.route.params))
            .subscribe(() => {
                if (this.initialized) {
                    this.startup(false);
                }
            });
        this.initialized = true;
        this.startup(true);
    }

    ngOnDestroy() {
        this.paramsSubscription?.unsubscribe();
    }

    startup(isFirstLoad: boolean) {
        this.loading.set(true);
        this.phoneModelSlug = this.route.snapshot.params["phone_model"];
        this.dealType = this.route.snapshot.data["dealType"] ?? DealType.bundle;

        if (this.dealType != "sim-only") {
            this.dealTypePhoneSelectTags = this.dealType;
        }

        this.dealState.apiDealTypes().subscribe({
            next: (res) => {
                this.initDeals(res, isFirstLoad);
            },
            error: () => {
                this.loading.set(false);
            },
        });

        // this is here because there is no "get phone by slug" endpoint
        this.phoneTitle = this.phoneModelSlug
            ?.replaceAll("-", " ")
            ?.split(" ")
            ?.map((word) => {
                if (word === "tcl") {
                    return word.toUpperCase();
                }
                if (word === "iphone") {
                    return "iPhone";
                }
                if (word === "se") {
                    return "SE";
                }
                return word[0].toUpperCase() + word.slice(1);
            })
            ?.join(" ");
    }


    get hasDeals() {
        return this.dealState.dealCount() === 0 &&
            this.dealState.status() !== HttpStatus.loading &&
            !this.loading();
    }

    get dealCountContent() {
        const count = this.dealState.dealCount();
        const resultPluralised = (count == 1) ? "result" : "results"
        return `Showing ${count} ${resultPluralised}`
    }

    get hasQueryParams() {
        return computed(() => {
            return (
                Object.entries(this.dealFilterState.selectedFilters).filter(([k, value]) => {
                    const key = k as keyof DealFilterParams;
                    return (
                        key !== "deal_type" &&
                        key !== "phone_model" &&
                        key !== "ordering" &&
                        key !== "phone_model_slug" &&
                        key !== "deal_credit_check" &&
                        key !== "device_pay_monthly" &&
                        key !== "phone_colour" &&
                        value !== undefined
                    );
                }).length > 0
            );
        });
    }

    async initDeals(apiDealTypes: DealTypeModel[], isFirstLoad: boolean) {
        let filters: Partial<DealFilterParams>;

        switch (this.dealType) {
            case "phone-only": {
                const model = await this.getPhoneModel();
                this.phoneTitle = model?.name;
                this.phoneService;
                const apiDealType = apiDealTypes.find(
                    (dealType) => dealType.formatted_name === "phones",
                );
                this.dealTypeId = apiDealType?.id;
                filters = dealFilterFromParams(this.route.snapshot.queryParams, {
                    phone_model_slug: this.phoneModelSlug,
                    deal_type: [apiDealType?.id?.toString() ?? "2"],
                    ordering: dealOrdering(this.dealType)[0].value,
                });
                break;
            }
            case "sim-only": {
                const apiDealType = apiDealTypes.find(
                    (dealType) => dealType.formatted_name === "sims",
                );
                this.dealTypeId = apiDealType?.id;
                filters = dealFilterFromParams(this.route.snapshot.queryParams, {
                    deal_type: [apiDealType?.id?.toString() ?? "1"],
                    ordering: dealOrdering(this.dealType)[0].value,
                });
                break;
            }
            case "bundle": {
                const model = await this.getPhoneModel();
                this.phoneTitle = model?.name;

                const apiDealType = apiDealTypes.find(
                    (dealType) => dealType.formatted_name === "bundles",
                );
                this.dealTypeId = apiDealType?.id;

                filters = dealFilterFromParams(this.route.snapshot.queryParams, {
                    phone_model_slug: this.phoneModelSlug,
                    deal_type: [apiDealType?.id?.toString() ?? "3"],
                    ordering: dealOrdering(this.dealType)[0].value,
                });
                break;
            }
            default:
                throw isNever(this.dealType);
        }

        this.dealFilterState.selectedFilters = filters;
        this.dealState.fetchDeals(filters, () => {
            this.loading.set(false);
            this.send_analytics_deal_count(isFirstLoad);
        });
    }

    private send_analytics_deal_count(isFirstLoad: boolean) {
        try {
            if (isFirstLoad) {
                gtag(
                    "event",
                    "deal_list__page_load",
                    {
                        deal_type: this.dealType,
                    }
                );
            }

            const filters = payloadForDealFilterParams(this.dealFilterState.selectedFilters);
            for (const key in keys_to_ignore) {
                if (filters[key]) {
                    delete filters[key];
                }
            }

            const count = this.dealState.dealCount()
            gtag(
                "event",
                "deal_list__deal_count",
                {
                    isFirstLoad,
                    ...filters,
                    deal_type: this.dealType,
                    deal_count: count
                }
            );

            if (count === 0) {
                gtag(
                    "event",
                    "deal_list__deal_count__empty",
                    {
                        isFirstLoad,
                        ...filters,
                        deal_type: this.dealType,
                        deal_count: count
                    }
                );
            }
        } catch { }
    }

    get colourOptions(): ColoursModel[] {
        return this.dealState.metadata?.phones?.colors || [];
    }

    get storageSizeOptions(): StorageSize[] {
        return this.dealState.metadata?.phones?.storage_sizes || [];
    }

    getPhoneModel() {
        return new Promise<PhoneModel | undefined>((resolve) => {
            if (this.phoneModelSlug) {
                this.phoneService.getPhoneModelBySlug(this.phoneModelSlug).subscribe((res) => {
                    resolve(res);
                });
            } else {
                resolve(undefined);
            }
        });
    }

    @HostListener("window:resize", ["$event"])
    onResize(event: Event) {
        const width = (event.target as Window).innerWidth;
        if (this.isMobile && width >= 1024) {
            this.isMobile = false;
        }
        if (!this.isMobile && width < 1024) {
            this.isMobile = true;
        }
    }

    get showFilters() {
        return this.dealType !== DealType.simOnly
    }

    get deals() {
        const unsortedDeals = this.dealState.deals();

        if (unsortedDeals.length === 0) {
            return unsortedDeals;
        }

        let sorted: DealModel[] = [];
        const orderingFilter = this.dealFilterState.selectedFilters.ordering;
        if (orderingFilter === "deal_pay_today_total_inc_vat" || orderingFilter === "-deal_pay_today_total_inc_vat") {
            let currentPayToday: number = unsortedDeals[0].deal_pay_today_total_inc_vat;
            let chunkedByTotalToday: DealModel[] = [];
            for (const deal of unsortedDeals) {
                if (!equalTo2DecimalPoints(deal.deal_pay_today_total_inc_vat, currentPayToday)) {
                    // Sort current chunk of deals with same total today by month and add the  to sorted
                    sorted = [...sorted, ...sortByMonthlyTotal(chunkedByTotalToday)];

                    // Reset to empty for next chunk
                    chunkedByTotalToday = [];

                    // Update the current pay today total value
                    currentPayToday = deal.deal_pay_today_total_inc_vat;
                }

                chunkedByTotalToday.push(deal)
            }

            // Add the last chunk of deals with same total
            sorted = [...sorted, ...sortByMonthlyTotal(chunkedByTotalToday)];

        } else {
            sorted = unsortedDeals;
        }

        return sorted;
    }

    get currentPageLink() {
        return this.route.snapshot.url;
    }

    get currentPageTitle() {
        switch (this.dealType) {
            case DealType.bundle:
                return `${this.phoneTitle} Deals`;
            case DealType.simOnly:
                return "Your SIM Only Deals";
            case DealType.phoneOnly:
                return `SIM free ${this.phoneTitle} Deals`;
            default:
                throw isNever(this.dealType);
        }
    }

    protected readonly faChevronRight = faChevronRight;
    protected readonly faClose = faClose;

    loadMore() {
        this.dealState.fetchNextPage();
    }

    protected readonly HttpStatus = HttpStatus;
    protected readonly Array = Array;
    get activeFilters(): { filter: Filter; selected: FilterOption }[] {
        const filters = this.dealFilterState.filters();
        const res: { filter: Filter; selected: FilterOption }[] = [];
        for (const [k, value] of Object.entries(this.dealFilterState.selectedFilters)) {
            const key = k as keyof DealFilterParams;

            if (key === "sim_unlimited_data") {
                const filter = filters.find(
                    (filter) => filter.key === "sim_data_allowance_gb_numeric",
                );

                if (!filter) {
                    continue;
                }
                res.push({
                    filter,
                    selected: {
                        label: "Unlimited data",
                        value: "-1",
                    },
                });
                continue;
            }
            if (key === "deal_type" || key === "phone_model" || value === undefined) {
                continue;
            }

            if (Array.isArray(value)) {
                value.forEach((v) => {
                    const filter = filters.find((filter) => filter.key === key);
                    const option = filter?.options.find(
                        (option) => option.value.toString() === v.toString(),
                    );
                    if (option === undefined || option === null) {
                        return;
                    }
                    res.push({ filter: filter!, selected: option });
                });
                continue;
            }
            const filter = filters.find((filter) => filter.key === key);
            const option = filter?.options.find(
                (option) => option.value.toString() === value.toString(),
            );
            if (option === undefined || option === null) {
                continue;
            }
            res.push({ filter: filter!, selected: option });
        }
        return res;
    }

    mobileSecondaryNavLocation(dt: DealType): string {
        switch (dt) {
            case "phone-only":
                return routeNames.simFreePhoneSelect.fullPath();
            case "sim-only":
                return routeNames.simOnlyDeals.list.fullPath();
            case "bundle":
                return routeNames.bundle.selectPhone.fullPath();
            default:
                throw isNever(dt);
        }
    }

    get breadcrumbs(): Breadcrumb[] {
        switch (this.dealType) {
            case "phone-only":
                return [
                    { label: "Compare SIM free phones", path: routeNames.simFreePhoneSelect.fullPath() },
                    { label: this.phoneTitle ?? this.phoneModelSlug ?? "SIM free deals" },
                ];
            case "sim-only":
                return [
                    { label: "Home", path: routeNames.home.fullPath() },
                    { label: "Compare SIM Only deals", path: routeNames.simOnlyDeals.list.fullPath() },
                ];
            case "bundle":
                return [
                    { label: "Compare phone contacts", path: routeNames.bundle.selectPhone.fullPath() },
                    { label: this.phoneTitle ?? this.phoneModelSlug ?? "Phone + SIM deals" },
                ];
            default:
                throw isNever(this.dealType);
        }
    }

    async clearFilters(dealTypeId: number) {
        const phoneModel = this.route.snapshot.params["phone_model"];
        const initialFilter: Partial<DealFilterParams> = {
            deal_type: [dealTypeId.toString()],
            ordering: this.dealFilterState.ordering().value,
        };
        if (phoneModel) {
            initialFilter.phone_model_slug = phoneModel;
        }

        this.dealFilterState.selectedFilters = dealFilterFromParams({}, initialFilter);

        await this.dealFilterState.applyFilters();
        return this.dealState.fetchDeals(this.dealFilterState.selectedFilters);
    }

    removeFilter(filter: { filter: Filter; selected: FilterOption }) {
        this.dealFilterState.removeFilter(filter);
    }

    protected readonly faTimes = faTimes;
    protected readonly DealType = DealType;
}

function equalTo2DecimalPoints(a: number, b: number): boolean {
    return Math.abs(a - b) < 0.01
}

function sortByMonthlyTotal(xs: DealModel[]): DealModel[] {
    return xs.sort(({ deal_monthly_total_inc_vat: a_monthly_total }, { deal_monthly_total_inc_vat: b_monthly_total }) => {
        if (equalTo2DecimalPoints(a_monthly_total, b_monthly_total)) {
            return 0;
        } else if (a_monthly_total < b_monthly_total) {
            return -1;
        } else {
            return 1;
        }
    });
}
