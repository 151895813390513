<div [ngClass]="padding">
    <button
        class="flex w-full items-center justify-between text-left font-semibold lg:text-xl"
        (click)="this.toggle()"
        aria-controls="{`accordion-text-01`}"
    >
        <span>{{ title }}</span>
        <fa-icon
            [ngClass]="{ '!rotate-180': open }"
            class="fill-indigo-500 ml-8 shrink-0 origin-center transform text-md transition duration-200 ease-out"
            [icon]="faChevronDown"
        ></fa-icon>
    </button>
    <div
        id="{`accordion-text-01`}"
        role="region"
        aria-labelledby="{`accordion-title-01`}"
        [ngClass]="open ? 'grid-rows-[1fr] pt-1 opacity-100' : 'grid-rows-[0fr] opacity-0'"
        class="grid overflow-hidden text-black text-opacity-60 transition-all duration-300 ease-in-out"
    >
        <div class="overflow-hidden text-black text-opacity-60">
            <ng-content></ng-content>
        </div>
    </div>
</div>
