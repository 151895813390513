<app-our-research-navbar></app-our-research-navbar>
<app-our-research-hero></app-our-research-hero>
<section class="bg-lime-gradient py-24">
    <div class="container">
        <p class="mb-3 text-center text-sm uppercase">our story</p>
        <h2 class="text-center text-4xl font-semibold"
            >Ever faced closed doors due to your credit score?</h2
        >
        <p class="mb-8 text-center text-3xl font-semibold">We know the feeling... </p>
        <div class="mx-auto flex flex-wrap justify-center gap-14 text-xl">
            <article class="max-w-[642px]">
                <p class="mb-4">
                    Our founders - Eva and Laura - were both rejected for a mobile phone contract in
                    their twenties.
                </p>
                <p class="mb-4">
                    This happens more often than you might expect. 5.8 million people in the UK are
                    estimated to be facing this same exclusion - experiencing rejections and often
                    paying premiums to access vital services (e.g. phone contracts, loans and
                    mortgages).
                </p>
                <p class="mb-4">
                    This is because credit checks often rely heavily on “credit history” data. So if
                    you’ve never borrowed money before - hello young people - you’ll likely also
                    have a “thin” (a.k.a empty) credit file.
                </p>
                <p class="mb-4">
                    Hungry for a solution, Noggin HQ was born. Our mission:
                    <strong>make access to credit equitable.</strong>
                </p>
            </article>
            <figure class="max-h-[469px]">
                <img
                    class="max-h-[469px] rounded-3xl border object-cover"
                    alt="Image of the founders of nogginhq"
                    ngSrc="/assets/images/founders.jpeg"
                    [height]="469"
                    [width]="476"
                />
            </figure>
        </div>
    </div>
</section>
<section class="pb-36 pt-12">
    <p class="mb-3 text-center text-sm uppercase">NOGGIN HQ</p>
    <h2 class="text-center text-4xl font-semibold uppercase">early investors</h2>
    <div class="mx-auto mt-14 flex flex-wrap items-center justify-center gap-9">
        <figure class="h-[161px] w-[161px] overflow-hidden rounded-full border">
            <img
                class="h-full w-full object-cover object-center"
                alt="BGV logo"
                ngSrc="/assets/images/bgv.png"
                height="225"
                width="225"
            />
        </figure>

        <figure
            class="h-[161px] w-[161px] overflow-hidden rounded-full border object-cover object-center"
        >
            <img
                class="h-full w-full object-cover object-center"
                alt="Oxford Capital logo"
                ngSrc="/assets/images/oxford-capital.jpeg"
                height="236"
                width="300"
            />
        </figure>

        <figure
            class="h-[161px] w-[161px] overflow-hidden rounded-full border object-cover object-center"
        >
            <img
                class="h-full w-full object-cover object-center"
                alt="Ada Ventures logo"
                ngSrc="/assets/images/ada-ventures.jpeg"
                height="189"
                width="267"
            />
        </figure>

        <figure
            class="h-[161px] w-[161px] overflow-hidden rounded-full border object-cover object-center"
        >
            <img
                class="h-full w-full object-cover object-center"
                alt="Syndicate Room logo"
                ngSrc="/assets/images/syndicate-room.jpeg"
                height="200"
                width="200"
            />
        </figure>

        <figure class="h-[161px] w-[161px] overflow-hidden rounded-full border">
            <img
                class="h-full w-full object-cover object-center"
                alt="Sheep logo"
                ngSrc="/assets/images/sheep.jpeg"
                height="598"
                width="599"
            />
        </figure>
    </div>
</section>
<app-footer></app-footer>
