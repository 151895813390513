import { Component, OnInit } from "@angular/core";
import { Store } from "@ngxs/store";
import { Router, RouterLink } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";
import { faChevronLeft, faClose } from "@fortawesome/free-solid-svg-icons";
import { CommonModule, Location } from "@angular/common";
import { UserModel } from "../../models/user";
import { UserService } from "../../services/user.service";
import { LogoutUserAction } from "../../state/user/user.actions";
import { routeNames } from "../../../app-routing.module";
import { AccountHeaderComponent } from "../../components/account-header/account-header.component";
import { ShimmerLoaderComponent } from "src/app/core/components/shimmer-loader/shimmer-loader.component";
import { MembershipStatusComponent } from "../../components/membership-status/membership-status.component";
import { FaIconComponent } from "@fortawesome/angular-fontawesome";

@Component({
    selector: "app-account",
    templateUrl: "./account.component.html",
    standalone: true,
    imports: [
        AccountHeaderComponent,
        ShimmerLoaderComponent,
        CommonModule,
        MembershipStatusComponent,
        RouterLink,
        FaIconComponent,
    ],
    styleUrls: ["./account.component.scss"],
})
export class AccountComponent implements OnInit {
    loading = false;
    error?: string;

    constructor(
        private store: Store,
        private router: Router,
        private location: Location,
        private userService: UserService,
        private _snackBar: MatSnackBar,
    ) {}
    ngOnInit() {
        this.userService.getUserInfo().subscribe();
    }

    get user() {
        return this.userService.currentUser();
    }

    back() {
        this.location.back();
    }

    logOut() {
        this.store.dispatch(new LogoutUserAction());
        return this.router.navigate(["/"]);
    }

    async deleteAccount() {
        this.loading = true;

        this.userService.deleteUser().subscribe({
            next: () => {
                this.loading = false;
                this.openSnackBar("User Deleted Successfully", "Success");
                return this.logOut();
            },
            error: (e) => {
                this.loading = false;
                console.error(e);
                this.openSnackBar(e?.error?.detail, "Error");
            },
        });

        this.closeModal();
        return this.logOut();
    }

    private openSnackBar(message: string, type: string): void {
        this._snackBar.open(type + ": " + message, "Dismiss", {
            panelClass: [type],
        });
    }

    protected readonly faChevronLeft = faChevronLeft;

    showDeleteConfirmModal() {
        const dialog = document.getElementById("delete-account-modal") as HTMLDialogElement;
        dialog.showModal();
    }
    closeModal() {
        const dialog = document.getElementById("delete-account-modal") as HTMLDialogElement;
        dialog.close();
    }

    protected readonly faClose = faClose;
    protected readonly routeNames = routeNames;
}
