<app-drawer>
    <app-navbar [dark]="false"></app-navbar>
    <section class="flex min-h-[900px] flex-1 flex-col items-center justify-center">
        <img
            alt="Smiley logo"
            height="80"
            class="mb-9 block"
            width="80"
            ngSrc="assets/svg/face-green.svg"
        />
        <h2 class="mb-9 text-3xl font-semibold">404</h2>
        <h1 class="mb-9 font-display text-5xl font-bold"
            >Uh Oh! We cannot find the page you are looking for.</h1
        >
        <a
            [routerLink]="routeNames.home.fullPath()"
            class="btn btn-secondary h-16 w-60 rounded-full text-3xl"
            >Go Home</a
        >
    </section>
    <app-footer></app-footer>
</app-drawer>
