<li class="flex items-center justify-center gap-1">
    <div class="dropdown flex-1">
        <div tabindex="0" role="button" class="py-1 text-black">
            <strong class="">Sort by:</strong>
            {{ dealFilterService.ordering().label }}
            <fa-icon class="ml-1" [icon]="faChevronDown"></fa-icon>
        </div>
        <ul
            tabindex="0"
            class="menu dropdown-content z-[1] w-52 rounded-box bg-base-100 p-2 shadow"
        >
            <li *ngFor="let item of dealOrdering(dealType)">
                <button (click)="setSortBy(item)">{{ item.label }}</button>
            </li>
        </ul>
    </div>
</li>
