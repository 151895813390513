import { Component, OnInit, signal } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { RouterLink } from '@angular/router';
import { MatSnackBar } from "@angular/material/snack-bar";
import { Store } from "@ngxs/store";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { CommonModule, Location } from "@angular/common";
import { delay, of, tap } from 'rxjs';
import { UserService } from "../../../user/services/user.service";
import { LoginUserAction } from "../../../user/state/user/user.actions";
import { routeNames } from "../../../app-routing.module";
import { ActivatedRoute } from "@angular/router"
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { PasswordFieldComponent } from "src/app/user/components/password-field/password-field.component";
import { NogginPhoneTileComponent } from "src/app/noggin-phone/noggin-phone-tile/noggin-phone-tile.component";
import { LoadingComponent } from "src/app/core/components/loading/loading.component";

@Component({
    selector: 'app-create-account',
    standalone: true,
    imports: [
        RouterLink,
        FontAwesomeModule,
        ReactiveFormsModule,
        PasswordFieldComponent,
        CommonModule,
        NogginPhoneTileComponent,
        LoadingComponent
    ],
    templateUrl: './create-account.component.html',
    styleUrl: './create-account.component.scss'
})
export class CreateAccountComponent implements OnInit {
    registerUserForm!: FormGroup;
    passwordLevel = 0;
    passwordLevelText = "";
    passwordVisible = signal(false);
    formSubmitting = signal(false);
    fromPage?: string;

    constructor(
        private fb: FormBuilder,
        private userService: UserService,
        private location: Location,
        private snackBar: MatSnackBar,
        private store: Store,
        private route: ActivatedRoute,
    ) {
        this.fromPage = this.route.snapshot.queryParams["from"];
    }

    ngOnInit(): void {
        gtag('event', 'create_account__page_load');
        this.registerUserForm = this.fb.group(
            {
                first_name: ["", Validators.required],
                last_name: ["", Validators.required],
                email: ["", [Validators.required, Validators.email]],
                terms_agree: [false, Validators.requiredTrue],
                email_opt_in: [false],
                password: [
                    "",
                    [
                        Validators.required,
                        Validators.pattern(
                            "^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\\W)(?!\\s).{8,}$",
                        ),
                    ],
                ],
            },
            {},
        );

        this.registerUserForm.get("password")?.valueChanges.subscribe((value) => {
            if (value.length >= 12 && !this.registerUserForm.get("password")?.errors) {
                this.passwordLevel = 3;
                this.passwordLevelText = "strong";
            } else if (value.length >= 8 && !this.registerUserForm.get("password")?.errors) {
                this.passwordLevel = 2;
                this.passwordLevelText = "Okay";
            } else if (value.length <= 8 && value.length > 0) {
                this.passwordLevel = 1;
                this.passwordLevelText = "Weak";
            }
        });
    }

    get showFirstNameErrorIsRequired() {
        return (
            this.registerUserForm.get('first_name')?.status === 'INVALID' &&
            this.registerUserForm.get('first_name')?.touched &&
            this.registerUserForm.get('first_name')?.errors?.['required']
        );
    }

    get showLastNameErrorIsRequired() {
        return (
            this.registerUserForm.get('last_name')?.status === 'INVALID' &&
            this.registerUserForm.get('last_name')?.touched &&
            this.registerUserForm.get('last_name')?.errors?.['required']
        );
    }

    get showEmailErrorIsRequired() {
        return (
            this.registerUserForm.get('email')?.status === 'INVALID' &&
            this.registerUserForm.get('email')?.touched &&
            this.registerUserForm.get('email')?.errors?.['required']
        );
    }

    get showEmailErrorPattern() {
        return (
            this.registerUserForm.get('email')?.status === 'INVALID' &&
            this.registerUserForm.get('email')?.touched &&
            !this.registerUserForm.get('email')?.errors?.['required'] &&
            this.registerUserForm.get('email')?.errors?.['email']
        )
    }

    getFormControl(name: string): FormControl {
        return this.registerUserForm.get(name) as FormControl;
    }

    async back(): Promise<void> {
        this.location.back();
    }

    togglePasswordVisibility(): void {
        this.passwordVisible.update((v) => !v);
    }

    submit(): void {
        this.formSubmitting.set(true);

        if (this.registerUserForm.invalid) {
            return;
        }

        const registerUserValues = {
            first_name: this.registerUserForm.get("first_name")?.value,
            full_name: this.registerUserForm.get("first_name")?.value + " " + this.registerUserForm.get("last_name")?.value,
            last_name: this.registerUserForm.get("last_name")?.value,
            email: this.registerUserForm.get("email")?.value,
            password: this.registerUserForm.get("password")?.value,
            email_opt_in: this.registerUserForm.get("email_opt_in")?.value ?? false,
        };

        this.userService.registerUser(registerUserValues)
            .pipe(
                tap(() => gtag('event', 'create_account__created')),
                delay(1000)
            )
            .subscribe({
                next: () =>
                    this.registerUserListener(
                        registerUserValues.email_opt_in,
                        registerUserValues.email,
                    ),
                error: (err): void => {
                    let error = err.error;
                    if (err.error.error) {
                        error = err.error.error;
                    } else if (err.error.detail) {
                        error = err.error.detail;
                    }
                    this.formSubmitting.set(false);
                    this.openSnackBar(error);
                },
            });
    }

    private registerUserListener(email_opt_in: boolean, email: string): void {
        this.store.dispatch(
            new LoginUserAction({
                email: this.registerUserForm.get("email")?.value,
                password: this.registerUserForm.get("password")?.value,
            }, routeNames.noCreditCheckPhones.fullPath()),
        )
            .subscribe({
                next: () => {
                    // the api doesn't allow updating this in the register call. Nebular have more things to concentrate on at
                    // the moment so we will just update the user after the register call
                    this.userService.updateUser({ email_opt_in, email }).subscribe();
                },
            });
    };

    private openSnackBar(errorDetail: string): void {
        this.snackBar.open("Error:", errorDetail, {
            duration: 5000,
        });
    }

    protected readonly faChevronLeft = faChevronLeft;
    protected readonly FormControl = FormControl;
    protected readonly routeNames = routeNames;
}
