import { Component } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatSnackBar } from "@angular/material/snack-bar";
import { BlogCardComponent } from "../blog-card/blog-card.component";
import { Article } from "../../models/article";
import { ArticlesService } from "../../services/articles.service";

@Component({
    selector: "home-blog-section",
    standalone: true,
    imports: [CommonModule, BlogCardComponent],
    templateUrl: "./blog-section.component.html",
    styleUrls: ["./blog-section.component.scss"],
})
export class BlogSectionComponent {
    articles: Article[] = [];

    constructor(
        private snackBar: MatSnackBar,
        private articleService: ArticlesService,
    ) {
        this.articleService.getAllArticles().subscribe({
            next: (r) => {
                this.articles = r;
            },
            error: (error) => {
                this.openSnackBar(error?.error?.detail, "Error");
            },
        });
    }

    private openSnackBar(message: string, type: string): void {
        this.snackBar.open(type + ": " + message, "Dismiss", {
            panelClass: [type],
        });
    }
}
