import { Component, Input } from "@angular/core";
import { CommonModule } from "@angular/common";

@Component({
    selector: "app-eye-open",
    standalone: true,
    imports: [CommonModule],
    templateUrl: "./eye-open.component.html",
    styleUrls: ["./eye-open.component.scss"],
})
export class EyeOpenComponent {
    @Input() color = "#0047FF";
    @Input() size = "24px";
    @Input("class") className = "";
}
