<div class="mb-3.5 h-20 grid grid-cols-3 flex rounded-3xl overflow-hidden border border-gray-300">
    <button
        class="uppercase px-2 py-2 text-sm font-medium border-r focus:outline-none focus:ring-2 focus:ring-inset transition-colors" 
        [ngClass]="{
            'bg-black text-white': activeState === 'noCreditCheck',
            'bg-white text-black': activeState !== 'noCreditCheck'
        }"
        (click)="setActiveState('noCreditCheck')"
    >
        No credit check
    </button>

    <button
        class="uppercase px-2 py-2 text-sm font-medium border-r focus:outline-none focus:ring-2 focus:ring-inset transition-colors"
        [ngClass]="{
            'bg-black text-white': activeState === 'allDeals',
            'bg-white text-black': activeState !== 'allDeals'
        }"
        (click)="setActiveState('allDeals')"
    >
        All deals
    </button>

    <button
        *ngIf="dealType !== DealType.simOnly"
        class="uppercase px-2 py-2 text-sm font-medium focus:outline-none focus:ring-2 focus:ring-inset transition-colors"
        [ngClass]="{
            'bg-black text-white': activeState === 'payMonthly',
            'bg-white text-black': activeState !== 'payMonthly'
        }"
        (click)="setActiveState('payMonthly')"
    >
        Pay monthly
    </button>

    <button
        *ngIf="dealType === DealType.simOnly"
        class="uppercase px-2 py-2 text-sm font-medium focus:outline-none focus:ring-2 focus:ring-inset transition-colors"
        [ngClass]="{
            'bg-black text-white': activeState === 'creditCheck',
            'bg-white text-black': activeState !== 'creditCheck'
        }"
        (click)="setActiveState('creditCheck')"
    >
        Credit check
    </button>
</div>

