
<div class="account-container">
    <button
        (click)="back()"
        class="bold mb-6 mr-auto flex items-center justify-center text-[14px] uppercase text-electric-blue h-8"
    >
        <fa-icon class="mr-2.5" [icon]="faChevronLeft"></fa-icon>
        Back
    </button>

    <section class="mb-4">
        <h1 class="text-3xl font-semibold">What is your address? 🏡</h1>

        <form (ngSubmit)="submit()" [formGroup]="form" class="mt-8">

            <div class="flex flex-col">
                <label for="residentStatus" class="mb-1.5 text-sm uppercase after:content-['*'] after:ml-0.5 after:text-red-500">Resident status</label>
                <select
                    formControlName="residentStatus"
                    id="residentStatus"
                    name="residentStatus"
                    type="text"
                    class="select rounded-xl border border-black"
                    [attr.readonly]="formSubmitting() ? 'readonly' : null"
                >
                    <option disabled>Select</option>
                    <option
                        *ngFor="let option of options"
                        [selected]="option[0] === this.form.get('residentStatus')?.value"
                        [value]="option[0]"
                    >
                        {{ option[1] }}
                    </option>
                </select>
            </div>

            <div class="mt-8 flex flex-col">
                <label for="addressLine1" class="mb-1.5 text-sm uppercase after:content-['*'] after:ml-0.5 after:text-red-500">Address Line 1</label>
                <input
                    formControlName="addressLine1"
                    id="addressLine1"
                    name="addressLine1"
                    type="text"
                    class="input rounded-xl border border-black p-4"
                    [attr.readonly]="formSubmitting() ? 'readonly' : null"
                    autocomplete="address-line1"
                />
                @switch(!!declarationLine1ErrorMessage) {
                    @case (true) {
                    <small class="text-error-red">{{ declarationLine1ErrorMessage }}</small>
                    }
                    @case (false) {
                        <small>&nbsp;</small>
                    }
                }
            </div>

            <div class="mt-2 flex flex-col">
                <label class="mb-1.5 text-sm uppercase" for="addressLine2">Address Line 2</label>
                <input
                    formControlName="addressLine2"
                    class="input rounded-xl border border-black p-4"
                    id="addressLine2"
                    name="addressLine2"
                    type="text"
                    inputmode="numeric"
                    autocomplete="address-line2"
                />
                @switch(!!declarationLine2ErrorMessage) {
                    @case (true) {
                    <small class="text-error-red">{{ declarationLine2ErrorMessage }}</small>
                    }
                    @case (false) {
                        <small>&nbsp;</small>
                    }
                }
            </div>

            <div class="mt-2 flex flex-col">
                <label class="mb-1.5 text-sm uppercase after:content-['*'] after:ml-0.5 after:text-red-500" for="addressTown">Town or City</label>
                <input
                    formControlName="addressTown"
                    class="input rounded-xl border border-black p-4"
                    id="addressTown"
                    name="addressTown"
                    type="text"
                    inputmode="numeric"
                    autocomplete="address-level2"
                />
                @switch(!!declarationTownErrorMessage) {
                    @case (true) {
                    <small class="text-error-red">{{ declarationTownErrorMessage }}</small>
                    }
                    @case (false) {
                        <small>&nbsp;</small>
                    }
                }
            </div>

            <div class="mt-2 flex flex-col">
                <label class="mb-1.5 text-sm uppercase after:content-['*'] after:ml-0.5 after:text-red-500" for="addressPostcode">Postcode</label>
                <input
                    formControlName="addressPostcode"
                    class="input rounded-xl border border-black p-4 max-w-32"
                    id="addressPostcode"
                    name="addressPostcode"
                    type="text"
                    inputmode="numeric"
                />
                @switch(!!declarationPostcodeErrorMessage) {
                    @case (true) {
                    <small class="text-error-red">{{ declarationPostcodeErrorMessage }}</small>
                    }
                    @case (false) {
                        <small>&nbsp;</small>
                    }
                }
            </div>

            <div class="mt-4 mb-8 h-14 w-full">
                <ng-container *ngIf="formSubmitting(); else content">
                    <app-loading class="block"></app-loading>
                </ng-container>
                <ng-template #content>
                    <button
                        class="btn mx-auto w-full block self-stretch rounded-full bg-electric-blue text-center uppercase text-white"
                        name="Submit"
                        type="submit"
                    >
                        Create account
                    </button>
                </ng-template>
            </div>
        </form>
    </section>
</div>

<section class="flex my-8 mx-auto max-w-lg" nomargin>
    <app-noggin-phone-tile
        caption="Why are we doing this?"
        heading="5.8 million people in the UK are excluded from credit!"
        text="We are dedicated to bringing a solution for people unfairly locked out of the current system."
    ></app-noggin-phone-tile>
</section>
