<ng-container *ngIf="dealDetailsState.status() === HttpStatus.loading; else page">
    <app-loading></app-loading>
</ng-container>
<ng-template #page>
    <section class="container max-w-screen-lg p-5">
        <app-breadcrumbs [breadcrumbs]="breadcrumbs"></app-breadcrumbs>
        <app-tags-row class="mt-6 block" *ngIf="deal" [deal]="deal"></app-tags-row>
        <h1 class="mb-5 mt-3 border-b border-black border-opacity-20 pb-7 text-3xl font-semibold"
            >Your {{ deal?.deal_retailer_name }} SIM</h1
        >
        <div class="mb-6 flex items-baseline justify-start">
            <h3 class="text-3.5xl font-medium"
                >£{{ parseFloat(deal?.sim_monthly_cost_inc_vat?.toString() ?? "0").toFixed(2) }}</h3
            ><p class="ml-2.5"> per month</p>
        </div>
        <app-squiggly-background class="mb-6 block w-full" *ngIf="deal?.deal_is_vip">
            <a
                [href]="deal?.members_benefit_link"
                class="mx-4 flex h-[114px] items-center justify-between bg-black px-4 text-white"
            >
                <h4>{{ deal?.members_benefit_text }}</h4>
                <img
                    alt="Member benefit image"
                    width="50"
                    height="50"
                    *ngIf="deal?.members_benefit_image"
                    [ngSrc]="deal!.members_benefit_image"
                />
            </a>
        </app-squiggly-background>
        <div *ngIf="deal?.deal_price_rises" class="my-6">
            <p class="flex items-center"
                ><fa-icon [icon]="faInfoCircle" class="mr-2"></fa-icon
                >{{ deal?.deal_price_rises }}</p
            >
        </div>
        <app-get-deal-btn *ngIf="deal" [deal]="deal"></app-get-deal-btn>
        <section class="mb-12 grid grid-cols-2 gap-3">
            <deals-detail-item
                label="contract length"
                *ngIf="deal?.sim_contract_length_friendly"
                [value]="deal?.sim_contract_length_friendly"
            ></deals-detail-item>

            <deals-detail-item label="credit check?" [value]="this.creditCheck"></deals-detail-item>

            <deals-detail-item
                label="data allowance"
                *ngIf="
                    deal?.sim_data_allowance_gb_numeric !== undefined || deal?.sim_unlimited_data
                "
                [value]="
                    deal?.sim_unlimited_data
                        ? 'Unlimited'
                        : deal?.sim_data_allowance_gb_numeric + 'GB'
                "
            ></deals-detail-item>

            <deals-detail-item
                label="connectivity"
                *ngIf="deal?.deal_connectivity"
                [value]="deal?.deal_connectivity"
            ></deals-detail-item>

            <deals-detail-item
                *ngIf="deal?.sim_text_allowance_numeric || deal?.sim_unlimited_texts"
                label="texts"
                [value]="deal?.sim_text_allowance_numeric?.toString() ?? 'Unlimited'"
            ></deals-detail-item>
            <deals-detail-item
                *ngIf="deal?.sim_minute_allowance_numeric || deal?.sim_unlimited_minutes"
                label="minutes"
                [value]="deal?.sim_minute_allowance_numeric?.toString() ?? 'Unlimited'"
            ></deals-detail-item>
        </section>
        <section class="mb-12" *ngIf="deal?.sim_details_free_text">
            <h3 class="mb-4 text-2xl font-semibold">Sim details</h3>
            <div class="prose mt-5 max-w-none" [innerHTML]="deal?.sim_details_free_text"></div>
        </section>
        <deals-perks *ngIf="dealPerks" [perks]="dealPerks"></deals-perks>
        <section class="mt-12" *ngIf="deal?.network_provider?.company_ethos">
            <h3 class="text-2xl font-semibold">{{ deal?.network_provider?.name }}'s ethos</h3>
            <div
                class="prose mt-5 max-w-none"
                [innerHTML]="deal?.network_provider?.company_ethos"
            ></div>
        </section>

        <section class="mt-12" *ngIf="deal?.our_thoughts">
            <h3 class="text-2xl font-semibold"
                >Why Noggin loves {{ deal?.network_provider?.name }}</h3
            >
            <p class="text-black text-opacity-60">{{ deal?.our_thoughts }}</p>
        </section>
        <app-deal-disclaimer class="mt-10 block"></app-deal-disclaimer>
    </section>
    <home-disclaimer-graphic class="my-10 block px-5"></home-disclaimer-graphic>
</ng-template>
