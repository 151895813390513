<article class="container mb-12">
    <h1 class="mx-auto mb-10 mt-24 max-w-[884px] text-4xl font-semibold"
        ><ng-container *ngIf="contactType === ContactType.contact; else complaints"
            >Contact Us</ng-container
        ><ng-template #complaints>Complaints</ng-template></h1
    >
    <form [formGroup]="contactForm" class="mx-auto flex max-w-[884px] flex-col items-center pb-10">
        <fieldset class="flex flex-col gap-5 self-stretch">
            <div class="flex flex-col">
                <label for="name" class="mb-1.5 text-sm uppercase">Full name</label>
                <input
                    formControlName="name"
                    id="name"
                    type="text"
                    class="input rounded-xl border border-black p-4"
                />
            </div>

            <div class="mt-4 flex flex-col">
                <label for="email" class="mb-1.5 text-sm uppercase">email</label>
                <input
                    formControlName="email"
                    id="email"
                    type="email"
                    class="input rounded-xl border border-black p-4"
                />
            </div>
            <small
                *ngIf="
                    contactForm.get('email')?.status === 'INVALID' &&
                    contactForm.get('email')?.touched &&
                    !contactForm.get('email')?.errors?.['required'] &&
                    contactForm.get('email')?.errors?.['email']
                "
                class="text-error-red"
                >Please enter valid email address, eg: example&#64;domain.com</small
            >
            <div class="mt-4 flex flex-col">
                <label for="query" class="mb-1.5 text-sm uppercase">Your Query</label>
                <textarea
                    id="query"
                    formControlName="message"
                    rows="5"
                    class="textarea rounded-xl border border-black p-4"
                ></textarea>
            </div>
        </fieldset>
        <button
            (click)="!loading() && submit()"
            [disabled]="contactForm.invalid"
            class="btn btn-outline btn-primary mx-auto mt-10 h-14 w-[280px] rounded-full"
            name="Submit Contact Form"
        >
            <ng-container *ngIf="!loading(); else loadingSpinner">Submit Query</ng-container>
            <ng-template #loadingSpinner>
                <span class="loading loading-dots loading-lg"></span>
            </ng-template>
        </button>
    </form>
    <app-contact-call-us></app-contact-call-us>
    <app-success (continue)="handleContinue()"></app-success>
</article>
