import { Component, Input } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterLink } from "@angular/router";

@Component({
    selector: "home-cta-card-external",
    standalone: true,
    imports: [CommonModule, RouterLink],
    templateUrl: "./cta-card.component.html",
    styleUrls: ["./cta-card.component.scss"],
})
export class CtaCardComponent {
    @Input() isRouterLink = false;
    @Input({ required: true }) href!: string;
    @Input({ required: true }) title!: string;
    @Input({ required: true }) background!: string;

    get additionalClasses() {
        return this.background;
    }
}
