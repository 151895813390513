import { Component, Input, OnInit } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { NgClass, NgForOf, NgIf } from "@angular/common";
import { RouterLink } from "@angular/router";
import { routeNames } from "../../../app-routing.module";
import { FaqItem, WebsiteCmsService } from "../../services/website-cms.service";

@Component({
    standalone: true,
    selector: "app-faq",
    templateUrl: "./faq.component.html",
    styleUrls: ["./faq.component.scss"],
    imports: [NgIf, NgForOf, NgClass, RouterLink],
})
export class FaqComponent implements OnInit {
    @Input() limit?: number;
    @Input() faqItems?: FaqItem[];
    totalItems = 0;
    openItems: number[] = [];
    @Input() headerClass = "text-3xl lg:text-5xl";
    @Input() questionClass = "";
    @Input("class") className = "";
    @Input() filterByTag?: string;

    constructor(
        private cmsService: WebsiteCmsService,
        private _snackBar: MatSnackBar,
    ) { }

    ngOnInit(): void {
        if (!this.faqItems) {
            this.cmsService.getFaqContent().subscribe({
                next: (faqList) => {
                    this.totalItems = faqList.length;
                    if (this.limit) {
                        faqList = faqList.slice(0, this.limit);
                    }

                    const filterByTag = this.filterByTag;
                    if (filterByTag) {
                        faqList = faqList.filter((item) => (item.tags === filterByTag));
                    }

                    this.faqItems = faqList;
                },
                error: (error) => {
                    this.openSnackBar(error?.error?.detail, "Error");
                },
            });
        }
    }

    toggle(id: number) {
        if (this.openItems.includes(id)) {
            this.openItems = this.openItems.filter((item) => item != id);
        } else {
            this.openItems.push(id);
        }
    }

    private openSnackBar(message: string, type: string): void {
        this._snackBar.open(type + ": " + message, "Dismiss", {
            panelClass: [type],
        });
    }

    protected readonly routeNames = routeNames;
}
