import { Component, OnInit, signal } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { UserService } from "../../../user/services/user.service";
import { ActivatedRoute, Router } from "@angular/router";
import { ContactType } from "../../models/contact-type";
import { SuccessComponent } from "../../components/success/success.component";
import { routeNames } from "../../../app-routing.module";

@Component({
    selector: "app-contact",
    templateUrl: "./contact.component.html",
    styleUrls: ["./contact.component.scss"],
})
export class ContactComponent implements OnInit {
    contactForm!: FormGroup;
    loading = signal(false);
    success: string | null = null;
    contactType!: ContactType;
    testOpen = false;

    testing() {
        this.testOpen = !this.testOpen;
    }

    constructor(
        private userService: UserService,
        private fb: FormBuilder,
        private _snackBar: MatSnackBar,
        private route: ActivatedRoute,
        private router: Router,
    ) {
        this.contactType = this.route.snapshot.data["type"];
    }

    ngOnInit(): void {
        this.contactForm = this.fb.group({
            email: ["", [Validators.email, Validators.required]],
            message: ["", [Validators.required]],
            name: ["", [Validators.required]],
        });
    }

    submit(): void {
        this.loading.set(true);
        if (this.contactType === ContactType.complaints) {
            this.userService.contactUs(this.contactForm.value, true).subscribe({
                next: () => {
                    this.success = "Message sent successfully!";
                    this.openSuccessModal();
                },
                error: () => {
                    this.openSnackBar("Error sending message");
                },
                complete: () => {
                    this.loading.set(false);
                },
            });
        } else {
            this.userService.contactUs(this.contactForm.value).subscribe({
                next: () => {
                    this.openSuccessModal();
                },
                error: () => {
                    this.openSnackBar("Error sending message");
                },
                complete: () => {
                    this.loading.set(false);
                },
            });
        }
    }

    openSuccessModal(): void {
        const modal = document.getElementById(SuccessComponent.id) as HTMLDialogElement;
        modal.showModal();
    }

    closeSuccessModal(): void {
        const modal = document.getElementById(SuccessComponent.id) as HTMLDialogElement;
        modal.close();
    }
    handleContinue() {
        this.closeSuccessModal();
        return this.router.navigate([routeNames.compare.mobile.fullPath()]);
    }

    private openSnackBar(message: string): void {
        this._snackBar.open("Status:", message, {
            duration: 5000,
        });
    }

    protected readonly ContactType = ContactType;
}
