<!-- <app-cookies></app-cookies> -->
<router-outlet></router-outlet>
<app-alert direction="bottom" [open]="bankingPopupOpen()">
    <div class="flex min-w-[50%] flex-1 items-center">
        <fa-icon class="text-xl" [icon]="faInfoCircle"></fa-icon>
        <p class="ml-4">
            Your open banking is not connected or has been automatically disconnected. Please
            reconnect to continue getting awesome vip deals.
        </p>
    </div>
    <div class="flex justify-end gap-3">
        <button (click)="closeBankingPopup()" class="btn btn-secondary"> Dismiss </button>
    </div>
</app-alert>
<app-notify></app-notify>
