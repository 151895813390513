import { DOCUMENT, PlatformLocation } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

const UTM_KEYS = {
    UTM_MEDIUM: "utm_medium",
    UTM_SOURCE: "utm_source",
    UTM_CAMPAIGN: "utm_campaign",
    UTM_TERM: "utm_term",
    CLICKID: "clickid"
} as const;

export type UtmKey = typeof UTM_KEYS[keyof typeof UTM_KEYS]

export const UtmKeys = Object.values(UTM_KEYS);

export function isUtmKey(other: string): boolean {
    return (UtmKeys as string[]).includes(other);
}

function startsWithUtmKey(target: string): boolean {
    return (UtmKeys as string[]).some(utmKey => target.startsWith(utmKey));
}

@Injectable({
    providedIn: 'root',
})
export class UtmHelper {
    constructor(
        private platformLocation: PlatformLocation,
        @Inject(DOCUMENT) private documentRef: Document
    ) { }

    get selectedSearchParams(): URLSearchParams | undefined {
        const currentQueryParameters = this.platformLocation?.search;

        if (!currentQueryParameters) {
            return;
        }

        const currentURLSearchParams = new URLSearchParams(currentQueryParameters);
        Array
            .from(currentURLSearchParams.keys())
            .forEach(paramKey => {
                if (!isUtmKey(paramKey)) {
                    currentURLSearchParams.delete(paramKey)
                }
            })

        return currentURLSearchParams;
    }

    get urlValues(): { key: string, value: string }[] | undefined {
        const currentQueryParameters = this.platformLocation?.search;

        if (!currentQueryParameters) {
            return;
        }

        const currentURLSearchParams = new URLSearchParams(currentQueryParameters);

        return [...currentURLSearchParams.entries()]
            .filter(([k, _]) => isUtmKey(k))
            .map(([key, value]) => ({ key, value }))
    }

    get cookieValues(): { key: string, value: string }[] | undefined {
        const cookies = this.documentRef.cookie

        if (!cookies) return;

        return cookies
            .split("; ")
            .map(r => r.trim())
            .filter(cookie => startsWithUtmKey(cookie))
            .map(cookie => {
                const [key, value] = cookie.split("=");
                return { key, value }
            });
    }

    get values(): { key: string, value: string }[] | undefined {
        return this.urlValues || this.cookieValues;
    }
}
