import { Component, HostBinding, Input } from "@angular/core";
import { NgClass, NgIf } from "@angular/common";

@Component({
    selector: "app-shimmer-loader",
    standalone: true,
    imports: [NgIf, NgClass],
    templateUrl: "./shimmer-loader.component.html",
    styleUrl: "./shimmer-loader.component.scss",
})
export class ShimmerLoaderComponent {
    @Input("class") @HostBinding("class") className = "";
    @HostBinding("class.block") classBlock = true;
    @Input() loadingClass = "h-4 w-full";
    @Input() loading = false;
}
