import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, RouterLink } from "@angular/router";
import { DomSanitizer, Meta, Title } from "@angular/platform-browser";
import { MatSnackBar } from "@angular/material/snack-bar";
import { DateTime } from "luxon";
import { ArticlesService } from "../../services/articles.service";
import { Article } from "../../models/article";
import { NgForOf, NgIf, NgOptimizedImage } from "@angular/common";
import { LoadingComponent } from "../../../core/components/loading/loading.component";
import { routeNames } from "../../../app-routing.module";

@Component({
    selector: "app-article-view",
    standalone: true,
    templateUrl: "./article-view.component.html",
    styleUrls: ["./article-view.component.scss"],
    encapsulation: ViewEncapsulation.None,
    imports: [RouterLink, NgOptimizedImage, NgIf, NgForOf, LoadingComponent],
})
export class ArticleViewComponent implements OnInit {
    loading: boolean = false;
    article?: Article;
    date?: string;
    windowWidth = 0;

    constructor(
        private articleService: ArticlesService,
        private route: ActivatedRoute,
        private titleService: Title,
        private metaService: Meta,
        private snackBar: MatSnackBar,
        private sanitizer: DomSanitizer,
    ) {}

    ngOnInit(): void {
        this.getArticle();
        this.windowWidth = window.innerWidth;
    }

    getArticle(): void {
        this.loading = true;
        this.route.params.subscribe(() => {
            const id =
                this.route.snapshot.params["id"] || Object.values(this.route.snapshot.params)[0];
            this.articleService.getArticle(id).subscribe({
                next: (res): void => {
                    this.article = res;
                    if (this.article?.text) {
                        this.article.text = <string>(
                            this.sanitizer.bypassSecurityTrustHtml(this.article.text)
                        );
                    }
                    this.date = DateTime.fromISO(this.article.created_at).toFormat("dd LLL yyyy");
                    this.setMetadata(this.article);
                },
                error: (err) => {
                    this.openSnackBar(err.error.detail);
                },
                complete: () => {
                    this.loading = false;
                },
            });
        });
    }

    setMetadata(article: Article): void {
        if (article.meta_title) {
            this.titleService.setTitle("Noggin | " + article.meta_title);
            this.metaService.updateTag({
                name: "title",
                content: article.meta_title,
            });
            this.metaService.updateTag({
                name: "twitter:title",
                content: article.meta_title,
            });
            this.metaService.updateTag({
                property: "og:title",
                content: article.meta_title,
            });
        } else if (article.page_title) {
            this.titleService.setTitle("Noggin | " + article.page_title);
            this.metaService.updateTag({
                name: "title",
                content: article.page_title,
            });
            this.metaService.updateTag({
                name: "twitter:title",
                content: article.page_title,
            });
            this.metaService.updateTag({
                property: "og:title",
                content: article.page_title,
            });
        } else if (article.title) {
            this.titleService.setTitle("Noggin | " + article.title);
            this.metaService.updateTag({
                name: "title",
                content: article.title,
            });
            this.metaService.updateTag({
                name: "twitter:title",
                content: article.title,
            });
            this.metaService.updateTag({
                property: "og:title",
                content: article.title,
            });
        } else {
            this.titleService.setTitle("Noggin | See Our Best SIM & Mobile Deals for You");
            this.metaService.updateTag({
                name: "title",
                content: "Noggin | See Our Best SIM & Mobile Deals for You",
            });
            this.metaService.updateTag({
                name: "twitter:title",
                content: "Noggin | See Our Best SIM & Mobile Deals for You",
            });
            this.metaService.updateTag({
                property: "og:title",
                content: "Noggin | See Our Best SIM & Mobile Deals for You",
            });
        }

        if (article.author?.full_name) {
            this.metaService.updateTag({
                property: "article:author",
                content: article.author.full_name,
            });
        }
        this.metaService.updateTag({ property: "article:section", content: "Technology" });

        this.metaService.updateTag({
            property: "article:published_time",
            content: new Date(article.created_at).toISOString(),
        });

        if (article.cover_image) {
            this.metaService.updateTag({ property: "og:image", content: article.cover_image });
            this.metaService.updateTag({ name: "twitter:image:src", content: article.cover_image });
        }
        if (article.meta_description) {
            this.metaService.updateTag({ name: "description", content: article.meta_description });
            this.metaService.updateTag({
                property: "og:description",
                content: article.meta_description,
            });
            this.metaService.updateTag({
                name: "twitter:description",
                content: article.meta_description,
            });
            this.metaService.updateTag({
                name: "msapplication-tooltip",
                content: article.meta_description,
            });
        }
        if (article.meta_keywords) {
            this.metaService.updateTag({ name: "keywords", content: article.meta_keywords });
        }
        if (article.tags) {
            for (const tag of article.tags) {
                this.metaService.addTag({ property: "article:tag", content: tag.name });
            }
        }
    }

    private openSnackBar(errorDetail: string): void {
        this.snackBar.open("Error:", errorDetail, {
            duration: 5000,
        });
    }

    protected readonly routeNames = routeNames;
}
