import { CommonModule, Location } from '@angular/common';
import { Component, OnInit, signal } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { routeNames } from 'src/app/app-routing.module';
import { LoadingComponent } from 'src/app/core/components/loading/loading.component';
import { NogginPhoneTileComponent } from 'src/app/noggin-phone/noggin-phone-tile/noggin-phone-tile.component';
import { ProductApplicationService } from 'src/app/user/services/product-application.service';


@Component({
    selector: 'app-user-financial-self-declarations',
    standalone: true,
    imports: [
        ReactiveFormsModule,
        CommonModule,
        NogginPhoneTileComponent,
        LoadingComponent,
        FaIconComponent
    ],
    templateUrl: './user-financial-self-declarations.component.html',
    styleUrl: './user-financial-self-declarations.component.scss'
})
export class UserFinancialSelfDeclarationsComponent implements OnInit {
    form!: FormGroup;
    formSubmitting = signal(false);
    options = [
        ["FULL_TIME", "Full Time"],
        ["PART_TIME", "Part Time"],
        ["STUDENT", "Student"],
        ["CONTRACT", "Contract"],
        ["FREELANCE", "Freelance"],
        ["UNEMPLOYED", "Unemployed"],
        ["NOT_SEEKING", "Not Seeking"],
        ["UNABLE_TO_WORK", "Unable to Work"],
        ["RETIRED", "Retired"],
        ["SELF_EMPLOYED", "Self Employed"],
        ["MULTIPLE_JOBS", "Multiple Jobs"],
        ["ON_LEAVE", "On Leave"],
        ["MILITARY", "Military"],
        ["INTERN", "Intern"]
    ]

    constructor(
        private formBuilder: FormBuilder,
        private location: Location,
        private service: ProductApplicationService,
        private router: Router,
    ) { }

    ngOnInit(): void {
        const VALIDATION_REGEX = `^(${this.options.map(x => x[0]).join("|")})$`;
        this.form = this.formBuilder.group(
            {
                declaration_bankruptcy: ["", [
                    Validators.required,
                    Validators.pattern("^[01]{1}$")
                ]],
                declaration_insolvency: ["", [
                    Validators.required,
                    Validators.pattern("^[01]{1}$")
                ]],
                declaration_employment_status: ["", [
                    Validators.required,
                    Validators.pattern(`^(${VALIDATION_REGEX})$`)
                ]],
                declaration_annual_income: ["", [
                    Validators.required,
                    Validators.pattern("^[1-9][0-9]*$")
                ]],
            },
            {}
        );
    }

    async back() {
        this.location.back();
    }

    submit() {
        if (this.form.invalid) {
            this.form.markAllAsTouched();
            return;
        }

        if (this.formSubmitting()) {
            return;
        }

        const data = {
            has_bankruptcy_declaration: this.form.get("declaration_bankruptcy")?.value,
            has_declared_insolvency: this.form.get("declaration_insolvency")?.value,
            current_employment_status: this.form.get("declaration_employment_status")?.value,
            current_annual_income: this.form.get("declaration_annual_income")?.value,
        };

        this.service
            .saveUserFinancialSelfDeclarations(data)
            .subscribe({
                next: () => {
                    this.router.navigateByUrl(routeNames.getStarted.userAddress.fullPath());
                }
            });
    }

    get declarationBankruptcyErrorMessage(): string | undefined {
        if (
            this.form.get("declaration_bankruptcy")?.touched &&
            this.form.get("declaration_bankruptcy")?.errors) {
            return "Select and option";
        }
        return;
    }

    get declarationInsolvencyErrorMessage(): string | undefined {
        if (
            this.form.get("declaration_insolvency")?.touched &&
            this.form.get("declaration_insolvency")?.errors) {
            return "Select and option";
        }
        return;
    }

    get declarationEmploymentStatusErrorMessage(): string | undefined {
        if (
            this.form.get("declaration_employment_status")?.touched &&
            this.form.get("declaration_employment_status")?.errors) {
            return "Select and option";
        }
        return;
    }

    get declarationAnnualIncomeErrorMessage(): string | undefined {
        if (
            this.form.get("declaration_annual_income")?.touched &&
            this.form.get("declaration_annual_income")?.errors) {
            return "Select and option";
        }
        return;
    }

    protected readonly faChevronLeft = faChevronLeft;
}
