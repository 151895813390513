/**
 * A function that takes an object of type never. This allows us to
 * that we have handled all cases for an object. Intented for use in
 * switch statement.
 *
 * @example
 * Here's an example with a switch statement where we match all cases:
 * ```
 * const foo: "a" | "b" = ...
 *
 * switch(foo) {
 *    case "a": break;
 *    case "b": break;
 *    default: throw isNever(foo);
 * }
 * // This compiles
 * ```
 * @example
 * Here's an example with a switch statement where we don't match all cases:
 * ```
 * const foo: "a" | "b" = ...
 *
 * switch(foo) {
 *    case "a": break;
 *    default: throw isNever(foo);
 * }
 * // This fails to compile becuase we didn't handle the "b" case.
 * ```
 */
export function isNever(_: never): Error {
    return new Error("isNever was called. This is likely because a switch is non-exhaustive or becuase there is a missing 'if' statement before isNever");
}
