import { inject, Injectable, signal } from "@angular/core";
import { DealModel } from "../models/deal";
import { DealService } from "../services/deal.service";
import { HttpStatus } from "../../core/models/http-status";
import { Router } from "@angular/router";
import { routeNames } from "../../app-routing.module";

@Injectable()
export class DealDetailsState {
    dealService = inject(DealService);
    router = inject(Router);
    deal = signal<DealModel | undefined>(undefined);
    error = signal<string | undefined>(undefined);
    status = signal<HttpStatus>(HttpStatus.initial);

    fetchDeal(id: number) {
        this.status.set(HttpStatus.loading);
        this.dealService.getSelectedDeal(id).subscribe({
            next: (value) => {
                this.deal.set(value);
                this.status.set(HttpStatus.success);
            },
            error: (err) => {
                if (err.status === 404) {
                    this.router.navigate([routeNames.notFound.fullPath()]);
                } else {
                    this.error.set("Something went wrong");
                    this.status.set(HttpStatus.error);
                }
            },
        });
    }
}
