<main class="relative flex flex-col h-full">
    <div>
        <header class="flex h-[72px] items-center my-auto w-full ml-5 lg:mx-16">
            <a id="header-logo" class="h-[27px]" [routerLink]="routeNames.noCreditCheckPhones.fullPath()">
                <app-logo-full></app-logo-full>
            </a>
        </header>

        <div class="p-5">
            <router-outlet></router-outlet>
        </div>
    </div>
</main>
