import { Component } from "@angular/core";

@Component({
    selector: "app-squiggly-background",
    standalone: true,
    imports: [],
    templateUrl: "./squiggly-background.component.html",
    styleUrl: "./squiggly-background.component.scss",
})
export class SquigglyBackgroundComponent {}
