import { Component, OnInit, signal } from "@angular/core";
import { ActivatedRoute, Router, RouterLink } from "@angular/router";
import { routeNames } from "../../../app-routing.module";
import { NgClass, NgForOf, NgIf, NgStyle } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { PhoneModel } from "../../models/phone";
import {
    Breadcrumb,
    BreadcrumbsComponent,
} from "../../../core/components/breadcrumbs/breadcrumbs.component";
import { DrawerComponent } from "../../../core/components/drawer/drawer.component";
import { NavbarComponent } from "../../../core/components/navbar/navbar.component";
import { PhonesService } from "../../services/phones.service";
import type { DealType } from "../../models/filter";
import { isNever } from "src/app/util/exhaustiveCheck";
import { InputFieldDetails } from "src/app/core/models/InputFieldDetails";

export type WizardDealTypes = Exclude<DealType, "sim-only">

type MobileForm = {
    manufacturerId?: string;
    modelId?: string;
};

@Component({
    selector: "app-mobile-selection",
    standalone: true,
    imports: [
        BreadcrumbsComponent,
        NgIf,
        DrawerComponent,
        NavbarComponent,
        NgForOf,
        FormsModule,
        NgStyle,
        RouterLink,
        NgClass,
    ],
    templateUrl: "./mobile-selection.component.html",
    styleUrl: "./mobile-selection.component.scss",
})
export class MobileSelectionComponent implements OnInit {
    dealType!: WizardDealTypes;
    routes = routeNames;
    phoneModelError: string | undefined;
    manufacturerError: string | undefined;
    loading = signal(false);
    manufacturers = signal<InputFieldDetails[]>([]);
    models = signal<PhoneModel[] | null>(null);
    form: MobileForm = {};

    constructor(
        private route: ActivatedRoute,
        private phoneService: PhonesService,
        private router: Router,
    ) { }

    ngOnInit() {
        this.loading.set(true);
        this.dealType = this.route.snapshot.data["dealType"] ?? "bundle";
        this.phoneService.getManufacturers(this.dealType).subscribe({
            next: (manufacturers) => {
                this.manufacturers.set(
                    manufacturers.map((m) => ({ label: m.name, value: m.id.toString() })),
                );
            },
            error: (err) => {
                console.error(err);
            },
            complete: () => {
                this.loading.set(false);
            },
        });

        gtag(
            "event",
            "wizard__page_load",
            {
                deal_type: this.dealType
            }
        )

        gtag("event", `wizard__page_load__${this.dealType}`)
    }

    get h1(): string {
        switch (this.dealType) {
            case "bundle":
                return "To find a Phone deal, choose your phone 🥰"

            case "phone-only":
                return "To find a SIM-free deal, choose your phone 🥰"

            default:
                throw isNever(this.dealType);
        }
    }

    get breadcrumbs(): Breadcrumb[] {
        switch (this.dealType) {
            case "bundle":
                return [
                    { label: "Home", path: this.routes.home.fullPath() },
                    { label: "Compare phone contracts", path: this.routes.bundle.selectPhone.fullPath() },
                ];
            case "phone-only":
                return [
                    { label: "Home", path: this.routes.home.fullPath() },
                    { label: "Compare SIM-free phones", path: this.routes.simFreePhoneSelect.fullPath() },
                ];
            default:
                throw isNever(this.dealType);
        }
    }

    get selectedModel(): PhoneModel | undefined {
        return this.models()?.find(
            (m) => (m.slug?.toString() ?? m.id.toString()) === this.form.modelId,
        );
    }

    onManufacturerChange(event: Event) {
        const target = event.target as HTMLSelectElement | null;
        const manufacturerId = target?.value;
        if (!manufacturerId) return;
        this.form = { ...this.form, manufacturerId, modelId: undefined };
        this.manufacturerError = undefined;
        this.getPhoneModelOptions(parseInt(manufacturerId));
    }

    onModelChange(value: Event) {
        const target = value.target as HTMLSelectElement | null;
        this.form.modelId = target?.value;
        this.phoneModelError = undefined;
    }

    getPhoneModelOptions(manufacturerId: number) {
        this.loading.set(true);
        this.phoneService.getPhoneModels(manufacturerId, this.dealType).subscribe({
            next: (phoneModels) => {
                this.models.set(phoneModels);
                this.form.modelId = phoneModels[0].slug?.toString() ?? phoneModels[0].id.toString();
                this.phoneModelError = undefined;
            },
            complete: () => {
                this.loading.set(false);
            },
        });
    }

    showResults() {
        if (!this.form.modelId || !this.form.manufacturerId) {
            if (!this.form.modelId) {
                this.phoneModelError = "Please select a phone model";
            }
            if (!this.form.manufacturerId) {
                this.manufacturerError = "Please select a manufacturer";
            }
            return;
        }

        gtag(
            "event",
            "wizard__selection",
            {
                deal_type: this.dealType,
                manufacturer_id: this.form.manufacturerId,
                phone_model_slug: this.form.modelId,
            }
        );

        switch (this.dealType) {
            case "bundle":
                return this.router.navigate([
                    this.routes.bundle.list.fullPath({ phoneModel: this.form.modelId.toString() })
                ]);
            case "phone-only":
                return this.router.navigate([
                    this.routes.simFreeDeals.list.fullPath({ phoneModel: this.form.modelId.toString() })
                ]);

            default:
                throw isNever(this.dealType);
        }
    }
}
