import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { Article } from "../models/article";

export type Tag = {
    id: number;
    name: string;
    description: string;
};

@Injectable({
    providedIn: "root",
})
export class ArticlesService {
    private baseUrl: string = environment.baseUrl;

    constructor(private http: HttpClient) {}

    getAllArticles(tags?: string[]): Observable<Article[]> {
        let parameters: HttpParams = new HttpParams();
        if (tags) {
            for (const tag of tags) {
                parameters = parameters.append("tags", tag);
            }
        }
        return this.http.get<Article[]>(`${this.baseUrl}/articles/`, {
            params: parameters,
        });
    }

    getArticle(articleId: number): Observable<Article> {
        return this.http.get<Article>(`${this.baseUrl}/articles/${articleId}`);
    }

    editArticle(articleId: number, existingArticle: Article): Observable<any> {
        return this.http.put<Article>(`${this.baseUrl}/articles/${articleId}`, existingArticle);
    }

    deleteArticle(articleId: number): Observable<void> {
        return this.http.delete<void>(`${this.baseUrl}/articles/${articleId}`);
    }

    getAllTags(): Observable<Tag[]> {
        return this.http.get<Tag[]>(`${this.baseUrl}/article-tags/`);
    }
}
