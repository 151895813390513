import { Component, HostBinding, Input } from "@angular/core";

@Component({
    selector: "app-menu-icon",
    standalone: true,
    imports: [],
    templateUrl: "./menu-icon.component.html",
    styleUrl: "./menu-icon.component.scss",
})
export class MenuIconComponent {
    @HostBinding("class") @Input("class") className?: string = "text-black";
    @Input() fill = "currentColor";
    @Input() height?: string = "100%";
    @Input() width?: string = "100%";
}
