<svg
    [attr.width]="width"
    [attr.height]="height"
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
>
    <g id="Icon/ Profile">
        <circle id="Ellipse 17" cx="10" cy="5" r="3" stroke="currentColor" stroke-width="2" />
        <path
            id="Rectangle 137"
            d="M15.0484 18H4.95161C4.14991 18 3.5 17.3501 3.5 16.5484C3.5 13.208 6.20796 10.5 9.54839 10.5H10H10.4516C13.792 10.5 16.5 13.208 16.5 16.5484C16.5 17.3501 15.8501 18 15.0484 18Z"
            stroke="currentColor"
            stroke-width="2"
            stroke-linejoin="round"
        />
    </g>
</svg>
