import { Component, OnInit, signal } from '@angular/core';
import { CommonModule, Location } from "@angular/common";
import { AbstractControl, FormBuilder, FormGroup, ReactiveFormsModule, ValidationErrors, Validators } from '@angular/forms';
import { LoadingComponent } from 'src/app/core/components/loading/loading.component';
import { NogginPhoneTileComponent } from 'src/app/noggin-phone/noggin-phone-tile/noggin-phone-tile.component';
import { Router, RouterLink } from '@angular/router';
import { routeNames } from 'src/app/app-routing.module';
import { UserService } from 'src/app/user/services/user.service';

@Component({
    selector: 'app-user-details',
    standalone: true,
    imports: [
        CommonModule,
        LoadingComponent,
        NogginPhoneTileComponent,
        ReactiveFormsModule,
        RouterLink,
    ],
    templateUrl: './user-details.component.html',
    styleUrl: './user-details.component.scss'
})
export class UserDetailsComponent implements OnInit {
    userDetailsForm!: FormGroup;
    formSubmitting = signal(false);

    constructor(
        private location: Location,
        private formBuilder: FormBuilder,
        private router: Router,
        private userService: UserService,
    ) { }

    ngOnInit(): void {
        this.userDetailsForm = this.formBuilder.group(
            {
                tel: ["", [
                    Validators.required,
                    Validators.minLength(10),
                    Validators.pattern("^[0-9]{10,11}$")
                ]],
                bday_day: ["", [
                    Validators.required,
                    Validators.minLength(1),
                    Validators.maxLength(2),
                    Validators.pattern("^([1-9]|0[1-9]|[12][0-9]|3[01])$")
                ]],
                bday_month: ["", [
                    Validators.required,
                    Validators.minLength(1),
                    Validators.maxLength(2),
                    Validators.pattern("^([1-9]|0[1-9]{1}|1[012])$")
                ]],
                bday_year: ["", [
                    Validators.required,
                    Validators.minLength(4),
                    Validators.maxLength(4),
                    Validators.pattern("^(19|20)[0-9]{2}$")
                ]],
            },
            {
                validators: [this.isValidDate],
            },
        );
    }

    submit() {
        if (this.userDetailsForm.invalid) {
            this.userDetailsForm.markAllAsTouched();
            return;
        }

        if (this.formSubmitting()) {
            return;
        }

        this.formSubmitting.set(true);

        // Ensure that month has two digits
        let m = this.userDetailsForm.get("bday_month")?.value;
        if (m.length === 1) {
            m = `0${m}`;
        }

        // Ensure that day has two digits
        let d = this.userDetailsForm.get("bday_day")?.value;
        if (d.length === 1) {
            d = `0${d}`;
        }

        const y = this.userDetailsForm.get("bday_year")?.value;
        const dob = `${y}-${m}-${d}`;

        const userDetailsValues = {
            phone_number: this.userDetailsForm.get("tel")?.value,
            date_of_birth: dob,
        };

        this.userService
            .saveUserDetails(userDetailsValues)
            .subscribe({
                next: () => {
                    this.router.navigateByUrl(routeNames.getStarted.userFinancialSelfDeclarations.fullPath());
                }
            });
    }

    async back() {
        this.location.back();
    }

    private isValidDate(group: AbstractControl): ValidationErrors | null {
        const d = Number(group.get('bday_day')?.value);
        const m = Number(group.get('bday_month')?.value);
        const y = Number(group.get('bday_year')?.value);

        const date = new Date(y, m - 1, d);
        if (isNaN(date.getTime()) || date.getDate() !== d) {
            return { invalidDate: true };
        }

        // Check if the date is in the past
        const today = new Date();
        today.setHours(0, 0, 0, 0); // Only compare date part
        return date < today ? null : { pastDate: true };
    }

    private checkFormError(fieldName: string) {
        return (
            this.userDetailsForm.get(fieldName)?.touched &&
            this.userDetailsForm.get(fieldName)?.status === 'INVALID'
        );
    }

    get errorMessageDay() {
        if (this.checkFormError("bday_day")) {
            if (this.userDetailsForm.get("bday_day")?.errors?.['required']) {
                return "Day is required.";
            }
            return "Day must be between 1 and 31.";
        }
        return;
    }

    get errorMessageMonth() {
        if (this.checkFormError("bday_month")) {
            if (this.userDetailsForm.get("bday_month")?.errors?.['required']) {
                return "Date of birth must include a month.";
            } else if (this.userDetailsForm.get("bday_month")?.errors?.['maxlength']) {
                return "Enter a month between 1 and 12.";
            }
            return "Enter a month between 1 and 12.";
        }
        return;
    }

    get errorMessageYear() {
        if (this.checkFormError("bday_year")) {
            if (this.userDetailsForm.get("bday_year")?.errors?.['required']) {
                return "Date of birth must include a year.";
            }
            return "Year must be a 4 digit number.";
        }
        return;
    }

    get errorMessageBday() {
        const allFieldsTouched = this.userDetailsForm.get("bday_day")?.touched &&
            this.userDetailsForm.get("bday_month")?.touched &&
            this.userDetailsForm.get("bday_year")?.touched;

        if (allFieldsTouched && this.userDetailsForm.errors?.['invalidDate']) {
            return "Date of birth must be a valid date.";
        }
        return;
    }

    get bdayErrorMessage() {
        return this.errorMessageDay || this.errorMessageMonth || this.errorMessageYear || this.errorMessageBday;
    }

    get telErrorMessage() {
        if (this.checkFormError("tel")) {
            if (this.userDetailsForm.get("tel")?.errors?.['required']) {
                return "Phone number is required";
            }
            return "Enter a phone number from the UK.";
        }

        return;
    }

}

