import { computed, Injectable, signal } from "@angular/core";
import { DealService } from "../services/deal.service";
import { DealMetaData, DealModel, DealPagination } from "../models/deal";
import { HttpStatus } from "../../core/models/http-status";
import { DealFilterParams } from "../models/filter";

@Injectable()
export class DealState {
    status = signal<HttpStatus>(HttpStatus.initial);

    private fetchedDealPages = signal<DealPagination[]>([]);
    private fetchedPagesLast = computed<DealPagination | undefined>(() => {
        if (this.fetchedDealPages().length > 0) {
            return this.fetchedDealPages()[this.fetchedDealPages().length - 1]
        }
        return;
    });
    private nextPageLink = computed<string | undefined>(() => this.fetchedPagesLast()?.next);

    dealCount = computed<number>(() => (this.fetchedDealPages().length > 0 ? this.fetchedDealPages()[0].count : 0));
    hasNextPage = computed<boolean>(() => Boolean(this.nextPageLink()));
    deals = computed<DealModel[]>(() => this.fetchedDealPages().map((page) => page.results).flat(1));

    metadata?: DealMetaData;

    constructor(private dealService: DealService) { }

    apiDealTypes() {
        return this.dealService.getDealTypes();
    }

    fetchDeals(filters: Partial<DealFilterParams>, onDone?: () => void) {
        this.status.set(HttpStatus.loading);
        return this.dealService.getFilteredDeals(filters).subscribe({
            next: (res) => {
                this.fetchedDealPages.set([res]);
                this.status.set(HttpStatus.success);
                if (res.metadata) this.metadata = res.metadata;
            },
            error: () => {
                this.status.set(HttpStatus.error);
            },
            complete: () => {
                if (onDone) {
                    onDone();
                }
            },
        });
    }

    fetchNextPage() {
        this.status.set(HttpStatus.loading);

        const nextPageLink = this.nextPageLink();

        if (!nextPageLink) {
            this.status.set(HttpStatus.success);
            return;
        }

        return this.dealService.getNextPage(nextPageLink).subscribe({
            next: (res) => {
                this.fetchedDealPages.update((value) => value.concat(res));
                this.status.set(HttpStatus.success);
            },
            error: () => {
                this.status.set(HttpStatus.error);
            },
        });
    }
}
