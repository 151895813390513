<app-mobile-compare-hero
    class="mt-6 md:mt-8"
></app-mobile-compare-hero>

<app-cms-carousel
    [dark]="false"
    class="bg-lime-gradient mb-20 block pt-[80px]"
></app-cms-carousel>

<home-member-benefits
    class="mb-20 mt-[117px] block"
></home-member-benefits>

<home-disclaimer-graphic
    class="block h-[480px] w-full px-5 lg:container lg:h-[700px]"
></home-disclaimer-graphic>

<app-cms-carousel
    [dark]="false"
    [carouselIndex]="1"
    class="bg-pink-gradient mt-28 block pb-[60px]"
></app-cms-carousel>

<app-cms-carousel
    [dark]="true"
    [carouselIndex]="2"
    class="block bg-black pb-20"
></app-cms-carousel>

<app-cms-carousel
    [dark]="false"
    [carouselIndex]="3"
    class="bg-lime-gradient block"
></app-cms-carousel>

<app-faq 
    [limit]="3" 
    class="mt-11 block px-5 py-16"
></app-faq>

<home-blog-section></home-blog-section>
