import { Component, Input } from "@angular/core";
import { FaIconComponent } from "@fortawesome/angular-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { RouterLink } from "@angular/router";
import { NgClass, NgForOf, NgIf } from "@angular/common";

export type Breadcrumb = {
    path?: string;
    label: string;
    queryParams?: { [key: string]: string | number | undefined };
};

@Component({
    selector: "app-breadcrumbs",
    standalone: true,
    imports: [FaIconComponent, NgForOf, RouterLink, NgIf, NgClass],
    templateUrl: "./breadcrumbs.component.html",
    styleUrl: "./breadcrumbs.component.scss",
})
export class BreadcrumbsComponent {
    @Input() breadcrumbs: Breadcrumb[] = [];
    faChevronRight = faChevronRight;
}
