import { Component, effect, inject, Input, OnInit } from "@angular/core";
import { DealFilterState } from "../../state/deal-filter.state";
import { FilterCollapseComponent } from "../filter-collapse/filter-collapse.component";
import { NgForOf, NgSwitch, NgSwitchCase } from "@angular/common";
import { ToggleButtonComponent } from "../../../core/components/toggle-button/toggle-button.component";
import { DealFilterParams, Filter, FilterOption } from "../../models/filter";

@Component({
    selector: "app-filter-input",
    standalone: true,
    imports: [FilterCollapseComponent, NgSwitch, NgSwitchCase, NgForOf, ToggleButtonComponent],
    templateUrl: "./filter-input.component.html",
    styleUrl: "./filter-input.component.scss",
})
export class FilterInputComponent {
    @Input({ required: true }) filter!: Filter;
    @Input() defaultOpen = false;
    dealFilterState = inject(DealFilterState);
    @Input() updateAutomatically = false;

    isRadioChecked(value: string) {
        return this.dealFilterState.selectedFilters[this.filter.key]?.toString() === value;
    }

    isArrayChecked(value: string) {
        return (
            this.dealFilterState.selectedFilters?.[this.filter.key] as string[] | undefined
        )?.includes(value);
    }

    toggleRadioFilter(item: FilterOption) {
        this.dealFilterState.toggleRadioFilter(this.filter.key, item.value, {
            updateAutomatically: this.updateAutomatically,
        });
        this.analytics(this.filter.key, item.value.toString(), item.label);
    }

    toggleDataAllowance(item: FilterOption) {
        this.dealFilterState.toggleDataAllowance(item, { updateAutomatically: this.updateAutomatically });
        this.analytics(this.filter.key, item.value.toString(), item.label);
    }

    toggleBooleanFilter(item: FilterOption) {
        this.dealFilterState.toggleBooleanFilter(this.filter.key, {
            updateAutomatically: this.updateAutomatically,
            removeOnFalse: this.filter.removeOnFalse
        });
        const newValue = this.dealFilterState.selectedFilters[this.filter.key]!;
        this.analytics(this.filter.key, newValue.toString(), item.label);
    }

    toggleArrayFilter(item: FilterOption) {
        this.dealFilterState.toggleArrayFilter(this.filter.key, item, {
            updateAutomatically: this.updateAutomatically
        });

        const values = this.dealFilterState.selectedFilters[this.filter.key]!;
        let value: boolean = Array.isArray(values) && values.includes(item.value.toString())

        gtag(
            "event",
            "deal_list__filter",
            {
                name: this.filter.key,
                value,
                label: item.label,
                values: values || []
            }
        )
    }

    setBooleanFilter(value: boolean) {
        this.dealFilterState.setBooleanFilter(this.filter.key, value, {
            updateAutomatically: this.updateAutomatically
        });
        this.analytics(this.filter.key, value.toString(), this.filter.title);
    }

    analytics(fieldName: string, value: string, label: string) {
        gtag(
            "event",
            "deal_list__filter",
            {
                name: fieldName,
                value,
                label
            }
        )
    }
}
