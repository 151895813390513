import { Component, HostBinding, Input } from "@angular/core";

@Component({
    selector: "app-profile-icon",
    standalone: true,
    imports: [],
    templateUrl: "./profile-icon.component.html",
    styleUrl: "./profile-icon.component.scss",
})
export class ProfileIconComponent {
    @HostBinding("class") @Input("class") className?: string = "text-black";
    @Input() height?: string = "100%";
    @Input() width?: string = "100%";
}
