<article class="mx-auto w-full">
    <div class="min-h-[195px] w-full overflow-hidden rounded-3xl border bg-off-white">
        <a class="relative flex h-full w-full flex-col" [routerLink]="dealUrl()">
            <section class="flex flex-1 items-center justify-between p-5">
                <div class="flex flex-col items-start justify-center">
                    <ul class="flex flex-1 items-center justify-start">
                        <li>
                            <figure
                                *ngIf="deal?.network_provider?.logo || deal?.deal_retailer_logo_url"
                                class="mb-2 max-w-32 overflow-hidden"
                            >
                                <img
                                    [ngSrc]="
                                        deal?.deal_retailer_logo_url ??
                                        deal?.network_provider?.logo ??
                                        ''
                                    "
                                    height="32"
                                    width="32"
                                    [alt]="deal?.network_provider?.name + ' image'"
                                    class="h-8 w-auto object-contain"
                                /> </figure
                        ></li>
                        <li>
                            <div
                                *ngIf="isVip"
                                class="bg-green-gradient mb-2 ml-1.5 rounded-full border px-4 py-1.5"
                                ><p class="text-sm font-semibold text-black">VIP DEAL</p></div
                            >
                        </li>
                        <li
                            *ngIf="
                                deal?.device_condition_friendly &&
                                deal?.device_condition_friendly !== 'New'
                            "
                            class="mb-2 ml-1.5 flex h-8 w-[101px] items-center justify-center rounded-full border border-black bg-off-white"
                            ><p class="text-center text-sm font-semibold uppercase text-black">{{
                                deal?.device_condition_friendly
                            }}</p>
                        </li>
                        <li
                            *ngIf="isOnCredit"
                            class="bg-pink-red-gradient mb-2 ml-1.5 flex h-8 w-[101px] items-center justify-center rounded-full border border-black text-center text-sm font-semibold uppercase text-white"
                            >on credit
                        </li>
                    </ul>
                    <app-shimmer-loader
                        loadingClass="w-full h-5"
                        class="mb-0.5"
                        [loading]="loading"
                    >
                        <h3 class="text-2xl font-semibold">{{ deal?.deal_name }}</h3>
                    </app-shimmer-loader>
                    <app-shimmer-loader class="" [loading]="loading">
                        <p *ngIf="bundle">
                            {{ deal?.network_provider?.name }}
                        </p>
                        <p *ngIf="dealType === DealType.phoneOnly">SIM free</p>
                    </app-shimmer-loader>
                </div>

                <app-squiggly-background
                    class="absolute right-5 top-1/2 block w-[402px] -translate-y-1/2"
                >
                    <div
                        class="mx-7 flex h-40 items-center justify-center divide-x divide-dashed divide-off-white bg-black px-5"
                    >
                        <app-shimmer-loader loadingClass="w-full h-16" [loading]="loading">
                            <h3 class="text-3.5xl font-medium text-off-white"
                                >{{
                                    deal?.sim_unlimited_data
                                        ? "Unlimited"
                                        : deal?.sim_data_allowance_gb_numeric ?? "Unlimited"
                                }}<span
                                    *ngIf="deal?.sim_data_allowance_gb_numeric"
                                    class="text-xl font-medium"
                                    >GB</span
                                >
                            </h3>
                            <p class="text-md text-off-white">data allowance</p>
                        </app-shimmer-loader>

                        <app-shimmer-loader
                            loadingClass="w-full h-16"
                            class="ml-5 block pl-5"
                            [loading]="loading"
                        >
                            <h3 class="text-3.5xl font-medium text-off-white">
                                <ng-container
                                    >£{{
                                        deal?.sim_monthly_cost_inc_vat?.toString()?.split(".")?.[0]
                                    }}.</ng-container
                                ><span class="text-xl font-medium">{{
                                    parseFloat(deal?.sim_monthly_cost_inc_vat?.toString() ?? "0")
                                        .toFixed(2)
                                        .split(".")?.[1] ?? "00"
                                }}</span>
                            </h3>
                            <p class="text-md text-off-white">per month</p>
                        </app-shimmer-loader>
                    </div>
                </app-squiggly-background>
            </section>
            <footer>
                <section [ngClass]="[isVip ? 'bg-green-gradient ' : 'bg-pink-100']">
                    <div class="flex max-w-[50%] justify-between p-5">
                        <div>
                            <p class="mb-0.5 text-sm uppercase">credit check?</p>
                            <p class="font-semibold underline">
                                {{ hasCreditCheckContent }}
                            </p>
                        </div>
                        <div>
                            <p class="mb-0.5 text-sm uppercase">connectivity</p>
                            <p class="font-semibold">{{ deal?.deal_connectivity }}</p>
                        </div>
                        <div>
                            <p class="mb-0.5 text-sm uppercase">contract length</p>
                            <p class="font-semibold">
                                {{ deal?.sim_contract_length_friendly }}
                            </p>
                        </div>
                    </div>
                </section>
            </footer>
        </a>
        <section class="bg-black px-6 py-2.5 text-md text-off-white" *ngIf="deal?.deal_price_rises">
            <p class="flex items-center"
                ><fa-icon [icon]="faInfoCircle" class="mr-2"></fa-icon
                >{{ deal?.deal_price_rises }}</p
            >
        </section>
    </div>
    <section
        class="bg-green-gradient mt-2.5 h-16 w-full rounded-3xl border border-black"
        *ngIf="isVip"
    >
        <a
            href="{{ deal?.members_benefit_link }}"
            class="flex h-full w-full items-center justify-center px-5 py-4"
        >
            <span class="font-bold">+</span>
            <p class="ml-2 flex-1">{{ deal?.members_benefit_text }}</p>
            <span class="h-9 w-9" *ngIf="deal?.members_benefit_image"
                ><img
                    alt="Member benefit icon image"
                    height="34"
                    width="34"
                    [ngSrc]="deal?.members_benefit_image ?? ''"
            /></span>
        </a>
    </section>
</article>
