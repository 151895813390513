import { Component, Input, OnInit } from "@angular/core";
import { CommonModule, NgOptimizedImage } from "@angular/common";
import { DateTime } from "luxon";
import { RouterLink } from "@angular/router";
import { Article } from "../../models/article";
import { routeNames } from "../../../app-routing.module";

@Component({
    selector: "home-blog-card",
    standalone: true,
    imports: [CommonModule, NgOptimizedImage, RouterLink],
    templateUrl: "./blog-card.component.html",
    styleUrls: ["./blog-card.component.scss"],
})
export class BlogCardComponent implements OnInit {
    date = "";
    @Input({ required: true }) article!: Article;
    ngOnInit(): void {
        this.date = DateTime.fromISO(this.article.created_at).toFormat("dd LLL yyyy");
    }

    protected readonly routeNames = routeNames;
}
