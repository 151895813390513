import { Component, inject, Input, OnInit } from "@angular/core";
import { DealFilterState } from "../../state/deal-filter.state";
import { DealType } from "../../models/filter";
import { CommonModule, NgIf } from "@angular/common";
import { isNever } from "src/app/util/exhaustiveCheck";
import { ActivatedRoute } from "@angular/router";

type CreditToggleStates = 'noCreditCheck' | 'allDeals' | 'payMonthly' | 'creditCheck';

@Component({
    selector: "app-credit-toggle",
    standalone: true,
    imports: [
        NgIf,
        CommonModule
    ],
    templateUrl: "./credit-toggle.component.html",
    styleUrl: "./credit-toggle.component.scss",
})
export class CreditToggleComponent implements OnInit {
    dealFilterState = inject(DealFilterState);
    @Input({ required: true }) dealType!: DealType;
    protected readonly DealType = DealType;
    activeState: CreditToggleStates;

    constructor(
        private route: ActivatedRoute,
        private dealState: DealFilterState
    ) {
        this.activeState = this.checkActiveState();
    }

    ngOnInit(): void {
        this.route.url.subscribe(() => {
            this.setFilters('allDeals');
        });

        this.route.params.subscribe(_ => {
            this.activeState = this.checkActiveState();
        });
    }


    private checkActiveState(): CreditToggleStates {
        const deal_credit_check = this.dealState.selectedFilters.deal_credit_check;
        const device_pay_monthly = this.dealState.selectedFilters.device_pay_monthly;

        if (typeof deal_credit_check !== "undefined") {
            return deal_credit_check ? "creditCheck" : "noCreditCheck";
        } else if (typeof device_pay_monthly !== "undefined" && device_pay_monthly) {
            return "payMonthly";
        } else {
            return "allDeals";
        }
    }

    private setFilters(state: CreditToggleStates) {
        switch (state) {
            case "noCreditCheck":
                this.dealFilterState.setBooleanFilter("deal_credit_check", false, { updateAutomatically: false, removeOnFalse: false });
                this.dealFilterState.removeFilterByKey("device_pay_monthly", { updateAutomatically: false });
                this.dealFilterState.removeFilterByKey("device_payment_type", { updateAutomatically: false });
                break;

            case "creditCheck":
                this.dealFilterState.setBooleanFilter("deal_credit_check", true, { updateAutomatically: false, removeOnFalse: false });
                this.dealFilterState.removeFilterByKey("device_pay_monthly", { updateAutomatically: false });
                this.dealFilterState.removeFilterByKey("device_payment_type", { updateAutomatically: false });
                break;

            case "allDeals":
                this.dealFilterState.removeFilterByKey("deal_credit_check", { updateAutomatically: false });
                this.dealFilterState.removeFilterByKey("device_pay_monthly", { updateAutomatically: false });
                this.dealFilterState.removeFilterByKey("device_payment_type", { updateAutomatically: false });
                break;

            case "payMonthly":
                this.dealFilterState.removeFilterByKey('deal_credit_check', { updateAutomatically: false });
                this.dealFilterState.setBooleanFilter('device_pay_monthly', true, { updateAutomatically: false, removeOnFalse: true });
                this.dealFilterState.removeFilterByKey("device_payment_type", { updateAutomatically: false });
                break;

            default:
                throw isNever(state);
        }
    }

    setActiveState(state: CreditToggleStates) {
        this.setFilters(state);
        this.dealFilterState.applyFilters();
        this.activeState = state;

        gtag(
            "event",
            "deal_list__filter__credit_toggle",
            {
                value: state
            }
        )
    }

}
