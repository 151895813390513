import { Component, Input, signal } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import { EyeOpenComponent } from "../../../core/components/eye-open/eye-open.component";
import { EyeCloseComponent } from "../../../core/components/eye-close/eye-close.component";

@Component({
    selector: "app-password-field",
    standalone: true,
    imports: [CommonModule, ReactiveFormsModule, EyeOpenComponent, EyeCloseComponent],
    templateUrl: "./password-field.component.html",
    styleUrls: ["./password-field.component.scss"],
})
export class PasswordFieldComponent {
    @Input() autocomplete = "";
    @Input({ required: true }) control!: FormControl;
    @Input() isreadonly: boolean = false;
    @Input() label = "Password";
    @Input() marginTop: `mt-${number}` = "mt-8";
    passwordVisible = signal(false);

    togglePasswordVisibility(): void {
        this.passwordVisible.update((v) => !v);
    }
}
