import { Component, Input } from "@angular/core";
import { animate, style, transition, trigger } from "@angular/animations";
import { FaIconComponent } from "@fortawesome/angular-fontawesome";
import { NgClass, NgIf, NgTemplateOutlet } from "@angular/common";

function createAlertAnimation(direction: "top" | "bottom") {
    const leaveTransform = direction === "top" ? "translateY(-100%)" : "translateY(100%)";
    return trigger(`alertAnimation_${direction}`, [
        transition(
            ":enter",
            [
                style({
                    opacity: 0,
                    transform: leaveTransform,
                }),
                animate("300ms ease-in", style({ opacity: 1, transform: "translateY(0)" })),
            ],
            {
                params: { hiddenValue: "" },
            },
        ),
        transition(":leave", [
            style({ opacity: 1, transform: "translateY(0)" }),
            animate(
                "300ms ease-out",
                style({
                    opacity: 0,
                    transform: leaveTransform,
                }),
            ),
        ]),
    ]);
}

@Component({
    selector: "app-alert",
    standalone: true,
    imports: [FaIconComponent, NgIf, NgClass, NgTemplateOutlet],
    templateUrl: "./alert.component.html",
    styleUrl: "./alert.component.scss",

    animations: [createAlertAnimation("top"), createAlertAnimation("bottom")],
})
export class AlertComponent {
    @Input() direction: "top" | "bottom" = "top";
    @Input({ required: true }) open!: boolean;
}
