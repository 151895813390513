<footer class="bg-lime-gradient overflow-hidden pb-10 pt-14">
    <div class="container mx-auto">
        <div class="flex flex-col lg:flex-row">
            <div class="flex-1">
                <div class="flex justify-start">
                    <app-logo-full height="28px"></app-logo-full>
                </div>
                <p class="my-5">
                    Join our newsletter to stay updated on new features and releases. By
                    subscribing, you agree to our Privacy Policy and consent to receive updates from
                    our company.
                </p>

                <div id="mc_embed_shell">
                    <form
                        action="https://nogginhq.us20.list-manage.com/subscribe/post?u=d7f9ce03dc8e6f52559312278&amp;id=5479e03090&amp;f_id=007014eaf0"
                        method="post"
                        id="mc-embedded-subscribe-form"
                        name="mc-embedded-subscribe-form"
                        onsubmit="this.submit(); this.reset(); return false;"
                        class="flex flex-wrap gap-3"
                        target="_blank"
                    >
                        <input
                            type="email"
                            name="EMAIL"
                            class="input flex-1 rounded-xl border border-black"
                            id="mce-EMAIL"
                            required=""
                            value=""
                        />
                        <div id="mce-responses" class="clear">
                            <div
                                class="response"
                                id="mce-error-response"
                                style="display: none"
                            ></div>
                            <div
                                class="response"
                                id="mce-success-response"
                                style="display: none"
                            ></div>
                        </div>
                        <div aria-hidden="true" style="position: absolute; left: -5000px"
                            ><input
                                type="text"
                                name="b_d7f9ce03dc8e6f52559312278_5479e03090"
                                tabindex="-1"
                                value=""
                            />
                        </div>
                        <div class="clear">
                            <input
                                type="submit"
                                name="subscribe"
                                id="mc-embedded-subscribe"
                                class="btn btn-outline w-[120px] rounded-[200px] bg-off-white"
                                value="Subscribe"
                            />
                        </div>
                    </form>
                    <!--
                    <input class="input flex-1 rounded-xl border border-black" type="text" />
                    <button class="btn btn-outline w-[120px] rounded-[200px] bg-off-white">
                        JOIN NOW
                    </button>
                    -->
                </div>
            </div>
            <div class="flex flex-1 flex-col lg:flex-row lg:justify-evenly">
                <div class="mt-12 flex flex-col gap-4">
                    <h3 class="font-semibold">Browse 👀</h3>
                    <footer-link [routerLink]="routeNames.home.fullPath()">Home</footer-link>
                    <footer-link [routerLink]="routeNames.simOnlyDeals.list.fullPath()">
                        SIMs
                    </footer-link>
                    <footer-link [routerLink]="routeNames.simFreePhoneSelect.fullPath()">
                        Phones
                    </footer-link>
                    <footer-link [routerLink]="routeNames.bundle.selectPhone.fullPath()">
                        Phones + SIMS
                    </footer-link>
                    <footer-link [routerLink]="routeNames.articles.list.fullPath()">
                        Blog
                    </footer-link>

                    <footer-link [routerLink]="routeNames.ourResearch.fullPath()">
                        About Us
                    </footer-link>
                </div>

                <div class="mt-12 flex flex-col gap-4">
                    <h3 class="font-semibold">The other stuff</h3>
                    <footer-link [routerLink]="routeNames.privacy.fullPath()" class=""
                        >Privacy Policy</footer-link
                    >
                    <footer-link [routerLink]="routeNames.terms.fullPath()"
                        >Terms of Service</footer-link
                    >
                    <footer-link [routerLink]="routeNames.cookies.fullPath()"
                        >Cookie Policy</footer-link
                    >
                    <footer-link [routerLink]="routeNames.complaints.fullPath()"
                        >Complaints</footer-link
                    >
                    <footer-link [routerLink]="routeNames.contact.fullPath()"
                        >Contact Us</footer-link
                    >
                    <footer-link [routerLink]="routeNames.faq.fullPath()"> FAQs </footer-link>
                </div>

                <div class="mt-12 flex flex-col gap-4">
                    <h3 class="font-semibold">Follow us</h3>
                    <footer-link
                        [icon]="faInstagram"
                        [href]="footer.Instagram"
                        analyticsName="Instagram"
                    >
                        Instagram
                    </footer-link >
                    <footer-link
                        [icon]="faLinkedin"
                        [href]="footer.LinkedIn"
                    >
                        LinkedIn
                    </footer-link
                    >
                    <footer-link
                        [icon]="faTiktok"
                        [href]="footer.TikTok"
                    >
                        TikTok
                    </footer-link >
                </div>
            </div>
        </div>
        <hr class="mb-[33px] mt-12" />
        <div class="flex flex-col-reverse items-start justify-between md:flex-row md:items-end">
            <div>
                <p class="mt-14">© 2024 Noggin HQ Ltd. All rights reserved.</p>
            </div>
            <div>
                <p class="mt-4 max-w-[350px]">
                    Noggin HQ Ltd. is authorised by the Financial Conduct Authority. Ref no.
                    993735
                </p>
            </div>
        </div>
    </div>
</footer>
