import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { Observable } from "rxjs";
import { UserAddressModel, UserFinancialSelfDeclarationsModel } from "../models/user";

export interface ProductApplicationSession {
    user_address: UserAddressModel;
    deal_id: string;
    contract_terms_section: string;
}

@Injectable({
    providedIn: "root",
})
export class ProductApplicationService {
    private baseUrl: string = environment.baseUrl;

    constructor(private http: HttpClient) { }

    getSession(): Observable<ProductApplicationSession> {
        return this.http.get<ProductApplicationSession>(`${this.baseUrl}/product-applications/session/`);
    }

    selectDeal(data: { deal_id: string }): Observable<Partial<ProductApplicationSession>> {
        return this.http.post(`${this.baseUrl}/product-applications/session/deal/`, data);
    }

    saveUserFinancialSelfDeclarations(data: UserFinancialSelfDeclarationsModel): Observable<{ id: string }> {
        return this.http.post<{ id: string }>(`${this.baseUrl}/product-applications/session/financial-self-declarations/`, data);
    }

    agreeToTerms(data: { agreed_to_terms: boolean, agreed_to_digital_signature: boolean }): Observable<Partial<ProductApplicationSession>> {
        return this.http.post(`${this.baseUrl}/product-applications/`, data);
    }

}
