import { Injectable, NgModule } from "@angular/core";
import {
    BrowserModule,
    HAMMER_GESTURE_CONFIG,
    HammerGestureConfig,
    HammerModule,
} from "@angular/platform-browser";
import { BrowserAnimationsModule, provideAnimations } from "@angular/platform-browser/animations";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { OverlayModule } from "@angular/cdk/overlay";
import { PortalModule } from "@angular/cdk/portal";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HTTP_INTERCEPTORS, HttpClientJsonpModule, HttpClientModule } from "@angular/common/http";
import { CommonModule, LocationStrategy, NgOptimizedImage } from "@angular/common";
import { NgxsModule } from "@ngxs/store";
import { GoogleMapsModule } from "@angular/google-maps";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatButtonModule } from "@angular/material/button";
import { MatInputModule } from "@angular/material/input";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatSelectModule } from "@angular/material/select";
import { MatTabsModule } from "@angular/material/tabs";
import * as Hammer from "hammerjs";
import { MatIconModule } from "@angular/material/icon";
import { RouterModule } from "@angular/router";
import { NgxPlaidLinkModule } from "ngx-plaid-link";
import { MatBadgeModule } from "@angular/material/badge";
import { MatMenuModule } from "@angular/material/menu";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatTooltipModule } from "@angular/material/tooltip";

import { MatTableModule } from "@angular/material/table";
import { MatSortModule } from "@angular/material/sort";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { NgPipesModule } from "ngx-pipes";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { SignUpComponent } from "./user/pages/sign-up/sign-up.component";
import { ArticleViewComponent } from "./blog/pages/article-view/article-view.component";
import { ContactComponent } from "./contact/pages/contact/contact.component";
import { ShareBankingDataComponent } from "./user/components/share-banking-data/share-banking-data.component";
import { AccountComponent } from "./user/pages/account/account.component";
import { WebsiteCmsPageWrapperComponent } from "./core/components/website-cms-page-wrapper/website-cms-page-wrapper.component";
import { NotFoundComponent } from "./core/pages/not-found/not-found.component";
import { MainScaffoldComponent } from "./core/components/main-scaffold/main-scaffold.component";
import { LoginComponent } from "./user/pages/login/login.component";
import { VipOfferComponent } from "./user/pages/vip-offer/vip-offer.component";
import { ForgottenPasswordComponent } from "./user/pages/forgotten-password/forgotten-password.component";
import { EditMembershipComponent } from "./user/pages/edit-membership/edit-membership.component";
import { EditAccountComponent } from "./user/pages/edit-account/edit-account.component";
import { ArticleListComponent } from "./blog/pages/article-list/article-list.component";
import { LogoFullComponent } from "./core/components/logo-full/logo-full.component";
import { NavbarComponent } from "./core/components/navbar/navbar.component";
import { LogoComponent } from "./core/components/logo/logo.component";
import { UserState } from "./user/state/user/user.state";
import { MobileCompareComponent } from "./core/pages/mobile-compare/mobile-compare.component";
import { FcaLogoComponent } from "./core/components/fca-logo/fca-logo.component";
import { FooterComponent } from "./core/components/footer/footer.component";
import { EyeCloseComponent } from "./core/components/eye-close/eye-close.component";
import { EyeOpenComponent } from "./core/components/eye-open/eye-open.component";
import { VipCardComponent } from "./user/components/vip-card/vip-card.component";
import { PasswordFieldComponent } from "./user/components/password-field/password-field.component";
import { AccountHeaderComponent } from "./user/components/account-header/account-header.component";
import { BankingConnectComponent } from "./user/components/banking-connect/banking-connect.component";
import { MembershipStatusComponent } from "./user/components/membership-status/membership-status.component";
import { MobileSelectionComponent } from "./deal/pages/mobile-selection/mobile-selection.component";
import { DrawerComponent } from "./core/components/drawer/drawer.component";
import { CarouselComponent } from "./core/components/carousel/carousel.component";
import { MobileCompareHeroComponent } from "./core/components/mobile-compare-hero/mobile-compare-hero.component";
import { FaqComponent } from "./core/components/faq/faq.component";
import { BlogCardComponent } from "./blog/components/blog-card/blog-card.component";
import { AuthorizationHeaderInterceptor } from "./core/interceptors/authorization-header-interceptor.service";
import { AuthGuard } from "./user/guards/auth.guard";
import { MemberBenefitsComponent } from "./user/components/member-benefits/member-benefits.component";
import { SimDetailsComponent } from "./deal/components/sim-details/sim-details.component";
import { ContactCallUsComponent } from "./contact/components/contact-call-us/contact-call-us.component";
import { SuccessComponent } from "./contact/components/success/success.component";
import { AlertComponent } from "./core/components/alert/alert.component";
import { NotifyComponent } from "./core/components/notify/notify.component";
import { ShimmerLoaderComponent } from "./core/components/shimmer-loader/shimmer-loader.component";
import { SelectiveQueryParamLocationStrategy } from "./router";

@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
    override overrides = {
        swipe: { direction: Hammer.DIRECTION_ALL },
    };
}

@NgModule({
    declarations: [
        AppComponent,
        SignUpComponent,
        ContactComponent,
        ShareBankingDataComponent,
        WebsiteCmsPageWrapperComponent,
        NotFoundComponent,
        MainScaffoldComponent,
        LoginComponent,
        ForgottenPasswordComponent,
        EditMembershipComponent,
        EditAccountComponent,
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthorizationHeaderInterceptor,
            multi: true,
        },
        AuthGuard,
        provideAnimations(),
        { provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerConfig },
        { provide: LocationStrategy, useClass: SelectiveQueryParamLocationStrategy }
    ],
    bootstrap: [AppComponent],
    exports: [FooterComponent],
    imports: [
        AccountComponent,
        VipOfferComponent,
        ArticleViewComponent,
        ArticleListComponent,
        LogoFullComponent,
        NavbarComponent,
        LogoComponent,
        CommonModule,
        HttpClientJsonpModule,
        GoogleMapsModule,
        HttpClientModule,
        SimDetailsComponent,
        BrowserModule,
        AppRoutingModule,
        RouterModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        OverlayModule,
        PortalModule,
        NgxsModule.forRoot([UserState]),
        MatDialogModule,
        MatFormFieldModule,
        MatButtonModule,
        MatInputModule,
        MatSnackBarModule,
        MatSelectModule,
        MatTabsModule,
        FormsModule,
        HammerModule,
        MatIconModule,
        NgxPlaidLinkModule,
        MatMenuModule,
        MatBadgeModule,
        MatProgressSpinnerModule,
        MobileCompareComponent,
        MatTooltipModule,
        MatTableModule,
        MatSortModule,
        MatProgressBarModule,
        NgPipesModule,
        MatSlideToggleModule,
        NgOptimizedImage,
        FcaLogoComponent,
        FontAwesomeModule,
        FooterComponent,
        EyeCloseComponent,
        EyeOpenComponent,
        VipCardComponent,
        PasswordFieldComponent,
        AccountHeaderComponent,
        BankingConnectComponent,
        MembershipStatusComponent,
        MobileSelectionComponent,
        DrawerComponent,
        CarouselComponent,
        MobileCompareHeroComponent,
        MemberBenefitsComponent,
        FaqComponent,
        BlogCardComponent,
        ContactCallUsComponent,
        SuccessComponent,
        AlertComponent,
        NotifyComponent,
        ShimmerLoaderComponent,
    ],
})
export class AppModule {}
