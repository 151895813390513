import { Component, HostBinding, Input, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NgxPlaidLinkModule } from "ngx-plaid-link";
import { BankingState } from "../../state/banking.state";
import { LoadingComponent } from "../../../core/components/loading/loading.component";

@Component({
    selector: "app-banking-connect",
    standalone: true,
    imports: [CommonModule, NgxPlaidLinkModule, LoadingComponent],
    templateUrl: "./banking-connect.component.html",
    styleUrls: ["./banking-connect.component.scss"],
})
export class BankingConnectComponent implements OnInit {
    @Input("class") @HostBinding("class") className = "";
    @Input() connectText = "Connect my banking data";
    @Input() connectBtnClass = "btn btn-outline btn-primary mt-8 w-full rounded-full uppercase";
    @Input() disconnectBtnClass = "btn btn-outline btn-error w-full rounded-full uppercase";

    constructor(public bankingState: BankingState) { }

    async ngOnInit() {
        await this.bankingState.init();
    }
}
