<div class="flex flex-col w-full -mx-5 -mt-5">
    <div
        class="h-3 bg-lime-green w-6/12 rounded-r-full"
    ></div>
</div>

<div class="account-container mt-6">
    <div class="flex justify-center">
        <p class="mx-auto uppercase text-sm font-light">Step 2 of 3</p>
    </div>

    <section class="mt-4 mb-8">
        <h1 class="text-3xl font-semibold">Share your digital bank statement</h1>
        <div class="mt-4">
            Connect to your banking data via our partner, Plaid, who is authorised by the Financial Conduct Authority: no. 804718.
        </div>

        <ul class="mt-8">
            <li *ngFor="let item of bulletPoints" class="mb-7 border-b pb-5">
                <ng-container *ngIf="{ open: openItems.includes(item.header) } as locals">
                    <h2>
                        <button
                            class="flex w-full items-center text-left text-xl font-semibold"
                            (click)="this.toggle(item.header)"
                            aria-controls="{`accordion-text-01`}"
                        >
                            <!-- Arrow --------------------------------------- -->
                            <svg
                                [ngClass]="{ '!rotate-180': locals.open }"
                                class="fill-indigo-500 mr-4 md:mr-2 shrink-0 origin-center transform transition duration-800 ease-out"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <g id="keyboard_arrow_down" clip-path="url(#clip0_510_21161)">
                                    <path
                                        id="Vector"
                                        d="M7.41 8.59003L12 13.17L16.59 8.59003L18 10L12 16L6 10L7.41 8.59003Z"
                                        fill="black"
                                    />
                                </g>
                                <defs>
                                    <clipPath id="clip0_510_21161">
                                        <rect width="24" height="24" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                            <!-- Arrow --------------------------------------- -->

                            <span>{{ item.header }}</span>
                        </button>
                    </h2>
                    <div
                        id="{`accordion-text-01`}"
                        role="region"
                        aria-labelledby="{`accordion-title-01`}"
                        [ngClass]="
                            locals.open
                                ? 'grid-rows-[1fr] pt-4 opacity-100'
                                : 'grid-rows-[0fr] opacity-0'
                        "
                        class="grid overflow-hidden text-black text-opacity-60 transition-all duration-300 ease-in-out"
                    >
                        <div
                            class="overflow-hidden text-black text-opacity-60"
                            [innerHtml]="item.text"
                        ></div>
                    </div>
                </ng-container>
            </li>
        </ul>

        <div class="mt-4">
            <app-banking-connect
                *ngIf="!bankingState.connected()"
            ></app-banking-connect>
        </div>

    </section>
</div>

<section class="flex my-8 mx-auto max-w-lg">
    <app-noggin-phone-tile
        caption="Why are we doing this?"
        heading="5.8 million people in the UK are excluded from credit!"
        text="We are dedicated to bringing a solution for people unfairly locked out of the current system."
    ></app-noggin-phone-tile>
</section>
